import React, { useContext, useEffect } from 'react';
import { useStyles } from './styles';
import SmallCard from '../SmallCard/SmallCard';
import { TournamentContext } from '../../context/tournamentContext';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';


const MarkerInfo = ({ tournaments }) => {
    const classes = useStyles();
    const history = useHistory();
    const { changeTournament } = useContext(TournamentContext);

    const handleClick = (props) => {
        changeTournament(props)
        history.push(`/home/tournament/${props.slug}`)
    }

    return (
        <div className={classes.container}>
               {
                tournaments.map((item , index) => (
                    <div key={index} className={classes.info} onClick={() =>handleClick(item)}>
                        <SmallCard currency={item.currency}  key={index} game={item.games[0]} date={`${format(new Date(item.started_at) , 'MMM do')} - ${format(new Date(item.ended_at) , 'MMM do')}`} price={item.prize_pool} image={item.banner}/>
                    </div>
                ))
            }
        </div>
    )
}

export default MarkerInfo;
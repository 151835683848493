import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    box:{
        flex: "1 1 0",
        width:"30%",
        borderRadius: "8px",
        border: "1px solid #172A44",
        background: "rgba(23, 42, 68, 0.1);",
        padding: theme.spacing(2),
        display: "flex",
        color:"white",
        justifyContent:"space-between",
        [theme.breakpoints.down("sm")] :{
            minWidth:"300px"
        }
    },
    tab_label:{
        minWidth:"120px"
    },
    box_section:{
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"column"
    },
    container:{
        padding:theme.spacing(4),
        color:"#fff",
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(1),
        }
    },
    header:{
        display:"flex",
        justifyContent:"space-between",
    },
    name_section:{
        display:"flex",
        flexDirection:"column",
        "& h2":{
            fontSize:"26px",
            margin:theme.spacing(1, 0)
        }
    },
    image_section:{
        display:"flex",
        alignItems:"center",
        gap:"8px"
    },
    button_section:{
        display:"flex",
        alignItems:"flex-end",
        gap:theme.spacing(1),
    },
    trophy_section:{
        display:"flex",
        gap:"12px",
        margin:theme.spacing(4 , 0),
        flexWrap:"wrap",
        [theme.breakpoints.down("sm")]:{
            flexDirection:"no-wrap"
        }
    },
    followers:{
        display:"flex",
        flexWrap:"wrap",
        gap:theme.spacing(1),
        marginBottom: theme.spacing(6)
    },
    gameplayed_box:{
        flex: "1 1 0",
        borderRadius: "8px",
        backgroundColor: "#0F162D",
        padding: theme.spacing(2),
        display: "flex",
        color:"white",
    },
    gameplayed_section:{
        marginTop:theme.spacing(3),
        display:"flex",
        flexDirection:"column",
        gap:"12px",
    },
    img:{
        minWidth:"160px",
        height:"130px",  
        backgroundSize:"cover",
        borderRadius:"8px",
        backgroundPosition:"center"
    },
    avatar:{
        minWidth:"80px",
        height:"80px",
        backgroundColor:"#232e46"

    },
    right:{
        paddingLeft: theme.spacing(2),
        textAlign:"justify",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]:{
            fontSize:"12px",
        }
    },
    info:{
    },
}));

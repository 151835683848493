import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from "components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from 'helpers/client';
import { AppContext } from 'context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import { t } from 'i18next';



const CreateLocation = ({  handleClose , edit, refetch}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);
    const handleSubmit = (data) => {
        isEmpty(edit) ? createLocation(data) : updateLocation(data)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        fee: Yup.number().required("Fee is Required"),
    });

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            fee: edit.fee ?? "",
        },

        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    


      const createLocation = async (formData) => {
        setLoading(true)

        await axiosInstance.post("api/locations/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
           setFeedback({message:response.data.message , severity:"error"})

        })
        .finally(() => {
            setLoading(false)
            handleClose()
        })
    }


    const updateLocation = async (formData) => {
        setLoading(true)

 
        await axiosInstance.put(`api/locations/update/${edit.id}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
            <>
                <h2>{isEmpty(edit) ? t("Create Location") : t("Update Location")}</h2>

                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    gap:"12px",
                }}>
                    
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.name}  placeholder={t("name")} variant="outlined" name="name" fullWidth />
                            {formik.touched.name  &&  formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                    </div>
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.fee} type="number" placeholder={t("Fee")} variant="outlined" name="fee" fullWidth />
                            {formik.touched.fee  &&  formik.errors.fee ? (
                                    <span className={classes.content__form_error}>{formik.errors.fee}</span>
                                ) : null}
                    </div>
                    <div style={{display:"flex" , justifyContent:"flex-end"}}>
                        <Button loading={loading} name={isEmpty(edit) ? t("Create Location") : t("Save") }  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                    </div>
                </div>
            </> 
        )
    }



export default CreateLocation
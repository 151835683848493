import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
   
    modal_container: {
      backgroundColor: "#0F2139",
      borderRadius:"8px",
      minWidth:"400px",
      padding: theme.spacing(3, 2),
      boxSizing:"border-box",
      color:"#fff",
      "& h2":{
          fontSize:"26px",
          marginBottom: "8px",
          marginTop: "0px"
      },
      "&:focus-visible": {
        outline: 'none'
    },
      [theme.breakpoints.down('sm')]:{
        minWidth:"320px",
      }
      
  },
 
}));

import React from 'react';
import { Button } from "@mui/material";
import { FaUser, FaCrown, FaRegUser } from 'react-icons/fa';
import { useStyles } from './styles';

const BadgeCard = ({name, onClick}) => {

  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        background: "linear-gradient(45deg, #3361ff 30%, #7040b7 90%)",
        borderRadius: "8px",
        border: 0,
        marginLeft: "12px",
        color: "white",
        height: "42px",
        padding: "0 30px",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
        },
      }}
    >
      <FaCrown className={classes.icon} /> <span className={classes.title}>{name}</span>
    </Button>
  );
}

export default BadgeCard;
import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
},  
textfield:{
    width:"45%",
},
bannerSection:{
  padding: theme.spacing(1 , 0),
  display:"flex",
  flexWrap:"wrap",
  gap:theme.spacing(1)
},
bannerItem:{
  height:"200px",
  width:"160px",
  borderRadius:theme.spacing(1),
  border:"1px solid #5A79A5",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  backgroundSize:"cover",
  backgroundPosition:"center"
},
input_section:{
  display:"flex",
  flexWrap:"wrap",
  gap:theme.spacing(1)
},
content__form_error:{
  fontSize:"12px",
  color:"red",
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  }
},                                              
}));

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getTournamentForEvent } from '../../api/tournaments';
import MarkerInfo from '../MarkerInfo/MarkerInfo';
import { useStyles } from './styles';

const MapMarker = ({image , id, place , show , tournament = false, slug}) => {
    const classes = useStyles();
    const history = useHistory();
    const [tournaments, setTournaments] = useState([])

    const {data, isLoading} = useQuery(
        ['tournamentByEventId', id], () =>  getTournamentForEvent(id), {enabled: !tournament}
    );

    const handleClick = () => {
        if(tournament) history.push(`/home/tournament/${slug}`);
    }

    useEffect(() => {
        // if(!isLoading) setTournaments(data);
    }, []);

    return (
        <div style={{display:"flex" , flexDirection:"row" , width:"fit-content"}} 
        onClick={() => handleClick()}>
            <div className={classes.container} style={{ backgroundImage:`url(${image})` }}>
                {!tournament && <div className={classes.notify} >{tournaments.length}</div>}
                {show && <MarkerInfo place={place} tournaments={tournaments}/>}
            </div>
        </div>
    )
}

export default MapMarker
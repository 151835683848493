import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
},  
options:{
  display:"flex",
  justifyContent:"flex-end"
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  }
},                                              
}));

import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { CircularProgress, TextField } from '@material-ui/core';
import Button from '../../components/Button/Button';
import { Link, useHistory } from 'react-router-dom';
import  { Formik }from 'formik'
import * as Yup from 'yup';
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import PasswordField from '../../components/PasswordField/PasswordField';
import Modal from '../../components/Modal/Modal';
import OtpInput from 'react-otp-input';
import { debounce } from 'lodash';
import axios from 'axios';
import Logo from '../../asset/img/google-icon.png'
import Discord from '../../asset/img/discord-icon.png'
import Facebook from '../../asset/img/facebook.png'
import Twitch from '../../asset/img/twitch-icon.png'
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import logo from '../../asset/img/logo.png'


export const handleSocial = async (provider) => {
    localStorage.clear();
    localStorage.setItem('provider', provider);
    await axiosInstance.post("api/social/login" ,{
        provider
    })
    .then(function ({data}) {
        window.location.href = data.redirect_url
    })
}

const Login = () => {

    const classes = useStyles();
    const history =  useHistory()
    const { t } = useTranslation();
    const [loginError, setLoginError] = useState("")
    const [otpError, setOtpError] = useState("")
    const {setLogin, setUser, setFeedback} = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false)
    const [secret, setSecret] = useState("");
    const [token, setToken] = useState("");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("code");

    const handleSubmit = async ({ email, password }) => {
        // setLoginError("");
        setLoading(true);
        await axiosInstance.post("api/login", {
            email,
            password
        }).then(function (res) {
            if(res.data && res.data.status === "success"){
                const authData = res.data.data;

                localStorage.setItem("token", authData.token);
                localStorage.setItem("user", JSON.stringify(authData.user));
                
                setUser(authData.user);
                setToken(authData.token);

                setLogin(true);

                if(authData.user.login_securities.length > 0 && authData.user.login_securities[0].enabled){
                    setModal(true);
                } else {
                    if(authData.user.is_password_verified === 0 || authData.user.is_password_verified === null){
                        window.location.href = "/password/policy";
                    }else if(authData.user.phone_verified_at === null){
                        window.location.href = "/verify/phone";
                    }else{
                        window.location.href = "/home";
                    }
                }
            }
        }).catch(function(e){
            // console.log('error found!');
            if(e.response.status === 401 || e.response.status === 404){
                console.log(e.response.data);
                setLoginError(e.response.data.message);
            }

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            if(e.response.status === 403 && e.response.message === "Authenticated"){
                logout();
            }

            if(e.response.status === 419 || e.response.status === 500){
                console.log(e.response.data);
                setLoginError("Invalid request, refresh page and try again!");
            }


        }).finally(() => {
            setLoading(false)
        });
    }

    const verify = debounce(async () => {
        setLoading(true)

        const verifyAxios = axios.create({
            baseURL:process.env.REACT_APP_HTTPS_ENDPOINT,
            withCredentials:true,
            headers: {
                'Authorization': `Bearer ${token}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
             }
        });
        
        await verifyAxios.get(`api/user?one_time_password=${secret}` ).then(({data}) => {
           
            if(Array.isArray(data.message)) {
                setOtpError(data.message[0])
            } else {
                setModal(false)
                window.location.href = "/home"
            } 
            
        }).finally(() =>  setLoading(false))
    },2000)
    
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is Required."),
        password: Yup.string().required("Password is Required"),
    });

    const logout = async () => {
        await axiosInstance.post("api/logout").then(() => {
            // Set a timeout to call the function after 3000 (3 seconds)
            const timeoutId = setTimeout(() => {
                window.location.href = "/login";
            }, 3000);
        });
    }

    useEffect(() => {
        if(secret.length === 6) {
            verify()
        }
     }, [secret])

     useEffect(() => {
        if (code !== null) {
          setLoading(true);
            axiosInstance.post("api/social/callback?code=" + code , {
              provider:`${localStorage.getItem('provider')}`
            }).then(({ data }) => {
                const userObj = data.data.user;
                localStorage.setItem("token" , data.data.token);
                localStorage.setItem("user" , JSON.stringify(userObj));
                
                setUser(userObj);
                setToken(data.data.token)
                setLogin(true)
                if(userObj.login_securities && userObj.login_securities.length > 0 && userObj.login_securities[0].enabled){
                    setModal(true)
                } else {
                    window.location.href = "/home"
                }
            });
        }
      }, [code]);

    return (
        <div className={classes.container} >
            <div style={{display:'flex', justifyContent:"flex-end",paddingRight:"20px"}}>
                <LanguageSwitch />
            </div>
            <div style={{display:"flex" , justifyContent:"center"}}>

                <div className={classes.login_section}>
                    <h2>{t("welcome_to")} <img onClick={() => history.push('/home')} style={{marginBottom:"-8px", cursor:"pointer"}} src={logo} alt="logo" width="100px" /></h2>
                    <div className={classes.icons}>
                        <img style={{cursor:"pointer"}} src={Logo} height="35px" width="35px"  alt="google logo" onClick={() => handleSocial("google")}/>
                        <img style={{cursor:"pointer"}} src={Discord} height="40px" width="40px"  alt="logo"  onClick={() => handleSocial("discord")}/>
                        <img style={{cursor:"pointer"}} src={Twitch} height="40px" width="40px"  alt="logo"  onClick={() => handleSocial("twitch")}/>
                        <img style={{cursor:"pointer"}} src={Facebook} alt="logo" height="30px" width="30px"  onClick={() => handleSocial("facebook")}/>
                    </div>
                    <p className={classes.info}>{t("use_email_username")}</p>

                    <Formik
                        initialValues={{ email: "", password:"" }}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                    {({ handleSubmit, handleChange, errors }) => (
                        <>
                        <div className={classes.input}>
                            <div className={classes.input_container}>
                                <TextField className={classes.textfield}  onChange={handleChange("email")} name="email"  variant="outlined" label="Email/Username" />
                                {errors.email && (
                                    <span className={classes.content__form_error}>{errors.email}</span>
                                    )}
                            </div>
                            <div className={classes.input_container}>
                                <PasswordField onChange={handleChange("password")}  />
                                    
                                {errors.password && (
                                    <span className={classes.content__form_error}>{errors.password}</span>
                                    )}
                            </div>
                            <div className={classes.input_container}>
                            <Button loading={loading} backgroundColor="#B00610"  name={t("login")} classes={{text:classes.button_text}} onClick={handleSubmit}/>
                            {loginError && (<span className={classes.content__form_error}>{loginError}</span>)}
                            {/* <span className={classes.content__form_error}>Invalid credentials</span> */}
                            </div>
                        </div>
                        </>
                        )}
                    </Formik>
                    <p className={classes.join}>{t("no_account")} <Link style={{
                        color:"#17a2b8"
                    }} to="/register">{t("join")}</Link> </p>
                    <Link style={{
                        color:"#17a2b8"
                    }} to="/forgot-password">{t("forgot_password")}</Link>

                </div>
            </div>
            <Modal open={modal} onClose={() => setModal(false)} >
                <h2>Enter One Time Password</h2>
                <div className={classes.otp_section}>
                    {
                        loading ? <CircularProgress color="inherit"/> :
                        <OtpInput
                            value={secret}
                            onChange={(otp) => setSecret(otp)}
                            numInputs={6}
                            separator={<span>-</span>}
                            inputStyle={{
                                width:"40px",
                                height:"50px",
                                border:"1px solid #ccc",
                                backgroundColor:"transparent",
                                color:"#fff",
                            }}
                        />
                    }
                </div>
                 {otpError && <span className={classes.content__form_error}>{otpError}</span>}
            </Modal>
        </div>
    )
}

export default Login
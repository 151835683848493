import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

 
    container:{
        color:"#fff",
        "& h2":{
            fontSize:"30px",
            margin:theme.spacing(2, 0)
        }
    }, 
    prize_container:{
        display:"flex",
        flexWrap:"wrap",
        gap:theme.spacing(3),
        marginBottom:theme.spacing(2)
    },
    add:{
        cursor:"pointer"
    },
    delete:{
        color:"#B00610"
    },
    input_section:{
        width:"45%",
        "& p":{
            fontSize:"18px",
            // marginBottom:theme.spacing(2)
        }
    },
    input_info:{
        display:"flex",
        marginBottom: theme.spacing(1),
        alignItems:"center",
        justifyContent:"space-between"
    },
    icon:{
        padding:0
    },
    textfield:{
        width:"100%"
    },
    editor:{
        // backgroundColor:"#fff",
        color:"#fff",
        height:"40vh",
        border:"1px solid #5A79A5",
        padding:theme.spacing(1),
        "&::-webkit-scrollbar": {
            display:"none",
          },
    }
    , 
    toolbar:{
        color:"#000",
        backgroundColor:"transparent",
        border:"1px solid #5A79A5",
        "&::-webkit-scrollbar": {
            display:"none",
          },
    },
    content__form_error:{
        fontSize:"12px",
        color:"red",
    },
    input_container:{
        width:"100%",
        display:"flex",
        flexWrap:"wrap",
        gap:"32px 12px",

    }
}));

import { formatDistanceToNow } from 'date-fns';
import React from 'react'
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles'
import { Avatar, ClickAwayListener, Grow, IconButton,  MenuItem, MenuList, Popper } from '@material-ui/core';
import { BsThreeDots } from "react-icons/bs";
import { AiOutlinePlus } from 'react-icons/ai';

export const Tag = ({ title , add = false , tagClick}) => {
    const classes = useStyles();

    const color = useRef('#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'))

    return (
        <div className={classes.tag} style={{backgroundColor:`${color.current}`}} onClick={() => tagClick(title)}>
            <p className={classes.tagDescription}>{title}</p>
            {
               add && <AiOutlinePlus className={classes.tagIcon} />
            }
        </div>
    )
}

export default function ForumCard({ title , body, user, image, created_at, handleTags, replies_count, tags , handlePost , slug ,can}) {
    const classes = useStyles();
    const history = useHistory(); 

    const [openOption, setOpenOption] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleToggle = () => {
      setOpenOption((prevOpen) => !prevOpen);
    };
  
    const handleCloseOption = (event , type) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      let newTags = [];

      tags.map(({name}) => newTags.push(name))

      const form = {
        body,
        slug,
        title,
        image,
        tags:newTags
      }
  
      handlePost(type , form)
      setOpenOption(false);
    };
    
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpenOption(false);
        } else if (event.key === 'Escape') {
          setOpenOption(false);
        }
      }
    
    
      // return focus to the button when we transitioned from !open -> open
      const prevOpen = React.useRef(openOption);
      React.useEffect(() => {
        if (prevOpen.current === true && openOption === false) {
          anchorRef.current.focus();
        }
    
        prevOpen.current = openOption;
      }, [openOption]);

  return (
    <div className={classes.container} >
        <div className={classes.tagSection}>
            {
                tags.map(( {name} , index) => <Tag key={index} title={name} tagClick={handleTags} />)
            }
            {
                (!!can && (can.delete || can.update)) &&
                    <IconButton
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={openOption ? 'composition-menu' : undefined}
                        aria-expanded={openOption ? 'true' : undefined}
                        aria-haspopup="true"
                    
                    >
                        <BsThreeDots size={25}  onClick={ handleToggle} />
                    </IconButton>
            }

        </div>
        <h2 className={classes.title} onClick={() => history.push(`/home/forum/${slug}`)}>{title} </h2>
        <p className={classes.description} onClick={() => history.push(`/home/forum/${slug}`)}>{body}</p>
        <div className={classes.authorSection}>
            <div style={{display:'flex', cursor:"pointer"}} onClick={() => history.push(`/home/player/${user.username}`)}>
                <Avatar className={classes.img} src={user.avatar} alt={user.username}  />
                <div style={{display:'flex', flexDirection:"column", gap:"4px"}}>
                    <p className={classes.name}>{user.username}</p>
                    <p className={classes.time}>{formatDistanceToNow(new Date(created_at))} ago</p>
                </div>
            </div>
            <p className={classes.comment}>{`${replies_count} ${replies_count === 1 ? 'comment' : "comments"}`}</p>
        </div>
        <Popper
                open={openOption}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <ClickAwayListener onClickAway={handleCloseOption}>
                  <MenuList
                    autoFocusItem={openOption}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                        can?.update &&  <MenuItem onClick={(e) => handleCloseOption(e , 'edit')}>Edit</MenuItem>
                    }
                     {
                        can?.delete &&  <MenuItem onClick={(e) => handleCloseOption(e , 'delete')}>Delete</MenuItem>
                    }
                  </MenuList>
                </ClickAwayListener>
            </Grow>
          )}
        </Popper>
    </div>
  )
}

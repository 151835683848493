import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from "../../components/Modal/Modal";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SimpleMap from '../../components/Map/Map';
import { useStyles } from './styles';
import Button from '../../components/Button/Button';
import SubscriptionPlan from '../../components/SubscriptionPlan/SubscriptionPlan';
import InfoCard from '../../components/InfoCard/InfoCard';
import { FaMedal} from 'react-icons/fa'
import TeamCard from '../../components/TeamCard/TeamCard';
import { Skeleton } from '@material-ui/lab';
import { isEmpty } from 'lodash-es';
import { Avatar } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { axiosInstance } from '../../helpers/client';
import { format  , formatDistance } from "date-fns";
import inflection from 'inflection';
import { AppContext } from '../../context/appContext';
import Invitation from '../../components/Invitation/Invitation';
import { ordinalSuffixOf } from '../../helpers/numberHelpers';
import { initializePayment } from '../../helpers/transaction';
import { useTranslation } from 'react-i18next';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <div style={{padding:"8px" }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const PrizeCard = ({ rank , currency , amount}) => {
  const classes = useStyles();
  return (
    <div className={classes.prize_container}>
    <p style={{
      margin:"0px 16px"
    }}><FaMedal /></p>
    <p style={{flex:3 }}>{ordinalSuffixOf(rank)}</p>
    <p style={{marginRight:"32px" }}>{currency}{amount}</p>
  </div>
  )
}



export default function Tournament() {
  const classes = useStyles();
  const history = useHistory();
  const [bracketId, setBracketId] = useState(null);
  const [ team , setTeam ] = useState({});
  const [ loading , setLoading ] = useState(false)
  const [ participantUuid , setParticipantUuid ] = useState({});
  const { setFeedback , user , loggedIn , feedback } = useContext(AppContext);
  const [game ,  setGame ] = useState({
    participants:[]
  });
  const [open, setOpen] = useState(false);
  const [ activeSub , setActiveSub ] = useState({})
  const [openInvite, setOpenInvite] = useState(false);
  const [_, setScrollIndex] = useState(0);
  const { tournamentSlug } = useParams();
  const ref = useRef();
  const [value, setValue] = useState(0);
  const [subscriptions , setSubscriptions] = useState([])
  const searchParams = new URLSearchParams(window.location.search);
  const reference = searchParams.get("reference") ?? searchParams.get("transaction_id")
  const paymentType =  searchParams.get("paymentType");
  const plan =  searchParams.get("plan");
  const { t }= useTranslation();

  const handleOpen = () => {
    if (!loggedIn) return history.push("/login")
    setOpen(true);
  };

    const handleClose = () => {
      setOpen(false);
      scroll(0);
    };

    const handleOpenInvite = () => {
      setOpenInvite(true);
    };
    
      const handleCloseInvite = () => {
        setOpenInvite(false);
      };

  const scroll = (index) => {
    ref.current.scrollLeft = index * ref.current.clientWidth;
    setScrollIndex(index);
  } 

  const handleConfirm = ( name ,slug , amount) => {
    if(!window.confirm("Subscribe to this plan")) return
    const redirectName = name === "paystack" ? "callback_url" : "redirect_url"
    const meta = name === "paystack" ? "metadata" : "meta";

    activeSub === null ?
      initializePayment(name , {
        amount,
        [redirectName]: `${process.env.REACT_APP_FRONT_DOMAIN}home/tournament/${tournamentSlug}?paymentType=subscription&plan=${slug}`,
        [meta]: {
          payment_type: "subscription",
        },
        customizations: {
          title: "New Premium User Subscription",
          description: `${user.name} is making a deposit of ${amount}`,
        },
      })

    : setFeedback({message:"You already have an active subscription"})

  }

  const handleCheckIn = async () => {
    await axiosInstance.post(`api/participants/check-in/${participantUuid.uuid}`).then(({data}) => {
      setFeedback({message:data.message})
    }).catch(({response}) => {
      setFeedback({message:response.data.errors[0] , severity:"error"})
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSubscriptionPlans = async() => {
    await axiosInstance.get("api/subscription-plans" ).then(function ({data}) {
      setSubscriptions(data.data)
  })
  .catch(function ({response}) {
      console.log(response)
  });
  }


  const getActiveSubscriptionPlan = async() => {
    await axiosInstance.get("api/subscriptions/active" ).then(function ({data}) {
      setActiveSub(data.data)
  })
  }

  const getTeam = async () => {
    await axiosInstance.get('api/account').then(({data}) => {
        setTeam(data?.data?.teams[0])
    })
}

  const getTournamentDetail = async () => {
    setLoading(true)
    await axiosInstance.get(`api/tournaments/show/${tournamentSlug}?include_participants=1` ).then(function ({data}) {
      setBracketId(data.data.bracket_id);
      const participants = data.data.participants;
      const obj = participants.find((item) => item?.data?.name === user?.username);
      setGame(data.data)
      setParticipantUuid(obj)
      if(!data?.data?.is_solo && loggedIn) getTeam()

      
  }).finally(() => setLoading(false))
  }

  const enrollForTournament = async() => {
    setLoading(true)
    let endpoint = "";
    let body = {
      tournament: game.slug
    };
    if(game.is_solo){
      endpoint = "api/participants/store";
    } else {
      endpoint = "api/participants/teams/store";
      body.team = team?.slug;
    }

    await axiosInstance.post(endpoint , body ).then(function ({data}) {
      setFeedback({message:data.message })
  })
  .catch(({ response }) => {
    
    if(response.data.errors){
      const errors = response.data.errors;
      return Object.keys(errors).map((error) => setFeedback({message: errors[error][0] , severity:"error"}))
    }
    setFeedback({message:response.data.message , severity:"error"}) 
  }).finally(() => {
    handleClose()
    setLoading(false)
  })
  }

  const subscribeToPlan = async () => {
    setLoading(true)
    await axiosInstance.post(`api/subscriptions/subscribe`,{
      reference,
      channel: 'flutterwave',
      plan
    } ).then(function ({data}) {
      setFeedback({message:data.message }) 
  })
  .catch(({ response }) => {
     setFeedback({message:response.data.message , severity:"error"}) 
  })
  .finally(() => {
    setLoading(false)
})
  }

  useEffect(() => {
    if(reference && paymentType === "subscription"){
      subscribeToPlan();
    }
  } , [reference])
  

useEffect(() => {
  getTournamentDetail()
},[tournamentSlug ])

useEffect(() => {
  if(!isEmpty(feedback)){
      getTournamentDetail()
  }
}, [feedback])


useEffect(() => {
  if(loggedIn){
    getActiveSubscriptionPlan();
  }
  getSubscriptionPlans();
} , [])


const gameInfo = [
  {
    title: t("type"),
    detail: game.type
  },
  {
    title: t("entry"),
    detail: `${game.currency} ${game.entry_fee}`
  },
  {
    title: t("time"),
    detail: format(new Date(game.started_at ?? null), "MMM do")
  },
  {
    title: t("available_slot"),
    detail: game.slots
  },
  {
    title: t("prize_pool"),
    detail: `${game.currency} ${game.prize_pool}`
  },
  {
    title: t("location"),
    detail: game.location
  },
  {
    title: t("team_size"),
    detail: game.team_size
  },
  {
    title: t("total_registered"),
    detail: game.participants.length
  },
]

const displayInfoCards = () => {
  return gameInfo.map(({title , detail }, index) => !loading ? 
  <InfoCard key={index} title={title} detail={detail}/>
  : <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" className={classes.info_skeleton} animation="wave" />)
}

const displayPrizes = () => {
  game.prizes && game?.prizes.sort(function(a, b) {
    return a.rank - b.rank;
  });

  return  game.prizes &&  game?.prizes.map(({rank , prize_type , value}, i) =>  <PrizeCard key={i} rank={rank} currency={prize_type} amount={value} />)
  
}


  return (
      <div className={classes.container}>
        <SimpleMap tournament={game} />
        <div className={classes.root}>
            <div className={classes.title_section}>
                <div className={classes.info_section}>
                  {
                    loading 
                    ? (
                      <>
                        <Skeleton   className={classes.info_skeleton_img} style={{backgroundColor:"#0F2139",marginRight:"8px"}} variant="circle"  animation="wave" />
                        <div className={classes.card_info}>
                          <Skeleton className={classes.info_skeleton} style={{backgroundColor:"#0F2139"}} variant="text"  height={40}  animation="wave" />
                          <Skeleton className={classes.info_skeleton} style={{backgroundColor:"#0F2139"}} variant="text"  height={20}  animation="wave" />
                          <Skeleton className={classes.info_skeleton} style={{backgroundColor:"#0F2139"}} variant="text"  height={20}  animation="wave" />
                        </div>
                      </>
                    )
                    
                    :
                      <>
                    <Avatar className={classes.img} src={ game.event ?  game.event.banner : game.banner} alt={game.name}/>
                     <div className={classes.card_info}>
                        {
                          game.event ? (
                            <>
                              <p className={classes.price}>{game.games[0].name}</p>
                              <p className={classes.card_info_name}>{game.event.name}</p>
                            </>
                          ) :  <p className={classes.card_info_name}>{game.name}</p>
                        }
                       
                        {
                          (game.started_at && game.started_at) && 
                        <p className={classes.card_info_date}>{format(new Date(game.started_at ), "MMM do")} - {format(new Date(game.ended_at), "MMM do")}</p>
                        }
                        <p className={classes.price}>{t("prize_pot")}: {game.currency}{game.prize_pool}</p>
                    </div>
                    </>
                  }
                </div>
                <div >
                    {
                      loading ? 
                        <Skeleton style={{backgroundColor:"#0F2139"}} variant="text"  height={20}  animation="wave" />
                          :
                        <p className={classes.start_time}> {formatDistance(new Date(game.started_at ?? null ), new Date() ,{ addSuffix: true })}</p>
                    }
                    {
                      game.status === "completed" || (new Date(game.ended_at) < new Date()) 
                      ? <p>{t("completed")}</p>

                      : <div className={classes.enroll_button_section}>
                      
                        {
                            ( game.is_organizer) && 
                                <Button backgroundColor="#172A44" name={t("invite")} width="100%" onClick={handleOpenInvite}/> 
                        }
                        {
                        
                          participantUuid?.uuid  
                          ? <Button backgroundColor="#172A44" name="CheckIn" width="100%" onClick={handleCheckIn} disabled={!participantUuid?.data?.can_check_in}/>
                          :  <Button  backgroundColor="#B00610" name={t("enroll")} width="100%" onClick={handleOpen}/>
                        }
                      </div> 
                    }
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                >
                      <div className={classes.containerMd} ref={ref}>
                        <div className={classes.scroll_box}>
                            <h2>{t(game.name)}</h2>
                            <p className={classes.p}>{t("Tournament participants will be notify upon check-in before tournament kicks off.")}</p>
                            <p className={classes.p}>{t("Game data and stats will be displayed on bracket tab section")}</p>
                            <div className={classes.subcription_box} >
                              {
                                (game.entry_fee > 0) ? (
                                  <p className={classes.p}><span className={classes.danger}>Note:</span> {t("Tournament entry fee will be deducted from your wallet balance to complete participant enrollment process")}</p>
                                ) : (
                                  <p className={classes.p}><span className={classes.danger}>Note:</span> {t("This tournament is free no entry fee required.")}</p>
                                )
                              }
                            </div> 
                            <Button 
                              width="100%"
                                  name={t("Continue")}
                                  loading={loading}
                                  margin="16px"
                                  backgroundColor="#B00610"
                                  onClick={() => enrollForTournament()} />
                        </div>
                      </div>
                </Modal>

                <Modal open={openInvite} onClose={handleCloseInvite}>
                  <Invitation slug={game.slug} type="tournament" team={!game.is_solo}/>
                </Modal>
            </div>
            <div>
                <Tabs value={value} onChange={handleChange}  variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab className={classes.tab_label} label={t("information")} {...a11yProps(0)} />
                    <Tab className={classes.tab_label} label={t("rules")} {...a11yProps(1)} />
                    <Tab className={classes.tab_label} label={t("bracket")} {...a11yProps(2)} />
                    <Tab className={classes.tab_label} label={t("prize")} {...a11yProps(3)} />
                    <Tab className={classes.tab_label} label={t("participant")} {...a11yProps(4)} />
             </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <h2 >{t("main_info")}</h2>
          
                <div className={classes.main_info_section}>
                  <div className={classes.main_info_cards}>
                    {displayInfoCards()}
                  </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className={classes.rules} dangerouslySetInnerHTML={{__html: game.rules}} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/* {
               game?.data?.full_challonge_url ? <div dangerouslySetInnerHTML={{__html: `<iframe title="challonge" src='${game?.data?.full_challonge_url}/module?theme=8039&show_standings=1' width="100%" height="700" frameBorder="0" scrolling="auto"  allowTransparency="true"/>`}}/> : <p>No Bracket Available</p>
              } */}
              { bracketId && (
                <iframe 
                  title="bracket-iframe"
                  src={`https://test.bracketpro.gg/bracket/webemd/${bracketId}`} 
                  id="bracket-iframe"
                  className={classes.bracketIframe}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full">

                  </iframe>
                )
              }
                
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className={classes.info_section_price}>
                  {displayPrizes()}
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
               <div className={classes.team_info_section}>
                 {
                   game.participants && game?.participants.map(({ participant}, i) => participant && <TeamCard key={i} banner={participant.avatar} name={game.is_solo ? participant.display_name : participant.name}  onClick={
                     () => game.is_solo ? 
                        history.push(`/home/player/${inflection.dasherize(participant.username)}`) 
                        : history.push(`/home/team/${participant.slug}`) }
                     />)
                 }
               </div>
            </TabPanel>
        </div>
      </div>

  );
}
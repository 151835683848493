import React, { useState , useEffect, useContext } from 'react'
import Table from '../../../components/Table/Table';
import { useStyles } from './styles';
import { get, isEmpty } from "lodash";
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import inflection from 'inflection';
import { useHistory } from 'react-router';
import Visibility from '@material-ui/icons/Visibility';
import { TournamentContext } from '../../../context/tournamentContext';
import { IconButton, MenuItem, Select } from '@material-ui/core';
import {  MdCheckCircle , MdCancel , MdDelete , MdEdit, MdCreditCard } from 'react-icons/md'
import Button from '../../../components/Button/Button'
import { format } from 'date-fns';
import Modal from '../../../components/Modal/Modal';
import CreateEvent from './CreateEvent';
import { handlePage } from '../../../helpers/paginator';
import { t } from 'i18next';


export default function ManageTournament () {
    const classes = useStyles()
    const history = useHistory()
    const [ game , setGame ] = useState("events");
    const [edit, setEdit] = useState({});
    const [ loading , setLoading ] = useState({
     icon:false , table:true , slug:null
    });

    const [ collection , setCollection] = useState({
        data:[] , links:{}
    })
    const [page , setPage ] = useState(0)
    const [ rows , setRowsPerPage ] = useState(20)
    const { setFeedback , user , feedback } = useContext(AppContext);
    const [ open , setOpen ] = useState(false);
    const [endpoint , setEndpoint ] = useState(user.role === 'admin' 
    ? `api/${game}?per_page=${rows}&sort[direction]=DESC&sort[field]=date_created` 
    : `api/${game}/user?per_page=${rows}&sort[direction]=DESC&sort[field]=date_created`)
    const { changeTournament } = useContext(TournamentContext);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setEdit({})
    };

    const handleChange = (event) => {
        setGame(event.target.value);
      };
    
      const actions = [
        {
          name: t("publish"),
          action:"publish"
        },
        {
          name: t("check_in"),
          action:"check-in/process"
        },
        {
          name:  t("start"),
          action:"start"
        },
        {
          name:  t("finalize"),
          action:"finalize"
        },
        
      ]




      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };


      const getEvents = async () => {
        setLoading({...loading , table:true ,slug:null});
        await axiosInstance.get(endpoint).then(function ({ data }) {
            setCollection(data);
        })
        .catch(({ response }) => console.log(response))
        .finally(() =>   setLoading({...loading , table:false , slug:null}))
    };

    const updateStatus = async (event_slug , status) => {
      setLoading({...loading , icon:true});
      await axiosInstance
        .patch(`api/${game}/status/update/${event_slug}` ,{
            status
        })
        .then(function ({ data }) {
          setFeedback({message:data.message});
        })
        .catch(({ response }) => {
          response?.data?.message !== undefined &&
            setFeedback({ message: response.data.message, severity: "error" });
  
          response?.data?.errors !== undefined
            ? setFeedback({ message: response.data.errors[0], severity: "error" })
            : setFeedback({ message: response.data.error, severity: "error" });
        })
        .finally(() => {
          setLoading({...loading , icon:false});
        });
    };

    const updateTournament = async ( published = false , name , slug ) => {
      setLoading({...loading , slug});
      await axiosInstance.put(`api/${game}/update/${slug}` , {
          name,
          published,
      } ).then(function ({ data }) {
          setFeedback({message:data.message});
      })
      .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}) )
      .finally(() =>   setLoading({...loading , slug:null}))
  }


  const updateEvent = async ( name , slug , location) => {
    setLoading({...loading , slug});
    await axiosInstance.put(`api/events/update/${slug}` , {
        name,
        published_at: format(new Date() , "yyyy-MM-dd HH:mm:ss"),
        location
    } ).then(function ({ data }) {
        setFeedback({message:data.message});
    })
    .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}) )
    .finally(() =>   setLoading({...loading , slug:null}))
}


  const processTournament = async (type ,  slug) => {
    setLoading({...loading , button:true , slug});
    await axiosInstance.post(`api/tournaments/${type}/${slug}` ,).then(function ({ data }) {
        setFeedback({message:data.message});
    })
    .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}) )
    .finally(() =>   setLoading({...loading ,slug:null}))
}


  const deleteTournament = async ( slug) => {
    await axiosInstance.delete(`api/${game}/delete/${slug}` ).then(function ({ data }) {
        setFeedback({message:data.message});
    })
    .catch(({ response }) => setFeedback({ message: response.data.message, severity: "error" }));
}

const settleTournament = async ( slug , amount , currency) => {
  if(!window.confirm(`You are about to settle the organizer of this tournament with ${currency}${amount}`)) return

  await axiosInstance.patch(`api/${game}/settle-organizer/${slug}` ).then(function ({ data }) {
      setFeedback({message:data.message});
  })
  .catch(({ response }) => setFeedback({ message: response.data.message, severity: "error" }));
}

      const schema = [

        {
          label:  t("name"),
          func: ({ item }) => {
            return  get(item, "name");
          },
        },
        {
          label: t("location"),
          func: ({ item }) => get(item, "location"),
        },
        {
            label: t("status"),
            func: ({ item }) => {
              if(game === "tournaments") return inflection.titleize(get(item, "status") ?? "")
              return get(item, "is_approved") ? t("approved"): t("pending")
            }
          },

        {
          label: t("actions"),
          func: ({ item }) => {
            const slug = get(item , 'slug');
            const name = get(item , 'name');
            const status = get(item, "status");

            let button = "";
            switch(status){
              case "pending":
                button = t("publish");
                break;
              case "active":
                button = get(item , "published_at") === null ? t("publish") :  t("check_in");
                break;
              case "checked_in":
                button = t("start");
                break;
              case "ongoing":
                button = t("finalize");
                break;
              default:
                break;
            }

            return (
            <div className={classes.icons}>
              {  (game === "tournaments" && status !== "completed") && 
              <Button 
                disabled={status === 'pending'}
                backgroundColor="#B00610" 
                name={ button }
                loading={(loading.slug === slug)}
                width="100%" 
                onClick={() => {
                  const filter = actions.find((item) => item.name === button)
                  console.log(filter);
                 return button === t("publish") ? updateTournament(true , name , slug ) : processTournament(filter.action, slug);
                }} 
                />
              }

            {  (game === "events" && get(item , "published_at") === null) && 
              <Button 
                backgroundColor="#B00610" 
                name={t("publish")}
                loading={(loading.slug === slug)}
                width="100%" 
                onClick={() => updateEvent( name , slug , get(item, "location") )} 
                />
              }
            
            {game === "tournaments" &&

              <IconButton color="inherit" style={{padding:"0"}} onClick={() =>  {
                changeTournament(item);
                history.push(`/home/tournament/${slug}`)}
              } >
                <Visibility size={25}  />
              </IconButton>
            }
              <IconButton color="inherit" style={{padding:"0"}} onClick={() =>  {
                  if(game === "events"){ 
                    setEdit(item)
                    handleOpen();
                  } else {

                    history.push(`/admin/tournament/${slug}`)}
                  }
                }>
                <MdEdit size={25}   />
              </IconButton>
              <IconButton color="inherit" style={{padding:"0"}} onClick={() =>  deleteTournament(slug)}>
                <MdDelete size={25} />
              </IconButton>
              {
                (status === 'pending' || get(item , 'is_approved') === false) && user.role === 'admin' ? 
                <>
                  <IconButton color="inherit" style={{padding:"0"}}  onClick={() =>  updateStatus(slug, 'declined')} >
                    <MdCancel size={25} />
                  </IconButton>
                  <IconButton color="inherit" style={{padding:"0"}} onClick={() =>  updateStatus(slug, 'approved')}>
                    <MdCheckCircle size={25}   />
                  </IconButton>
                </>

                : null
   
              }
              {
                (user.role === 'admin' && game === "tournaments" && status === "completed") &&
                <IconButton color="inherit" style={{padding:"0"}}>
                      <MdCreditCard size={25} onClick={() =>  settleTournament(slug, get(item, 'organizer_settlement'), get(item, 'currency') )}  />
                  </IconButton>
              }
              
            </div>
            )
          },
        },
      ];

      useEffect(() => {
        const endpoint =  user.role === 'admin' 
        ? `api/${game}?per_page=${rows}&sort[direction]=DESC&sort[field]=date_created` 
        : `api/${game}/user?per_page=${rows}&sort[direction]=DESC&sort[field]=date_created`
        setEndpoint(endpoint)

      },[game , rows])
      

      useEffect(() => {
        getEvents()
      }, [ endpoint])

      useEffect(() => {
        if(!isEmpty(feedback)){
          getEvents();
        }
      }, [feedback ]);

    
    return (
        <div className={classes.container}>
            <h2>{game === "events" ? t("manage_event") : t("manage_tournament")}</h2>
            <div className={classes.options}>
              <Select
                  variant="outlined"
                  className={classes.select}
                  value={game}
                  defaultValue={game}
                  onChange={handleChange}
              >
                  <MenuItem value={"events"}>{t("event")}</MenuItem>
                  <MenuItem value={"tournaments"}>{t("tournament")}</MenuItem>
              </Select>
                <Button
                  classes={{ text: classes.button_text }}
                  backgroundColor="#B00610"
                  name={game === "events" ? t("create_event") : t("create_tournament")}
                  onClick={() =>game === "tournaments" ?
                   history.push('/admin/tournament/create')
                  : handleOpen()}
                />            
            </div>
            <Table   
                handlePrev={() => handlePage(setEndpoint ,collection , 'prev')} 
                handleNext={() => handlePage(setEndpoint ,collection , 'next')} 
                total={100} 
                schema={schema}  
                collection={collection.data} 
                loading={loading.table}
                links={collection.links}  
                page={page} 
                handleChangeRowsPerPage={handleChangeRowsPerPage} 
                rowsPerPage={rows}/>
            <Modal open={open} onClose={handleClose}>
                <CreateEvent handleClose={handleClose} edit={edit} />
            </Modal>
        </div>
    )
}

import React, { useContext, useState , useEffect } from 'react'
import CircleCard from '../../components/CircleCard/CircleCard';
import RectangleCard from '../../components/RectangleCard/RectangleCard';
import { useStyles } from './styles';
import { TournamentContext } from '../../context/tournamentContext';
import { useHistory } from 'react-router';
import { axiosInstance } from '../../helpers/client';
import { Skeleton } from '@material-ui/lab';
import { useGame } from '../../hooks/useGame';
import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';
import { handlePage } from '../../helpers/paginator';
import { useTranslation } from 'react-i18next';


export const games = [
    {
        id:1,
        image:"https://res.cloudinary.com/aladeen/image/upload/v1598104693/qtqqh5uodavfghs2ezax.jpg",
        name:"COD",
        border:"blue",
    },
    {
        id:2,
        image:"https://res.cloudinary.com/aladeen/image/upload/v1634346750/18br-lineup-social-social-1920x1080-98b108d7ddfb_lyvsus.jpg",
        name:"Fortnite",
        border:"purple",
    },
    {
        id:3,
        image:"https://res.cloudinary.com/aladeen/image/upload/v1634346772/Street_Fighter_V_box_artwork_fjimty.png",
        name:"SFV",
        border:"yellow",
    },
    {
        id:4,
        image:"https://res.cloudinary.com/aladeen/image/upload/v1634346564/pubg-bg_ax1vvl.jpg",
        name:"PUBG",
        border:"blue",
    },
    {
        id:5,
        image:"https://res.cloudinary.com/aladeen/image/upload/v1634347131/capsule_616x353_kd6k1t.jpg",
        name:"FIFA",
        border:"red",
    },
    {
        id:6,
        image:"https://res.cloudinary.com/aladeen/image/upload/v1634346708/4yfeeKKfJdD5WzDQsoiM3xrcqPlpDLm7_modolq.jpg",
        name:"Tekken 7",
        border:"red",
    },
]


export default function Game() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const { changeTournament } = useContext(TournamentContext);
    const { data:game , isLoading } = useGame('query' , 'getGames');
    const [ collection , setCollection] = useState( {
        data:[] , links:{}
    })
    const [selectedGame , setSelectedGame] = useState({  name: t("all") });
    const [endpoint , setEndpoint ] = useState(
        selectedGame.name === t("all") 
        ? `api/tournaments?per_page=12&sort[direction]=DESC&sort[field]=date_created`
        : `api/games/${selectedGame.slug}/tournaments?per_page=8`
    )
    const [loading , setLoading] = useState({
        games:true
    })

    const getTournamentForGame = async () => {
        setLoading({...loading , games:true})
        await axiosInstance.get(endpoint).then(function ({ data }) {
            setCollection(data);
        }).finally(() => setLoading({...loading , games:false}))
    };

    const handleTournamentClick = (props) => {
        changeTournament(props);
        history.push(`/home/tournament/${props.slug}`);
    }


      
    useEffect(() => {
        const endpoint = selectedGame.name === 'All' 
        ? `api/tournaments?per_page=12&sort[direction]=DESC&sort[field]=date_created`
        : `api/games/${selectedGame.slug}/tournaments?per_page=8`
        setEndpoint(endpoint)

    },[selectedGame  ])

    useEffect(() => {
         getTournamentForGame()
    }, [ endpoint])


    return (
        <div className={classes.container}>
            <div className={classes.game_section}>
                <h2>{t("games")}</h2>
                <p>{t("choose_game")}</p>
                <div className={classes.games}>
                    {isLoading ? 
                        games.map((_ , index) =>   <Skeleton key={index} className={classes.info_skeleton_img} style={{backgroundColor:"#0F2139"}} variant="circle"   animation="wave" /> )
                        :
                            game.map(({image , name , slug} , index) => 
                            <CircleCard 
                                key={index} 
                                image={image} 
                                name={name}
                                buttonClick={() => setSelectedGame({id:index , name , slug})} 
                                disabled={index === selectedGame.id ? false : true} 
                                border={"#"+Math.floor(Math.random()*16777215).toString(16)}/>)
                    }
                </div>
             </div>

            <div className={classes.load_tournament_section}>
                <div className={classes.title}>
                    <h2>{selectedGame.name} {t("tournament")}</h2>
                    <div className={classes.filter}>
                        {/* <Select
                            variant="outlined"
                            size="small"
                            className={classes.select}
                        >
                            <MenuItem>Lagos</MenuItem>
                            <MenuItem>Benin</MenuItem>
                            <MenuItem>Calabar</MenuItem>
                            <MenuItem>Delta</MenuItem>
                        </Select> */}
                        
                    </div>
                 </div>
                <div className={classes.load_tournament}>
                    {
                        loading.games ? 

                        <>
                           <Loading />
                           <Loading />
                        </> :
                       ( collection.data.length > 0 ?
                        collection.data.map(({name,is_exclusive,entry_fee, banner ,games ,currency , prize_pool, started_at , slots ,ended_at , slug} , index) => 
                         <RectangleCard
                            key={index}
                             description={name} 
                             exclusive={is_exclusive}
                             fee={entry_fee}
                             slot={slots}
                             start={started_at} 
                             end={ended_at} 
                             prize={prize_pool} 
                             image={banner}
                             currency={currency}
                             onClick={() => handleTournamentClick({name, banner , prize_pool, started_at , ended_at , slug})} />
                         ) : <p>{t("no_available_tournament")}</p>)
                    }
                </div>
                <div>
                    {
                         collection.data.length > 0 &&
                         <div className={classes.pagination}>
                            <Button disabled={!(Boolean(collection.links.prev))} name={t("next")} onClick={() => handlePage(setEndpoint ,collection , 'prev')}/>
                            <Button disabled={!(Boolean(collection.links.next))} name={t("prev")} onClick={() => handlePage(setEndpoint ,collection , 'next')}/>
                         </div>
                    }
                </div>
             </div>
        </div>
    )
}

import React from 'react'
import { useStyles } from './styles';
import { TextField as MuiTextfield, IconButton } from '@material-ui/core';
import { t } from 'i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from '@material-ui/core/Tooltip';

export default function Textfield({name, title, info, formik, full = false , ...props}) {
    const classes = useStyles();

    return (
        <div className={classes.input_section} style={{
            width: full ? "auto" :"45%"
        }}>
            <div className={classes.input_info} >
                <p>{t(title ?? name)}</p>
                {
                    !!info &&
                    <Tooltip title={info} arrow>
                        <IconButton classes={{
                            root:classes.icon
                        }}>
                            <BsInfoCircleFill size={15}/>
                        </IconButton>
                    </Tooltip>
                }
            </div>
            <MuiTextfield {...props} className={classes.textfield} name={name} variant="outlined"  /> 
            {
                formik.touched[name]  && formik.errors[name] ? (
                    <span className={classes.content__form_error}>{formik.errors[name]}</span>
                ) : null
            }   
        </div>
    )
}

import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { MdAddCircleOutline } from 'react-icons/md';
import { CartContext } from '../../context/cartContext';
import { useStyles } from './styles';



const CartItem = ({slug, name, price ,type, quantity = 0, image, summary = true}) => {
    const classes = useStyles();
    const { removeItem , updateQuantity} = useContext(CartContext)

    return (
        <div className={classes.cartItem}>
            <div className={classes.cartItemImage} style={{backgroundImage:`url(${image})`}}></div>
            <div className={classes.productInfo}>
                <p>{name}</p>
                <p>{price}</p>
            </div>
            <div className={classes.counterSection}>
                <p className={classes.type}>{type === "digital"  ? "digital"  :" hardware"}</p>
                {
                    summary  ?
                    <div style={{display:'flex' , gap:"8px", alignItems:"center"}}>
                        <p>QTY</p>
                        <MdAddCircleOutline onClick={() => updateQuantity('increment' , slug)} />
                        <p>{quantity}</p>
                        <AiOutlineMinusCircle onClick={() => quantity > 1 && updateQuantity('decrement', slug)}/>
                        <p className={classes.remove} onClick={() => removeItem(slug)}>Remove</p>
                    </div>
                    :
                    <p>x {quantity}</p>
                }
                 
            </div>
        </div>
    )
}


export default React.memo(CartItem)
import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import { useStyles } from './styles';
import { axiosInstance } from '../../helpers/client';
import { useHistory } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useContext } from 'react';
import { AppContext } from '../../context/appContext';
import Coin from "../../asset/svg/gamrcoin.svg";
import BuyCoins from '../BuyCoins/BuyCoins';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Numeral from 'react-numeral';
// import WalletLogo from '../../asset/img/wallet-logo.png';


const Wallet = ({wallet , handleDepositPayment}) => {
    const classes = useStyles();
    const history = useHistory();
    const {user} = useContext(AppContext);
    const [account, setAccount] = useState({});
    const [userBanks , setUserBanks] = useState([]);
    const [userEmail, setUserEmail] = useState("");
    const [modal, setModal] = useState({
        coins: false, 
        withdraw: false,
        method: false,
        paypal: false,
        bank: false,
        paypalPreview: false,
        bankPreview: false
    });
    const [loading , setLoading] = useState(false);
    const {setFeedback, settings} = useContext(AppContext);
    const { t } = useTranslation();
    const [ fetch, setFetch] = useState(false);

    const getUserBanks = async (form) => {
        setLoading({...loading, userBanks: true});

        await axiosInstance.get('api/userbank' , form).then(({data}) => {
            setUserBanks(data.data)
        }).finally(() => setLoading({...loading, userBanks: false}))
    }
    
    const getAccountData  = async () => {
        setLoading(true)
        await axiosInstance.get('api/account').then(({data}) => {
            setAccount(data.data)
            formik.setFieldValue('bannerImage', data.data.avatar ?? "")
        })
        .finally(() => setLoading(false))
    }

    const withdraw = async () => {
        setLoading(true);
        await axiosInstance.post("api/wallet/requests/withdraw" ,{
            amount: formik.values.amount,
            email: formik.values.email
        } ).then(({data}) => {
            setFeedback({message: data.message});
            if(data.status === "success"){
                setModal({...loading, 
                    coins: false, 
                    withdraw: false,
                    method: false,
                    paypal: false,
                    bank: false,
                    paypalPreview: false,
                    bankPreview: false
                });
            }
        }).catch(({response}) => {
            setFeedback({message: response.data.message, severity: "error"})
        }).finally(() => {
            setLoading(false)
           setModal({...modal, withdraw:false})
        })
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.number().required().positive().integer().label("Amount"),
    });

    const formik = useFormik({
        initialValues:{
            amount: "",
            email: null
        },
        validationSchema: validationSchema,
        onSubmit: withdraw
    });

    const handleWithdrawalMethod = () => {
        getUserBanks();
        if(formik.values.amount === ""){
            setFeedback({message: "Enter a valid amount to proceed!", severity:"error"})
        }else{
            setModal({...modal, method: true});
        }
    }

    const withdrawFromWalletToBank = () => {
        if(formik.values.amount === ""){
            setFeedback({message: "Enter a valid amount to proceed!", severity:"error"})
        }else{
            // getAccountData();
            setModal({...modal, bank: true});
        }
    }

    const withdrawFromWalletToPayPal = () => {
        if(formik.values.amount === ""){
            setFeedback({message: "Enter a valid amount to proceed!", severity:"error"})
        }else{
            setModal({...modal, paypal: true});
        }
    }

    const showPreviewBankTransaction = () => {
        if(formik.values.amount === ""){
            setFeedback({message: "Enter a valid amount to proceed!", severity:"error"});
        }else{
            setModal({...modal, bankPreview: true});
        }
    }

    const showPreviewPayPalTransaction = () => {
        if(formik.values.amount === ""){
            setFeedback({message: "Enter a valid amount to proceed!", severity:"error"});
        }else if(formik.values.email === ""){
            setFeedback({message: "Enter a valid PayPal Email to proceed!", severity:"error"});
        }else{
            setModal({...modal, paypalPreview: true});
        }
    }

    const handleProfileRedirect = () => {
        window.location.href = "home/profile";
    }

    return (
        <div className={classes.container}>
            <p>{t("balance")}</p>
            <h2>{wallet.currency}{wallet.amount}</h2>
            <div style={{
                display:"flex",
                gap:"8px",
            }} > 
                <Button name={t("deposit")} backgroundColor="#B00610" onClick={handleDepositPayment} />
                <Button name={t("withdraw")}  onClick={() => setModal({...modal , withdraw:true})}  />
            </div>
            <Button
              coin={Coin}
              onClick={() => setModal({...modal, coins:true})}
              name={`${wallet?.gc_amount}GC`}
              startIcon={<img src={Coin} height="20px" alt="logo" />}
            />
            <p style={{cursor:"pointer"}} onClick={() => history.push('/home/profile')}>{t("view_transactions")}</p>

            {/* USD Withdrawal */}
            <Modal open={modal.withdraw} onClose={() => setModal({...modal, withdraw: false})}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "50px",
                    minHeight: "260px",
                    minWidth: "350px",
                  }}
                >
                    <h2>Enter the amount</h2>
                    <p>Kindly, enter the amount you would love to withdraw</p>
                    <br />
                    <TextField
                        required
                        placeholder="Amount"
                        variant="outlined"
                        name="amount"
                        type="number"
                        onChange={formik.handleChange}
                    />
                    <br />
                    { formik.errors.amount ? (
                        <span className={classes.content__form_error}>{formik.errors.amount}</span>
                    ) : null}
                    <Button
                        classes={{ text: classes.button_text }}
                        name="Continue"
                        backgroundColor="#B00610"
                        className={classes.create_button}
                        onClick={handleWithdrawalMethod}
                    />
                </div>
            </Modal>

            {/* Coin Withdrawal */}
            <Modal open={modal.coins} onClose={() => setModal({...modal, coins: false})}>
                <BuyCoins />
            </Modal>

            {/* Withdrawal Method */}
            <Modal open={modal.method} onClose={() => setModal({...modal, method: false})}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "30px",
                    minHeight: "260px",
                    minWidth: "400px"
                  }}
                >
                    <h2>Select withdrawal Options</h2>
                    <p>Click any of the option below to make withdrawal</p>
                    <br />
                    <List sx={{ width: '100%', maxWidth: 360,  }}>
                      <ListItem onClick={withdrawFromWalletToBank} alignItems="flex-start" sx={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '10px',
                        borderColor: '#172A44',
                        paddingTop: "10px",
                        width: '400px',
                        cursor: 'pointer'
                      }}>
                        <ListItemAvatar>
                          <Avatar alt="Travis Howard" src="https://res.cloudinary.com/delino12/image/upload/v1716981950/wallet-logo.png" />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Withdrawal into my bank account"
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline', fontSize: "14px" }}
                                component="span"
                                variant="p"
                                color='info.main'
                              >
                                Available only for Naira account
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <ListItemIcon sx={{ color: "#FFFFFF" }}>
                            <ArrowForwardIosIcon />
                        </ListItemIcon>
                      </ListItem>
                      <br />
                      <Divider variant="inset" component="li" />

                      <ListItem onClick={withdrawFromWalletToPayPal} alignItems="flex-start" sx={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '10px',
                        borderColor: '#172A44',
                        paddingTop: "10px",
                        width: '400px',
                        cursor: 'pointer'
                      }}>
                        <ListItemAvatar>
                          <Avatar alt="PayPal" src="https://res.cloudinary.com/delino12/image/upload/v1716981950/wallet-logo.png" />
                        </ListItemAvatar>
                        <ListItemText
                          primary="Withdrawal using PayPal"
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline', fontSize: "14px" }}
                                component="span"
                                variant="p"
                                color='info.main'
                              >
                                Best for USD withdrawals
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <ListItemIcon sx={{ color: "#FFFFFF" }}>
                            <ArrowForwardIosIcon />
                        </ListItemIcon>
                      </ListItem>
                    </List>
                    <br />
                </div>
            </Modal>

            {/* Show Bank Details Preview Modal */}
            <Modal open={modal.bank} onClose={() => setModal({...modal, bank: false})}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "30px",
                    minHeight: "260px",
                    minWidth: "300px"
                  }}
                >
                    
                    {
                        userBanks && userBanks.map((bank) => {
                            let ngnAmount = parseInt(settings && settings.find((data) => data.name === "usd_rate").data) *  parseInt(formik.values.amount);
                            if(bank.is_active === true ) {
                                return (
                                    <div>
                                        <h2>Bank Details</h2>
                                        <Typography
                                            sx={{ display: 'inline', fontSize: "14px" }}
                                            component="span"
                                            variant="p"
                                            color='info.main'
                                          >Please note that transactions take up to 24 hours to complete.<br /> You will be charged 3% as Transaction fee.
                                        </Typography>
                                    
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 320, backgroundColor: "#0F2139"}} aria-label="simple table">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell sx={{ fontWeight: "bold", color: "#FFF"}}>Transaction Preview</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow
                                                      key="1"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Amount
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            ${formik.values.amount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                      key="2"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Bank Name
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            {bank.bank_name}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                      key="3"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Account Number
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            {bank.account_number}
                                                        </TableCell>
                                                    </TableRow>
                                                    

                                                    <TableRow
                                                      key="4"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Beneficiary Name
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            {bank.beneficiary_name}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow
                                                      key="5"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Currency
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            {bank.currency}
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow
                                                      key="5"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Exchanged @ ({parseInt(settings && settings.find((data) => data.name === "usd_rate").data)})
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            ₦<Numeral
                                                                value={ngnAmount}
                                                                format={"0,0.00"}
                                                            />
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow
                                                      key="7"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Transaction Fee (3%) + ₦100
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            ₦<Numeral
                                                                value={(3/100) * parseInt(ngnAmount) + 100}
                                                                format={"0,0.00"}
                                                            />
                                                        </TableCell>
                                                    </TableRow>  
                                                    <TableRow
                                                      key="7"
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                                            Total Amount
                                                        </TableCell>
                                                        <TableCell sx={{ color: "#FFF" }} align="right">
                                                            ₦<Numeral
                                                                value={parseInt(ngnAmount) - (3/100) * parseInt(ngnAmount) - 100 }
                                                                format={"0,0.00"}
                                                            />

                                                        </TableCell>
                                                    </TableRow>                                        
                                                </TableBody>
                                            </Table>
                                        </TableContainer> 

                                        <br />
                                        <Button
                                            classes={{ text: classes.button_text }}
                                            name="Continue"
                                            backgroundColor="#B00610"
                                            className={classes.create_button}
                                            onClick={formik.handleSubmit}
                                        />
                                    </div>

                                    
                                )
                            }
                        })
                    }

                    {
                        userBanks && userBanks.length <= 0 ? (
                            <div>
                                <h2>No Bank Details Found</h2>
                                <Typography
                                    sx={{ display: 'inline', fontSize: "14px" }}
                                    component="span"
                                    variant="p"
                                    color='info.main'
                                  >Go to profile screen and click on cashier tab to setup bank details.
                                </Typography>
                                <br />
                                <br />
                                <br />
                                <Button
                                    classes={{ text: classes.button_text }}
                                    name="Go to Profile"
                                    backgroundColor="#B00610"
                                    className={classes.create_button}
                                    onClick={handleProfileRedirect}
                                />
                            </div>
                            
                        ) : null
                    }
                </div>
            </Modal>

            {/* Preview Bank Transaction Details */}
            <Modal open={modal.bankPreview} onClose={() => setModal({...modal, bankPreview: false})}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "30px",
                    minHeight: "260px",
                    minWidth: "400px"
                  }}
                >
                    <h2>Confirm Transaction Details</h2>
                    <Typography
                        sx={{ display: 'inline', fontSize: "14px" }}
                        component="span"
                        variant="p"
                        color='info.main'
                      >Kindly bear in mind that transactions take up to 24 hours to complete
                    </Typography>
                    <br />
                    
                    <br />
                </div>
            </Modal>

            {/* Show PayPal Input Email Modal */}
            <Modal open={modal.paypal} onClose={() => setModal({...modal, paypal: false})}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "30px",
                    minHeight: "260px",
                    minWidth: "400px"
                  }}
                >
                    <h2>Enter your PayPal ID/Email</h2>
                    <Typography
                        sx={{ display: 'inline', fontSize: "14px" }}
                        component="span"
                        variant="p"
                        color='info.main'
                      >Provide a registered paypal emaill address to enable withdrawal
                    </Typography>
                    <br />
                    <TextField
                        required
                        placeholder="Eg. someone@example.com"
                        variant="outlined"
                        name="email"
                        type="text"
                        onChange={formik.handleChange}
                    />
                    <br />
                    <Button
                        classes={{ text: classes.button_text }}
                        name="Continue"
                        backgroundColor="#B00610"
                        className={classes.create_button}
                        onClick={showPreviewPayPalTransaction}
                    />
                </div>
            </Modal>

            {/* Preview PayPal Transaction Details */}
            <Modal open={modal.paypalPreview} onClose={() => setModal({...modal, paypalPreview: false})}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "30px",
                    minHeight: "260px",
                    minWidth: "400px"
                  }}
                >
                    <h2>Confirm Transaction Details</h2>
                    <Typography
                        sx={{ display: 'inline', fontSize: "14px" }}
                        component="span"
                        variant="p"
                        color='info.main'
                      >Kindly bear in mind that transactions take up to 24 hours to complete
                    </Typography>
                    <br />
                    <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 320, backgroundColor: "#0F2139"}} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: "bold", color: "#FFF"}}>Transaction Preview</TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                  key="1"
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                        Amount
                                    </TableCell>
                                    <TableCell sx={{ color: "#FFF" }} align="right">
                                        ${formik.values.amount}
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                  key="5"
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                        User ID / Email
                                    </TableCell>
                                    <TableCell sx={{ color: "#FFF" }} align="right">
                                        {formik.values.email}
                                    </TableCell>
                                </TableRow>

                                <TableRow
                                  key="7"
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                        Transaction Fee (3%) + USD 0.25
                                    </TableCell>
                                    <TableCell sx={{ color: "#FFF" }} align="right">
                                        $<Numeral
                                            value={(3/100) * parseInt(formik.values.amount) + 0.25 }
                                            format={"0,0.00"}
                                        />
                                    </TableCell>
                                </TableRow>  
                                <TableRow
                                  key="7"
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell sx={{ color: "#FFF" }} component="th" scope="row">
                                        Total Amount
                                    </TableCell>
                                    <TableCell sx={{ color: "#FFF" }} align="right">
                                        $<Numeral
                                            value={parseInt(formik.values.amount) - (3/100) * parseInt(formik.values.amount) - 0.25 }
                                            format={"0,0.00"}
                                        />
                                    </TableCell>
                                </TableRow>                                        
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Button
                        classes={{ text: classes.button_text }}
                        name="Continue"
                        backgroundColor="#B00610"
                        className={classes.create_button}
                        onClick={formik.handleSubmit}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default Wallet
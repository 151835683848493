import { Avatar } from '@material-ui/core';
import React from 'react'
import { useStyles } from './styles'


const Feed = ({image , content , date , name}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Avatar className={classes.img} src={image} alt={name}  />
            <div>
                <p className={classes.name}>{name}</p>
                <p className={classes.date}>{date}</p>
                <div className={classes.content}>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Feed
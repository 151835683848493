import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './styles';
import Button from '../Button/Button';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import ImageUploader from '../ImageUploader/ImageUploader';
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function CreateTeam({edit = {} , handleClose}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ loading , setLoading ] = useState({team:false , cards:true})
    const { setFeedback  } = useContext(AppContext);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        avatarImage: isEmpty(edit) 
        ? Yup.mixed().required().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024))
        : Yup.mixed().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)),
        location:Yup.string().required().label('Location'),
        members_limit:Yup.number().positive().max(12).label('Members limit')
    });

    const createTeam = async (form) => {
        setLoading({...loading , team:true})

        await axiosInstance.post("api/teams/store" , form ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading({...loading , team:false}))
    }

    const updateTeam = async (form) => {
        setLoading({...loading , team:true})
        if(form.avatar === "") delete form.avatar

        await axiosInstance.put(`api/teams/update/${edit.slug}` , form ,
  
        ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading({...loading , team:false}))
    }

    const handleSubmit = async (form) => {
        
        isEmpty(edit) ? createTeam(form) : updateTeam(form)
        
       
    }


    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("avatar" , "")
            formik.setFieldValue("avatarImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("avatar" , baseURL)
            formik.setFieldValue("avatarImage" , e?.target.files[0])
        };
    }

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            description: edit.description ?? "",
            image_file: "",
            avatar: edit.avatar ?? "",
            avatarImage: "",
            location:edit.location ?? "",
            is_public:edit.is_public ?? true,
            members_limit:edit.members_limit ?? "",
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    useEffect(() => {
        console.log(formik.values)
    }, [formik.values])


  return (
    <>
    <h2>
        {
            isEmpty(edit) ? t("create_team") : t("update_team")
        }
    </h2>
    <div className={classes.create_section}>
        <div className={classes.image_section}>
            <ImageUploader handleFile={handleFile} file={   isEmpty(edit) ? formik.values?.avatarImage :  formik.values?.avatar} id="team" />
            {formik.touched.avatarImage  && formik.errors.avatarImage ? (
                <span className={classes.content__form_error}>{formik.errors.avatarImage}</span>
            ) : null}
        </div>
        <div>
            <TextField placeholder={t("name")} variant="outlined" value={formik.values.name} name="name" onChange={formik.handleChange} fullWidth/>
            {formik.touched.name  && formik.errors.name ? (
                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                ) : null}
        </div>
        <div>
            <TextField placeholder={t('location')} value={formik.values.location} variant="outlined" name="location" onChange={formik.handleChange} fullWidth/>
            {formik.touched.location  && formik.errors.location ? (
                    <span className={classes.content__form_error}>{formik.errors.location}</span>
                ) : null}
        </div>
        <div>
            <TextField placeholder={t("member_limit")} value={formik.values.members_limit} variant="outlined" name="members_limit" type="number" onChange={formik.handleChange} fullWidth/>
            {formik.touched.members_limit  && formik.errors.members_limit ? (
                    <span className={classes.content__form_error}>{formik.errors.members_limit}</span>
                ) : null}
        </div>
        <FormControlLabel
        className={classes.textfield}
        value={formik.values.is_public}
        name="is_public"
        onChange={(e) => formik.setFieldValue("is_public" , e?.target.checked)}
        control={<Checkbox checked={formik.values.is_public}  style={{color:"#fff"}}/>}
        label="Public"
        labelPlacement="end"
        />
        <TextField placeholder={t("team_desc")} value={formik.values.description} variant="outlined" name="description" minRows="3" multiline onChange={formik.handleChange} />
        <Button name={t("confirm")}  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit} loading={loading.team}/>
        
    </div>
</>
  );
}

import axios from 'axios';

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");
const source = axios.CancelToken.source();

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_HTTPS_ENDPOINT,
    withCredentials: true,
    cancelToken: source.token,
    headers: {
        "Authorization": `Bearer ${token}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if(error && error.response.status === 401){
        if(user) localStorage.removeItem("user");
        if(token) localStorage.removeItem("token");
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if(error && error.response.status === 403 && error.response.message === "Authenticated"){
        logout();
    }

    // return error;
    return Promise.reject(error);
});


const logout = async () => {
    await axiosInstance.post("api/logout").then(() => {
        // Set a timeout to call the function after 3000 (3 seconds)
        const timeoutId = setTimeout(() => {
            window.location.href = "/login";
        }, 3000);
    });
}

export {axiosInstance, source}
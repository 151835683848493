import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  container:{
    borderRadius:"8px",
    padding:"16px 32px",
    backgroundColor:"#0F2139",
    display:"flex",
    flexDirection:"column",
    gap:"8px",
    color:"#fff",
    alignItems:"center",
    "& h2":{
        fontSize:"26px",
        margin:"0px 0px"
    },
    "& p":{
        fontSize:"14px",
        fontWeight:300,
        fontFamily:"Quicksand"
    }
},
}));


import React, { useEffect, useState } from 'react'
import { useStyles } from './styles';
import { FormControlLabel, MenuItem, Checkbox , IconButton,TextField as MuiTextfield, } from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { EditorState , ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ordinalSuffixOf } from '../../helpers/numberHelpers';
import { convertToHTML } from 'draft-convert';
import { TiPlus } from "react-icons/ti";
import { MdCancel } from 'react-icons/md';
import ImageUploader from '../ImageUploader/ImageUploader';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { t } from 'i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from '@material-ui/core/Tooltip';
import Textfield from '../Common/Textfield';
import Select from '../Common/Select';
import data from './info'



export const Details = ({ handleCheck , formik , edit = false}) => {
    const classes = useStyles();
   
    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("banner" , "")
            formik.setFieldValue("bannerImage" , "")
            return false;
        }
        reader.onload = () => {
        baseURL = reader.result;
        formik.setFieldValue("banner" , baseURL)
        formik.setFieldValue("bannerImage" , e?.target.files[0])
        };
    }

    return (
        <div className={classes.container}>
        <h2>{t("informations")}</h2>
        <div className={classes.input_container} >
             <div className={classes.input_section}>
                 <ImageUploader handleFile={handleFile} file={edit ? formik.values.banner : formik.values.bannerImage} />
                 {formik.touched.bannerImage  && formik.errors.bannerImage ? (
                        <span className={classes.content__form_error}>{formik.errors.bannerImage}</span>
                    ) : null}
            </div>
            <div className={classes.input_section}>
            
            </div>
       
            <Textfield  onChange={formik.handleChange} title="team_size" formik={formik} name="team_size"  value={formik.values.team_size}  type="number" /> 

            <Select
                title="currency"
                info={data.currency}
                name="prize_type"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.prize_type}
            >
                <MenuItem value="GC">GC</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
            </Select>   

            <Select
                title="Entry"
                name="entry"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.entry}
            >
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
            </Select>
            {
                formik.values.entry === "paid" &&
                <Textfield 
                    title="entry" 
                    formik={formik}  
                    name="entry_fee"  
                    value={formik.values.entry_fee} 
                    onChange={formik.handleChange}  
                    type="number" />
            }
                

            <Select
                title="platform"
                name="platform"
                info={data.platform}
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.platform}
            >
                <MenuItem value="mobile">Mobile</MenuItem>
                <MenuItem value="ps4">PS4</MenuItem>
                <MenuItem value="xbox">Xbox</MenuItem>
                <MenuItem value="ps5">PS5</MenuItem>
                <MenuItem value="pc">PC</MenuItem>
            </Select>
            
           
            <div className={classes.input_section}>
                <div className={classes.input_info} >
                    <FormControlLabel
                        className={classes.textfield}
                        name="is_public"
                        onChange={handleCheck}
                        control={<Checkbox  style={{color:"#fff"}} checked={formik.values.is_public}/>}
                        label={t("public")}
                        labelPlacement="end"
                        />
                    <Tooltip title={data.public} arrow>
                        <IconButton classes={{
                            root:classes.icon
                        }}>
                            <BsInfoCircleFill size={15}/>
                        </IconButton>
                    </Tooltip>
                </div>
             
            </div> 
            <div className={classes.input_section}>
                <div className={classes.input_info} >
                    <FormControlLabel
                        className={classes.textfield}
                        name="is_exclusive"
                        onChange={handleCheck}
                        control={<Checkbox  style={{color:"#fff"}} checked={formik.values.is_exclusive}/>}
                        label={t("exclusive")}
                        labelPlacement="end"
                        />
                    <Tooltip title={data.exclusive} arrow>
                        <IconButton classes={{
                            root:classes.icon
                        }}>
                            <BsInfoCircleFill size={15}/>
                        </IconButton>
                    </Tooltip>
                </div>
               
            </div>
            
        </div>
    </div>
    )
}


export const Information = ({ formik , game , events}) => {
    const classes = useStyles();
    const handleChange = address => {
        formik.setFieldValue('location' , address)
    };
    
     
    const handleSelect = address => {
        formik.setFieldValue('location' , address)
        geocodeByAddress(address).then(results => getLatLng(results[0]))
          .then(({ lat , lng }) => {
              formik.setFieldValue('lng' , String(lng))
              formik.setFieldValue('lat' , String(lat))
            })
          .catch(error => console.error('Error', error));
      };

    return (
        <div className={classes.container}>
        <h2>{t("information")}</h2>
        <div className={classes.input_container} >
            
            <Textfield info={data.name} title="name" onChange={formik.handleChange} formik={formik} value={formik.values.name}   variant="outlined" name="name" fullWidth />
   
            <Select
                title="games"
                name="games"
                info={data.game}
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.games}
            >
                {
                    game.map(({slug , name}, i) =>  <MenuItem key={i} value={slug}>{name}</MenuItem>)
                }
            </Select>
  
            <Select
                title="event"
                info={data.event}
                name="event_id"
                formik={formik}
                onChange={formik.handleChange}
                value={formik.values.event_id}
            >
                <MenuItem value="">None</MenuItem>

                {
                    events.map(({id , name}, i) =>  <MenuItem key={i} value={id}>{name}</MenuItem>)
                }
            </Select>
            
            <div className={classes.input_section}>
                <div className={classes.input_info} >
                    <p>{t("location")}</p>
                    <Tooltip title={data.location} arrow>
                        <IconButton classes={{
                            root:classes.icon
                        }}>
                            <BsInfoCircleFill size={15}/>
                        </IconButton>
                    </Tooltip>
                </div>

                <PlacesAutocomplete
                    value={formik.values.location}
                    onChange={handleChange}
                    onSelect={handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <MuiTextfield   {...getInputProps({placeholder: t("location"),className: 'location-search-input',})}  variant="outlined" name="address" fullWidth />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, i) => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : classes.suggestion_item;
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' ,color:"black"}
                                : { backgroundColor: '#ffffff', cursor: 'pointer' , color :"black" };
                                return (
                                <div
                                    key={i}
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
                {formik.touched.location  &&  formik.errors.location ? (
                            <span className={classes.content__form_error}>{formik.errors.location}</span>
                        ) : null}
            </div>

            <Textfield title="description" onChange={formik.handleChange} formik={formik}  value={formik.values.description}  placeholder={t("description")} variant="outlined" fullWidth name="description" minRows="3" multiline />
        </div>
    </div>
    )
}


export const Rules = ({ formik , edit=false}) => {
    const classes = useStyles();
    const blocksFromHtml = htmlToDraft(formik.values.rules);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [editorState, setEditorState] = useState(() => formik.values.editor ??  ( edit ? EditorState.createWithContent(contentState) : EditorState.createEmpty()));

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        formik.setFieldValue('rules' ,currentContentAsHTML !== "<p></p>" ? currentContentAsHTML : "" );
        formik.setFieldValue('editor' ,editorState );
    }


    return(
        <div className={classes.container}>
            <h2>{t("rules")}</h2>
            <Editor
              
                initialContentState={editorState}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                toolbarClassName={classes.toolbar}
                editorClassName={classes.editor}
            />
             {
                    formik.touched.rules  && formik.errors.rules ? (
                        <span className={classes.content__form_error}>{formik.errors.rules}</span>
                    ) : null
                }  
        </div>
    )
}

export const Bracket = ({formik , handleCheck}) => {
    const classes = useStyles();
    return(
        <div className={classes.container}>
            <h2>{t("bracket")}</h2>
            <div className={classes.input_container} >
                <Select
                    formik={formik}
                    title="bracket_type"
                    name="tournament_type"
                    onChange={formik.handleChange}
                    value={formik.values.tournament_type}
                    >
                    <MenuItem value="single elimination">Single Elimination</MenuItem>
                    <MenuItem value="double elimination">Double Elimination</MenuItem>
                    <MenuItem value="battle">Battle Royale</MenuItem>
                </Select>
                
                {
                    formik.values.tournament_type === "battle" ?
                    <Textfield  
                        name="slots"  
                        info={data.slots}
                        formik={formik}
                        value={formik.values.slots} 
                        onChange={formik.handleChange} 
                            />

                    :
                    <Select
                        formik={formik}
                        name="slots"
                        info={data.slots}
                        onChange={formik.handleChange}
                        value={formik.values.slots}
                    >
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={32}>32</MenuItem>
                        <MenuItem value={64}>64</MenuItem>
                        <MenuItem value={128}>128</MenuItem>
                    </Select> 
                }   
        
                <Select
                    name="ranked_by"
                    info={data.ranked_by}
                    formik={formik}
                    onChange={formik.handleChange}
                    value={formik.values.ranked_by}
                >
                    <MenuItem value="match wins">Match Wins</MenuItem>
                    <MenuItem value="points scored">Points Scored </MenuItem>
                </Select>
              
                <Select
                    name="bracket_input"
                    info={data.bracketInput}
                    formik={formik}
                    onChange={formik.handleChange}
                    value={formik.values.bracket_input}
                >
                    <MenuItem value="multi">Multi</MenuItem>
                    <MenuItem value="single">Single</MenuItem>
                </Select>

                {/* <Textfield  formik={formik} onChange={formik.handleChange} name="group_slot"  value={formik.values.group_slot} type="number" disabled/>  */}

                {
                    formik.values.tournament_type === "battle" ?
                    <>
                        <Textfield info={data.lobby} title="lobby_count" formik={formik} onChange={formik.handleChange} name="bracket_count"  value={formik.values.bracket_count}  type="number"  disabled={formik.values.team_size === 1}/> 
                        <Textfield info={data.games_per_lobby} formik={formik} onChange={formik.handleChange} name="games_count"  value={formik.values.games_count}  type="number" /> 
                        <Textfield info={data.stageCount} formik={formik} onChange={formik.handleChange} name="stage_count"  value={formik.values.stage_count}  type="number"  disabled={formik.values.team_size === 1}/> 
                    </>
                    :
                    <>
                        <Select
                            name="match_round"
                            info={data.matchRound}
                            formik={formik}
                            onChange={formik.handleChange}
                            value={formik.values.match_round}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>

                        </Select> 
                        
                        <div className={classes.input_section}>  
                            <div className={classes.input_info} >
                                <FormControlLabel
                                    className={classes.textfield}
                                    name="hold_third_place_match"
                                    value={formik.values.hold_third_place_match}
                                    onChange={handleCheck}
                                    control={<Checkbox  style={{color:"#fff"}}/>}
                                    label={t("third_place")}
                                    labelPlacement="end"
                                    />
                                <Tooltip title="Used to determine if third-place matches should be automatically generated in a tournament" arrow>
                                    <IconButton classes={{
                                        root:classes.icon
                                    }}>
                                        <BsInfoCircleFill size={15}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={classes.input_section}>  
                            <div className={classes.input_info} >
                                <FormControlLabel
                                    className={classes.textfield}
                                    name="is_robin"
                                    value={formik.values.is_robin}
                                    onChange={handleCheck}
                                    control={<Checkbox  style={{color:"#fff"}}/>}
                                    label={t("round_robin")}
                                    labelPlacement="end"
                                    />
                                <Tooltip title="Used to determine if a tournament uses RRB (round robin) framework" arrow>
                                    <IconButton classes={{
                                        root:classes.icon
                                    }}>
                                        <BsInfoCircleFill size={15}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </>
                }
                
                <div className={classes.input_section}>
                    <div className={classes.input_info} >
                        <FormControlLabel
                            className={classes.textfield}
                            name="input_limit"
                            value={formik.values.input_limit}
                            onChange={handleCheck}
                            control={<Checkbox  style={{color:"#fff"}}/>}
                            label={t("limit_input")}
                            labelPlacement="end"
                            />
                        <Tooltip title={data.inputLimit} arrow>
                            <IconButton classes={{
                                root:classes.icon
                            }}>
                                <BsInfoCircleFill size={15}/>
                            </IconButton>
                        </Tooltip>
                    </div> 
                </div>
           </div>
        </div>
    )
}

export const Prize = ({ formik  }) => {
    const [inputFields, setInputFields] = useState( [...formik.values.prize] );

    const classes = useStyles();

    const handleChangeInput = (index, event) => {
        const newInputFields = inputFields.map((i, id) => {
          if (index === id) {
              if(event.target.value == ""){
                    i[event.target.name] = 0;
              } else {

                  i[event.target.name] = event.target.value;
              }
          }
          return i;
        });   
        setInputFields(newInputFields);
    };
    
    const handleAddFields = () => {
        setInputFields([...inputFields, {  value:0 }]);
    };
    
    const handleRemoveFields = (id) => {
        const values = [...inputFields];
        values.splice(id, 1);
        setInputFields(values);
    };

    useEffect(() => {
        formik.setFieldValue('prize' , inputFields)
    }, [inputFields])

    return(
        <div className={classes.container}>
            <h2>{t("prize")}</h2>
            <div className={classes.prize_container}>
                { 
                    inputFields.map((item,index) => {
                        return (
                            <div className={classes.input_section} key={index}>
                                <p>{ordinalSuffixOf(index + 1)}</p>
                                <div style={{display:"flex"}}>
                                    <MuiTextfield  
                                        name="value" 
                                        className={classes.textfield}
                                        defaultValue={item.value}
                                        onChange={(event) => handleChangeInput(index, event)}
                                        variant="outlined" 
                                        type="number"
                                        placeholder={t("prize")} />    
                                    <IconButton
                                        className={classes.delete}
                                        disabled={inputFields.length === 1}
                                        onClick={() => handleRemoveFields(index)}
                                    >
                                        <MdCancel />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    formik.touched.prize  && formik.errors.prize ? (
                        <span className={classes.content__form_error}>{formik.errors.prize}</span>
                    ) : null
                }  
            </div>
            {
                <span className={classes.add} onClick={() => handleAddFields()}><TiPlus/>{t("add_prize")}</span>
            }
        </div>
    )
}

export const DateTime = ({formik }) => {
    const classes = useStyles();
      
    return (
        <div className={classes.container}>
            <h2>{t("date")}</h2>
            <div className={classes.input_container} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className={classes.input_section}>
                        <p>{t("start_time")}</p>
                        <DateTimePicker  inputVariant="outlined" value={formik.values.start_at} onChange={(date) => formik.setFieldValue('start_at' , date)} style={{width:"100%"}}/>
                        {formik.touched.start_at && formik.errors.start_at ? (
                            <span className={classes.content__form_error}>{formik.errors.start_at}</span>
                        ) : null}
                    </div>
                    <div className={classes.input_section}>
                        <p>{t("end_time")}</p>
                        <DateTimePicker  inputVariant="outlined" value={formik.values.ended_at} onChange={(date) => formik.setFieldValue('ended_at' , date)} style={{width:"100%"}}/>
                        {formik.touched.ended_at && formik.errors.ended_at ? (
                            <span className={classes.content__form_error}>{formik.errors.ended_at}</span>
                        ) : null}
                    </div>
                </MuiPickersUtilsProvider>
                <Textfield info={data.check_in} formik={formik} defaultValue={1} value={formik.values.check_in_duration} onChange={formik.handleChange} name="check_in_duration"  type="number"   /> 
            </div>
        </div>
    )
}
import React , {useEffect, useRef, useState, useContext}from 'react';
import {useStyles} from './styles';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '../../components/Button/Button';
import {Link} from 'react-router-dom';
import {useHistory, useParams} from 'react-router';
import * as Yup from 'yup';
import {axiosInstance} from '../../helpers/client';
import PasswordField from '../../components/PasswordField/PasswordField';
import {useFormik, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../context/appContext';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from "../../components/Modal/Modal";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import logo from '../../asset/img/logo.png';

const Account = () => {
    const classes = useStyles();
    const history =  useHistory();
    const {token} = useParams();
    const [feedback, setFeedback] = useState({
        message: "", 
        severity: ""
    });
    const [loading, setLoading] = useState(false);
    const [isMoreReason, setIsMoreReason] = useState(false);
    const [moreReason, setMoreReason] = useState('');
    const [reason, setReason] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [extraReason, setExtraReason] = useState('');
    const {loggedIn, user, setLogin} = useContext(AppContext);
    const { t } = useTranslation();

    const handleChange = async (event) => {
        setReason(event.target.value);
        if(event.target.value === "Other reason"){
            setIsMoreReason(true);
        }else{
            setIsMoreReason(false);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setFeedback({});
    }

    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const handleLastName = (event) => {
        setLastName(event.target.value);
    }

    const handleUsername = (event) => {
        setUsername(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleMoreReason = (event) => {
        setExtraReason(event.target.value);
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    }

    const terminateAccount = async () => {
        setLoading(true);
        if(reason === ""){
            setFeedback({message: "You have to provide us with a reason to want your account deleted!", severity: "warning"});
            return false;
        }
        if(email === ""){
            setFeedback({message: "Provid a valid email address", severity: "warning"});
            return false;
        }
        if(phoneNumber === ""){
            setFeedback({message: "Provide a valid phone number", severity: "warning"});
            return false;
        }
        let payload = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "username": username,
            "phone": phoneNumber,
            "reason": reason,
            "more_reason": extraReason,
        }
        await axiosInstance.post(`api/deletion/request`, payload).then(function ({data}) {
            setFeedback({message: data.message, severity: data.status});
            if(data.status === "success"){
                logout();
            }
        }).catch(({ response }) => {
            setFeedback({message: response.data.message, severity:"error"});
        }).finally(() => {
            setLoading(false)
        });
    }

    const logout = async () => {
        // Set a timeout to call the function after 5000 milliseconds (5 seconds)
        const timeoutId = setTimeout(() => {
            localStorage.clear();
            clearTimeout(timeoutId);
            window.location.href = "/home";
        }, 3000);
    }

    return (
        <div className={classes.container} >
            <div style={{display:'flex', justifyContent:"flex-end",paddingRight:"20px"}}>
                <LanguageSwitch />
            </div>
            <div style={{display:"flex" , justifyContent:"center"}}>

                <div className={classes.login_section}>
                    <br />
                    <img onClick={() => history.push('/home')} style={{marginBottom:"-8px", cursor:"pointer"}} src={logo} alt="logo" width="100px" />
                    <br />
                    <br />
                    <h2>{t("Account deletion request!")}</h2>
                    <br />
                    <p className={classes.p}> 
                        <span className={classes.warning}>{t("Warning")}: </span> 
                        {t("Deleting personal Gamr account removes all tournament participation ownership from your account. ")}
                    </p>
                    <br />
                    <p className={classes.p}> 
                        {t("All the tournaments you have created or joined, all your activities, wallet balance, subscription and tournament prize pool will be removed from Gamr forever. You will not be able to restore the content once deleted. ")}
                    </p>
                    <br />
                    <FormControl fullWidth>
                        <TextField
                            required
                            className={classes.textfield}
                            InputProps={{
                                style: {color: "white"}
                            }}
                            id="outlined-basic" 
                            label="First name" 
                            variant="outlined"
                            onChange={handleFirstName} />
                    </FormControl>
                    <br /><br />
                    <FormControl fullWidth>
                        <TextField
                            required 
                            className={classes.textfield}
                            InputProps={{
                                style: {color: "white"}
                            }}
                            id="outlined-basic" 
                            label="Last name" 
                            variant="outlined"
                            onChange={handleLastName} />
                    </FormControl>
                    <br /><br />
                    <FormControl fullWidth>
                        <TextField
                            required 
                            className={classes.textfield}
                            InputProps={{
                                style: {color: "white"}
                            }}
                            id="outlined-basic" 
                            label="Username" 
                            variant="outlined"
                            onChange={handleUsername} />
                    </FormControl>
                    <br /><br />
                    <FormControl fullWidth>
                        <TextField
                            required 
                            className={classes.textfield}
                            InputProps={{
                                style: {color: "white"}
                            }}
                            label="Email"
                            onChange={handleEmail} />
                    </FormControl>
                    <br /><br />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Why are you deleting your account?</InputLabel>
                        <Select
                            style={{backgroundColor: "#fff"}}
                            className={classes.selectField}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reason}
                            label="Why are you deleting your account?"
                            onChange={handleChange}
                        >
                            <MenuItem value="I don’t need it anymore">{t("I don’t need it anymore")}</MenuItem>
                            <MenuItem value="I’m switching to another tournament platform">{t("I’m switching to another tournament platform")}</MenuItem>
                            <MenuItem value="Gamr subscription is too expensive">{t("Gamr subscription is too expensive")}</MenuItem>
                            <MenuItem value="Other reason">{t("Other reason")}</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    {(isMoreReason ) ? (
                        <FormControl style={{paddingBottom: "10px", marginBottom: "10px"}} fullWidth>
                            <TextField 
                                className={classes.textfield}
                                InputProps={{
                                    style: {color: "white"}
                                }}
                                id="outlined-basic" 
                                label="Tell us why you want to leave" 
                                variant="outlined"
                                onChange={handleMoreReason} />
                        </FormControl> 
                    ) : ""}
                    <FormControl fullWidth>
                        <TextField 
                            required
                            className={classes.textfield}
                            InputProps={{
                                style: {color: "white"}
                            }}
                            label="Phone number"
                            onChange={handlePhoneNumber} />
                    </FormControl>
                    <br /><br />
                    <Button className={classes.btnRed} backgroundColor="#B00610" name={t("Submit Request")} onClick={terminateAccount}/>  
                </div>
            </div>
            <Snackbar open={!!feedback.message} autoHideDuration={6000} onClose={handleClose}  key={"bottom" + "left"}  anchorOrigin={{ vertical:"bottom", horizontal:"left" }}>
                <MuiAlert  elevation={6} variant="filled" onClose={handleClose} severity={feedback.severity}>
                        {feedback.message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default Account
import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        flex: "1 1 auto",
        width:"360px",
        borderRadius: "8px",
        backgroundColor: "#0F2139",
        alignItems:"center",
        padding: theme.spacing(2),
        display: "flex",
        justifyContent:"space-between",
        color:"white",
        [theme.breakpoints.down('sm')] :{
            minWidth:"320px",
        }
    },
}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  info_skeleton_rect:{
    width:"360px",
    flex: "1 1 auto",
    borderRadius:theme.spacing(1)
},
}));

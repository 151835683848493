import React, { useContext } from 'react';
import { useStyles } from './styles';
import RectangleCard from '../../components/RectangleCard/RectangleCard';
import MyCarousel from '../../components/Carousel/Carousel';
import { useHistory } from 'react-router';
import { TournamentContext } from '../../context/tournamentContext';
import Loading from '../../components/Loading/Loading';
import { useTranslation } from "react-i18next";
import { useQuery } from 'react-query';
import { getTournaments } from 'api/tournaments';


const Main = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { data:tournament, isLoading } = useQuery('getTournaments', getTournaments);
    const { changeTournament } = useContext(TournamentContext);


    const handleTournamentClick = (props) => {
        changeTournament(props);
        history.push(`/home/tournament/${props.slug}`);
    }

    return (
        <div className={classes.container}>
            <MyCarousel />

            <div className={classes.load_tournament_section}>
                <div className={classes.title}>
                    <h2>{t("latest")}</h2>
                    <p onClick={() => history.push("/home/tournament")}>{t("see_all")}</p>
                </div>
                <div className={classes.load_tournament}>
                    {
                        !isLoading ?
                        tournament.map(({name, entry_fee, games, is_exclusive, banner, prize_pool, currency, started_at, ended_at, id, slug}, index) => 
                        <RectangleCard
                            key={index}
                            exclusive={is_exclusive}
                            fee={entry_fee}
                            description={name}
                            game={games[0]}
                            start={started_at} 
                            end={ended_at} 
                            prize={prize_pool} 
                            image={banner}
                            currency={currency}
                            onClick={() => handleTournamentClick({id ,name, banner  , prize_pool, started_at , ended_at , slug})} />
                        ) :
                        <>
                           <Loading />
                           <Loading />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Main;
import { FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from '@material-ui/core'
import React from 'react'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import { initializePayment } from "../../helpers/transaction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStyles } from './styles';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from 'context/appContext';

export default function Deposit( {...props }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AppContext);


    const validationSchema = Yup.object().shape({
        gateway: Yup.string().required().label("Payment Gateway"),
        amount: Yup.number().required().positive().integer().label("Amount"),
    });
    
    const handleSubmit = ({amount , gateway}) => {
      setLoading(true)

      let gatewayData = {};

      switch(gateway){
        case "flutterwave":
          gatewayData = {
            amount,
            payment_options: "id",
            redirect_url: `${process.env.REACT_APP_FRONT_DOMAIN}/home?paymentType=credit&gateway=flutterwave`,
            meta: {
              payment_type: "credit",
            },
            customizations: {
              title: "Gamr Deposit",
              description: `${user.name} is making a deposit of ${amount}`,
            },
          };
          break;
        case "paystack":
            gatewayData = {
              amount,
              channels: ["card"],
              metadata: {
                payment_type: "credit",
              },
              callback_url:  `${process.env.REACT_APP_FRONT_DOMAIN}home?paymentType=credit&gateway=paystack`,
            };
            break;
        case "paypal":
          gatewayData = {
            amount,
            description: "Paypal Deposit",
            return_url:  `${process.env.REACT_APP_FRONT_DOMAIN}home?paymentType=credit&gateway=paypal`,
          };
          break;
        default:
          break
      }

      initializePayment(gateway , gatewayData)
    }
    
    

    const formik = useFormik({
        initialValues: {
            gateway: "flutterwave",
            amount: "",
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });
    
  return (
    <Modal {...props}>
        <div className={classes.tab_section}>
            <div
                style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "8px 0px",
                }}
            >
                <Select
                    variant="outlined"
                    value={formik.values.gateway}
                    error={!!formik.errors?.gateway}
                    onChange={formik.handleChange}
                    name="gateway"
                >
                    <MenuItem value={"paypal"}>Paypal</MenuItem>
                    <MenuItem value={"flutterwave"}>Flutterwave</MenuItem>
                </Select>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
                <OutlinedInput
                    id="outlined-adornment-amount"
                    placeholder="Amount"
                    
                    name="amount"
                    startAdornment={<InputAdornment color="white" position="start">$</InputAdornment>}
                    type="number"
                    onChange={formik.handleChange}
                    // labelWidth={60}
                />
                </FormControl>
                    { formik.errors.amount ? (
                            <span className={classes.content__form_error}>{formik.errors.amount}</span>
                        ) : null}
                <Button
                    classes={{ text: classes.button_text }}
                    name="Continue"
                    loading={loading}
                    backgroundColor="#B00610"
                    className={classes.create_button}
                    onClick={formik.handleSubmit}
                />
            </div>            
        </div>
    </Modal>
  )
}

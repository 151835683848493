import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        display:"flex",
        width:"38%",
        alignItems:"center",
        flexDirection:"column",
        borderRadius:"8px",
        border:"0.5px solid #5A79A5",
        padding:theme.spacing(2),
        paddingTop:theme.spacing(4),
        marginRight:"8px",
        // "&:hover":{
        //     border:"1px solid #E8454E",
        // }
    },
   
    price:{
        fontSize:"32px",
        fontWeight:500,
        marginTop:"8px",
        fontFamily:"Quicksand",
        color:"#fff",
        [theme.breakpoints.down('sm')]:{
            fontSize:"28px"
        }
    },
    info:{
        color: "#546884",
        fontSize:"14px",
        fontWeight:300,
        margin:theme.spacing(1, 0),
        fontFamily:"Quicksand",
    }
}));

import React , {useEffect, useRef, useState, useContext}from 'react';
import {useStyles} from './styles';
import {Snackbar, TextField} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '../../components/Button/Button';
import {Link} from 'react-router-dom';
import {useHistory, useParams} from 'react-router';
import * as Yup from 'yup';
import {axiosInstance} from '../../helpers/client';
import PasswordField from '../../components/PasswordField/PasswordField';
import PhoneNumberField from '../../components/PhoneNumberField/PhoneNumberField';
import {useFormik, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../context/appContext';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import logo from '../../asset/img/logo.png';

const PhoneNumberVerification = () => {
    const classes = useStyles();
    const history =  useHistory()
    const {token} = useParams();
    const [feedback, setFeedback] = useState({
        message: "", severity: ""
    });
    const [countryCode, setCountryCode] = useState("234");
    const [reference, setReference] = useState("");
    const [toggleCode, setToggleCode] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const {loggedIn, user, setLogin} = useContext(AppContext);
    const { t } = useTranslation();

    const handleChangeCountryCode = (e) => {
        setCountryCode(e.target.value);
    }

    const handleSubmit = async ({phone_number}) => {
        let userPhone = `+${countryCode}${phone_number}`;
        let cleanPhone = userPhone.replace("+2340", "+234");
        setLoading(true);
        await axiosInstance.post('api/phone/otp', {
            number: cleanPhone,
        }).then(function ({data}) {
            setFeedback({message: `An OTP (One time password) code has been sent to ${phone_number}`});
            setReference(data.reference);
            setToggleCode(true);
        }).catch(function ({response}) {
            setFeedback({message: "Error sending phone number verification request!"});
        }).finally(() => { 
            setLoading(false)
        });
    }

    const handleOtpSubmit = async () => {
        // console.log(code);
        setLoading(true);
        await axiosInstance.post('api/phone/verify', {
            reference: reference,
            code: code
        }).then(function ({data}) {
            setFeedback({message: `Verification successful!`});
            goHome();
        }).catch(function ({response}) {
            setFeedback({message: "Error validating OTP Code request!"});
        }).finally(() => { 
            setLoading(false)
        });
    }

    const handleChange = (e) => {
        // console.log("Hello");
    }

    const skipPhoneVerification = () => {
        window.location.href = "/home";
    }

    const handleOtpChange = async (e) => {
        console.log(e.target.value);
        setCode(e.target.value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setFeedback({});
    }

    const handleOtpResend = () => {
        setFeedback({message: `Check phone number and try again!`});
        setToggleCode(false);
    }

    const validationSchema = Yup.object().shape({
        phone_number: Yup.string().required("No phone number provided.").min(6, "Phone number is too short.").matches(/^\d+$/, "Must be numeric or digits only!"),
    });

    const formik = useFormik({
        initialValues:{
            phone_number: ""
        },
        onChange: handleChange,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    const logout = async () => {
        await axiosInstance.post("api/logout").then(() => {
            // Set a timeout to call the function after 5000 milliseconds (5 seconds)
            const timeoutId = setTimeout(() => {
                setLogin(false);
                localStorage.clear();
                clearTimeout(timeoutId);
                window.location.href = "/home";
            }, 3000);
        });
    }

    const goHome = async () => {
        // Set a timeout to call the function after 5000 milliseconds (5 seconds)
        const timeoutId = setTimeout(() => {
            setLogin(false);
            clearTimeout(timeoutId);
            window.location.href = "/home";
        }, 3000);
    }

    return (
        <div className={classes.container} >
            <div style={{display:'flex', justifyContent:"flex-end",paddingRight:"20px"}}>
                <LanguageSwitch />
            </div>
            <div style={{display:"flex" , justifyContent:"center"}}>
                <div className={classes.login_section}>
                    <br />
                    <img onClick={() => history.push('/home')} style={{marginBottom:"-8px", cursor:"pointer"}} src={logo} alt="logo" width="100px" />
                    <br />
                    <br />
                    <h3>Verify account phone number</h3>
                    <p>To add extra security to your account we recommend verifying your mobile phone number</p>
                    <br />

                    <div>
                        <div className={classes.input}>
                            {(toggleCode == true) ? (
                                <div>
                                    <div className={classes.input_container}>
                                        <TextField className={classes.textfield} id="otp" name="otp" variant="outlined" label="OTP Code" inputProps={{ maxLength: 4 }} onChange={handleOtpChange} />
                                    </div>
                                    <div className={classes.input_container}>
                                        <Button loading={loading} backgroundColor="#B00610"  name={t("Confirm")} classes={{text:classes.button_text}} onClick={handleOtpSubmit}/>    
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className={classes.input_container}>
                                        <PhoneNumberField countryCode={countryCode} handleCountryCode={handleChangeCountryCode} id="phone_number" onChange={formik.handleChange} name="phone_number" />
                                        {formik.errors.phone_number && (
                                            <span className={classes.content__form_error}>{formik.errors.phone_number}</span>
                                        )}

                                    </div>
                                    <div className={classes.input_container}>
                                        <Button loading={loading} backgroundColor="#B00610"  name={t("Verify Phone Number")} classes={{text:classes.button_text}} onClick={formik.handleSubmit}/>    
                                    </div>
                                    <div className={classes.input_container}>
                                        <Button loading={loading} backgroundColor="#070515"  name={t("Skip Verification")} classes={{text:classes.button_text}} onClick={skipPhoneVerification}/>    
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={!!feedback.message} autoHideDuration={6000} onClose={handleClose}  key={"bottom" + "left"}  anchorOrigin={{ vertical:"bottom", horizontal:"left" }}>
                <MuiAlert  elevation={6} variant="filled" onClose={handleClose} severity={feedback.severity}>
                        {feedback.message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default PhoneNumberVerification
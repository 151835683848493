import { Avatar, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { useRef } from 'react'
import { axiosInstance } from '../../helpers/client'
import Button from '../Button/Button'


export default function Reply({ postSlug , userReply , resetReply , refetch, replyRef}) {

    const reply = async ({ body} , { resetForm}) => {

       
        await axiosInstance.post(`api/forum/posts/${postSlug}/replies`, {
            body,
            replied: userReply.slug
        }).then(() => {
            resetReply()
            resetForm()
            refetch()
        })
    }

    const formik = useFormik({
        initialValues:{
            body:"",
            replied: userReply.slug 
        },
        onSubmit:reply
    })

    const handleCancel = () => {
        formik.resetForm()
        resetReply()
    }
    
    return (
        <div style={{margin:"16px 0"}}>
            
            <div style={{display:"flex", gap:"8px" , marginBottom:"10px", alignItems:"center"}}>
                <Avatar />
                <div style={{ width:"100%"}}>
                    <p style={{
                        fontSize:"12px",
                        color: "rgba(215 , 215 , 215 , 0.7)",
                        marginBottom:"8px",
                        fontStyle:"italic",
                        textOverflow:"ellipsis",
                        overflow:"hidden"
                    }}>{userReply.body}</p>

                    <TextField
                        variant='outlined'
                        fullWidth
                        multiline
                        inputRef={replyRef}
                        placeholder={`Reply to ${userReply.user.username ?? 'post'}`}
                        value={formik.values.body}
                        onChange={formik.handleChange('body')}
                        minRows={1}
                    />
                </div>
            </div>
            <div style={{display:'flex', justifyContent:"flex-end", gap:"8px"}}>
                <Button name="Send" onClick={() => formik.handleSubmit()}/>
                <Button name="Cancel" onClick={() => handleCancel()}/>
            </div>
        </div>
    )
}

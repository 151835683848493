import React from 'react'
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './styles';


export default function Loading() {
    const classes = useStyles();

    return (
        <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={160} className={classes.info_skeleton_rect} animation="wave" /> 
    )
}

import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "./styles";
import { Skeleton } from "@material-ui/lab";
import { get } from "lodash";
import Button from '../../components/Button/Button';
import { useTranslation } from "react-i18next";


export default function Table({
  loading = false,
  schema,
  shortLoading = false,
  collection,
  identifier,
  onClick,
  handlePage,
  page,
  handleChangeRowsPerPage,
  rowsPerPage,
  total,
  handlePrev,
  handleNext,
  links,
  paginate = true
}) {
  const classes = useStyles();
  const { t } = useTranslation()
 

  return (
    <>
      <TableContainer component={Paper}>
        <MuiTable className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {schema.map(({ label }, index) => (
                <TableCell key={index} className={classes.tableHeadCell}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          { 
          loading 
          ?
          <TableBody>
            {schema.map((_, index) => (
              <TableRow key={index} classes={{root: classes.table_row_root}}>
                {
                  schema.map((t , i) =>  <TableCell key={i}> <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={ shortLoading ? 80 : 200}/></TableCell>)
                }
              </TableRow>
            ))}
          </TableBody> 
          :
          <TableBody style={{ borderBottom: "none" }}>
            {( collection
            ).map((item, rowIndex) => {
              const uniqueId = identifier ? get(item, identifier) : rowIndex;

              return (
                <TableRow
                  key={rowIndex}
                  classes={{
                    root: classes.table_row_root,
                  }}
                  onClick={() => {
                    if (onClick) {
                      const index = page * rowsPerPage + rowIndex;
                      onClick(index);
                    }
                  }}
                  style={{ cursor: onClick ? "pointer" : "null" }}
                >
                  {schema.map((SchemaItem, cellIndex) => {
                    return "component" in SchemaItem ? (
                      <TableCell
                        key={cellIndex}
                        className={classes.tableBodyCell}
                      >
                        {SchemaItem.component({
                          item,
                          uniqueId,
                        })}
                      </TableCell>
                    ) : (
                      <TableCell
                        key={cellIndex}
                        className={classes.tableBodyCell}
                      >
                        {SchemaItem.func({ item, uniqueId })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          }
        </MuiTable>
      </TableContainer>
      {
        paginate && 
        <div style={{marginTop:"16px" , display:"flex" , gap:"12px" , justifyContent:"flex-end"}}>

            <Select
                  placeholder={t("rows")}
                  variant="outlined"
                  className={classes.select}
                  value={rowsPerPage}
                  defaultValue={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
              >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
              </Select>
              { links.prev && 

              <Button
                name={t("prev")}
                classes={{text:classes.button_text}}
                onClick={handlePrev}
                className={classes.backButton}
              />
              }
              {
                links.next &&

                <Button 
                    classes={{text:classes.button_text}} 
                    loading={loading} 
                    backgroundColor="#B00610" 
                    name={t("next")} 
                    onClick={handleNext} 
                    />
              }
        </div>
      }
    </>
  );
}

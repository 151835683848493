import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        height:"60px",
        backgroundColor:"#0A091A",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        color:"white",
        boxSizing:"border-box"
    },
    two_factor:{
      display:"flex",
      justifyContent:"space-between"
    },
    icons:{
      display:"flex",
      margin: theme.spacing(3,0),
      gap:theme.spacing(2),
      alignItems:"center"
  },
  button_text:{
    padding:theme.spacing(1.5 , 2)
  },
  input:{
      display:"flex",
      flexDirection:"column",
      gap:theme.spacing(2),
  },
  input_container:{
    display:"flex",
    width:"100%",
    flexDirection:"column",
    gap:theme.spacing(1),
    [theme.breakpoints.down('sm')] :{
      width:"100%"
    }
},
modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
barcode:{
    marginBottom:theme.spacing(1)
},

barcode_container: {
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  color:"#fff",
  "& h2":{
      fontSize:"18px",
      marginBottom: "8px",
      marginTop: "0px"
  },
  
},
  content__form_error:{
    fontSize:"12px",
    color:"red",
  },
}));

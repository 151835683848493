import React from 'react'
import { useStyles } from './styles';
import { format  } from "date-fns";
import inflection from 'inflection';

const TransactionCard = ({amount , status , type ,paymentMethod, isCredit, comment , date , currency , reference, ...props}) => {
    const classes = useStyles();
    let d = new Date(0)
    d.setUTCSeconds(date)
    const time = format( d, 'do MMM yyyy')

    return (
        <div className={classes.container} style={
              {
                border:`0.2px solid ${isCredit ?  '#00B628' : '#BA5B60'}`
            }
        }>
            <div>
                <p>{inflection.titleize(type )}</p>
                {/* <p>{reference}</p> */}
                <p>{time}</p>
            </div>
            <div>
                <p>{inflection.capitalize(status)}</p>
                <p>{comment}</p>
            </div>
            <p>{currency} <span style={
              {
                color:`${isCredit ? '#00B628' : '#BA5B60'}`
            }
        }>{amount}</span></p>
        </div>
    )
}

export default TransactionCard;
import React, { useContext, useEffect, useState } from 'react'
import { axiosInstance } from '../../helpers/client'
import Switch from '@material-ui/core/Switch';
import { useStyles } from './styles'
import { FormControl, FormControlLabel, OutlinedInput, Checkbox, CircularProgress, TextField } from '@material-ui/core';
import Button from '../../components/Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { AppContext } from '../../context/appContext';
import OtpInput from 'react-otp-input';
import { debounce } from 'lodash-es';
import Modal from '../Modal/Modal';
import { useTranslation } from 'react-i18next';



const Security = ({ twoFactorEnabled , refetch}) => {
    const classes = useStyles()
    const [twoFactor ] = useState(twoFactorEnabled.length > 0 ? twoFactorEnabled[0]?.enabled : false)
    const [openBarcode, setOpenBarcode] = useState(false);
    const [ loading , setLoading ] = useState({password: false , verify:false});
    const [ barcode , setBarcode ] = useState("");
    const [ secret , setSecret ] = useState("");
    const { setFeedback } = useContext(AppContext)
    const { t } = useTranslation();


    const handleSchema = Yup.object().shape({
        password: Yup.string().required().min(8).label('Password'),
        password_confirmation:  Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        old_password: Yup.string().required().label("Old Password")
    })

    const handleOpenBarode = () => {
        setOpenBarcode(true);
      };
    
      const handleCloseBarcode = () => {
        setOpenBarcode(false);
      };

    const formValues = [
        {
            name: "old_password",
            label: t("old_password")
        },
        {
            name:"password",
            label: t("new_password")
        },
        {
            name:"password_confirmation",
            label: t("confirm_password")
        },
    ]
    const handleSubmit = async (form) => {
        setLoading(true)
        await axiosInstance.put('api/account/update/password' , form).then(({data}) => {
                setFeedback({message:data.message , severity:"success" })
            }).catch(({response}) => {
                if(response.status === 400){
                    formik.setFieldError('password_confirmation' , response.data.message)
                } else {
                    setFeedback({ message:response.data.message , severity:"error"})
                }
            })
            .finally(() => setLoading(false))
    }

    const formik = useFormik({
        initialValues: {
          password: "",
          old_password: "",
          secret:"",
          sign_out_devices: false,
        },
        onSubmit: handleSubmit,
        validationSchema:handleSchema
    });

    
    
    const handleChange = (event) => {
        
        setOpenBarcode(true)
        if(event.target.checked){
            generate2faSecret()
        } 
            
    };

    const generate2faSecret = async () => {
        await axiosInstance.post('api/auth/key/generate' , {
            channel :"google2fa"
        }).then(({data}) => {
            enable2fa()
        }).catch(({response}) => console.log(response))
    }


    const enable2fa = async () => {
        await axiosInstance.get('api/auth/url?channel=google2fa' , {
            channel :"google2fa"
        }).then(({data}) => {
            setBarcode(data.data.url)
        }).catch(({response}) => console.log(response))
    }

    const disable2fa = async () => {
        await axiosInstance.post('api/auth/disable' , {
            channel :"google2fa",
            password: formik.values.password
        }).then(({data}) => {
            refetch()
            handleCloseBarcode()
            setFeedback({message:data.message , severity:"success"})

        }).catch(({response}) => {
            setFeedback({message:response.data.message , severity:"error"})
        })
    }

    const reauthenticate = async () => {
        await axiosInstance.get(`api/account?one_time_password=${secret}`).then(() => setFeedback({}))
    }

    const verify = debounce(async () => {
        setLoading({...loading , verify:true})
        await axiosInstance.post('api/auth/enable' , {
            channel :"google2fa",
            secret,
        }).then(({data}) => {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            reauthenticate()
            handleCloseBarcode()
        }).catch(({response}) => {           
            reauthenticate()
            setFeedback({message:response.data.message , severity:"error"})
        })
        .finally(() =>  setLoading({...loading , verify:false}))
    },2000)


    useEffect(() => {
        if(secret.length === 6) {
            verify()
        }
     }, [secret])


    return (
        <div>
            <div>
                <h2>{t("2fa")}</h2>
                <div className={classes.two_factor}>
                    <p>{t("2fa_info")}</p>
                    <FormControlLabel
                        control={
                        <Switch
                            color="primary"
                            checked={twoFactor}
                            onChange={handleChange}
                            name="twoFactor"
                        />
                        }
                    />
                </div>
                <Modal
                    open={openBarcode}
                    onClose={handleCloseBarcode}
                >
                        <div >
                            {
                                twoFactor ? 
                                <div style={{display:"flex" , gap:"8px"}}> 
                                    <TextField placeholder='Password' type="password" name="password" variant="outlined" fullWidth onChange={formik.handleChange} />
                                    <Button classes={{text:classes.button_text}} loading={loading.disable} backgroundColor="#B00610" name='Confirm' onClick={disable2fa}/>
                                </div>
                                :
                                <div className={classes.barcode_container}>
                                <div className={classes.barcode} dangerouslySetInnerHTML={{__html:barcode}}/>
                                <h2>{t("scan_code")}</h2>
                                {
                                    loading.verify ? <CircularProgress color="inherit"/> :
                                    <OtpInput
                                        value={secret}
                                        onChange={(otp) => setSecret(otp)}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                        inputStyle={{
                                            width:"50px",
                                            height:"50px",
                                            border:"1px solid #ccc",
                                            backgroundColor:"transparent",
                                            color:"#fff"
                                        }}
                                    />
                                }
                                </div>
                            }
                        </div>
                </Modal>
               
                
            </div>
            <div>
                <h2>{t("change_password")}</h2>
                <div className={classes.password}>
                    <div className={classes.input_container}>
                        {
                            formValues.map(({name , label}, i) => 
                                <div key={i}>
                                    <FormControl variant="outlined">
                                        
                                        <OutlinedInput  
                                        autoComplete="new-password"
                                        id="outlined-adornment-password" 
                                        type={ 'password'}
                                        onChange={formik.handleChange}  
                                        name={name}
                                        placeholder={label}
                                         
                                        />
                                    </FormControl>
                                    {
                                        (formik.touched[name] && formik.errors[name]) && (
                                        <span className={classes.content__form_error}>{formik.errors[name]}</span>
                                        )
                                    }
                
                                </div>
                            )
                        }
                        <FormControlLabel
                            className={classes.textfield}
                            value={formik.values.sign_out_devices}
                            name="sign_out_devices"
                            onChange={(e) => formik.setFieldValue("sign_out_devices" , e?.target.checked)}
                            control={<Checkbox checked={formik.values.sign_out_devices}  style={{color:"#fff"}}/>}
                            label={t("sign_out_devices")}
                            labelPlacement="end"
                            />
                    </div>
                </div>
            </div>
            <div style={{ marginTop:"16px" ,marginBottom:"60px"}}>
                <Button classes={{text:classes.button_text}} loading={loading.password} backgroundColor="#B00610" name={t("update")} onClick={formik.handleSubmit}/>
            </div>
        </div>
    )
}

export default Security
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(0, 2),
        color:"#fff",
        "& h2": {
            fontSize:"22px"
        },
        [theme.breakpoints.down("sm")]:{
            padding:0,
            boxSizing:"border-box",
            paddingBottom:"16px"
        }
    },
    title:{
        fontSize: "18px",
        fontWeight: 700,
        color: "white",
        marginBottom: "18px",
        marginTop: "18px"
    },
    subTitle:{
        fontSize: "14px",
        fontWeight: 400,
        color: "white",
        marginBottom: "18px",
        marginTop: "18px"
    },
    headerTitle:{
        fontSize: "14px",
        fontWeight: 700,
        color: "#00ff99",
        marginBottom: "18px",
        marginTop: "18px"
    },
    divider: {
        border: "0.5px solid #888",
        marginTop:"18px",
    },
    detail:{
        fontSize:"12px",
        fontFamily:"Quicksand"
    },
    listItemWrapper: {
        display: "flex",
        padding: theme.spacing(1, 1),
    },
    listIcon: {
        position: "relative",
        fontSize: "18px",
        top: "4px",
        marginRight: "4px"
    },
    listItem:{
        fontSize:"14px",
        fontFamily:"Quicksand",
        marginBottom:"8px"
    },
    amount: {
        float: "right",
        fontWeight: 700,
    },
    subAmount: {
        fontSize: "22px",
        float: "right",
        fontWeight: 700,
    },
    muted: {
        fontSize: "14px",
        marginTop: "4px",
        color: "#939393",
    },
    superScript: {
        position: "relative",
        fontSize: "10px",
        top: "-6px"
    },
    cancelBtn: {
        float: "right",
        padding: "0.8rem",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "700"
    },
    danger: {
        color: "#f00",
        fontSize: "14px",
        fontWeight: "700" 
    },
    success: {
        color: "#00ff61",
        fontSize: "14px",
        fontWeight: "700" 
    },
    primary: {
        color: "#1f64d5",
        fontSize: "14px",
        fontWeight: "700" 
    },
    chatSection:{   
        display:"flex" , 
        flexDirection:"column" , 
        gap:"8px" , 
        padding:"8px 0px" , 
        height:"60%" , 
        overflowY:"scroll",
        "&::-webkit-scrollbar":{
            height:"4px"
        }
    }
}));

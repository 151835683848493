import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        height:"60px",
        backgroundColor:"#0A091A",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        color:"white"
    },
    search_section:{
        width:"60%",
        padding:"8px 0px",
        boxSizing:"border-box",
    },
    input_field:{
        color:"#546884"
    },
    textfield:{
        color:"#5A79A5",
        '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#5A79A5',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#5A79A5',
            },
            '&:hover fieldset': {
              borderColor: '#5A79A5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5A79A5',
            },
          },
    },
    img_section:{
        width:"18%",
        boxSizing:"border-box",
        paddingLeft:theme.spacing(2),
    }
}));

export const mapStyle =[
  {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": 36
          },
          {
              "color": "#000000"
          },
          {
              "lightness": 40
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#000000"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "all",
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#055164"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#000000"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "weight": "0.01"
          },
          {
              "lightness": "53"
          },
          {
              "saturation": "-9"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#1e1e1e"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#bf7725"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#2f2f2f"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "weight": "0.01"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#515151"
          },
          {
              "lightness": "0"
          },
          {
              "saturation": "0"
          },
          {
              "weight": "5"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "lightness": "-71"
          },
          {
              "weight": 0.2
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "weight": "0.01"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#515151"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "weight": "0.01"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#515151"
          },
          {
              "lightness": "0"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "weight": "0.01"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#bf7725"
          },
          {
              "lightness": "-25"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#055164"
          },
          {
              "visibility": "on"
          },
          {
              "saturation": "0"
          },
          {
              "lightness": "0"
          }
      ]
  }
]
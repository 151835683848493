import React, { useContext,  useState } from 'react';
import { MenuItem,  Select, TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import PasswordField from '../../../components/PasswordField/PasswordField';



const CreateUser = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required.").min(1, "Name is Too Short."),
        username: Yup.string().required("Username is Required.").min(1, "Username is Too Short."),
        email: Yup.string().email().required("Email is Required."),
        phone_number:  Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        password: Yup.string().min(8, "Password is too short - should be 8 chars minimum.")
                    .matches(/(?=.*[0-9])/, "Password must contain a number."),
    });


    const handleSubmit = (e) => {
        isEmpty(edit) ? createUser() : updateUser()
    }

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            username:edit.username ?? "",
            display_name: edit.display_name,
            phone_number: edit?.profile?.phone_number ?? "",
            email: edit.email ?? "",
            role: edit.role ?? "",
            password: "",
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    


    const createUser = async () => {
        setLoading(true)
    
        const formData =  {...formik.values ,  password_confirmation:formik.values.password}

        await axiosInstance.post("api/users" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            const errors = response.data.errors;
            Object.keys(errors).map((error) => formik.setFieldError(error , errors[error][0]))
        })
        .finally(() => setLoading(false))
    }


    const updateUser = async () => {
        setLoading(true)


        const formData = {
            name: formik.values.name,
            display_name: formik.values.display_name,
            role: formik.values.role,
            profile: {
                organization_email: formik.values.email,
                phone_number: String(formik.values.phone_number)
            },
        };

        await axiosInstance.put(`api/users/${edit.username}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }

    const createForm = [
        {
            title:"name",
            placeholder:"Name",
        },
        {
            title:"username",
            placeholder:"Username",
        },
        {
            title:"email",
            placeholder:"Email",
        },
    ]

    const editForm = [
        {
            title:"name",
            placeholder:"Name",
        },
        {
            title:"display_name",
            placeholder:"Username",
        },
        {
            title:"email",
            placeholder:"Email",
        },
        {
            title:"phone_number",
            placeholder:"Phone Number",
        },
    ]

    const form = isEmpty(edit) ? createForm : editForm;
    

    return (
                <>
                    <h2>{isEmpty(edit) ? "Create User" : "Update User"}</h2>
                    <div className={classes.form_section}>
                        {
                            form.map(({title , placeholder} , index) => (
                                <div key={index} >
                                    <TextField onChange={formik.handleChange} placeholder={placeholder} variant="outlined" name={title} value={formik.values[title]} fullWidth />
                                    {formik.touched[title]  && formik.errors[title] ? (
                                            <span className={classes.content__form_error}>{formik.errors[title]}</span>
                                        ) : null}
                                </div>
                            ))
                        }

                        {
                           isEmpty(edit) && 
                            <div>
                                <PasswordField onChange={formik.handleChange("password")}  />
                            
                                {formik.touched.password  && formik.errors.password ? (
                                        <span className={classes.content__form_error}>{formik.errors.password}</span>
                                    ) : null}
                            </div>
                        }
                        {
                            (!isEmpty(edit) && edit.role !== "admin") &&
                            <div className={classes.input_section}>
                                <Select
                                    variant="outlined"                                
                                    value={formik.values.role}
                                    error={!!formik.errors?.role}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    defaultValue={"admin"}
                                    name="role"
                                >
                                    <MenuItem value={"admin"}>Admin</MenuItem>
                                    <MenuItem value={"tournament_organizer"}>Tournament Organizer</MenuItem>
                                    <MenuItem value={"gamer"}>Gamer</MenuItem>
                                </Select>
                                { formik.errors.role ? (
                                    <span className={classes.content__form_error}>{formik.errors.role}</span>
                                ) : null}
                                
                            </div>  
                        }

                        <div style={{display:"flex" , justifyContent:"flex-end"}}>
                            <Button loading={loading} name={isEmpty(edit) ? "Create" : "Update"}  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                        </div>
                    </div>
                </> 
    )
}



export default CreateUser
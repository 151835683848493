import React, { useContext, useEffect, useState } from 'react'; 
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';





const ReferralHistory = ({  handleClose , username , refetch , monetized}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);
    const [stat, setStat] = useState({
        rewards_redeemed:0,
        rewards_unredeemed:0,
        referrals_redeemed:0,
        referrals_unredeemed:0,
    })


    const redeemReward = () => {
        const url = monetized ? `api/referral/rewards/${username}` : `api/referral/${username}`;
        axiosInstance({
            method: monetized ? 'patch' :'post',
            url
        }).then(({data}) => {
            setFeedback({message:data.message , severity:"success"});
            handleClose();
            refetch();
        }).catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
            setLoading(false)
        });
    }

    const getReferralStatus =  () => {
         axiosInstance.get(`api/referral/${username}/stats` ).then(function ({ data }) {
            setStat(data)
        })
    }



    useEffect(() => {
        getReferralStatus()
    } ,[])


    return (
        <>
            <h2> {username}</h2>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"12px",

            }}>
                {
                    monetized ? 
                    <>
                        <p>Rewards Redeemed: {stat.rewards_redeemed}</p>
                        <p>Rewards Pending: {stat.rewards_unredeemed}</p>
                    </> 
                    :
                    <>
                        <p>Referrals Redeemed: {stat.referrals_redeemed}</p>
                        <p>Referrals UnRedeemed: {stat.referrals_unredeemed}</p>
                    </>
                }
                <Button name="Redeem" onClick={() => redeemReward()} />
            </div>
        </> 
    )
}



export default ReferralHistory
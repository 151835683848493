import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { debounce, get } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import { IconButton, TextField , CircularProgress, Select, MenuItem} from "@material-ui/core";
import {  MdCheckCircle , MdCancel  } from 'react-icons/md'
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { Autocomplete } from "@material-ui/lab";
import { format } from "date-fns";
import { handlePage } from "../../../helpers/paginator";
import { convertISOTime } from "../../../helpers/date";
import BankDetailModal from "../../../components/BankDetailModal/BankDetailModal";


export default function Payment() {
  const classes = useStyles();
  const [ search , setSearch ] = useState('');
  const [ users , setUsers ] = useState([]);
  const [selectedUser, setSelectedUser ] = useState({})
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
  const [page, setPage] = useState(0);
  const [rows, setRowsPerPage] = useState(5);
  const [ toggle , setToggle ] = useState(true)
  const [endpoint, setEndpoint] = useState(`api/wallet/requests?sort%5Bfield%5D=date_created&sort%5Bdirection%5D=DESC&per_page=5` );
  const [ loading , setLoading ] = useState({ table:true , icons:null , fund:false });
  const [ modal , setModal ] = useState({ status:false , fund:false ,details:false})
  const { setFeedback  , user} = useContext(AppContext);

  const handleModal = (type , action) => {
    setModal({...modal , [type]:action})
  }

  const handleToggle = () => {
    setToggle(!toggle)
  }

  const handleChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('user' , value)
    setSearch(value);
};


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUsers = debounce( async () => {
    await axiosInstance.get(`api/players/search/users?q=${formik.values.user}&only_by_usernames=1&per_page=30`).then(({data}) => {
        setUsers(data.data)
      })
    }
  , 2000)

  const handleWithdrawalRequest = async (status , id ) => {
    setLoading((loading) => ({...loading , icons:id}))
    await axiosInstance
    .put(`api/wallet/requests/update/${id}` , {
      status,
      message: formik.values.message
    })
    .then(function ({ data }) {
      setFeedback({message:data.message});
      getWithdrawalRequest();
    })
    .catch(({ response }) => console.log(response))
    .finally(() => setLoading({...loading , icons:null}))
  }

  const validationSchema = Yup.object().shape({
    message: Yup.string().label("Message"),
    user: Yup.string().required().label("User"),
    amount: Yup.number().required().positive().integer().label("Amount"),
  });

  const fundUser = async (value) => {
    setLoading({...loading , fund:true})

    await axiosInstance.post("api/rewards" , {
      username:value.user,
      amount:value.amount,
      status:value.status,
      transaction_type_id:value.transaction_type_id,
      currency: value.currency
    }).then(({data}) => {
      setFeedback({message:data.message , severity:data.status})
      handleModal("fund" , false)
    })
    .finally(() => setLoading({...loading , fund:false}))

  }

  const formik = useFormik({
      initialValues:{
          message:"",
          currency:"USD",
          user:"",
          status:"transfer",
          transaction_type_id:2,
          amount:0
      },
      validationSchema:validationSchema,
      onSubmit:fundUser
  })


  const getWithdrawalRequest = async () => {
    setLoading({...loading , table:true})

    await axiosInstance.get(endpoint).then(function ({ data }) {
        setCollection(data);
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoading({...loading , table:false}))
  };

  const schema = [
    {
      label: "Amount",
      func: ({ item }) => {
        return get(item, "amount");
      },
    },
    {
      label: "Currency",
      func: ({ item }) => get(item, "currency"),
    },
    {
      label: "Type",
      func: ({ item }) => get(item, "transaction_type_id") 
    },
    {
      label: "To",
      func:({item}) => get(item , "receiver")
    },
    {
      label: "From",
      func:({item}) => get(item , "sender" )
    },
    
    {
      label: "Created",
      func: ({ item }) =>  format(new Date(get(item, "created_at")) , 'MMM do yyyy HH:mm:ss'),
    },
  ];

  const withdrawalSchema = [
    {
      label: "Name",
      func: ({ item }) => get(item, "user.name")
    },
    {
      label: "Amount",
      func: ({ item }) => {
        return get(item, "amount");
      },
    },
    {
      label: "Currency",
      func: ({ item }) => get(item, "currency"),
    },
    {
      label: "Status",
      func:({item}) => get(item , "status")
    },
    {
      label: "Created",
      func: ({ item }) => {        
        return format( convertISOTime( new Date(get(item, "created_at"))) , 'do MMM yyyy  HH:mm:ss')
      }  
    },
    {
      label: "Actions",
      func: ({ item }) => (
           <div style={{ display:"flex" , gap:"8px" }}>
             {
             loading.icons !== get(item, "id") ?

              <>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdCancel size={25} onClick={() =>  setModal({status:true , id: get(item, "id") })}  />
              </IconButton>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdCheckCircle size={25} onClick={() => handleWithdrawalRequest( 'approved' , get(item, "id"))}  />
              </IconButton>
              <p style={{cursor:"pointer"}} onClick={() => {
                setSelectedUser(get(item, "user"))
                setModal({details:true})
              }}>Bank Details</p>
              </>
              :
              <CircularProgress size={25}  color="inherit"/>
             }

            </div>
      ),
    },
  ];



  useEffect(() => {
    const url = toggle 
    ? `api/rewards?per_page=${rows}`
    :`api/wallet/requests?sort%5Bfield%5D=date_created&sort%5Bdirection%5D=DESC&per_page=${rows}`
    setEndpoint(url)
  }, [ rows , toggle]);

  useEffect(() => {
      getWithdrawalRequest();
    
  }, [endpoint]);

  useEffect(() => {
    formik.values.user !== "" && getUsers()
} ,[formik.values.user])

  return (
    <div className={classes.container}>
      <h2>{toggle ? "Manage Transaction" : "Manage Withdrawal"}</h2>
      <div className={classes.button_section}>
        <Button
          classes={{ text: classes.button_text }}
          backgroundColor="#B00610"
          name={"Fund User"}
          onClick={() => handleModal("fund" , true)}
        />
        {
          user.role === 'admin' && 
          <Button
            classes={{ text: classes.button_text }}
            backgroundColor="#B00610"
            name={toggle ? "Manage Withdrawal" : "Manage Transaction"}
            onClick={() => handleToggle()}
          />
        }
      </div>
      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        loading={loading.table}
        shortLoading={true}
        schema={toggle ? schema : withdrawalSchema}
        collection={collection.data}
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
      <Modal open={modal.fund} onClose={() => setModal(false)} >
        <h2>Credit/Debit User</h2>
        <div   
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "8px 0px",
            }}>

            <Autocomplete
                id="combo-box-demo"
                fullWidth
                onInputChange={(e , v) => formik.setFieldValue( "user", v)}
                options={users}
                getOptionLabel={(option) => option.username }
                renderInput={(params) => <TextField {...params} placeholder="Search User" onChange={formik.handleChange("user")} variant="outlined" />}
              />
               { formik.errors.amount ? (
                                <span className={classes.content__form_error}>{formik.errors.user}</span>
                            ) : null}
                {
                    user.admin && 
                  <Select
                      variant="outlined"      
                      placeholder="Transaction Type"          
                      value={formik.values.transaction_type_id}
                      onChange={formik.handleChange}
                      name="transaction_type_id"
                  >
                      <MenuItem value={2}>Credit</MenuItem>
                      <MenuItem value={1}>Debit</MenuItem> 
                  </Select>
                }
            <Select
                variant="outlined"      
                placeholder="Currency"          
                value={formik.values.currency}
                onChange={formik.handleChange}
                name="currency"
            >
                <MenuItem value={"USD"}>USD</MenuItem>
                <MenuItem value={"GC"}>GC</MenuItem>
            </Select>
            <TextField
              placeholder="Amount"
              variant="outlined"
              type='number'
              name="amount"
              onChange={formik.handleChange}
            />
                 { formik.errors.amount ? (
                                <span className={classes.content__form_error}>{formik.errors.amount}</span>
                            ) : null}
         
            <Button
              classes={{ text: classes.button_text }}
              name="Continue"
              loading={loading.fund}
              backgroundColor="#B00610"
              className={classes.create_button}
              onClick={() => formik.handleSubmit()}
            />
        </div>
      </Modal>
      <Modal open={modal.status} onClose={() => setModal(false)} >
        <div   style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "8px 0px",
                  }}>

            <TextField
              required
              minRows={4}
              multiline
              placeholder="Optional Message"
              variant="outlined"
              name="message"
              onChange={formik.handleChange}
            />
         
            <Button
              classes={{ text: classes.button_text }}
              name="Continue"
              loading={loading.icons}
              backgroundColor="#B00610"
              className={classes.create_button}
              onClick={() => handleWithdrawalRequest('declined' , modal.id)}
            />
        </div>
      </Modal>
      <BankDetailModal open={modal.details} onClose={() => setModal(false)} user={selectedUser}/>
    
    </div>
  );
}

import { Avatar, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import { debounce, get, isEmpty } from "lodash";
import { axiosInstance } from "../../helpers/client";
import { useHistory, useLocation } from 'react-router-dom';
import { useGame } from '../../hooks/useGame';
import { handlePage } from '../../helpers/paginator';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';


export default function LoadGame() {
    const classes = useStyles()
    const history = useHistory();
    const { t } = useTranslation();
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const gameId = query.get('gameId');
    const [game, setGame] = useState(null);

    const games = [
      {
        "id": 11233,
        "title": "Element Blocks",
        "image": "https://elementblocks.h5games.usercontent.goog/v/79834f3b-dae6-4966-981f-3453fec52569/high_res_tall.jpg",
        "link": "https://play.famobi.com/wrapper/element-blocks/A1000-10"
      },
      {
        "id": 12433,
        "title": "Fruit Crush Frenzy",
        "image": "https://res.cloudinary.com/delino12/image/upload/v1719484823/fruitfenzy.jpg",
        "link": "https://play.famobi.com/wrapper/fruit-crush-frenzy/A1000-10"
      },
      {
        "id": 33322,
        "title": "Word Search Classic",
        "image": "https://res.cloudinary.com/delino12/image/upload/v1719485009/wordsearch.jpg",
        "link": "https://play.famobi.com/wrapper/word-search-classic/A1000-10"
      }
    ];

    const fetchGameDetails = () => {
        const g = games.find(x => x.id == gameId);
        console.log("Game Data");
        console.log(g);
        setGame(g);
    }

    useEffect(() => {
        fetchGameDetails();
    }, [fetchGameDetails, game]);

    return (
        <div className={classes.container}>
            <div style={{display: "flex"}}>
                <div className={classes.login_section}>
                  {
                    game && game !== null ? (
                        <iframe style={{height: "100vh", width: "100vw"}} src={game.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    ) : (
                        <h5>Error loading games</h5>
                    )
                  }
                </div>
            </div>
        </div>
    )
}

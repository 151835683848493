import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        height:"60px",
        backgroundColor:"#0A091A",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        color:"white",
        boxSizing:"border-box"
    },
    paper:{
        backgroundColor:"unset"

    },
    logged_in:{
      display:"flex",
      gap:"12px",
      alignItems:"center",
      padding:theme.spacing(1 ,0 ),
      boxSizing:"border-box",
      [theme.breakpoints.down("sm")]:{
        display:"none"
      }
  
    },
    bottomnav:{
      display:"none",
      width:"100%",
      position:"fixed",
      bottom:0,
      backgroundColor:"#0B0D21",
      boxSizing:"border-box",
      '& .MuiBottomNavigationAction-root':{
          color:"#546884",
          [theme.breakpoints.down("xs")]:{
            minWidth:0
          }
      },
      '& .MuiBottomNavigationAction-root.Mui-selected':{
        color:"#fff",
      },
      fontFamily:"Quicksand",
      fontWeight:700,
      [theme.breakpoints.down("sm")]:{
        display:"flex"
      }
    },
    content__form_error:{
      fontSize:"12px",
      color:"red"
    },
    mobile_nav:{
      display:"none",
      justifyContent:"space-between",
      alignItems:"center",
      padding:theme.spacing(1 ,0 ),
      boxSizing:"border-box",
      [theme.breakpoints.down("sm")]:{
        display:"flex"
      }
    },
    auth:{
        display:"flex",
        gap:theme.spacing(1),
        padding:theme.spacing(0 , 2)
    },
    search_section:{
        flexGrow:1,
        padding:"8px 0px",
        boxSizing:"border-box",
    },
    input_field:{
        color:"#546884"
    },
    textfield:{
        color:"#5A79A5",
        '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#5A79A5',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#5A79A5',
            },
            '&:hover fieldset': {
              borderColor: '#5A79A5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5A79A5',
            },
          },
    },
    img_section:{
        boxSizing:"border-box",
        paddingLeft:theme.spacing(2),
        cursor:"pointer",
        [theme.breakpoints.up("sm")]:{
          width:"18%",
        }
    },
    profile:{
      height:"40px",
      width:"40px",
      borderRadius:"50%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      backgroundColor:"#172A44"
    },
    profile_section:{
      borderLeft:" 0.5px solid #172A44",
      padding:theme.spacing(0 , 2),
      cursor:"pointer"
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
   
    modal_container: {
      backgroundColor: "#0F2139",
      borderRadius:"8px",
      width:"400px",
      padding: theme.spacing(3),
      color:"#fff",
      "& h2":{
          fontSize:"26px",
          marginBottom: "8px",
          marginTop: "0px"
      },
      
  },
  create_section:{
    display:"flex",
    justifyContent:"space-between",
  },
  button_text:{
    padding: theme.spacing(3),
    marginBottom: "20px",
    marginTop: "20px"
  },
  select:{
    color:"#5A79A5",
    margin:theme.spacing(2, 0),
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5A79A5"
      },
      "& .MuiOutlinedInput-input" :{
        padding: "12px 16px"
    }
  }, 
  "@media only screen and (max-width: 768px)":{
    search_section:{
        display:"none",
    },
    container:{
      justifyContent:"space-between"
    }
  }
}));

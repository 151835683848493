import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        height: "100vh",
        display:"flex",
        background: "linear-gradient( #0e1028 , #070515)",
        flexDirection:"column",
        width:"100%",
        color:"#fff"
    },
    login_section:{
        flex: "1 1 auto",
        justifySelf:"center",
        maxWidth:"520px",
        height:"90%",
        "& h2": {
          marginBottom:0,
          fontSize:"30px"
        },
        [theme.breakpoints.down("sm")]:{
          width:"300px",
          padding:theme.spacing(0,1)
        },
    },
    h3: {
        fontSize: "24px"
    },
    h4: {
        fontSize: "18px"
    },
    p: {
        fontSize: "16px",
        marginTop: "4px",
    },
    muted: {
        fontSize: "14px",
        marginTop: "4px",
        color: "#454545",
    },
    btnRed: {
        padding: "0.8rem",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "700",
        backgroundColor: "#FF0E10",
        width: "40%"
    },
    btnMTN: {
        padding: "0.8rem",
        color: "#000",
        fontSize: "12px",
        fontWeight: "700",
        backgroundColor: "#FFCC08",
        width: "100%"
    },
    btnPrimary: {
        padding: "0.8rem",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "700",
        width: "100%"
    },
    inputText: {
        color: "#fff",
    },
    inputAdorment: {
        color: "rgb(209 246 170)",
    },
    textfield:{
      borderRadius:"8px",
      color:"#fff",
      '& label.Mui-focused': {
          color: 'white',
        },
        "& .MuiFormLabel-root": {
          color: "white"
      },
        "& .MuiInputBase-root ":{
            color:"white"
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#5A79A5',
          },
          '&:hover fieldset': {
            borderColor: '#5A79A5',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#fff',
          },
        },
    },
    selectField:{
      borderRadius:"8px",
      color:"#fff",
      '& label.Mui-focused': {
          color: 'white',
        },
        "& .MuiFormLabel-root": {
          color: "white"
      },
        "& .MuiSelectBase-root ":{
            color:"white"
        },
        '& .MuiOutlinedSelect-root': {
          '& fieldset': {
            borderColor: '#5A79A5',
          },
          '&:hover fieldset': {
            borderColor: '#5A79A5',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#fff',
          },
        },
    },
    warning: {
        color: "#FF0E10",
    },
    subcriptionBox: {

    },
    subscriptionAmount: {

    },
    walletBalance: {
        width: '400px', // Set the desired width
        margin: 'auto', 
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.10)',
    },
    icon: {
        position: "relative",
        fontSize: "18px",
        top: "4px",
        marginRight: "4px"
    },
    amount: {
        fontWeight: 700,
    },
    subscriptionAmount: {
        width: '400px', // Set the desired width
        margin: 'auto', // Center the modal horizontally
        padding: '20px',
        color: "green",
        fontSize: "28px",
        fontWeight: 700,
        textAlign: "center"
    },
    divider: {
        border: "0.5px solid #888",
        marginTop:"18px",
    },
}));

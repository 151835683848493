import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        flex: "1 1 auto",
        width: "360px",
        borderRadius: "8px",
        backgroundColor: "#0F2139",
        padding: theme.spacing(2),
        display: "flex",
        color:"white",
    },
    img:{
        minWidth:"130px",
        height:"130px",  
        backgroundSize:"cover",
        borderRadius:"8px",
        backgroundPosition:"center"
    },
    right:{
        paddingLeft: theme.spacing(2),
        flex:1
    },
    tagSection:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    tag:{
        padding:theme.spacing(0.5, 1),
        borderRadius:"10px",
        backgroundColor: ({exclusive , fee}) => exclusive ? "#8800ff" : (fee > 0 ? "#025170" :"#a60264"),
        fontSize:"12px"
    },
    info:{
        height: "65%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom:theme.spacing(1)
    },
    description:{
        fontFamily:"Quicksand",
        fontSize:"16px",
        fontWeight:700,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow:"ellipsis",
    },
    date:{
        fontSize:"16px",
        fontFamily:"Quicksand",
        fontWeight:300,

    },
    prize:{
        fontSize:"14px",
        fontFamily:"Quicksand",
        fontWeight:700,
        color:"#00B628"
    },
    start:{
        fontSize:"11px",
        color:"#FF959B"
    }
}));

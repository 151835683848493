import React from 'react';
import { useStyles } from './styles';
import Button from '../Button/Button';
import { t } from 'i18next';



const SubscriptionPlan = ({name , period, periodValue , subscriptionValue , currency , ...props}) => {
    const classes = useStyles();
    

    return (
        <div className={classes.container}>
            <p className={classes.info}>{name}</p>
            <p className={classes.price}>{currency}{subscriptionValue}</p>
            <p className={classes.info}>{periodValue }{period}</p>
            <Button width="100%" hoverColor="red" backgroundColor="#172A44" name={t("confirm")} {...props}/>
        </div>
    )
}

export default SubscriptionPlan;
import React from 'react';
import { useStyles } from './styles';
import { useHistory } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import { BsThreeDotsVertical } from 'react-icons/bs'
import { List, ListItem, ListItemText, Popover } from '@material-ui/core';
import inflection from 'inflection';




const MemberCard = ({avatar , username , role ,joined ,isMember , display_name, options ,handleOption ,...props }) => {
    const classes = useStyles(); 
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(3333)

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
    return (
        <div className={classes.container} {...props}>
            <div style={{display:"flex"}}>
                <Avatar alt={username} src={avatar} className={classes.img}  onClick={() => history.push(`/home/player/${username}`)}/>
                <div className={classes.right}>
                    <div className={classes.info}>
                        <p className={classes.start}>{display_name}</p>
                        <p className={classes.description}>{inflection.titleize(role)}</p>
                        <p className={classes.prize}>{joined}</p>
                    </div>
                </div>
            </div>
            {
                (isMember.member && (isMember.role === 'leader' || isMember.role === 'co-leader')) && 
                <div className={classes.option}>
                    <BsThreeDotsVertical  onClick={handleClick}/>
                </div>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
                }}
            >
                 <List >
            {
                options.map(({name , action }, index) => (
                    <ListItem
                        button
                        key={index}
                        classes={{
                            gutters:classes.list_item_gutters,
                            root:classes.list_item_root
                        }}
                        onClick={() => handleOption(name , action , username , role)}
                    >
                        <ListItemText className={classes.text}>{name}</ListItemText>
                    </ListItem>
                        ))
                    }
                </List>
            </Popover>
        </div>
    )
}

export default MemberCard;
import React, { useContext, useState } from 'react';
import { MdHome, MdRssFeed, MdSettings , } from "react-icons/md";
import { IoGameControllerOutline, IoGameController, IoTrophy } from 'react-icons/io5';
import { AiFillLock , AiOutlineAreaChart ,AiOutlineGift , AiFillCreditCard, AiOutlineShoppingCart} from 'react-icons/ai';
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { BiVideoRecording } from "react-icons/bi";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStyles } from './styles';
import { FaUser, FaCrown, FaRegUser } from 'react-icons/fa';
import { GiRank3 } from 'react-icons/gi'
import { AppContext } from '../../context/appContext';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const adminLinks = [
  {
    id: 1,
    name: "Dashboard",
    icon: <MdHome />,
    route: "/admin/dashboard",
  },
  {
    id: 2,
    name: "Manage User",
    icon: <FaUser  />,
    route: "/admin/manage-users",
  },
  {
    id: 3,
    name: "Tournament/Event",
    icon: <IoTrophy  />,
    route: `/admin/tournament`,
  },
  {
    id: 4,
    name: "Referral",
    icon: <AiOutlineGift  />,
    route: "/admin/referral",
  },
  {
    id: 5,
    name: "Matches",
    icon: <AiOutlineAreaChart  />,
    route: "/admin/matches",
  },
  {
    id: 6,
    name: "Forum",
    icon: <MdRssFeed  />,
    route: "/admin/forum",
  },
  {
    id: 7,
      name: "Game",
      icon: <IoGameController />,
      route: "/admin/game",
  },
  {
    id: 8,
      name: "Payment",
      icon: <AiFillCreditCard  />,
      route: "/admin/payment",
  },
  // {
  //   id: 9,
  //   name: "Leaderboard",
  //   icon: <GiRank3  />,
  //   route: "/admin/leaderboard",
  // },
  {
    id: 10,
    name: "Shop",
    icon: <AiOutlineShoppingCart  />,
    route: "/home/market",
    subLinks:[
      {
        id: 11,
        name: "Order",
        icon: <GiRank3  />,
        route: "/admin/orders",
      },
      {
        id: 12,
        name: "Products",
        icon: <GiRank3  />,
        route: "/admin/products",
      },
      {
        id: 13,
        name: "Products Code",
        icon: <GiRank3  />,
        route: "/admin/product-codes",
      },
      {
        id: 14,
        name: "Categories",
        icon: <GiRank3  />,
        route: "/admin/categories",
      },
      {
        id: 15,
        name: "Locations",
        icon: <GiRank3  />,
        route: "/admin/locations",
      },
      {
        id: 16,
        name: "Vendor",
        icon: <GiRank3  />,
        route: "/admin/vendors",
      },
    ]
  },
  {
    id: 17,
      name: "Subscription",
      icon: <AiFillLock  />,
      route: "/admin/subscription",
  },
  {
    id: 18,
    name: "Activity",
    icon: <AiFillLock  />,
    route: "/admin/achievement",
},
  {
    id: 19,
    name: "Settings",
    icon: <MdSettings  />,
    route: "/admin/settings",
},
];

const userLinks = [
    {
      id: 1,
      name: "home",
      icon: <MdHome />,
      route: "/home",
    },
    {
      id: 2,
      name: "tournament",
      icon: <IoTrophy  />,
      route: `/home/tournament`,
    },
    {
      id: 3,
      name: "feed",
      icon: <MdRssFeed  />,
      route: "/home/forum",
    },
    {
      id: 4,
        name: "profile",
        icon: <FaRegUser  />,
        route: "/home/profile",
    },
    // {
    //   id: 5,
    //     name: "leaderboard",
    //     icon: <GiRank3  />,
    //     route: "/home/leaderboard",
    // },
    {
      id: 6,
      name: "Shop",
      icon: <AiOutlineShoppingCart  />,
      route: "/home/market",
    },
    {
      id: 7,
        name: "live_stream",
        icon: <BiVideoRecording  />,
        route: "/home/live-stream",
    },
    {
      id: 8,
        name: "Subscription",
        icon: <FaCrown  />,
        route: "/home/subscription",
    },
    {
      id: 9,
        name: "Online Games",
        icon: <IoGameControllerOutline  />,
        route: "/home/online",
    },
];

const toLinks = [
  {
    id: 1,
    name: "Tournament/Event",
    icon: <IoTrophy  />,
    route: "/admin/tournament",
  },
  {
    id: 2,
    name: "Matches",
    icon: <AiOutlineAreaChart  />,
    route: "/admin/matches",
  },
  {
    id: 3,
    name: "Payment",
    icon: <AiFillCreditCard  />,
    route: "/admin/payment",
  },
];

const LeftSideBar =  memo(() =>  {
    const { t }     = useTranslation();
    const history   = useHistory();
    const classes   = useStyles();
    const location  = useLocation();
    const { user }  = useContext(AppContext)
    const [toggle, setToggle] = useState(null)

    const handleToggle = (name) => {
      const value = name === toggle ? null : name
      setToggle(value)
    }

    const activeLink =  location.pathname.split('/')[1] === "admin"  ? (user.role === "admin" ? adminLinks : toLinks) : userLinks

    return (
      <div className={classes.container}>
        <List>
            {
                activeLink.map(({id, name , icon , route, subLinks}, index) => {
                  return (
                    <div key={index}>
                      <ListItem
                          button
                          key={id}
                          href={route}
                          className={`${
                            location.pathname === `${route}`  || location.pathname.split('/')[2] == name.toLowerCase()
                              ? classes.listItem_active
                              : classes.listItem_inactive
                          } `}
                          onClick={() => {
                            subLinks && subLinks.length > 0 
                            ?  handleToggle(name)
                            : history.push(`${route}`)
                          }}
                      >
                          <ListItemIcon className={classes.icon} >{icon}</ListItemIcon>
                          <ListItemText className={classes.text}>{t(name)}</ListItemText>
                      </ListItem>
                      {

                      (subLinks && subLinks.length > 0) &&
                        (<div className={
                          toggle === name ? classes.open : classes.close
                        }>
                            {
                              subLinks.map(({name , route, }, index) => {
                                return (
                                  
                                  <ListItem
                                      button
                                      key={index}
                                      href={route}
                                      className={`${
                                        location.pathname === `${route}`  || location.pathname.split('/')[2] == name.toLowerCase()
                                          ? classes.listItem_active
                                          : classes.listItem_inactive
                                      } ${classes.listItem}`}
                                      onClick={() => history.push(`${route}`)}
                                  >
                                      <ListItemText className={classes.text}>{t(name)}</ListItemText>
                                  </ListItem>
                                  )
                            })
                            }
                        </div>)
                      }
                    </div>
                  )
                    
                  })
            }
        </List>
 

        <div className={classes.footerLinks}>
          <Link to="/privacy-policy">{t("privacy")}</Link>
          <Link to="/term-of-use">{t("term_of_use")}</Link>
        </div>
      </div>
    )
})

export default LeftSideBar;
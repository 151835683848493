import React, { useContext, useEffect, useState } from 'react'
import { axiosInstance } from '../../helpers/client'
import { useStyles } from './styles'
import { TextField, Select, MenuItem, FormControl} from '@material-ui/core';
import Button from '../../components/Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { AppContext } from '../../context/appContext';
import Modal from '../Modal/Modal';
import { TiPlus } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';


const PaymentAccount = ({info , refetch}) => {
    const classes = useStyles()
    const [ loading , setLoading ] = useState(false);
    const { setFeedback } = useContext(AppContext)
    const [open, setOpen] = useState(false);
    const [banks , setBanks ] = useState([]);
    const [ userBanks , setUserBanks ] = useState([]);
    const { t } = useTranslation()

    const handleOpen = () => {
        setOpen(true);
      };
      
    const handleClose = () => {
        setOpen(false);
    };


    const handleSchema = Yup.object().shape({
        currency: Yup.string().required().label('Currency'),
        beneficiary_name: Yup.string().required().label('Beneficiary Name'),
        beneficiary_address: Yup.string().label('Beneficiary Address'),
        beneficiary_country: Yup.string().label('Beneficiary Country'),
        swift_code:Yup.string().when("currency", {
            is: (val) => val === "USD",
            then: Yup.string().required().label("Swift Code")
        }),
        routing_number: Yup.string().min(8).max(12).label('Routing Number'),
        account_number: Yup.number().integer().required().label('Account number').typeError('Account number must be a valid number'),
        bank_name: Yup.string().required().label('Bank Name'),
    })

    const getBanks = async () => {
        await axiosInstance.get('api/userbank/supported/banks').then(({data}) => {
            setBanks(data.data)
        })
    }

    const verifyAccount = async() => {
        await axiosInstance.post(`api/userbank/resolve/account`,{
           account_number:formik.values.account_number,
           account_bank: formik.values.bank_code
        })
        .then(({data}) => {
            formik.setFieldValue("beneficiary_name" , data.data.account_name)
        })
        .catch(({response}) => {
            formik.setFieldValue("beneficiary_name" , "")

            if(response.status === 422){
                const errors = response.data.errors;
                Object.keys(errors).map((error) => formik.setFieldError(error , errors[error][0]))
            } else {
                setFeedback({message:response.data.message , severity:'error'})
            }
        })
    }


    const formValues = [
        {
            name:"routing_number",
            label: "Routing Number"
        },
        {
            name:"beneficiary_address",
            label: "Beneficiary Address"
        },
        {
            name:"beneficiary_country",
            label: "Beneficiary Country"
        },
    ]

    const addBank = async (form) => {
        setLoading({...loading ,add:true })


        await axiosInstance.post('api/userbank' , form ).then(({data}) => {
            setFeedback({message:data.message , severity:data.status})
            getUserBanks();
            handleClose()
        })
        .catch(({response}) => {
            const errors = response.data.errors;
            Object.keys(errors).map((error) => formik.setFieldError(error , errors[error][0]))
        })
        .finally(() => {
            setLoading({...loading ,add:false })
        })
    }

    const getUserBanks = async (form) => {
        setLoading({...loading ,userBanks:true })

        await axiosInstance.get('api/userbank' , form).then(({data}) => {
            setUserBanks(data.data)
        }).finally(() => setLoading({...loading ,userBanks:false }))
    }

    const setBankAsDefault = async (slug) => {
        setLoading({...loading ,selectBank:true })
        await axiosInstance.put(`api/userbank/set-as-default/${slug}`).then(({data}) => {
            getUserBanks();
            setFeedback({message:data.message , severity:data.status})
        }).finally(() => setLoading({...loading ,selectBank:false }))
    }


    const formik = useFormik({
        initialValues: {
          account_number: 0,
          routing_number: "",
          bank_name: "",
          swift_code:"",
          bank_code:"",
          beneficiary_country:"",
          beneficiary_name: "",
          beneficiary_address:"",
          currency:"NGN"

        },
        onSubmit: addBank,
        validationSchema:handleSchema
    });

    const handleSelect = (e) => {
        const bankName = e.target.value;
        formik.setFieldValue('bank_name' , bankName)

        const filterBank = banks.find(({name}) => name === bankName)
        formik.setFieldValue('bank_code' , filterBank.code)

    } 

    useEffect(() => {
        getBanks()
        getUserBanks()

    },[])

    useEffect(() => {
        if(
            formik.values.account_number.toString().length === 10 
            && formik.values.currency === 'NGN' 
            && formik.values.bank_name !== ""
            && formik.errors.account_number === undefined
            
            ){
            verifyAccount()
        }
    },[formik.values , formik.errors])


    
    return (
        <div className={classes.container}>
            <p style={{marginBottom:"8px"}}>{t("no_withdraw_acc")}</p>
            <Button name={t("add_withdraw_acc")} startIcon={<TiPlus size={15} />} onClick={handleOpen}/>
            <div className={classes.userBankSection}>
                {
                    loading.userBanks ? <p>{t("loading")}</p> :
                    
                    userBanks.map(({bank_name ,account_number , beneficiary_name , currency, is_active, slug }, i) => 
                    <div key={i} className={classes.bankCard}>
                        <p>{bank_name}</p>
                        <p>{account_number}</p>
                        <p>{beneficiary_name}</p>
                        <p>{currency}</p>
                        <p>Active: {`${is_active}`}</p>
                        {
                            !is_active &&
                            <Button 
                                classes={{ text: classes.button_text }}
                                backgroundColor="#B00610"
                                name="Set as Active" 
                                onClick={() => setBankAsDefault(slug)} 
                                disabled={loading.selectBank} 
                                loading={loading.selectBank}/>
                        }
                    </div>
                    )
                }
            </div>

            
            <Modal open={open} onClose={handleClose}>
                <div className={classes.input_container}>
                    <div className={classes.input_section}>
                        <p>Account Type</p>
                        <FormControl fullWidth size="small">
                        <Select
                            className={classes.textfield}
                            variant="outlined" 
                            fullWidth    
                            placeholder="Account Type"          
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            name="currency"
                        >
                            <MenuItem value={"USD"}>USD</MenuItem>
                            <MenuItem value={"NGN"}>NGN</MenuItem>
                        </Select>
                        </FormControl>
                    </div>

                        <div className={classes.input_section}>
                            <p>Bank Name</p>
                            {
                                formik.values.currency === "NGN" ?
                                <FormControl fullWidth size="small">

                                <Select
                                    className={classes.textfield}
                                    variant="outlined"  
                                    fullWidth    
                                    placeholder="Bank Name"          
                                    value={formik.values.bank_name}
                                    onChange={handleSelect}
                                    name="bank_name"
                                >

                                    {
                                        banks.map(({name }, i) => <MenuItem key={i} value={name} >{name}</MenuItem>)
                                    }
                                
                                </Select>
                                    </FormControl>
                                :
                                <TextField size="small"  onChange={formik.handleChange('bank_name')} fullWidth  value={formik.values.bank_name} className={classes.textfield} variant="outlined" /> 

                            }
                        </div>
                        <div  className={classes.input_section}>
                            <p>Account Number</p>
                            
                            <TextField size="small" onChange={formik.handleChange("account_number")} fullWidth name="account_number" value={formik.values.account_number} className={classes.textfield} variant="outlined" /> 
                            {
                                 formik.errors.account_number ? (
                                    <span className={classes.content__form_error}>{formik.errors.account_number}</span>
                                ) : null
                            }   
                        </div>
                        {
                            formik.values.currency === "USD" &&
                            <div  className={classes.input_section}>
                                <p>Swift Code</p>
                                <TextField size="small"  onChange={formik.handleChange("swift_code")} fullWidth value={formik.values.swift_code} className={classes.textfield} variant="outlined" /> 
                                {
                                    formik.touched.swift_code && formik.errors.swift_code ? (
                                        <span className={classes.content__form_error}>{formik.errors.swift_code}</span>
                                    ) : null
                                }   
                            </div>
                        }


                        <div  className={classes.input_section}>
                            <p>Beneficiary Name</p>
                            <TextField size="small"  disabled={formik.values.currency !== "USD"} onChange={formik.handleChange("beneficiary_name")} fullWidth value={formik.values.beneficiary_name} className={classes.textfield} variant="outlined" /> 
                            {
                                formik.touched.beneficiary_name && formik.errors.beneficiary_name ? (
                                    <span className={classes.content__form_error}>{formik.errors.beneficiary_name}</span>
                                ) : null
                            }   
                        </div>



                    {formik.values.currency === "USD" &&
                        formValues.map(({name , label} , index) =>  (

                        <div key={index} className={classes.input_section}>
                            <p>{label}</p>
                            <TextField size="small"  onChange={formik.handleChange} fullWidth  name={name}  value={formik.values[name]} className={classes.textfield} variant="outlined" type={name === "acc_number" && "number"}/> 
                            {
                                formik.touched[name]  && formik.errors[name] ? (
                                    <span className={classes.content__form_error}>{formik.errors[name]}</span>
                                ) : null
                            }   
                        </div>
                        ))
                    }
                </div>
                <div style={{marginTop:"16px"}}>
                    <Button classes={{text:classes.button_text}} loading={loading.add} backgroundColor="#B00610" name='Add Account' onClick={formik.handleSubmit}/>
                </div>
            </Modal>
        </div>
    )
}

export default PaymentAccount

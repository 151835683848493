import {React, useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Switch from '@mui/material/Switch';

const SubscriptionDetails = ({subscription_token, payment_method, started_at, ended_at, subscription_type, handleCancel }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const autoRenew = true;
    const handleAutoRenewal = () => {
        
    }

    return (
        <div className={classes.container}>
            <div className={classes.info}>
                <div>
                    <p className={classes.subTitle}>
                        {subscription_type.description} 
                        <span className={classes.subAmount}>
                            <span className={classes.superScript}>$</span> {subscription_type.subscription_value}
                            <br />
                            <span className={classes.muted}>
                                {t("Monthly")}
                            </span>
                        </span>
                    </p>
                    <p className={classes.title}>
                        {t(subscription_type.name)}
                    </p>
                    <p className={classes.subTitle}>
                        {t("Subscription payment date")}: <b className={classes.success}>{started_at}</b>
                    </p>
                    <p className={classes.subTitle}>
                        {t("Next payment due date")}: <b className={classes.success}>{ended_at}</b>
                    </p>
                    <p className={classes.subTitle}>
                        {t("Subscription Channel")}: {
                            (payment_method === "wallet_transaction") ? <b className={classes.success}>{t("Wallet")}</b> 
                            : (payment_method === "mtn_transaction") ? <b className={classes.success}>{t("MTN")}</b> : "N/A"}
                    </p>
                    <p className={classes.subTitle}>
                        {t("Subscription Status")}: <b className={classes.success}>{t("Active")}</b>
                    </p>
                    <br />
                    <p className={classes.subTitle}>
                        <a className={classes.primary}>See Plan Details</a>
                        <Button className={classes.cancelBtn} name="Cancel Subscription" onClick={handleCancel} />
                    </p>
                </div>
                <br />
            </div>
        </div>
    )
}

export default SubscriptionDetails;
import { makeStyles, } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        borderRadius: theme.spacing(1),
        width:"120px",
        flex:"1 1 auto",
        padding: theme.spacing(2, 4),
        color:"white",
        background: "rgba(23, 42, 68, 0.1)",
        border: "1px solid #172A44",
        [theme.breakpoints.down("sm")] :{
            width:"80px"
        }
    },
    title:{
        fontSize:"12px",
        color:"white",
        marginBottom:"8px"
    },
    detail:{
        fontSize:"14px",
        fontWeight:700,
        fontFamily:"Quicksand"
    },
    info:{
        // height:"60px"
    },
}));

import React from 'react'
import { useStyles } from './styles';
import inflection from 'inflection';
import { useRef } from 'react';

export default function  DashboardCard({ name , data}) {
    const classes = useStyles();

    const from = useRef('#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'))
    const to = useRef('#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'))
    const title = inflection.humanize(name);


    return (
        <div className={classes.container} style={{ background: `linear-gradient(135deg, ${from.current} 0%, ${to.current} 100%)`,}}>
            <p className={classes.title}>{title.toUpperCase()}</p>
            <p className={classes.stat}>{data.total}</p>
            <div className={classes.detailSection}>
                {
                    Object.keys(data).filter((key) => key !== 'total').map((key, i) => (
                    <div className={classes.detail} key={i}>
                        <p>{data[key]}</p>
                        <p className={classes.info}>{inflection.humanize(key)}</p>
                    </div>
                    ))
                }
            </div>
        </div>
    )
}

import React, { createContext, useState } from "react";
import { useContext } from "react";
import { useCallback } from "react";
import { AppContext } from './appContext'



const initial = {
    items:[],
    total:0,
    totalShipping:0,
    removeItem: (cart) => {},
    addItem: (item) => {},
    clearCart: () => {}
}
export const CartContext = createContext(initial);

const  CartStateProvider = ({ children }) => {
    const cart =   JSON.parse(localStorage.getItem('cart'))
    const [items , setItems] = useState(cart ?? []);
    const { setFeedback } = useContext(AppContext)

    const getTotal = useCallback(() => {
      let count = 0;
      items.map((item) => count += (item.price * item.quantity))
      return Math.round(count)
    },[items])

    const getShipping = useCallback( () => {
      let total = 0;
      const arrayUniqueByKey = [...new Map(items.map(item => [item.vendor.slug, item])).values()]
      arrayUniqueByKey.map((unique) => total += unique.vendor.shipping_fee);;

      return total
    },[items])


  
    return (
      <CartContext.Provider
        value={{
          items,
          total: getTotal(),
          totalShipping: getShipping(),
          clearCart: () => { 
            localStorage.removeItem('cart')
            setItems([])
          },
          addItem: (cart) => {
            if(!cart.in_stock) {
              return  setFeedback({message:"This item is not in stock", severity:"error"})

            }
            
            localStorage.removeItem('cart')

            const arr = [...items];
            const obj = arr.findIndex(el => el.slug === cart.slug);

            if(arr.length > 0 && arr[0].type !== cart.type) {
              return  setFeedback({message:"You cannot add incompatible products in cart. Kindly check out first",  severity:"error"})
            }

            if (obj === -1) {
              cart['quantity'] =  1
              arr.push(cart);
            } else {
              arr[obj]['quantity'] += 1
            }

            setItems(arr)            
            localStorage.setItem("cart" , JSON.stringify(arr));
            setFeedback({message:"Item Added to Cart"})
          },
          removeItem: (slug) =>  {
            const arr = items.filter((item) => item.slug !== slug)

            setItems(arr)            
            localStorage.setItem("cart" , JSON.stringify(arr));
        },

        updateQuantity: (operator , slug) => {
          localStorage.removeItem('cart')

          const arr = [...items];

          const obj = arr.findIndex(el => el.slug === slug);

          switch (operator) {
            case "increment":
              arr[obj]['quantity'] += 1;
              break;
            case "decrement":
              arr[obj]['quantity'] -= 1;
              break
            default:
              break;
          }

          setItems(arr)            
          localStorage.setItem("cart" , JSON.stringify(arr));
        }
        }}
      >
            {children}
      </CartContext.Provider>
    );
  }
  
  export default CartStateProvider;
import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { debounce, isEmpty } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';



const ReferralMonetization = ({  handleClose , edit , game , refetch}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);
    const [ search , setSearch ] = useState('');
    const [ users , setUsers ] = useState([]);

    const manageReferralStatus =  (data) => {
        setLoading(true)

        const username =  isEmpty(edit) ? ( search ?? data.username)  : data.username

         axiosInstance.patch(`api/referral/monetized-referrers/${username}` , {
            action: data.action
         } ).then(function ({ data }) {
            setFeedback({message:data.message , severity:"success"});
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
            setLoading(false)
        });
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string().required().label("Username"),
    });

    

    const formik = useFormik({
        initialValues: {
            username: edit.username ?? "",
            action: isEmpty(edit) ? "remove" : "add",
        },
        onSubmit: manageReferralStatus,
        validationSchema:validationSchema
    });

    
    const handleChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        formik.setFieldValue("username" , value);
    };

    const getParticipant = debounce( () => {
        axiosInstance.get(`api/players/search/users?q=${search}`).then(({data}) => {
            setUsers(data.data)
        })
    }, 1000)


    

    useEffect(() => {
        if (search.length >= 3) getParticipant()
    } ,[search])


    return (
        <>
            <h2>{isEmpty(edit) ? "Add Record" : "Update Record"}</h2>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"12px",

            }}>
              
                <div >
                    {
                        isEmpty(edit) ?
                            <Autocomplete
                                id="combo-box-demo"
                                onInputChange={(e , v) => setSearch(v)}
                                options={users}
                                getOptionLabel={(option) =>  option.username }
                            
                                renderInput={(params) => <TextField {...params} onChange={handleChange} variant="outlined" fullWidth placeholder="Name" />}
                                />
                        :
                        <TextField value={edit.username}  variant="outlined" fullWidth disabled/>
                    }
                   
                    {formik.touched.name  && formik.errors.name ? (
                            <span className={classes.content__form_error}>{formik.errors.name}</span>
                        ) : null}
                </div>

                    <Select
                        variant="outlined"
                        fullWidth
                        name='action'
                        value={formik.values.action}
                        onChange={formik.handleChange} 
                    >
                        <MenuItem  value="add">Add</MenuItem> 
                        <MenuItem  value="remove">Revoke</MenuItem> 
                    </Select>
                



                <div style={{display:"flex" , justifyContent:"flex-end"}}>
                    <Button loading={loading} name={"Save"}  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                </div>
            </div>
        </> 
    )
}



export default ReferralMonetization
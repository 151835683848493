import { createTheme } from "@material-ui/core/styles";



export default createTheme({
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: ["Quicksand"].join(","),
    fontSize: 16,
    h2: {
      fontSize: "16px",
      fontWeight: 400,
    },
    h3: {},
    h4: {},
    h5: {
      fontSize: "1.4rem",
    },
    h6: {},
    body1: {
      fontSize: "16px",
      fontFamily: "Quicksand",
      fontWeight:500,
    },
    body2: {
     
    },
  },
  include: {
    "@keyframes gradient": {
      from: { width: 0 },
      to: { width: "100%" },
    },
  },
  design: {
    font: {
      title: "Quicksand",
    },
    colors: {
      width: "1366px",
      Red01:"EA3C53",
      blue: "#0889F7",
    },
  },
  overrides: {
    MuiTableCell :{
      body:{
        color:"#fff"
      },
      root:{
        borderBottom:"unset"
      },
      head: {
        backgroundColor: "#0D0F24",
        color: "#fff",
      },
    }, 
    MuiButton:{
      root:{
        color:"#fff",
        fontFamily:"Quicksand",
        fontWeight:300,
        borderRadius:"6px",
        minWidth:"80px",
    },
      text:{
        padding:`4px 8px`,
      }
    },
    MuiTypography :{
      colorTextSecondary:{
        color:"#fff"
      }
    },
    MuiSelect:{
      icon:{
        color: "#5A79A5"
      }
    },
    MuiPaper :{
      root:{
        // backgroundColor:"unset"
      },
      rounded:{
        borderRadius:"8px"
      }
    },
    MuiStepper:{
      root:{
        padding:"24px 0"
      }
    },
    MuiSwitch:{
      track:{
        backgroundColor:"#ccc"
      }
    },
    MuiStepConnector:{
      root:{
        border:"2px solid #fff"
      }
    },
    MuiIconButton: {
      root:{
        color:"#fff",
        "&.Mui-disabled":{
          color:"#2c3d70"
        },
      },
     
    },
    MuiStepLabel:{
      label:{
        color:"#26293D",
        "&.MuiStepLabel-active":{
          color:"#ccc"
        },
        "&.MuiStepLabel-completed":{
          color:"#fff"
        },
      },

    },
    MuiOutlinedInput:{
      root:{
        "& fieldset":{
          borderColor: '#5A79A5',
        },
        '&:focused fieldset': {
          borderColor: 'green',
        },
        '&:hover fieldset': {
            borderColor: '#5A79A5',
        },
        '&:focused': {
          color: 'white',
        },
      }
    },
    MuiFormLabel:{
        root:{
          color: 'white',
        }
    },
    MuiInputBase: {
      root:{
        color:"#fff",
        '&:hover fieldset': {
          borderColor: '#5A79A5',
        },
      },
      input: {
        "&::placeholder": {
          color: "#546884"
        },
        color: "white",
      }
    },
    MuiAlert:{
      filledSuccess:{
        backgroundColor:"#0F2139"
      }
    }
  },
});

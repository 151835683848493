import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
  container:{
    borderRadius:"8px",
    flex:"1 1 0",
    minWidth:"360px",
    maxHeight:"80vh",
    padding:"8px 0px",
    backgroundColor:"#0F2139",
    
  display:"flex",
  flexDirection:"column",   
},
team_invite_section:{
  display:"flex",
  marginTop: theme.spacing(1),
  gap:theme.spacing(1),
  justifyContent:"center"
},
notification_count:{
  padding:"4px",
  borderRadius:"50%",
  backgroundColor:"red",
  fontSize:"8px",
  textAlign:"center",
  position:"absolute",
  top:0,
  right:0,
},
not_container:{
  overflowY:"scroll",
  "&::-webkit-scrollbar":{
    width:"2px"
  },

  color:"#fff",
  "& h2":{
      fontSize:"26px",
      margin:"8px 0px"
  },
  "& p":{
      fontSize:"14px",
      fontFamily:"Quicksand",
      fontWeight:300
  }
},
notification_item:{
  cursor:"pointer",
  width:"100%",
  "&:hover": {
    background: "#12162B",
    color: "#FFF",
  }
  // display:"flex",
  // flexDirection:"column"
}
}));

import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import ImageUploader from '../../../components/ImageUploader/ImageUploader';



const CreateGame = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);


    const handleSubmit = (e) => {
        isEmpty(edit) ? createGame(e) : updateGame(e)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        imageHolder:  isEmpty(edit) 
        ? Yup.mixed().required().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024))
        : Yup.mixed().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)),
       
    });

    

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            image: edit.image ?? "",
            imageHolder: "",
            description: edit.description ?? "",
            is_active: edit.is_active ?? true,
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("image" , "")
            formik.setFieldValue("imageHolder" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("image" , baseURL)
            formik.setFieldValue("imageHolder" , e?.target.files[0])
        };
    }

    


    const createGame = async (data) => {

        setLoading(true)
        await axiosInstance.post("api/games/store" , data ).then(function ({ data }) {
            setFeedback({message:data.message , severity:"success"});
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
            setLoading(false)
        });
    }


    const updateGame = async (formData , slug) => {
        setLoading(true)
        

        await axiosInstance.put(`api/games/update/${slug}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
        <>
            <h2>Create Game</h2>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"12px",

            }}>
                <div className={classes.image_section}>
                    <ImageUploader handleFile={handleFile} file={   isEmpty(edit) ? formik.values?.imageHolder :  formik.values?.image} id="team" />

                    {formik.touched.imageHolder  && formik.errors.imageHolder ? (
                        <span className={classes.content__form_error}>{formik.errors.imageHolder}</span>
                    ) : null}
                </div>
                <div >
                    <TextField onChange={formik.handleChange} value={formik.values.name} placeholder="Name" variant="outlined" name="name" fullWidth />
                    {formik.touched.name  && formik.errors.name ? (
                            <span className={classes.content__form_error}>{formik.errors.name}</span>
                        ) : null}
                </div>
                <TextField onChange={formik.handleChange} value={formik.values.description} placeholder="Brief description about Game" variant="outlined" fullWidth name="description" minRows="3" multiline />
                <FormControlLabel
                    className={classes.textfield}
                    value={formik.values.is_active}
                    name="is_active"
                    onChange={(e) => formik.setFieldValue("is_active" , e?.target.checked)}
                    control={<Checkbox checked={formik.values.is_active}  style={{color:"#fff"}}/>}
                    label="Active"
                    labelPlacement="end"
                    />
                <div style={{display:"flex" , justifyContent:"flex-end"}}>
                    <Button loading={loading} name="Create"  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                </div>
            </div>
        </> 
    )
}



export default CreateGame
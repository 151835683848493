import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
      container:{
        padding: theme.spacing(4),
        '& h3':{
          fontSize:"24px",
          color:"#fff",
        }
      },
      listContainer:{
        display:'flex',
        flexWrap:'wrap',
        justifyContent:"flex-start",
        gap:"32px",
        marginBottom:"16px",
        [theme.breakpoints.down("sm")]:{
          flexWrap:"nowrap",
          overflowX:"scroll",
        }
      },
      heroSection:{
        width:"100%",
        height:"80vh",
        position:"relative",
        backgroundSize:"cover",
        
        display:"flex",
        flexDirection:"column-reverse",
        alignItems:"flex-start",
        [theme.breakpoints.down("sm")]:{
          height:"50vh",
        }
       
      },
      heroImage:{
        width:"100%",
        height:"80vh",
       
        position:"relative",
        backgroundSize:"cover",
        filter: `brightness(40%)`,
        [theme.breakpoints.down("sm")]:{
          height:"50vh",
          
        }
      },
      heroDescription:{
        color:"#fff",
        padding:"32px",
        marginBottom:"12px",
        width:"40%",
        position:"absolute",
        display:"flex",
        textOverflow:"wrap",
        gap:"16px",
        flexDirection:"column"
      },
      productContainer:{
        flex:"1 1 auto",
        display:"flex",
        flexDirection:"column",
        minWidth:"200px",
        maxWidth:"240px",
        cursor:"pointer"
      },
      image:{
        height:"260px",
        // width:"100%",
        display:'flex',
        flexDirection:'column-reverse',
        backgroundSize:"cover",
        backgroundPosition:"50% 0%",
        [theme.breakpoints.down("sm")]:{
          backgroundSize:"contain",
          backgroundRepeat:"no-repeat",
        }
      },
      title:{
        color:"white"
      },
      priceSection:{
        display:'flex',
        flexDirection:"column",
        gap:"8px"
      },
      price:{
        fontSize:"20px",
        color:"white"
      },
      oldPrice:{
        color: 'rgba(255, 255, 255, 0.3)',
        fontSize:"12px",
        display: ({ oldPrice }) => oldPrice === null ? 'hidden' : 'block'
      },
      newItem:{
        color:"#fff",
        fontSize:"16px"
      },
      info:{
          padding:"16px",
          backgroundColor:({inStock }) => inStock ? "#000099" : "#121B33",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          gap:"12px",
          color:"#fff"
      },
      titleSection:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:"12px",
        '& p':{
          fontSize:"14px",
        }
      },
      discount:{
        padding:theme.spacing(1,2),
        color:"#fff",
        flexGrow:0,
        alignSelf:"flex-start",
        backgroundColor:({inStock }) => inStock ? "#000099" : "#121B33",

      },
      showSection:{
        display:'flex',
        justifyContent:"space-between",
        gap:"32px",
        marginBottom:"32px",
        [theme.breakpoints.down("sm")]:{
          flexDirection:"column"
        }
      },
      singleImage:{
        height:"400px",
        flex:1,
        backgroundSize:"contain",
        [theme.breakpoints.down("sm")]:{
          flex:"none"
        }
        
      },
      descriptionSection:{
        width:"35%",
        color:"#fff",
        display:"flex",
        flexDirection:"column",
        gap:"16px",
        [theme.breakpoints.down("sm")]:{
          width:"100%",
        }
      },
      extraInfo:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:'center',
        padding:theme.spacing(2, 1),
        borderBottom:"1px solid #ccc"
      }
   
}));

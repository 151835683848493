import React, { useContext, useEffect, useState } from 'react'
import { axiosInstance } from '../../helpers/client'
import { useStyles } from './styles'
import { AiOutlineGift } from 'react-icons/ai';
import { AppContext } from '../../context/appContext';
import { AiOutlineCopy } from 'react-icons/ai';
import InfoCard from '../InfoCard/InfoCard';
import { useTranslation } from 'react-i18next';


const UserReferral = ({ monetized = false}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const { user } = useContext(AppContext)
    const [ stat, setStat ] = useState({
        rewards_redeemed:0
    });
    const { t } = useTranslation()

    const handleOpen = () => {
        setOpen(true);
      };
      
    const handleClose = () => {
        setOpen(false);
    };


    const getReferralStat = () => {
        axiosInstance.get('api/referral/stats').then(({data}) => {
            setStat(data)
        })
    }

    const handleCopy = () => {
        handleOpen()
        navigator.clipboard.writeText(`${t("share_link_info")}

         ${process.env.REACT_APP_FRONT_DOMAIN}register?referral=${user.username}`)

        setTimeout(() => handleClose(), 2000)
    }

    useEffect(() => getReferralStat(), [])

    
    return (
        <div className={classes.container}>
            <div style={{display:'flex' , justifyContent:"flex-start", gap:"8px" }}>
                <h2 >{t("invite_friends")}</h2>
                <AiOutlineGift size={55} />
            </div>
            <p>{t("invite_info")}</p>
            <h3>{t("referral_earning")}</h3>
            <div style={{display:'flex', gap:"8px" , marginBottom:"16px"}}>
                <InfoCard title={t("redeemed")} detail={stat[monetized ? `rewards_redeemed` : "referrals_redeemed" ]} />
                <InfoCard title={t("pending")} detail={stat[monetized ? 'rewards_unredeemed' : "referrals_unredeemed"]} />
            </div>


            <p>{t("share_link")}</p>

            <div style={{display:'flex', gap:"8px"}}>
                <div className={classes.shareLink}>{t("share_link_info")}<br/>
                <a href={`${process.env.REACT_APP_FRONT_DOMAIN}register?referral=${user.username}`}>{`${process.env.REACT_APP_FRONT_DOMAIN}register?referral=${user.username}`}</a></div>
                <AiOutlineCopy size={25} cursor="pointer" onClick={handleCopy}  />
                {open && <p>{t("copied")}</p>}
            </div>
            

           
        </div>
    )
}

export default UserReferral

import { IconButton, Popover } from '@material-ui/core'
import React from 'react'
import { useState , useEffect } from 'react';
import {  VscBell } from 'react-icons/vsc'
import Notification from "../Notification/Notification";
import { useStyles } from './styles';
import { axiosInstance } from "../../helpers/client";



export default function NotificationIcon() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;
    const [ anchorNotification , setAnchorNotification ] = useState(null)
    const openNotificationPop = Boolean(anchorNotification);
    const [ notifications , setNotification ] = useState([]);
    const [fetching , setFetching ] = useState(true)
    
    const openNotification =  (event) => {
        setAnchorNotification(event.currentTarget);
      };
      const handleNotificaionPopClose = () => {
        setAnchorNotification(null);
      };

      const getNotification = async () => {
        await axiosInstance
          .get("api/notifications/unread")
          .then(function ({ data }) {
            setNotification(data.data);
          }).finally(() => setFetching(false))
      };

      useEffect(() => {
        getNotification();
      }, []);


  return (
    <IconButton color="inherit" >
      <div onClick={openNotification} style={{position:"relative" , display:"flex" , alignItems:"flex-end"}}>

        <VscBell size={25}  />
        {
          notifications.length > 0 && <span className={classes.notification_count}>{notifications.length}</span>
        }
        
      </div>
        <Popover
            classes={{
                paper: classes.paper,
            }}
            id={id}
            open={openNotificationPop}
            anchorEl={anchorNotification}
            onClose={handleNotificaionPopClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
        <Notification notification={notifications} fetching={fetching} refetch={getNotification}/>
        </Popover>
    </IconButton>
  )
}

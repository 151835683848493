import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import ImageUploader from '../../../components/ImageUploader/ImageUploader';



const LeaderboardBanner = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);


    const updateGame = async (formData) => {
        setLoading(true)
        

        await axiosInstance.post(`api/configurations/leaderboard` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string(),
        description: Yup.string(),
        imageHolder: Yup.mixed().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)),
       
    });

    

    const formik = useFormik({
        initialValues: {
            title: edit.title ?? "",
            banner  : edit.banner ?? "",
            imageHolder: "",
            description: edit.description ?? "",
        },
        onSubmit: updateGame,
        validationSchema:validationSchema
    });

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("banner" , "")
            formik.setFieldValue("imageHolder" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("banner" , baseURL)
            formik.setFieldValue("imageHolder" , e?.target.files[0])
        };
    }

    


  
    

    return (
        <>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"12px",

            }}>
                <div className={classes.image_section}>
                    <ImageUploader handleFile={handleFile} file={   isEmpty(edit) ? formik.values?.imageHolder :  formik.values?.banner} id="team" />

                    {formik.touched.imageHolder  && formik.errors.imageHolder ? (
                        <span className={classes.content__form_error}>{formik.errors.imageHolder}</span>
                    ) : null}
                </div>
                <div >
                    <TextField onChange={formik.handleChange} value={formik.values.title} placeholder="Title" variant="outlined" name="title" fullWidth />
                    {formik.touched.title  && formik.errors.title ? (
                            <span className={classes.content__form_error}>{formik.errors.title}</span>
                        ) : null}
                </div>
                <TextField onChange={formik.handleChange} value={formik.values.description} placeholder="Brief description about Brand" variant="outlined" fullWidth name="description" minRows="3" multiline />

                <div style={{display:"flex" , justifyContent:"flex-end"}}>
                    <Button loading={loading} name="Save"  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                </div>
            </div>
        </> 
    )
}



export default LeaderboardBanner
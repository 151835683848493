import React from 'react';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';

const Button = ({name, hasImage, imageUrl, loading, backgroundColor = "#172A44", padding, width, ...props }) => {

    const classes = useStyles()
    return (
        <MuiButton classes={{
            text: classes.text,
            root: classes.root,
        }} style={{ backgroundColor , padding, width }} disabled={loading}  {...props}>
            { loading ?  <CircularProgress size={25}  color="inherit"/> :  name } {hasImage ?  <img style={{marginLeft: "5px"}} src={imageUrl} alt="Logo" width="40" /> : ""}
        </MuiButton>
    )
}

export default Button;
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    icon:{
        marginRight:"4px"
    },
    container:{
        ".secondary":{
            backgroundColor: "#B00610"
        },
        ".primary":{
           backgroundColor: "#172A44"
        }
    },
    root:{
        '&:disabled':{
            color:'white',
            opacity:"0.5"
        }
    },
    text:{
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(0.4, 0.5)
        }
    },
}));

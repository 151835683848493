import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function PasswordField({ confirm = false, ...props }) {
    const [ showPassword , setShowPassword ] = useState(false)
    const { t } = useTranslation();

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowPassword = () => {
        setShowPassword(showPassword => !showPassword);
    }


    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">{confirm ? t("confirm_password") : t("password")}</InputLabel>
            <OutlinedInput  
                id="outlined-adornment-password" 
                
                type={showPassword ? 'text' : 'password'}
                {...props}  
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    color="inherit"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}  
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                } 
            label="Password" />
        </FormControl>
    )
}

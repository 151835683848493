import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';



const CreateSubscription = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
    });


    const handleSubmit = (e) => {
        isEmpty(edit) ? createSubscription() : updateSubscription()
    }

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            period:edit.period ?? "month",
            plan_description: edit.description ?? "",
            period_value: edit.period_value ?? "",
            subscription_value:edit.subscription_value ?? "",
            is_enabled  : true,
            paystack_token:edit.plan_tokens?.paystack ?? "",
            flutterwave_token:edit.plan_tokens?.flutterwave ?? "",
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    


    const createSubscription = async () => {
        setLoading(true)
        const plan_tokens  = {
            paystack : formik.values.paystack_token,
            flutterwave : formik.values.flutterwave_token
        }

        const formData = {...formik.values , plan_tokens ,};

        await axiosInstance.post("api/subscription-plans/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }


    const updateSubscription = async () => {
        setLoading(true)
        const plan_tokens  = {
            paystack : formik.values.paystack_token,
            flutterwave : formik.values.flutterwave_token
        }

        const formData = {...formik.values , plan_tokens ,  slug:edit.slug  };

        await axiosInstance.put("api/subscription-plans/update" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
                <>
                    <h2>Create Subscription</h2>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"12px",
            
                    }}>
                        <div className={classes.image_section}>
                            {formik.touched.image_file  && formik.errors.image_file ? (
                                <span className={classes.content__form_error}>{formik.errors.image_file}</span>
                            ) : null}
                        </div>
                        <div >
                            <TextField onChange={formik.handleChange} placeholder={` Name`} variant="outlined" name="name" value={formik.values.name} fullWidth />
                            {formik.touched.name  && formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                        </div>
                        <div >
                            <TextField onChange={formik.handleChange} placeholder={`Subscription Value`} variant="outlined" value={formik.values.subscription_value} name="subscription_value" fullWidth type="number" />
                            {formik.touched.subscription_value  && formik.errors.subscription_value ? (
                                    <span className={classes.content__form_error}>{formik.errors.subscription_value}</span>
                                ) : null}
                        </div>
                        <div >
                            <TextField onChange={formik.handleChange} placeholder={`Flutterwavey Token`} variant="outlined" value={formik.values.flutterwave_token} name="flutterwave_token" fullWidth  />
                            {formik.touched.flutterwave_token  && formik.errors.flutterwave_token ? (
                                    <span className={classes.content__form_error}>{formik.errors.flutterwave_token}</span>
                                ) : null}
                        </div>
                        <div >
                            <TextField onChange={formik.handleChange} placeholder={`Paystack Token`} variant="outlined" value={formik.values.paystack_token} name="paystack_token" fullWidth  />
                            {formik.touched.paystack_token  && formik.errors.paystack_token ? (
                                    <span className={classes.content__form_error}>{formik.errors.paystack_token}</span>
                                ) : null}
                        </div>
                        
                        <div className={classes.input_section}>
                        <TextField onChange={formik.handleChange}  style={{
                                    width:"50%"
                                }} placeholder={`Period`} variant="outlined" value={formik.values.period_value} type="number" name="period_value"  />
                            <Select
                                variant="outlined"
                                style={{
                                    width:"50%"
                                }}
                                
                                value={formik.values.period}
                                error={!!formik.errors?.period}
                                onChange={formik.handleChange}
                                name="period"
                            >
                                <MenuItem value={"month"}>Month</MenuItem>
                                <MenuItem value={"year"}>Year</MenuItem>
                                <MenuItem value={"biannual"}>Biannual</MenuItem>
                            </Select>
                            { formik.errors.period ? (
                                <span className={classes.content__form_error}>{formik.errors.period}</span>
                            ) : null}
                            
                        </div>  
                        <TextField onChange={formik.handleChange} placeholder={`Brief description about subscription`} variant="outlined" value={formik.values.plan_description} fullWidth name="plan_description" minRows="3" multiline />
                        <FormControlLabel
                            className={classes.textfield}
                            value={formik.values.is_enabled}
                            name="is_enabled"
                            onChange={(e) => formik.setFieldValue("is_enabled" , e?.target.checked)}
                            control={<Checkbox checked={formik.values.is_enabled}  style={{color:"#fff"}}/>}
                            label="Active"
                            labelPlacement="end"
                            />
                        <div style={{display:"flex" , justifyContent:"flex-end"}}>
                            <Button loading={loading} name={isEmpty(edit) ? "Create" : "Update"}  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                        </div>
                    </div>
                </> 
    )
}



export default CreateSubscription
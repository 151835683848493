import React from 'react';
import { useLocation } from 'react-router';
import Body from '../../components/Body/Body';
import LeftSideBar from '../../components/LeftSideBar/LeftSideBar';
import BottomNav from '../../components/NavBar/BottomNav';
import NavBar from '../../components/NavBar/NavBar';
import RightSideBar from '../../components/RighSideBar/RightSideBar';
import { useStyles } from './styles';


const Home = () => {
    const classes = useStyles();
    const location = useLocation()
    
    return (
        <div className={classes.app_container}>
            <NavBar/>
            <div className={classes.container}>
                <LeftSideBar/>
                <Body />
                {
                    location.pathname.split('/')[1].toString() !== "admin" &&
                    <>
                        {/* <RightSideBar/> */}
                        <BottomNav/>
                    </>
                }
            </div>
        </div>
    )
}

export default Home
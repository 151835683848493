import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
  container:{
    color:"#fff",
    width:"100%",

    padding: theme.spacing(2 , 0),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    },
    "& p":{
      marginBottom:"8px"
    }
}, 
prize_container:{
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(3),
    marginBottom:theme.spacing(2)
},
shareLink:{
  border: "1px solid rgba(90, 121, 165, 0.12)",
  borderRadius:"8px",
  padding:"8px",
  width:"50%",
  [theme.breakpoints.down('sm')]:{
    width:"100%"
  }
  
},
input_section:{
    // width:"45%",
    "& p":{
        fontSize:"18px",
        marginBottom:theme.spacing(1)
    },
    [theme.breakpoints.down('sm')] :{
      // width:"100%"s
    }
},
textfield:{
    marginBottom:"8px"
},
bankCard:{
  flex: "1 1 auto",
  width:"360px",
  padding:theme.spacing(1),
  borderRadius:"8px",
  backgroundColor:"#172A44",
  display:"flex",
  flexDirection:"column",
  gap:"8px",
},
userBankSection:{
  marginTop: theme.spacing(1),
  display:"flex",
  flexWrap:"wrap",
  gap:"8px"
},

content__form_error:{
    fontSize:"12px",
    color:"red",
},
  button_text:{
    padding:theme.spacing(1.5 , 2)
  },
  input:{
      display:"flex",
      flexDirection:"column",
      gap:theme.spacing(2),
  },
  input_container:{
    gap:theme.spacing(2),
    [theme.breakpoints.down('sm')]:{
      flexWrap:"nowrap",
      flexDirection:"column"
    }
},
  content__form_error:{
    fontSize:"12px",
    color:"red",
  },
}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        position:"absolute",
        left:"100%",
        top:"-200%",
        width:"200px",
        backgroundColor:"#fff",
        marginLeft:theme.spacing(1),
        display:"flex",
        borderRadius:"8px",
        flexDirection:"column",
        overflow:"hidden"
    },
    info:{
        padding: theme.spacing( 1),
        backgroundColor:"#E3EDFC",
        "&:hover":{
            backgroundColor:"#D9E8FE"

        }
    }

}));

import React, { useContext,  useState } from 'react'
import { axiosInstance } from '../../helpers/client'
import { useStyles } from './styles'
import {  TextField} from '@material-ui/core';
import Button from '../../components/Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { AppContext } from '../../context/appContext';
import { useTranslation } from 'react-i18next';


const PersonalInfo = ({info , refetch}) => {
    const classes = useStyles()
    const [ loading , setLoading ] = useState(false);
    const { setFeedback } = useContext(AppContext)
    const [account  ] = useState(info);
    const { t } = useTranslation();

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

    const handleSchema = Yup.object().shape({
        name: Yup.string().label('Name'),
        display_name: Yup.string().label('Display Name'),
        phone_number:  Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        email: Yup.string().email().label('Email'),
        username: Yup.string().label('Username'),
    })


    const formValues = [
        {
            name: "name",
            label: t("name")
        },
        {
            name:"display_name",
            label: t("display_name")
        },
        {
            name:"username",
            label: t("username")
        },
        {
            name:"email",
            label: "Email"
        },
        {
            name:"phone_number",
            label: t("number")
        },
    ]
    const handleSubmit = async (form) => {
        setLoading(true)
        await axiosInstance.put('api/account/update' , form).then(({data}) => {
            setFeedback({message:data.message , severity:"success" })
                refetch()
            }).catch(({response}) => {
                const errors = response.data.errors;
                if(response.status === 422){
                    Object.keys(errors).map((error) => formik.setFieldError(error , errors[error][0]))
                } else {
                    setFeedback({ message:response.data.message , severity:"error"})
                }
            })
            .finally(() => setLoading(false))
    }

    const formik = useFormik({
        initialValues: {
          name: account.name,
          email: account.email,
          username: account.username,
          display_name: account.display_name,
          phone_number: account.phone ?? null,
        },
        onSubmit: handleSubmit,
        validationSchema:handleSchema
    });

    
    return (
        <div className={classes.container}>
            <div className={classes.input_container}>
                {
                    formValues.map(({name , label} , index) => (

                    <div key={index} className={classes.input_section}>
                        <p>{label}</p>
                        <TextField onChange={formik.handleChange} name={name}  value={formik.values[name]} className={classes.textfield} variant="outlined" fullWidth/> 
                        {
                            formik.touched[name]  && formik.errors[name] ? (
                                <span className={classes.content__form_error}>{formik.errors[name]}</span>
                            ) : null
                        }   
                    </div>
                    ))
                }
            </div>
            <div style={{marginTop:"16px"}}>
                <Button classes={{text:classes.button_text}} loading={loading} backgroundColor="#B00610" name={t("update")} onClick={formik.handleSubmit}/>
            </div>
        </div>
    )
}

export default PersonalInfo

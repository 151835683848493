import { makeStyles } from "@material-ui/core/styles";
;

const colors = [
    "#6EC5E9",
    "#00B628"
]

const randomElement = colors[Math.floor(Math.random() * colors.length)];
export const useStyles = makeStyles((theme) => ({

    container:{
        display:"flex",
        minWidth:"200px",
        maxWidth:"360px",
        flex:"1 1 auto",
        justifyContent:"space-between",
        borderRadius:"8px",
        alignItems:"center",
        cursor:"pointer",
        border:"1px solid #172A44",
        background: "rgba(23, 42, 68, 0.1)",
        padding:theme.spacing(1),
        [theme.breakpoints.down("sm")] :{
            width:"160px"
        }
    },
    img:{
        width:"50px",
        height:"50px",  
        backgroundColor:randomElement,
    },
    right:{
        marginLeft: theme.spacing(1),
    },
    info:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    description:{
        fontFamily:"Quicksand",
        fontWeight:500,
        fontSize:"12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow:"ellipsis",
        width:"90%"
    },
    date:{
        fontSize:"16px",
        fontWeight:300,
        fontFamily:"Quicksand",
    },
    prize:{
        fontSize:"12px",
        fontFamily:"Quicksand",
        fontWeight:300,
        color:"#6EC5E9"
    },
    start:{
        fontSize:"16px",
        color:"#FFF",
        fontFamily:"Quicksand",
        fontWeight:700,
    
    },
    option:{
        height:"50px",
        display:"flex",
        alignItems:"flex-end",
    }
    ,list_item_gutters:{
        paddingLeft:"8px",
        paddingRight:"8px",
    }
    ,list_item_root:{
        paddingTop:"0px",
        paddingBottom:"0px",
    }
}));

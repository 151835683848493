import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
},  
options:{
  display:"flex",
  justifyContent:"flex-end"
},
top_section:{
  display:'flex',
  gap:"32px",
},
img:{
  height:"200px",
  width:"200px",
  backgroundSize:"cover",
  backgroundPosition:"center"
},
imgLogo:{
  display:"flex",
  alignItems:"center",
  gap:"8px",
  cursor:"pointer"
},
option:{
  display:"flex",
  gap:"8px",
  alignItems:"flex-start"
},
description:{
flex:1,
gap:"16px",
display:"flex",
flexDirection:"column",
alignItems:"flex-start",
"& h3":{
  margin:0
}
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  }
},                                              
}));

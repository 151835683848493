import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        borderRadius: "8px",
        backgroundColor: "#0F162D",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2),
        display: "flex",
        color:"white"
    },
    content:{
        textAlign:"justify",
        fontFamily:'Quicksand',
        fontSize:"14px",
        fontWeight:500,
    },
    img:{
        minWidth:"50px",
        height:"50px",  
        marginRight: theme.spacing(1.5),
    },
    date:{
        fontSize:"14px",
        fontFamily:"Quicksand",
        marginBottom: theme.spacing(1)
    },
    name:{
        fontSize:"16px",
        fontFamily:"Quicksand",
        fontWeight:500
       
    },
   
}));

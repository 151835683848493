import React, { useContext } from 'react';
import { Route, Switch , Redirect } from "react-router-dom";
import { useStyles } from './styles';
import Main from '../../pages/Main/Main';
import Tournament from '../../pages/Tournament/Tournament';
import FeedPage from '../../pages/Feed/FeedPage';
import Game from '../../pages/Game/Game';
import PrayerProfile from '../../pages/PlayerProfile/PrayerProfile';
import { AppContext } from '../../context/appContext';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Leaderboard from '../../pages/Leaderboard/Leaderboard';
import Profile from '../../pages/Profile/Profile';
import CreateTournamentPage from '../../pages/CreateTournament/CreateTournamentPage';
import { AdminRoute } from '../../App';
import ManageUsers from '../../pages/Admin/ManageUsers/ManageUsers';
import ManageTournament from '../../pages/Admin/Tournament/ManageTournament';
import ManageSubscription from '../../pages/Admin/Subscription/ManageSubscription';
import ManageGame from '../../pages/Admin/ManageGame/ManageGame';
import Payment from '../../pages/Admin/Payment/Payment';
import ManageAchievement from '../../pages/Admin/Achievement/Achievement';
import ViewTournament from '../../pages/Admin/Tournament/ViewTournament';
import TeamProfile from '../../pages/TeamProfile/TeamProfile';
import Matches from '../../pages/Admin/Matches/Matches';
import Search from '../../pages/Search/Search';
import Stream from '../../pages/Stream/Stream';
import AdminSettings from '../../pages/Admin/AdminSettings/AdminSettings';
import ManageLeaderboard from '../../pages/Admin/Leaderboard/ManageLeaderboard';
import ManageReferral from '../../pages/Admin/Referral/ManageReferral';
import Dashboard from '../../pages/Admin/Dashboard/Dashboard';
import Forum from '../../pages/Forum/Forum';
import SinglePost from '../../pages/Forum/SinglePost';
import ManageForum from '../../pages/Admin/Forum/ManageForum';
import Market from '../Market/Market';
import ShowProduct from '../Market/ShowProduct';
import Cart from '../Cart/Cart';
import ManageProduct from '../../pages/Admin/Market/Product/ManageProduct';
import ManageCategory from 'pages/Admin/Market/Category/ManageCategory';
import ManageVendor from 'pages/Admin/Market/Vendor/ManageVendor';
import ListOrder from 'pages/Admin/Market/Order/ListOrder';
import ManageLocation from 'pages/Admin/Market/Location/ManageLocation';
import Checkout from '../Cart/Checkout';
import ManageCode from 'pages/Admin/Market/ProductCode/ManageCode';
import Subscription from 'pages/Subscription/Subscription';
import OnlineGame from '../../pages/OnlineGame/OnlineGame';

export const PrivateRoute = ({children , ...props}) => {
    const { loggedIn } = useContext(AppContext);
    return (
        loggedIn ? 
            <Route {...props}>{children}</Route> 
        : <Redirect to="/login" />
    )
}

const Body = () => {
    const classes = useStyles();
    const {  feedback ,setFeedback } = useContext(AppContext);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setFeedback({});
    };
    
    return (
        <div className={classes.container}>
            <Switch>
                <Route exact path={"/home"}  >
                    <Main />
                </Route>
                <AdminRoute exact path={"/admin/dashboard"}  >
                   <Dashboard />
                </AdminRoute>
                <AdminRoute exact path={"/admin/manage-users"}  >
                   <ManageUsers />
                </AdminRoute>
                <AdminRoute exact path={"/admin/forum"}  >
                   <ManageForum />
                </AdminRoute>
                <AdminRoute exact path={"/admin/game"}  >
                   <ManageGame />
                </AdminRoute>
                <AdminRoute exact path={"/admin/settings"}  >
                   <AdminSettings />
                </AdminRoute>
                <AdminRoute exact path={"/admin/tournament"}  >
                   <ManageTournament />
                </AdminRoute>
                <AdminRoute exact path={"/admin/leaderboard"}  >
                   <ManageLeaderboard />
                </AdminRoute>
                <AdminRoute exact path={"/admin/matches"}  >
                   <Matches />
                </AdminRoute>
                <AdminRoute exact path={"/admin/referral"}  >
                   <ManageReferral />
                </AdminRoute>
                <AdminRoute exact path={"/admin/tournament/create"}>
                    <CreateTournamentPage />
                </AdminRoute>
                <AdminRoute exact path={"/admin/tournament/:slug"}  >
                   <ViewTournament />
                </AdminRoute>
                <AdminRoute exact path={"/admin/subscription"}  >
                   <ManageSubscription />
                </AdminRoute>
                <AdminRoute exact path={"/admin/achievement"}  >
                   <ManageAchievement />
                </AdminRoute>
                <AdminRoute exact path={"/admin/payment"}  >
                   <Payment />
                </AdminRoute>
                <AdminRoute exact path={"/admin/products"}  >
                   <ManageProduct />
                </AdminRoute>
                <AdminRoute exact path={"/admin/categories"}  >
                   <ManageCategory />
                </AdminRoute>
                <AdminRoute exact path={"/admin/vendors"}  >
                   <ManageVendor />
                </AdminRoute>
                <AdminRoute exact path={"/admin/locations"}  >
                   <ManageLocation />
                </AdminRoute>
                <AdminRoute exact path={"/admin/product-codes"}  >
                   <ManageCode />
                </AdminRoute>
                <AdminRoute exact path={"/admin/orders"}  >
                   <ListOrder />
                </AdminRoute>

                <PrivateRoute path={"/home/feed"}>
                    <FeedPage />
                </PrivateRoute>
                <PrivateRoute  path={"/home/live-stream"}>
                    <Stream />
                </PrivateRoute>
                <PrivateRoute path={"/home/profile"}>
                    <Profile />
                </PrivateRoute>
                <PrivateRoute path={"/home/tournament/edit/:slug"}>
                    <CreateTournamentPage />
                </PrivateRoute>

                <PrivateRoute path={"/home/subscription"}>
                    <Subscription />
                </PrivateRoute>

                <Route exact path={"/home/tournament"}>
                    <Game />
                </Route>
                <Route exact path={"/home/cart"}>
                    <Cart />
                </Route>
                <Route exact path={"/home/checkout"}>
                    <Checkout/>
                </Route>
                <Route exact path={"/home/tournament/:tournamentSlug"}>
                    <Tournament />
                </Route>
                <Route exact path={"/home/market"}>
                    <Market />
                </Route>
                <Route exact path={"/home/market/:productSlug"}>
                    <ShowProduct />
                </Route>
                <Route  path={"/home/search"}>
                    <Search />
                </Route>
                <Route  path={"/home/player/:player"}>
                    <PrayerProfile />
                </Route>
                <Route  path={"/home/team/:team"}>
                    <TeamProfile />
                </Route>
                <Route  path={"/home/leaderboard"}>
                    <Leaderboard />
                </Route>
                <PrivateRoute exact path={"/home/forum"}>
                    <Forum />
                </PrivateRoute>
                <PrivateRoute exact path={"/home/forum/:slug"}>
                    <SinglePost />
                </PrivateRoute>
                <Route path={"/home/online"}>
                  <OnlineGame />
                </Route>
            </Switch>
            <Snackbar open={!!feedback.message} autoHideDuration={6000} onClose={handleClose}  key={"bottom" + "left"}  anchorOrigin={{ vertical:"bottom", horizontal:"left" }}>
                <MuiAlert  elevation={6} variant="filled" onClose={handleClose} severity={feedback.severity}>
                        {feedback.message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default Body
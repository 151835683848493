import { makeStyles, } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        marginRight: theme.spacing(1),
        borderRadius: "50%",
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        color:"white",
        cursor:"pointer"
    },
    img:{
        minWidth:"100px",
        height:"100px",  
        borderRadius: "50%",
        backgroundSize:"cover",
        backgroundPosition:"center"
    },
    text:{
        marginTop: theme.spacing(1),
        textAlign:"center",
        color:"white"
    }
}));

import React from 'react';
import { useStyles } from './styles';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';



const TeamCard = ({name , banner , description ,  ...props}) => {
    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <div className={classes.container} {...props}>
            <Avatar alt={name} src={banner} className={classes.img} />
            <div className={classes.right}>
                <div className={classes.info}>
                    <p className={classes.start}>{name}</p>
                    <p className={classes.description}>{description}</p>
                    <p className={classes.prize}>{t("view")}</p>
                </div>
            </div>
        </div>
    )
}

export default TeamCard;
import React, { useContext,  useState, useRef } from 'react';
import { axiosInstance } from '../../helpers/client';
import { useStyles } from './styles';
import Button from '../../components/Button/Button';
import { AppContext } from '../../context/appContext';
import { useTranslation } from 'react-i18next';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from "../../components/Modal/Modal";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";


const AccountSetting = ({info , refetch}) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isMoreReason, setIsMoreReason] = useState(false);
    const [moreReason, setMoreReason] = useState('');
    const [reason, setReason] = React.useState('');
    const [deleteWord, setDeleteWord] = React.useState('');
    const {setFeedback, setLogin} = useContext(AppContext);
    const ref = useRef();
    const { t } = useTranslation();

    const openDeleteAccountModal = () => {
        setOpenModal(true);
    }

    const closeDeleteAccountModal = () => {
        setOpenModal(false);
    }

    const handleChangeDeleteWord = (event) => {
        setDeleteWord(event.target.value);
    }

    const terminateAccount = async () => {

        if(deleteWord !== "delete"){
            setFeedback({message: "Kindly type in the word delete to confirm account termination request!", severity: "error"});
            return false;
        }

        setLoading(true);
        await axiosInstance.delete(`api/account`, { 
            reason: reason + moreReason,
        }).then(function ({data}) {
            setFeedback({message:data.message});
            logout();
        }).catch(({ response }) => {
            setFeedback({message:response.data.message, severity:"error"});
        }).finally(() => {
            setLoading(false)
        });
    }

    const logout = async () => {
        const timeoutId = setTimeout(() => {
            setLogin(false);
            localStorage.clear();
            clearTimeout(timeoutId);
            window.location.href = "/home";
        }, 3000);
    }

    const handleChange = (event) => {
        setReason(event.target.value);
        if(event.target.value === 4){
            setIsMoreReason(true);
        }else{
            setIsMoreReason(false);
        }
    }

    const modalStyle = {
        width: '420px', // Set the desired width
        margin: 'auto', // Center the modal horizontally
        minHeight: '420px',
        padding: '20px',
        borderRadius: '8px',
    }

    const styles = {
        placeholder: {
            color: 'white' // Set the color to white
        }
    }
    
    return (
        <div className={classes.container}>
            <div>
                <div style={{marginTop: "16px"}}>
                    <h2>{t("Account Settings")}</h2>
                    <p>By deleting your account you will no longer be able to access your gamr activities on the Gamr Web and Mobile application</p> <br />
                    <Button classes={{text:classes.btnRed}} loading={loading} backgroundColor="#B00610" name={t("Delete Account")} onClick={openDeleteAccountModal}/>
                </div>

                <Modal size="sm"
                  open={openModal}
                  onClose={closeDeleteAccountModal}
                >
                  <div ref={ref}>
                    <div style={modalStyle}>
                        <br />
                        <h2>{t("Are you sure you want to delete this account?")}</h2>
                        <br />
                        <p className={classes.p}> <span className={classes.warning}>{t("Warning")}:</span> {t("This action CAN NOT be undone. Please be certain about your decision")}</p>
                        <br />
                        <FormGroup>
                            <FormControlLabel control={<IoMdCheckmarkCircleOutline style={{fontSize: "24px", padding: "10px"}} /> } label="You won't be able to access the Gamr Web App" />
                            <FormControlLabel control={<IoMdCheckmarkCircleOutline style={{fontSize: "24px", padding: "10px"}} />} label="You won't be able to log into Gamr Mobile App" />
                            <FormControlLabel control={<IoMdCheckmarkCircleOutline style={{fontSize: "24px", padding: "10px"}} />} label="Tournament created will be remove from Gamr" />
                            <FormControlLabel control={<IoMdCheckmarkCircleOutline style={{fontSize: "24px", padding: "10px"}} />} label="Tournament participation records will be deleted" />
                            <FormControlLabel control={<IoMdCheckmarkCircleOutline style={{fontSize: "24px", padding: "10px"}} />} label="You won't be able to post on Gamr Forum" />
                        </FormGroup>
                        <br />
                        <FormControl fullWidth>
                            <InputLabel InputLabelProps={{ style: { color: "white" } }} id="demo-simple-select-label">Why are you deleting your account?</InputLabel>
                            <Select
                                style={{color: "#fff"}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={reason}
                                label="Why are you deleting your account?"
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>{t("I don’t need it anymore")}</MenuItem>
                                <MenuItem value={2}>{t("I’m switching to another tournament platform")}</MenuItem>
                                <MenuItem value={3}>{t("Gamr subscription is too expensive")}</MenuItem>
                                <MenuItem value={4}>{t("Other reason")}</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <br />
                        {(isMoreReason ) ? (
                            <FormControl style={{paddingBottom: "10px", marginBottom: "10px"}} fullWidth>
                                <TextField 
                                    InputLabelProps={{ style: { color: "white" } }}
                                    InputProps={{
                                        style: {color: "white"}
                                    }}
                                    id="outlined-basic" 
                                    label="Tell us why you want to leave" 
                                    variant="outlined" />
                            </FormControl> 
                        ) : ""}
                        <FormControl fullWidth>
                            <TextField 
                                InputLabelProps={{ style: { color: "white" } }}
                                InputProps={{
                                    style: {color: "white"}
                                }}
                                id="outlined-basic" 
                                label="Type the word `delete` to confirm your decision" 
                                variant="outlined"
                                onChange={handleChangeDeleteWord} />
                        </FormControl>
                        <br /><br />
                        <Button className={classes.btnRed} backgroundColor="#B00610" name={t("Delete")} onClick={terminateAccount}/>
                    </div>
                  </div>
            </Modal>
            </div>
        </div>
    );
}

export default AccountSetting

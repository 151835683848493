import { axiosInstance } from "../helpers/client";


export const getProducts = async () => await axiosInstance.get("api/categories?include_products=1").then( ({ data })  => data.data)

export const getLocations = async () => await axiosInstance.get("api/locations?per_page=30").then( ({ data })  => data.data)

export const getCategories = async () => await axiosInstance.get("api/categories").then( ({ data })  => data.data)

export const getVendors = async () => await axiosInstance.get("api/vendors").then( ({ data })  => data.data)

export const getMarketBanners = async () => await axiosInstance.get("api/configurations/market-banner").then( ({ data })  => data.data)
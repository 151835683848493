import React from 'react';
import { FaRegCheckCircle, FaSortAmountDown, FaCoins } from "react-icons/fa";
import { useStyles } from './styles';
import { format  } from "date-fns";
import inflection from 'inflection';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Numeral from 'react-numeral';


const SubscriptionTransaction = ({transactionsData, paymentChannel, ...props}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.info}>
                <p className={classes.title}>
                    Transaction
                </p>
                <TableContainer>
                    <Table sx={{ minWidth: "80%" }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><p className={classes.headerTitle}>Transaction Date</p></TableCell>
                                <TableCell><p className={classes.headerTitle}>Currency</p></TableCell>
                                <TableCell><p className={classes.headerTitle}>Amount</p></TableCell>
                                <TableCell><p className={classes.headerTitle}>Description</p></TableCell>
                                <TableCell><p className={classes.headerTitle}>Status</p></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactionsData.map((row, index) => (
                                <TableRow
                                  key={index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0, color: "white" } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <p className={classes.subTitle}>{row.created_at}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p className={classes.subTitle}>{row.currency}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p className={classes.subTitle}>
                                            {
                                                (row.payment_method === "mtn_transaction") ? (
                                                    (row.currency === "USD") ? (
                                                        (row.comment === "Gamr pro subscription - Monthly") ? (
                                                            <div>{"$"+ row.amount}</div>
                                                        ) : (row.comment === "Gamr pro subscription - Weekly") ? (
                                                            <div>
                                                                $<span><Numeral
                                                                value={parseFloat(50/100) * parseFloat(row.amount)}
                                                                format={"0,0.00"}
                                                                /> </span>
                                                            </div>
                                                        ) : (row.comment === "Gamr pro subscription - Daily") ? (
                                                            <div>
                                                                $<span><Numeral
                                                                value={parseFloat(10/100) * parseFloat(row.amount)}
                                                                format={"0,0.00"}
                                                                /> </span>
                                                            </div>
                                                        ) : (<div>N/A</div>)
                                                    ) : (
                                                        <div>
                                                            <FaCoins /> {row.amount}
                                                        </div>
                                                    )
                                                ) : (
                                                    (row.currency === "USD") ? "$"+ row.amount : (
                                                        <div>
                                                            <FaCoins /> {row.amount}
                                                        </div>
                                                    )
                                                )
                                            }
                                        </p>
                                    </TableCell>
                                    <TableCell><p className={classes.subTitle}>{row.comment}</p></TableCell>
                                    <TableCell><p className={classes.subTitle}>{row.status}</p></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default SubscriptionTransaction;
import React, { useContext, useState , useEffect } from 'react'
import { useStyles } from './styles';
import {  Avatar, IconButton} from '@material-ui/core';
import { useParams , useHistory} from 'react-router';
import { axiosInstance } from '../../helpers/client';
import  { Tag } from '../../components/ForumCard/ForumCard';
import {  FaFacebook, FaRegComment,  FaTwitter, FaWhatsapp ,FaTelegramPlane } from 'react-icons/fa';
import { AiFillHeart, AiOutlineFileText, AiOutlineHeart, AiOutlineShareAlt } from 'react-icons/ai';
import { formatDistanceToNow } from 'date-fns';
import Button from '../../components/Button/Button';
import Reply from '../../components/Reply/Reply';
import { AppContext } from '../../context/appContext';
import { useRef } from 'react';
import { MdDelete} from 'react-icons/md';
import Modal from '../../components/Modal/Modal';
import { IoLogoReddit } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { getInterest } from '../../api/interest';
import { IoMdArrowBack } from 'react-icons/io'
import forumbg from '../../asset/svg/foum-share.svg'
import logo from '../../asset/img/logo.png'
import {Helmet} from "react-helmet";
import { TwitterShareButton , RedditShareButton , FacebookShareButton , WhatsappShareButton , TelegramShareButton} from 'react-share'



const Comment = ({ user , slug , likes_count , editComment, liked , handleReply , body , replied, created_at , can ,  refetch}) => {
    const classes = useStyles();
    const history = useHistory()

    const deleteComment = () => {
        axiosInstance.delete(`api/forum/replies/${slug}`).then(() => {
            refetch()
        })
    }

    const toggleLike = (type , slugType) => {
        axiosInstance.post(`api/forum/${type}/${slugType}/like`).then(() => {
            refetch()
        })
    }
    
    

    return (
        <div id={slug}>
            {
                !!replied?.body && 
                <a className={classes.replied} href={`#${replied.slug}`}>
                    {replied.body}
                </a>
            }
            <div style={{display:'flex'}}>
                <div style={{cursor:"pointer"}} onClick={() => history.push(`/home/player/${user.username}`)}>
                     <Avatar  src={user.avatar} alt={user.username}  />
                     {/* <div className={classes.line}></div> */}
                </div>
                <div style={{display:'flex', flexDirection:"column", gap:"4px"}}>
                    <div style={{marginLeft:"12px"}}>

                        <p className={classes.name } style={{cursor:"pointer"}} onClick={() => history.push(`/home/player/${user.username}`)}>{user.username}</p>
                        <p className={classes.time}>{formatDistanceToNow(new Date(created_at))}</p>
                    </div>
                    <div>
                        <p style={{margin:"16px 0"}}>{body}</p>

                        <div style={{display:'flex' , gap:'12px', alignItems:"flex-end"}}>
                            <div onClick={() => toggleLike('replies' , slug)} style={{display:'flex', alignItems:'center', gap:"4px" , cursor:"pointer"}}> 
                                {
                                    liked 
                                    ? <AiFillHeart  color="#B00610" size={25} /> 
                                    : <AiOutlineHeart  size={25} /> 
                                }
                                {
                                    likes_count ?? 0
                                }
                            </div>
                           
                            <p className={classes.reply} onClick={() => handleReply(slug , user , body)}>Reply</p>
                            {
                                can?.delete &&  <MdDelete onClick={() => deleteComment(slug)} size={20} />
                            }
                            {
                                // can.update &&  <MdEdit onClick={() => editComment(slug , body , replied.slug)} size={20} />
                            }
                        </div>

                    </div>  
                </div>
            </div>
        </div>
    )
}


export default function SinglePost() {
    const classes = useStyles();
    const history = useHistory();
    const { slug } = useParams();
    const replyRef = useRef(null);
    const [ open , setOpen] = useState(false)
    const shareUrl = `${process.env.REACT_APP_FRONT_DOMAIN}home/forum/${slug}`
    const { setFeedback , loggedIn} = useContext(AppContext)
    const { data:interest,  } = useQuery('interests', getInterest , {
        enabled:loggedIn
    })
    const [ data , setData] = useState({})
    const [ replies , setReplies] = useState([ ])
    const [ userReply , setUserReply] = useState({
        user:{} , slug:"" , body:""    })


    const [loading , setLoading] = useState({
        post:true
    })


    const [openCopy, setOpenCopy] = useState(false)


    const getPost = async () => {
        setLoading({...loading , post:true})
        await axiosInstance.get(`api/forum/posts/${slug}`).then(function ({ data }) {
            setData(data.data);
        }).finally(() => setLoading({...loading , post:false}))
    };

    const toggleLike = (type , slugType) => {
            let newCount = data.liked ? data.likes_count - 1 : data.likes_count + 1;  
        axiosInstance.post(`api/forum/${type}/${slugType}/like`).then(() => {
            setData((item) => ({...item, liked: !item.liked, likes_count: newCount}))
        })
    }

    const getReplies = async () => {
        await axiosInstance.get(`api/forum/posts/${slug}/replies`).then(function ({ data }) {
            setReplies(data.data);
        })
    };

    const handleCopyOpen = () => {
        setOpenCopy(true)
    }
    const handleCopyClose = () => {
        setOpenCopy(false)
    }

    const handleCopy = () => {
        handleCopyOpen()
        navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_DOMAIN}home/forum/${slug}`)

        setTimeout(() => handleCopyClose(), 2000)
    }

    const resetReply = () => {
        setUserReply({
            user:{},
            slug:"",
            body:""
        })
    }

    const editComment = (slug , body , replySlug) => {

    }

    const handleReply = (slug , user , body) => {
        setUserReply({slug , user , body})
        replyRef.current.focus();

    }

    const handleClose = () => {
        setOpen(false)
    }

    const addTag = (tag) => {
        axiosInstance.put(`api/forum/interests/${tag}`).then(({data}) => {
            setFeedback({message:data.message , severity:"success"})
        })
    }

    const hasInterest = (name) => {
        if(!loggedIn )  return false

        return interest.some((data) => data.name === name)
    }

  

    useEffect(() => {
        getPost()
        getReplies()
   }, [])




    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Gamr Forum - ${data?.title ?? ""}`}</title>
                <link rel="canonical" href={shareUrl} />
                <meta name="description" content={data.title} />
              
                <meta name="twitter:site" content="@gamr" />
                <meta name="twitter:title" content="Gamr Forum" />
                <meta name="twitter:description" content={data.title} />
                <meta name="twitter:image" content={logo} />
                <meta name="twitter:card" content="summary_large_image"/>

                <meta property="og:site_name" content="Gamr" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={shareUrl} />
                <meta property="og:title" content="Gamr Forum" />
                <meta property="og:description" content={data.title}/>
                <meta property="og:image" content={logo} /> 

                <meta property="og:type" content="website" />
            </Helmet>
            <IconButton onClick={() => history.goBack()}>
                <IoMdArrowBack size={25}/>
            </IconButton>
        {
            !loading.post &&
            <div className={classes.container}>
    
                <h2 className={classes.title}>{data.title}</h2>
                <div style={{display:'flex' , justifyContent:'space-between', marginBottom:"32px"}}>
                    <div style={{display:'flex' , cursor:"pointer"}} onClick={() => history.push(`/home/player/${data.user.username}`)}>
                        <Avatar className={classes.img} src={data.user.image} alt={data.user.username}  />
                        <div style={{display:'flex', flexDirection:"column", gap:"4px"}}>
                            <p className={classes.name}>{data.user.username}</p>
                            <p className={classes.time}>{formatDistanceToNow(new Date(data.created_at))}</p>
                        </div>
                    </div>
                    <div className={classes.tagSection}>
                        {
                            data.tags.map(({name} , index) => <Tag key={index} title={name} add={!hasInterest(name)} tagClick={addTag}/>)
                        }

                    </div>

                </div>
                <p className={classes.description}>{data.body}</p>
                {
                    !!data.image && <div style={{backgroundImage:`url(${data.image})`, width:"100%" , height:"50vh" , backgroundRepeat:"no-repeat", backgroundSize:"contain"}}></div> 
                }
                <div style={{display:'flex' , gap:'16px', alignItems:'center', marginTop:'16px'}}>
                    
                    <span style={{display:'flex', alignItems:'center', gap:"4px"}}><FaRegComment size={25} />{data.replies_count ?? 0}</span>
                    <span onClick={() => toggleLike('posts' , slug)} style={{display:'flex', alignItems:'center', gap:"4px" , cursor:"pointer"}}> 
                        {
                            data.liked 
                            ? <AiFillHeart  color="#B00610" size={25} /> 
                            : <AiOutlineHeart  size={25} /> 
                        }
                        {
                            data.likes_count ?? 0
                        }
                    </span>
                    <span style={{display:'flex', alignItems:'center', gap:"4px"}}><AiOutlineShareAlt onClick={() => setOpen(true)} size={25} /> </span>
                    
                </div>

                <div className={classes.commentSection}>
                    {
                         replies.map((item ,index) => <Comment key={index} refetch={getReplies} handleReply={handleReply} {...item}/>)
                    }
                </div>
                <div className={classes.replySection} >
                    <Reply postSlug={slug} userReply={userReply} resetReply={resetReply} refetch={getReplies} replyRef={replyRef}/>  
                </div>
            </div>
        }
        <Modal open={open} onClose={handleClose}>
            <div className={classes.shareGradient} style={{backgroundImage:`url(${forumbg})`}}>
                <h2>Share With Your Squad</h2>
            </div>
            <div className={classes.copySection}>
                <div className={classes.copyInfo}>
                    <AiOutlineFileText size={40}/>
                    <div className={classes.details}>
                        <p className={classes.shareTitle}> {data.title}</p>
                        <p className={classes.shareDescription}>{data.body}</p>
                    </div>
                </div>
                <div>
                    {
                        openCopy && <p>copied!</p>
                    }
                    <Button name="Copy" onClick={handleCopy}/>
                  
                </div>
            </div>
            <div className={classes.iconSection}>
                <WhatsappShareButton url={shareUrl}>
                    <FaWhatsapp size={30}/>
                </WhatsappShareButton>
                <TwitterShareButton url={shareUrl}>
                    <FaTwitter size={30}/>
                </TwitterShareButton>
                <RedditShareButton url={shareUrl} >
                    <IoLogoReddit size={30}/>
                </RedditShareButton>
                <TelegramShareButton  url={shareUrl}>
                    <FaTelegramPlane size={30}/>
                </TelegramShareButton>
                <FacebookShareButton  url={shareUrl}>
                    <FaFacebook size={30}/>
                </FacebookShareButton>
            </div>
        </Modal>
        </>
    )
}

import React from "react";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Chip, TextField } from "@material-ui/core";
import { useState } from "react";
import { axiosInstance } from "../../helpers/client";
import { useEffect } from "react";

const filter = createFilterOptions();


const Taggables = ({
  onChange,
  onBlur,
  tags,
  placeholder,
}) => {


  const [ search , setSearch ] = useState('')
  const [ data , setData ] = useState('')
  const handleChange = (event) => {
    const value = event.target.value;
    setSearch(value);
};

const getTags = async () => {
  await axiosInstance.get(`api/forum/tags?search=${search}`).then(({data}) => {
      let arr = [];
      data.data.map(({name}) => arr.push(name))
      setData(arr)
  })
}

useEffect(() => {
  getTags();
} ,[search])


  return (
    <div>
      <Autocomplete
        multiple
        options={data}
        value={tags ?? []}
        onChange={onChange}
        onInputChange={(e , v) => setSearch(v)}
        renderTags={(value, getTagProps) =>
          value.map((value, index) => (
            <Chip
              variant="default"
              label={value}
              key={index}
              {...getTagProps({ index })}
            />
          ))
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
  
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push(inputValue)
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name="tags"
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder ?? "Add tags"}
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default Taggables;

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
  container:{
    color:"#fff",
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
}, 
prize_container:{
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(3),
    marginBottom:theme.spacing(2)
},
input_section:{
    width:"45%",
    "& p":{
        fontSize:"18px",
        marginBottom:theme.spacing(2)
    }
},
textfield:{
    width:"100%"
},

content__form_error:{
    fontSize:"12px",
    color:"red",
},
input_container:{
    width:"100%",
    display:"flex",
    flexWrap:"wrap",
    gap:"32px 12px",

},
  button_text:{
    padding:theme.spacing(1.5 , 2)
  },
  input:{
      display:"flex",
      flexDirection:"column",
      gap:theme.spacing(2),
  },
  input_container:{
    display:"flex",
    width:"50%",
    flexDirection:"column",
    gap:theme.spacing(1),
}
}));

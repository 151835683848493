import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    box:{
        flex: "1 1 0",
        width:"30%",
        borderRadius: "8px",
        border: "1px solid #172A44",
        background: "rgba(23, 42, 68, 0.1);",
        padding: theme.spacing(2),
        display: "flex",
        color:"white",
        justifyContent:"space-between",
        [theme.breakpoints.down("sm")] :{
            minWidth:"300px"
        }
    },
    tab_label:{
        minWidth:"120px"
    },
    box_section:{
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"column"
    },
    container:{
        color:"#fff",
    },
    tab_section:{
        padding:theme.spacing(1)
    },
    header:{
        padding:theme.spacing(2 , 3),
        display:"flex",
        backgroundSize:"cover",
        backgroundPosition:"center",
        flexDirection:"column",
        height:"25vh",
        justifyContent:"space-between",
    },
    header_info:{
        display:"flex",
        justifyContent:"space-between"
    },
    title:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& h2":{
            fontSize:"32px",
            margin:0
        }
    },
    name_section:{
        display:"flex",
        flexDirection:"column",
        "& h2":{
            fontSize:"26px",
            margin:theme.spacing(1, 0),
            [theme.breakpoints.down("sm")]:{
                fontSize:"20px"
            }
        }
    },
    image_section:{
        display:"flex",
        alignItems:"center",
        gap:"8px"
    },
    button_section:{

        [theme.breakpoints.down("sm")]:{
            display:"none"
        }
    },

    avatar:{
        minWidth:"80px",
        height:"80px",
        backgroundColor:"#232e46"
    },

    info:{
    },
}));

import React, { useContext, useEffect, useState } from 'react'
import { useStyles } from './styles';
import { a11yProps, TabPanel } from '../Tournament/Tournament';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Banner from '../../asset/img/profile.png';
import { AppContext } from '../../context/appContext';
import inflection from 'inflection';
import Security from '../../components/Security/Security';
import { axiosInstance } from '../../helpers/client';
import PersonalInfo from '../../components/PersonalInfo/PersonalInfo';
import Team from '../../components/Team/Team';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import { useFormik } from 'formik';
import TransactionCard from '../../components/TransactionCard/TransactionCard';
import { Skeleton } from '@material-ui/lab';
import PaymentAccount from '../../components/PaymentAccount/PaymentAccount';
import UserReferral from '../../components/UserReferral/UserReferral';
import { useTranslation } from 'react-i18next';
import RewardCard from '../../components/RewardCard/RewardCard';
import AccountSetting from '../../components/AccountSetting/AccountSetting';


export default function Profile() {
    const { user} = useContext(AppContext)
    const classes = useStyles(); 
    const [value, setValue] = useState(0);
    const [ loading , setLoading ] = useState(false)
    const [account, setAccount] = useState({});
    const [ transactions , setTransactions ] = useState([])
    const [ rewards , setRewards ] = useState([])
    const { setFeedback  } = useContext(AppContext)
    const [ fetch , setFetch ] = useState(false)
    const { t } = useTranslation()



    const getAccountData  = async () => {
        setLoading(true)
        await axiosInstance.get('api/account').then(({data}) => {
            setAccount(data.data)
            formik.setFieldValue('bannerImage', data.data.avatar ?? "")
        })
        .finally(() => setLoading(false))
    }

    const getTrasactions  = async () => {
        await axiosInstance.get('api/wallet/transactions').then(({data}) => {
            setTransactions(data.data)
        })
    }

    const getRewards  = async () => {
        await axiosInstance.get('api/activity-challenges/rewards').then(({data}) => {
            setRewards(data.data)
        })
    }

    const formik = useFormik({
        initialValues:{
            bannerImage:user.avatar ?? "",
            avatar:""
        }
    })

    const updateImage = async () => {
        await axiosInstance.put('api/account/update' , formik.values ).then(({data}) => {
                setFeedback({ message:data.message , severity:"success"})
            }).catch(({response}) => {
                setFeedback({ message:response.data.message , severity:"error"})
            })
    }

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("avatar" , "")
            formik.setFieldValue("bannerImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("avatar" , baseURL)
            formik.setFieldValue("bannerImage" , e?.target.files[0])
        };
    }

    useEffect(() => {
        if( formik.values.avatar !== "") updateImage();
    }, [formik.values])

    useEffect(() => {
        getTrasactions();
        getRewards();
    },[])

    useEffect(() => {
        getAccountData();
    }, [fetch])

   

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
        <div className={classes.container}>
            <div className={classes.header} style={{backgroundImage:`url(${Banner})`}}>
                <div className={classes.title}>
                    <h2>{t("profile")}</h2>
                   
                </div>
                {
                      loading 
                      ? (
                        <div style={{display:"flex"}}>
                          <Skeleton style={{backgroundColor:"#0F2139",marginRight:"8px"}} variant="circle" width={80} height={80} animation="wave" />
                          <div className={classes.card_info}>
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={160} height={40}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={150} height={20}  animation="wave" />
                          </div>
                        </div>
                      )
                      
                      :
                    <div className={classes.header_info}>
                      <div className={classes.image_section}>
                            <ImageUploader handleFile={handleFile} file={formik.values.bannerImage} editable/>
                        <div className={classes.name_section}>
                              <h2>{inflection.titleize(user.name)}</h2>
                              <p>{user.email}</p>
                        </div>
                      </div>
                  </div>
                }
                
            </div>
            <div className={classes.tab_section}>
                <div>
                    <Tabs value={value} onChange={handleChange}  variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                        <Tab className={classes.tab_label} label={t("transactions")} {...a11yProps(0)} />
                        <Tab className={classes.tab_label} label={t("personal_info")} {...a11yProps(1)} />
                        <Tab className={classes.tab_label} label={t("team")} {...a11yProps(2)} />
                        <Tab className={classes.tab_label} label={t("Rewards")} {...a11yProps(3)} />
                        <Tab className={classes.tab_label} label={t("security")} {...a11yProps(4)} />
                        <Tab className={classes.tab_label} label={t("cashier")} {...a11yProps(5)} />
                        <Tab className={classes.tab_label} label={t("referral")} {...a11yProps(6)} />
                    </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                    <div style={{display:"flex", gap:"8px" , flexWrap:"wrap" , padding:"8px 0px"}}>
                        {
                            transactions.map((item, index) => 
                            <TransactionCard 
                                key={index}
                                amount={item.amount} 
                                comment={item.comment}
                                currency={item.currency} 
                                type={item.transaction_type} 
                                paymentMethod={item.payment_method} 
                                isCredit={item.is_credit} 
                                status={item.status} 
                                reference={item.transaction_reference} 
                                date={item.transaction_date} />
                            )
                        }  
                    </div>
                </TabPanel>
                {
                    !loading && 
                    <>
                        <TabPanel value={value} index={1}> 
                            <PersonalInfo info={account} refetch={() => setFetch(!fetch)}/>

                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Team teams={account.teams}/>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                        <div style={{display:"flex", gap:"8px" , flexWrap:"wrap" , padding:"8px 0px"}}>
                        {
                            rewards.length > 0 ? rewards.map((item, index) => 
                                <RewardCard 
                                    key={index}
                                    data={item}
                                    refetch={() => getRewards()}
                            />
                            ) : <p>There is no reward to claim. Complete activities to claim rewards</p>
                        }  
                    </div>
                    
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Security twoFactorEnabled={account.login_securities} refetch={() => setFetch(!fetch)}/>

                            <hr />
                            <AccountSetting />
                            <br />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            <PaymentAccount />
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                            <UserReferral monetized={account.monetized_referrer} />
                        </TabPanel>
                    </>
                }
            </div>
        
        </div>
    )
}

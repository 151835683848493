import { makeStyles, } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        height: 'calc(100vh - 60px)',
        display:"flex",
    },
    app_container:{
        maxWidth:"1980px",
        margin:"0px auto",
        height:"100%",
        width:"auto"
    },
    search_section:{
        width:"60%"
    },
    "@media only screen and (max-width: 768px)":{
        search_section:{
            display:"none",
        }
      }

}));

import React, { useContext, useState } from 'react';
import { useStyles } from './styles';
import Button from '../Button/Button';
import {  TextField } from '@material-ui/core';
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import { isEmpty } from 'lodash';
import Taggables from '../Taggables/Taggables';
import { useCallback } from 'react';
import { BsImages } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ImageUploader from '../ImageUploader/ImageUploader';
import { t } from 'i18next';

export default function CreateForumPost({edit = {} , handleClose , refetch}) {
    const classes = useStyles();
    const history = useHistory();
    const [ loading , setLoading ] = useState({team:false , cards:true})
    const { setFeedback  } = useContext(AppContext);

    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Name is Required"),
        body: Yup.string().required("Description is Required"),
        tags: Yup.array().of(Yup.string()).min(1).required(),
        avatarImage: Yup.mixed().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024))
        .test('format',
        'upload a valid image', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),

    });

    const createPost = async (form) => {
        setLoading({...loading , team:true})

        await axiosInstance.post(`api/forum/posts` , form ).then( ({ data }) =>  {
            handleClose()
            history.push(`/home/forum/${data.data.slug}`)
            
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
          
            setLoading({...loading , team:false})
        } )
    }

    const updatePost = async (form) => {
        setLoading({...loading , team:true})
        if(form.avatarImage === "") delete form.image

        await axiosInstance.put(`api/forum/posts/${edit.slug}` , form ,
  
        ).then( ({ data }) =>  {
            setFeedback({message:"Post Updated!" , severity:"success"})
            handleClose()
            refetch()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading({...loading , team:false}))
    }

    const handleSubmit = async (form) => {
        isEmpty(edit) ? createPost(form) : updatePost(form)
    }




    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("image" , "")
            formik.setFieldValue("avatarImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("image" , baseURL)
            formik.setFieldValue("avatarImage" , e?.target.files[0])
        };
    }

    const formik = useFormik({
        initialValues: {
            title: edit.title ?? "",
            body: edit.body ?? "",
            avatarImage:  "",
            image  : edit.image ?? "",
            tags:edit.tags ?? ['esports']

        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });


    const handleTags = useCallback(
        (tags) => {
          formik.setFieldValue('tags' , tags)
        },
        [formik.values.tags]
      );


  return (
    <>
    <h2>
        {
            isEmpty(edit) ? t("create_post") : t("update_post")
        }
    </h2>
    <div className={classes.create_section}>

        <div>
            <TextField placeholder={t("title")} variant="outlined" value={formik.values.title} name="title" onChange={formik.handleChange} fullWidth/>
            {formik.touched.title  && formik.errors.title ? (
                    <span className={classes.content__form_error}>{formik.errors.title}</span>
                ) : null}
        </div>
        <Taggables tags={formik.values.tags} onChange={(e , tags) => handleTags(tags)}/>
        {formik.touched.tags  && formik.errors.tags ? (
                    <span className={classes.content__form_error}>{formik.errors.tags}</span>
                ) : null}

        <TextField placeholder={t("your_mind")} value={formik.values.body} variant="outlined" name="body" minRows="10" multiline onChange={formik.handleChange} />
        {formik.touched.body  && formik.errors.body ? (
                    <span className={classes.content__form_error}>{formik.errors.body}</span>
                ) : null}
        <label htmlFor='cv'>

                <div style={{display:'flex', gap:"8px"}}>
                    <BsImages  size={25}/>
                    <p >{formik.values.avatarImage?.name ?? t("attach_file")}</p>
                </div>
                {
                    ( formik.values.avatarImage !== "" ||
                    formik.values.image !== "") &&
                    <ImageUploader handleFile={handleFile} file={   isEmpty(edit) ? formik.values?.avatarImage :  formik.values?.image} id="post" />
                }

            {
                (formik.touched.avatarImage && formik.errors) && (
                    <p style={{color:"red"}}>{formik.errors.avatarImage}</p>
                )
            }
        </label>
        <input 
            accept="image/*" 
            id="cv" hidden type="file"  
            onChange={handleFile } />
        <Button name={
           t("confirm")
        }  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit} loading={loading.team}/>
        
    </div>
</>
  );
}

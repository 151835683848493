import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    container:{
        height: "100vh",
        display:"flex",
        justifyContent:"center",
        backgroundSize:"cover",
        background: "linear-gradient( #0e1028 , #070515)",
        backgroundPosition:"center",
        alignItems:"center",
        width:"100%",
        color:"#fff"
    },
    login_section:{
        flex: "1 1 auto",
        maxWidth:"420px",
        height:"90%",
        "& h2": {
          marginBottom:0,
          fontSize:"30px"
        },
        [theme.breakpoints.down("sm")]:{
          width:"300px",
          padding:theme.spacing(0,1)
        },
    },
    info:{
        marginBottom: theme.spacing(3),
    },
    icons:{
        display:"flex",
        margin: theme.spacing(3,0),
        gap:theme.spacing(2),
        alignItems:"center"
    },
    input:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(2),
    },
    input_container:{
      display:"flex",
      flexDirection:"column",
      gap:theme.spacing(0.5),
  },
    content__form_error:{
      fontSize:"12px",
      color:"red",
    },
    textfield:{
        borderRadius:"8px",
        color:"#fff",
        '& label.Mui-focused': {
            color: 'white',
          },
          "& .MuiFormLabel-root": {
            color: "white"
        },
          "& .MuiInputBase-root ":{
              color:"white"
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#5A79A5',
            },
            '&:hover fieldset': {
              borderColor: '#5A79A5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
    },
    join:{
        marginTop:theme.spacing(5),
    },
    button_text:{
      padding:theme.spacing(1.5 , 2)
    },
    otp_section:{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
    },
    otp_input:{
        width:"50px",
        height:"50px",
        border:"1px solid #ccc",
        backgroundColor:"transparent",
        color:"#fff",
        [theme.breakpoints.down('sm')]:{
          wdth:"30px",
          height:"30px"
        }
    }

}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    box:{
        flex: "1 1 0",
        width:"320px",
        borderRadius: "8px",
        border: "1px solid #172A44",
        background: "rgba(23, 42, 68, 0.1);",
        padding: theme.spacing(2),
        display: "flex",
        color:"white",
        justifyContent:"space-between",
        [theme.breakpoints.down("sm")] :{
            minWidth:"300px"
        }
    },
    box_section:{
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"column"
    },
}));

import { Avatar, CircularProgress, TextField } from '@material-ui/core'
import { format } from 'date-fns';
import React , {useEffect , useState , useContext }from 'react'
import Button from '../../components/Button/Button';
import { axiosInstance } from '../../helpers/client'
import { convertISOTime } from '../../helpers/date';
import { useStyles } from './styles';
import { AppContext } from '../../context/appContext';


const ChatListItem = ({created , username , message , avatar }) => {
    return (
        <div style={{display:"flex"}}>
            <div style={{display:"flex" , flexDirection:"column"}}>
                <div style={{display:"flex" , alignItems:"center" ,gap:"8px"}}>
                    <Avatar src={avatar} alt={username} />
                    <p>{`${username} :`}</p>
                </div>
            </div>
            <div  style={{display:"flex" , alignItems:"center" ,gap:"8px"}}>
                <p>{message}</p>
            </div>
        </div>
    )
}


export default function Stream() {
    const classes = useStyles();
    const { user } = useContext(AppContext);
    const [chat, setChat] = useState([]);
    const [loading, setLoading] = useState({
        subcription: false
    });
    const [message, setMessage] = useState("");
    const [embedLink, setEmbedLink] = useState("");
    
    const handleChange = (e) => {
        setMessage(e.target.value);
    }

    const getChat = async () => {
        setLoading({...loading , subscription:true})
        await axiosInstance.get(`api/live-chats?chat_id=${embedLink}`).then(({data}) => {
            setChat(data.data)
        } ).finally(() => setLoading({...loading , subscription:false}))
    }

    const getEmbedLink = async () => {
        setLoading({...loading , subscription:true})
        await axiosInstance.get('api/configurations').then(({data}) => {
            
            setEmbedLink(data.data[7]?.data ?? "87BoQgqwP94")
        } ).finally(() => setLoading({...loading , subscription:false}))
    }


    const sendMessage = async ( ) => {
        setLoading({...loading , message:true})
        await axiosInstance.post('api/live-chats' , {
            chat_id:embedLink,
            message
        }).then(() => {
            getChat()
        }).finally(() => setLoading({...loading, message:false}))
    }

    useEffect(() => {
        if((embedLink && user.is_pro)){
            getChat();
        }
        getEmbedLink();
    },[embedLink, user.is_pro])
      
    return (
        <div className={classes.container} >
        {
            loading.subscription ? (
                <div style={{display:"flex" , height:"100vh" , alignItems:"center" , justifyContent:"center" , color:"#fff" }}>
                    <CircularProgress color='inherit' />
                </div>
            ): (
                <>
                {
                    (user.is_pro) ?
                        <div style={{padding:"8px" , height:"100vh" ,}}>
                            <iframe width="100%" height="50%" src={`https://www.youtube.com/embed/${embedLink}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <div >
                                <h2>Chat</h2>
                                <div className={classes.chatSection}>
                                    {
                                        chat.map(({user , message , created_at}, i) => <ChatListItem key={i} avatar={user?.avatar} message={message} created={format( convertISOTime(created_at) , "HH:mm:ss")} username={user?.username ?? "Anonymous"} />)
                                    }
 
                                </div>
                                <div style={{display:"flex" , gap:"8px" ,  marginBottom:"16px"}}>
                                    <TextField variant='outlined' fullWidth onChange={handleChange} name="message"/>
                                    <Button name="Send" onClick={sendMessage} loading={loading.message}/>
                                </div>
                            </div>
                        </div>
                    : <h2 className={classes.title}>Ops! You do not have an active subscription to access this page, subscribe to Gamr Pro to proceed.</h2>
                }
                </>
            )
        }
        </div>
    )
}

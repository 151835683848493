import React, { useContext } from 'react';
import Button from "../../../components/Button/Button";
import { useStyles } from "./styles";
import {  MenuItem, Select, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useState } from 'react';
import { t } from 'i18next';


const ManageScores = ({ slug  , player1 , player2 }) => {
    const classes = useStyles();
    const { setFeedback } = useContext(AppContext)
    const [ loading , setLoading ] = useState(false)

    const validationSchema = Yup.object().shape({
        player1: Yup.string().required("Player1 is Required"),
        player2: Yup.string().required("Player2 is Required"),
        winner: Yup.string().required("Winner is Required"),
    });

    const updateScores = async () => {
        const playerWon = formik.values.player1 > formik.values.player2 ? player1 : player2
        formik.setFieldValue('winner' , playerWon.uuid)
        setLoading(true)
        await axiosInstance.put(`api/matches/update/${slug}` , {
            match :{
                scores_csv : `${formik.values.player1}-${formik.values.player2}`
            },
            winner: formik.values.winner
        }).then(({data}) => {
            setFeedback({message:data.message })
        }).catch(({ response }) => {
            setFeedback({ message: response.data.message, severity: "error" });

        })
        .finally(() => setLoading(false))
    }

    const handleSubmit = (e) => {
         updateScores()
    }

    const formik = useFormik({
        initialValues:{
            player1:"",
            player2:"",
            winner:""
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    })
    return (
        <div className={classes.scores_container}>
            <h2>{t("update_scores")}</h2>
            <div style={{display:"flex" , justifyContent:"space-between"}}>
                <div style={{display:"flex" , flexDirection:"column"}}>
                    <p>{player1?.data?.display_name}</p>
                    <TextField name="player1" onChange={formik.handleChange} type="number" />
                    {formik.touched.player1  && formik.errors.player1 ? (
                                    <span className={classes.content__form_error}>{formik.errors.player1}</span>
                                ) : null}
                </div>
                <div style={{display:"flex" , flexDirection:"column"}}>
                    <p>{player2?.data?.display_name}</p>
                    <TextField name="player2" type="number"  onChange={formik.handleChange} />
                    {formik.touched.player2  && formik.errors.player2 ? (
                                    <span className={classes.content__form_error}>{formik.errors.player2}</span>
                                ) : null}
                </div>
            </div>
            <div>
            <Select
                variant="outlined"
                name="winner"
                fullWidth
                // className={classes.select}
                value={formik.values.winner}
                onChange={formik.handleChange}
            >
                <MenuItem value={player1?.uuid}>{player1?.data?.display_name}</MenuItem>
                <MenuItem value={player2?.uuid}>{player2?.data?.display_name}</MenuItem>
            </Select>
            {formik.touched.winner  && formik.errors.winner ? (
                                    <span className={classes.content__form_error}>{formik.errors.winner}</span>
                                ) : null}
            </div>
            <Button name="Submit" loading={loading} onClick={formik.handleSubmit}/>
        </div>
    )
}

export default ManageScores
import React from 'react'
import { useStyles } from './styles';
import { IconButton , Select as MuiSelect} from '@material-ui/core';
import { t } from 'i18next';
import { BsInfoCircleFill } from 'react-icons/bs';
import Tooltip from '@material-ui/core/Tooltip';

export default function Select({formik, children, name, title, info, full = false , data = [], ...props}) {

    const classes = useStyles();

    return (
        
        <div className={classes.input_section}  style={{
            width: full ? "auto" :"45%"
        }}>
            <div className={classes.input_info} >
                <p>{t(title ?? name)}</p>
                {
                    !!info &&
                    <Tooltip title={info} arrow>
                        <IconButton classes={{
                            root:classes.icon
                        }}>
                            <BsInfoCircleFill size={15}/>
                        </IconButton>
                    </Tooltip>
                }
            </div>
            <MuiSelect
                className={classes.textfield}
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                size="small"
                name={name}
                value={formik.values[name]}
                {...props}
            >
                {children}
            </MuiSelect>
            {
                formik.touched[name]  &&  formik.errors[name] 
                ? <span className={classes.content__form_error}>{formik.errors[name]}</span>
                : null
            }   
        </div>
    )
}

import React, { useState , useContext, useEffect } from 'react'
import { useStyles } from './styles';
import Stepper from '@material-ui/core/Stepper';
import { useParams } from 'react-router';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '../../components/Button/Button';
import { Bracket, DateTime, Details, Information, Prize, Rules } from '../../components/TournamentInformation/TournamentInfo';
import { axiosInstance } from '../../helpers/client';
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppContext } from '../../context/appContext';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { WalletContext } from 'context/walletCotext';

  
export default function CreateTournamentPage({edit = {}}) {
  const classes = useStyles();
  const history = useHistory()
  const { t } = useTranslation()
  const { slug }  = useParams();
  const [ games , setGames ] = useState([])
  const [ events , setEvents ] = useState([])
  const [activeStep, setActiveStep] = useState(0);
  const [ loading , setLoading ] = useState(false);
  const steps = [t("information"), t("detail"), t("rules"), t("bracket") , t("prize") , t("date")];
  const { setFeedback  } = useContext(AppContext);
  const { wallet  } = useContext(WalletContext);

  const getEvents = async () => {
    await axiosInstance.get("api/events/user").then(function ({ data }) {
        setEvents(data.data);
    })
    // .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}));
};


const getGames = async () => {
    await axiosInstance.get("api/games?per_page=30" ).then(function ({ data }) {
        setGames(data.data);
    })
};

  const handleSubmit = () => {
      if(activeStep === steps.length - 1){
         
          let newTournamnetObject = {};
          newTournamnetObject.tournament_type = formik.values.tournament_type;
          newTournamnetObject.hold_third_place_match = formik.values.hold_third_place_match;
          newTournamnetObject.ranked_by = formik.values.ranked_by;
          newTournamnetObject.description = formik.values.description;
          newTournamnetObject.check_in_duration = formik.values.check_in_duration;
          newTournamnetObject.start_at = formik.values.start_at;

         
          let tournamentform = {};  
          tournamentform.name = formik.values.name;
          tournamentform.location = formik.values.location;
          tournamentform.lng = formik.values.lng;
          tournamentform.lat = formik.values.lat;
          tournamentform.platform = formik.values.platform;
          tournamentform.games = [ formik.values.games ];
          if(formik.values.event_id){
            tournamentform.event_id = formik.values.event_id;
          }
          if(formik.values.banner.startsWith("data:")){

            tournamentform.banner = formik.values.banner;
          }
          tournamentform.entry_fee = formik.values.entry === "free" ? 0 : formik.values.entry_fee;
          tournamentform.rules = formik.values.rules;
          tournamentform.is_public = formik.values.is_public;
          tournamentform.is_third = formik.values.hold_third_place_match;
          tournamentform.is_robin = formik.values.is_robin;
          tournamentform.input_limit = formik.values.input_limit;
          tournamentform.group_slot = formik.values.group_slot;
          tournamentform.bracket_category = formik.values.tournament_type === "battle" ? "battle" : "elimination";
          tournamentform.bracket_input = formik.values.bracket_input;
          tournamentform.bracket_count = formik.values.bracket_count;
          tournamentform.stage_count = formik.values.stage_count;
          tournamentform.match_round = formik.values.match_round;
          tournamentform.games_count = formik.values.games_count;
          tournamentform.prize_pool = formik.values.prize_pool;
          tournamentform.currency = formik.values.prize_type;
          tournamentform.ended_at = formik.values.ended_at;
          tournamentform.is_exclusive = formik.values.is_exclusive;
          tournamentform.team_size = formik.values.team_size;
          tournamentform.slots = formik.values.slots;
          tournamentform.tournament = newTournamnetObject;

          isEmpty(edit) ? createTournament(tournamentform) : updateTournament(tournamentform);
          return
      }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const addPrize = async (prize , rank, tournament_id , prize_type) => {
      await axiosInstance.post("api/tournaments/prizes/store" , {
        tournament_id,
        winner_rank: rank + 1,
        prize_type,
        value: prize
    } , ).then(() => {
         return true;
      })
  }


  const deleteAndCreatePrize = async (currency , tournamentId) => {
    await axiosInstance.delete(`api/tournaments/prizes/clear/${slug}` ).then(() => {
      formik.values.prize.map(({value:item } , index) => {
        if(item !== 0)  addPrize(item , index, tournamentId , currency);
        return true;
      })
   })
  } 

  const updateTournament = async (tournamentForm) => {
    setLoading(true)
    await axiosInstance.put(`api/tournaments/update/${slug}` , tournamentForm ).then(function ({ data }) {

      deleteAndCreatePrize(tournamentForm.currency , edit.id)
      setFeedback({message:data.message})

      history.push('/admin/tournament')
    })
    .catch(({ response }) => {
      response?.data?.message !== undefined && setFeedback({message:response.data.message , severity:"error"}) 

      response?.data?.errors !== undefined 
      ? setFeedback({message:response.data.errors[0] , severity:"error"}) 
      : setFeedback({message:response.data.error , severity:"error"})
    })
    .finally(() => {
      setLoading(false)
  });
}

  const createTournament = async (tournamentForm) => {
    setLoading(true)
    await axiosInstance.post("api/tournaments/store" , tournamentForm, )
    .then(function ({ data }) {
      formik.values.prize.map(({value:item } , index) => {
        if(item !== 0) return addPrize(item , index, data.data.id , tournamentForm.currency);
        return false
      })
      setFeedback({message:data.message})
      history.push('/admin/tournament')
    })
    .catch(({ response }) => {
      if(typeof response?.data?.message === "string") {
        setFeedback({message:response.data.message , severity:"error"}) 
      } else {

        response?.data?.errors !== undefined 
        ? setFeedback({message:response?.data?.errors[0] , severity:"error"}) 
        : setFeedback({message:response?.data?.error , severity:"error"})
      }

    })
    .finally(() => {
      setLoading(false)
  });
}


const handleSchema = () => {
  switch(activeStep){
    case 0:
        return Yup.object().shape({
          name: Yup.string().required().label("Name"),
          games: Yup.string().required().label("Game"),
          location:  Yup.string().required().label("Location"),
          description: Yup.string().required().label("Description"),
        });
    case 1:
        return Yup.object().shape({
          bannerImage: isEmpty(edit) 
          ? Yup.mixed().required().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)).label("Banner")
          : Yup.mixed().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)).label("Banner"),
         
          team_size: Yup.number().required().positive().integer().label("Team Size"),
          entry_fee:  Yup.number().required().min(0).integer().when('entry', {
            is:  (val) => val === "paid",
            then:Yup.number().required().positive().min(1),
          }).label("Entry Fee"),
        });
        case 2:
          return Yup.object().shape({
            rules: Yup.string().required().label("Rules"),
          });
      case 3:
        return Yup.object().shape({
          tournament_type: Yup.string().required().label("Type"),
          slots: Yup.number().required().min(4).integer().label("Slots"),
          ranked_by: Yup.string().required().label("Ranked"),
          bracket_count: Yup.number().positive().integer().required().label("Bracket Count"),
          games_count:  Yup.number().when('tournament_type', {
            is:  (val) => val === "battle",
            then:Yup.number().required().positive().min(4).max(16).integer().label("Game Count"),
          }),
          stage_count:  Yup.number().when('team_size', {
            is:  (val) => val === 1,
            then:Yup.number().required().positive().integer().label("Stage Count"),
          }),
          match_round:  Yup.number().when('tournament_type', {
            is:  (val) => val !== "battle",
            then: Yup.number().required().positive().integer().label("Match Round"),
          })
        });
    case 4:
      return Yup.object().shape({
        prize: Yup.array().of(
          Yup.object().shape({
            value: Yup.number().required()
          })
        ).min(1).required()
        .test('sum', 'You do not have enough in your wallet to sponsor this tournament. Kindly fund your wallet to proceed.', function (numbers) {
          const amount = formik.values.prize_type === "USD" ? wallet.amount : wallet.gc_amount;
          const sum = numbers.reduce((total, number) => total + number.value, 0);
          return sum <= amount;
        }),
      });
    case 5:
      return Yup.object().shape({
        start_at: Yup.date().required().label('Start At'),
        ended_at: Yup.date().min(Yup.ref('start_at') , 'End Date field must be later than Start Date').label('End Date'),
        check_in_duration:Yup.number().required().positive().integer().label("Check-In Duraction")
        .test('len', 'Check-in must be an interval of 5', val => val % 5 === 0),
      });
      default:
        return Yup.object().shape({});
    }
}

const formik = useFormik({
  initialValues: {
    name:edit.name ?? "",
    games:edit.games ? edit.games[0].slug : "",
    location:edit.location ?? "",
    description:edit?.data?.description ?? "" ,
    event_id:edit.event?.id ?? "",
    tournament_type: edit.type ?? "single elimination",
    ranked_by: edit?.data?.ranked_by ?? "match wins",
    hold_third_place_match: edit?.data?.hold_third_place_match ??  false,
    swiss_rounds: edit?.data?.swiss_rounds ?? "",
    check_in_duration: 10,
    banner:edit.banner ?? "",
    bannerImage: "",
    entry_fee:edit.entry_fee ?? 0,
    entry:edit.entry_fee > 0 ? "paid" : 'free',
    games_count:edit.games_count ?? 4,
    bracket_count:edit.bracket_count ?? 1,
    stage_count:edit.stage_count ?? 1,
    match_round:edit.match_round ?? 1,
    is_third: edit.is_third ??  false,
    is_robin: edit.is_robin ??  false,
    bracket_input: edit.bracket_input ?? "multi",
    group_slot: edit.group_slot ?? 4,
    input_limit: edit.input_limit ?? false,
    rules:  edit.rules ?? "",
    is_public:edit.is_public ?? true,
    is_exclusive:edit.is_exclusive ?? false,
    team_size:edit.team_size ?? "",
    platform:edit.platform ?? "ps5",
    slots:edit.slots ?? "",
    lat:edit.lat ?? 6.4283376,
    lng:edit.lng ?? 3.409435,
    start_at:edit.started_at ?? new Date(),
    ended_at:edit.ended_at ?? new Date(),
    prize_type:edit.currency ?? "GC",
    prize:edit.prizes ?? [
      {value: 10},
      {value: 0},
      {value: 0},
    ]
  },
  onSubmit: handleSubmit,
  validationSchema:handleSchema
});

useEffect(() => console.log(formik.errors), [formik.errors])



  function getStepContent(stepIndex ) {
    switch (stepIndex) {
        case 0:
            return <Information formik={formik}  handleCheck={handleCheck} game={games} events={events}/>;
        case 1:
          return <Details formik={formik}  handleCheck={handleCheck} edit={!isEmpty(edit)}/>;
        case 2:
            return <Rules formik={formik} edit={!isEmpty(edit)}/>;
        case 3:
            return <Bracket formik={formik} handleCheck={handleCheck}/>;
        case 4:
            return <Prize formik={formik} />;
        case 5:
            return <DateTime formik={formik}/>;
        default:
            return 'Unknown stepIndex';
        }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleCheck = (event) => {
    formik.setFieldValue( [event.target.name] ,  event.target.checked)
}


useEffect(() => {
  getEvents();
  getGames();
}, [])


  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{minHeight:"600px"}}>
            {getStepContent(activeStep)}
      </div>
      <div style={{marginTop:"16px"}}>
        {
          activeStep > 0 &&
          <Button
            name={t("prev")}
            classes={{text:classes.button_text}}
            onClick={handleBack}
            className={classes.backButton}
          />
        }
        <Button classes={{text:classes.button_text}} loading={loading} backgroundColor="#B00610" name={activeStep === steps.length - 1 ? t("finish") : t("next")} onClick={formik.handleSubmit}/>
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from "components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from 'helpers/client';
import { AppContext } from 'context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import { t } from 'i18next';



const CreateCode = ({  handleClose , edit, refetch}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);

    const validationSchema = Yup.object().shape({
        code: Yup.string().required("Code is Required"),
        product_slug: Yup.string().required("Poduct ID is Required"),
    });

    const createCode = async (formData) => {
        setLoading(true)

        await axiosInstance.post("api/product-codes/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
           setFeedback({message:response.data.message , severity:"error"})

        })
        .finally(() => {
            setLoading(false)
            handleClose()
        })
    }

    const formik = useFormik({
        initialValues: {
            code: edit.code ?? "",
            product_slug: edit.product_slug ?? "",
            currency: "USD"
        },

        onSubmit: createCode,
        validationSchema:validationSchema
    });
    

    return (
            <>
                <h2>{isEmpty(edit) ? t("Create Code") : t("Update Code")}</h2>

                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    gap:"12px",
                }}>
                    
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.code}  placeholder={t("Code")} variant="outlined" name="code" fullWidth />
                            {formik.touched.code  &&  formik.errors.code ? (
                                    <span className={classes.content__form_error}>{formik.errors.code}</span>
                                ) : null}
                    </div>
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.product_slug} placeholder={t("Product ID")} variant="outlined" name="product_slug" fullWidth />
                            {formik.touched.product_slug  &&  formik.errors.product_slug ? (
                                    <span className={classes.content__form_error}>{formik.errors.product_slug}</span>
                                ) : null}
                    </div>
                    <div style={{display:"flex" , justifyContent:"flex-end"}}>
                        <Button loading={loading} name={isEmpty(edit) ? t("Create Code") : t("Save") }  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                    </div>
                </div>
            </> 
        )
    }



export default CreateCode
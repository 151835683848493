import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { get } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import { IconButton } from '@material-ui/core';
import {  MdCheckCircle , MdCancel  ,  MdDelete } from 'react-icons/md'
import Button from "../../../components/Button/Button";
import CreateGame from "./CreateGame";
import Modal from "../../../components/Modal/Modal";
import { handlePage } from "../../../helpers/paginator";

export default function ManageGame() {
  const classes = useStyles();
  const [game, setGame] = useState("account");
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
  const [page, setPage] = useState(0);
  const [edit, setEdit] = useState({});
  const [rows, setRowsPerPage] = useState(30);
  const [endpoint, setEndpoint] = useState(null);
  const [ open , setOpen ] = useState(false)
  const [ loading , setLoading ] = useState({table: true , icon: false});

  const { setFeedback } = useContext(AppContext);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateGameStatus = async (username , status) => {
    await axiosInstance
      .put(`api/account/status/${username}` ,{
          status
      })
      .then(function ({ data }) {
        setFeedback(data.message);
      })
      .catch(({ response }) => {
        response?.data?.message !== undefined &&
          setFeedback({ message: response.data.message, severity: "error" });

        response?.data?.errors !== undefined
          ? setFeedback({ message: response.data.errors[0], severity: "error" })
          : setFeedback({ message: response.data.error, severity: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteGame = async (slug) => {
    if(!window.confirm("Are you sure you want to delete this game")) return
    await axiosInstance.delete(`api/games/${slug}`).then(({data}) => {
      setFeedback({message:data.message , severity:"success"})
    }).catch(({response}) =>   setFeedback({message:response.data.message , severity:"error"}))
  }

  const getGames = async () => {
    setLoading({...loading, table:true})
    await axiosInstance
      .get(endpoint ?? `api/games?per_page=30`)
      .then(function ({ data }) {
        setCollection(data);
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoading({...loading , table:false}))
  };

  const schema = [
    {
      label: "Name",
      func: ({ item }) =>  get(item, "name")
    },
    {
      label: "Status",
      func: ({ item }) => (get(item, "is_active") ? "Approved" : "Pending"),
    },

    {
      label: "Actions",
      func: ({ item }) => {
        const slug = get(item , 'slug')
            return (
            <div style={{ display:"flex" , gap:"8px" }}>
              {
                get(item , 'is_active') ? null : 
                <>
                  <IconButton color="inherit" style={{padding:"0"}}>
                    <MdCancel size={25} onClick={() =>  updateGameStatus(slug, 'declined')}  />
                  </IconButton>
                  <IconButton color="inherit" style={{padding:"0"}}>
                    <MdCheckCircle size={25} onClick={() =>  updateGameStatus(slug, 'approved')}  />
                  </IconButton>
                </>
              }
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdDelete size={25} onClick={() =>  deleteGame(slug)}  />
              </IconButton>
              {/* <IconButton color="inherit" style={{padding:"0"}}>
                <MdEdit size={25} onClick={() => {
                  setEdit(item)
                  handleOpen()
                  }}  />
              </IconButton> */}
            </div>
          )
        }
    },
  ];


  useEffect(() => {
    getGames();
  }, [rows, game]);

  return (
    <div className={classes.container}>
      <h2>Manage Games</h2>
      <div className={classes.options}>
          <Button
            classes={{ text: classes.button_text }}
            backgroundColor="#B00610"
            name={`Create Game`}
            onClick={() => handleOpen()}
          />            
      </div>
      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        schema={schema}
        loading={loading.table}
        collection={collection.data}
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
      <Modal open={open} onClose={handleClose}>
          <CreateGame handleClose={handleClose} edit={edit} />
      </Modal>
    </div>
  );
}

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(2),
        color:"#fff",
        "& h2": {
            fontSize:"22px"
        },
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(1)
        }
        
    },
    game_section:{
        margin: theme.spacing(4, 0),
        "& h2":{
            margin: theme.spacing(1,0)
        },
        "& p":{
            marginBottom:theme.spacing(2)
        },
        [theme.breakpoints.down("sm")]:{
            margin: theme.spacing(1, 0),
        }
    },
    info_skeleton_img:{
        height:"120px",
        width:"120px",
      [theme.breakpoints.down("xs")]: {
        height:"90px",
        width:"90px",
      },
    },
    games:{
        display:"flex",
        gap: theme.spacing(10),
        overflowX:"scroll",
        "&::-webkit-scrollbar":{
            height:"15px"
        },
        [theme.breakpoints.down("lg")] :{
            gap:theme.spacing(4)
        }
    },
    pagination:{
        margin: theme.spacing(1 , 0),
        display:'flex',
        gap:"8px"
    },
    load_tournament:{
        display:"flex",
        flexWrap:"wrap",
        gap:"12px"
    },
    load_tournament_section:{
        marginBottom: theme.spacing(6)
    },
    title:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& P": {
            fontSize:"14px",
            color:"#6EC5E9"
        },
    },
    select:{
        color:"#5A79A5",
        width:"140px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5A79A5"
          },
          "& .MuiOutlinedInput-input" :{
            padding: "12px 16px"
        }
    },
    filter:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
    },
}));

import { t } from 'i18next';
import React from 'react';
import { useStyles } from './styles';


const SmallCard = ({name , date, image , currency , price ,game , ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.container} {...props}>
            <div className={classes.image} style={{
                backgroundImage:`url(${game?.image ?? image})`,
            }}></div>
            <div className={classes.card_info}>
                <p className={classes.card_info_name}>{name ?? game?.name}</p>
                <p className={classes.card_info_date}>{date}</p>
                <p className={classes.price}>{t("prize_pot")}:  {currency === "USD" ? "$" : currency}{price}</p>
            </div>
        </div>
    )
}

export default SmallCard;
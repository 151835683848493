import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        display:'flex',
        flexDirection:'column',
        borderRadius: "20px",
        gap:"10px",
        background: "rgba(0, 0, 0, 0.11)",
        padding: theme.spacing(5),
        color:"white",
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(2)
        }
       
    },
    tag:{
        padding:"6px 10px",
        borderRadius:"20px",
        alignSelf:"center",
        display:'flex',
        gap:"10px",
        "&:hover":{
            opacity: 0.9,
            cursor:"pointer"
           }
    },
    tagSection:{
        display:"flex",
        justifyContent:'flex-end',
        gap:'8px',
    },
    title:{
        fontSize:"24px",
        cursor:"pointer"
    },
    
 
    description:{
        fontFamily:"Quicksand",
        fontSize:"14px",
        fontWeight:400,
        display: '-webkit-box',
        maxWidth: '100%',
        cursor:"pointer",
        // margin: '0 auto',
        WebkitBoxOrient:"vertical",
        WebkitLineClamp:'3',
        overflow: "hidden",
        lineHeight: 1.75,
        textOverflow:"ellipsis",
        color: 'rgba(255, 255, 255, 0.5)',
        marginBottom:"32px"
    },
    authorSection:{
        display:'flex',
        justifyContent:'space-between',
    },
    name:{
        fontSize:'14px'
    },
    time:{
        fontSize:'10px',
        color:"rgba(255, 255, 255, 0.2)"
    },
   
    tagDescription:{
        fontSize:"12px",
    },
    tagIcon:{
      
    },
    comment:{
        color:"#546884",
        fontSize:'10px',
    },
    img:{
        minWidth:"50px",
        height:"50px",  
        marginRight: theme.spacing(1.5),
    },
    start:{
        fontSize:"11px",
        color:"#FF959B"
    }
}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    user:{
        display:"flex",
        alignItems:"center",
        gap:"8px",
        cursor:"pointer"
    },
    paginator:{
      color:"#fff",
      backgroundColor:"#121B33"
    },
    points:{
        backgroundColor:"#E8454E",
        padding:theme.spacing(1, 2),
        borderRadius:"8px",
        width:"fit-content",
        },
    table: {
        flex:"1 1 0"
      }, 
      root:{
        color:"#fff"
      },
      table_row_root: {
        color:"#fff",
        '&:nth-of-type(odd)': {
          backgroundColor: "#121B33",
        },
        '&:nth-of-type(even)': {
            backgroundColor:"#0B0C21"
          },
      },                                                
}));

import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Button from '../Button/Button';
import { useStyles } from './styles';
import { axiosInstance } from '../../helpers/client';
import CircleCard from '../CircleCard/CircleCard';
import { games } from '../../pages/Game/Game';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ImageUploader from '../ImageUploader/ImageUploader';
import { useHistory } from 'react-router';
import { AppContext } from '../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";



const CreateTournament = ({  handleClose}) => {
    const classes = useStyles();
    const history = useHistory()
    const maxSteps = 5;
    const [loading , setLoading ] = useState(false)
    const {  user , setFeedback } = useContext(AppContext);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedGame , setSelectedGame] = useState({ });
    const [event , setEvent] = useState([]);
    const [game , setGame] = useState(games)

    const validationSchema = Yup.object().shape({
        type: Yup.string().required("Type is Required."),
        name: Yup.string().required("Name is Required"),
        image_file: Yup.mixed().when("type", {
            is: (val) => val !== "Tournament",
            then:Yup.string().required("Banner is Required"),
          }),
        location:Yup.string().when("type", {
          is: (val) => val === "Tournament",
          then: Yup.string().required("Must enter Location")
        }),
        started_at:Yup.date().when("type", {
            is: (val) => val === "Event",
            then: Yup.date().required().label("Start Date")
        }),
        ended_at:Yup.date().when("type", {
            is: (val) => val === "Event",
            then: Yup.date().min(Yup.ref('started_at') , 'End Date field must be later than Start Date').required().label('End Date')
        })
    });

    const createGame = async ({image_file , name , is_active, description}) => {

        const data = new FormData();
        data.append('image_file',image_file)
        data.append('name',name)
        data.append('is_active',is_active)
        data.append('description',description)

        setLoading(true)
        await axiosInstance.post("api/games/store" , data, {
            headers: {
            'Content-Type': "multipart/form-data"
            }
          }).then(function ({ data }) {
            setFeedback({message:data.message , severity:"success"});
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
            setLoading(false)
        });
    }

    const handleSubmit = (e) => {
        switch(formik.values.type){
            case "Game":
                createGame(e);
                break;
            case "Event":
                createEvent(e);
                break;
            default:
                handleNext();
        }
    }

    const formik = useFormik({
        initialValues: {
            type: "",
            name: "",
            image_file: "",
            description: "",
            location:"",
            lng:"",
            lat:"",
            started_at: new Date(),
            ended_at: new Date(),
            game:[],
            is_active: true,
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    
    const getEvents = async () => {
        await axiosInstance.get("api/events/user").then(function ({ data }) {
            setEvent(data.data);
        })
        .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}));
    };

    const getGames = async () => {
        await axiosInstance.get("api/games" , {
            per_page:20
        }).then(function ({ data }) {
            setGame(data.data);
            setSelectedGame({id:0 , name:data.data[0].name})
        })
        .catch(({ response }) => console.log(response));
    };

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("image_file" , "")
            formik.setFieldValue("bannerImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("image_file" , baseURL)
            formik.setFieldValue("bannerImage" , e?.target.files[0])
        };
    }

    const handleChange = address => {
        formik.setFieldValue('location' , address)
    };
    
     
    const handleSelect = address => {
        formik.setFieldValue('location' , address)
        geocodeByAddress(address).then(results => getLatLng(results[0]))
          .then(({ lat , lng }) => {
              formik.setFieldValue('lng' , String(lng))
              formik.setFieldValue('lat' , String(lat))
            })
          .catch(error => console.error('Error', error));
      };

   

    const createEvent = async (eventForm) => {
        setLoading(true)
        const startDate = format(new Date(eventForm.started_at), "yyyy-MM-dd'T'hh:mm:ss");
        const endDate = format(new Date(eventForm.ended_at), "yyyy-MM-d'T'hh:mm:ss");

        const formData = {...formik.values , started_at:startDate , ended_at:endDate};

        await axiosInstance.post("api/events/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"})

            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
            handleClose()

        })
        .finally(() => setLoading(false))
    }


   
     

    useEffect(() => {
        getEvents();
        getGames();
    }, [])
    
    useEffect(() => {
        console.log(formik.errors)
    },[formik.errors])
  
    const handleNext = () => {
        
        if(formik.values.type === ""){
            formik.setFieldError('type' , "This field is required")
            return
        }

        if(activeStep === 1 && formik.values.type ==="Event"){
            createEvent();
            return
        }

        if(activeStep === 1 && formik.values.type ==="Game"){
            createGame();
            return
        }
        if(activeStep === (maxSteps - 1)){
            return
        }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

  
    const handleSelectGame =  (id , name , slug) => {
        setSelectedGame({id , name})
        formik.setFieldValue("game", [slug])
    }
    
    const redirectCreatePage = () => {
        handleClose()
        localStorage.removeItem("tournament");
        localStorage.setItem("tournament" , JSON.stringify(formik.values));
        history.push("/admin/tournament/create")
    }

    return (
        <>
            { activeStep === 0 &&
                <>
                    <h2>Choose Type</h2>
                    <div>
                        <div className={classes.input_section}>
                            <Select
                                variant="outlined"
                                className={classes.select}
                                fullWidth
                                error={!!formik.errors?.type}
                                onChange={formik.handleChange}
                                name="type"
                            >
                                <MenuItem value={"Event"}>Event</MenuItem>
                                <MenuItem value={"Tournament"}>Tournament</MenuItem>
                                {
                                    user.role === "admin" && <MenuItem value={"Game"}>Game</MenuItem>
                                }
                            </Select>
                            { formik.errors.type ? (
                                <span className={classes.content__form_error}>{formik.errors.type}</span>
                            ) : null}
                            
                        </div>
                        <div  style={{
                        display:"flex",
                        justifyContent:"flex-end",
                    }}>
                            <Button name="Next" onClick={handleNext} backgroundColor="#B00610" />
                        </div>
                    </div>
                </>
            }

            { activeStep === 1 && formik.values.type ==="Game" ? 
                <>
                    <h2>Create {formik.values.type}</h2>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"12px",
            
                    }}>
                        <div className={classes.image_section}>
                            <ImageUploader handleFile={(e) => formik.setFieldValue("image_file" , e?.target.files[0])} file={formik.values?.image_file} />
                            {formik.touched.image_file  && formik.errors.image_file ? (
                                <span className={classes.content__form_error}>{formik.errors.image_file}</span>
                            ) : null}
                        </div>
                        <div >
                            <TextField onChange={formik.handleChange} placeholder={`${formik.values?.type} Name`} variant="outlined" name="name" fullWidth />
                            {formik.touched.name  && formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                        </div>
                        <TextField onChange={formik.handleChange} placeholder={`Brief description about ${formik.values?.type}`} variant="outlined" fullWidth name="description" minRows="3" multiline />
                        <FormControlLabel
                            className={classes.textfield}
                            value={formik.values.is_active}
                            name="is_active"
                            onChange={(e) => formik.setFieldValue("is_active" , e?.target.checked)}
                            control={<Checkbox checked={formik.values.is_active}  style={{color:"#fff"}}/>}
                            label="Active"
                            labelPlacement="end"
                            />
                        <div style={{display:"flex" , justifyContent:"flex-end"}}>
                            <Button loading={loading} name="Create"  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                        </div>
                    </div>
                </> : null
            }

            { activeStep === 1 &&  formik.values.type !=="Game" ?
                <>
                    <h2>Create {formik.values.type}</h2>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"12px",
                    }}>
                        {
                            formik.values.type ==="Event" ?
                            <div className={classes.image_section}>
                                <ImageUploader handleFile={handleFile} file={formik.values.bannerImage} />
                                {formik.errors.bannerImage ? (
                                    <span className={classes.content__form_error}>{formik.errors.bannerImage}</span>
                                ) : null}
                            </div>
                            :null
                        }
                        <div>
                            <TextField onChange={formik.handleChange}  value={formik.values.name}  placeholder={`${formik.values.type} Name`} variant="outlined" name="name" fullWidth />
                            {formik.touched.name  &&  formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                        </div>
                        <div>
                            <PlacesAutocomplete
                                value={formik.values.location}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <TextField  {...getInputProps({placeholder: 'Select Location...',className: 'location-search-input',})}  variant="outlined" name="address" fullWidth />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion , i) => {
                                            const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : classes.suggestion_item;
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' ,color:"black"}
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' , color :"black" };
                                            return (
                                            <div
                                                key={i}
                                                {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                            {formik.touched.location  &&  formik.errors.location ? (
                                        <span className={classes.content__form_error}>{formik.errors.location}</span>
                                    ) : null}
                        </div>
                        {
                            formik.values.type ==="Event" ?
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className={classes.date}>
                                        <DateTimePicker label="Start Time" inputVariant="outlined" value={formik.values.started_at} onChange={(date) => formik.setFieldValue('started_at' , date)} />
                                        {formik.touched.started_at && formik.errors.started_at ? (
                                            <span className={classes.content__form_error}>{formik.errors.started_at}</span>
                                        ) : null}
                                    </div>
                                    <div className={classes.date} >
                                        <DateTimePicker label="End Time" inputVariant="outlined" value={formik.values.ended_at} onChange={(date) => formik.setFieldValue('ended_at' , date)} />
                                        {formik.touched.ended_at &&  formik.errors.ended_at ? (
                                            <span className={classes.content__form_error}>{formik.errors.ended_at}</span>
                                        ) : null}
                                    </div>
                                </MuiPickersUtilsProvider>
                            : null
                        }
 
                        <TextField onChange={formik.handleChange}  value={formik.values.description}  placeholder={`Brief description about ${formik.values.type}`} variant="outlined" fullWidth name="description" minRows="3" multiline />
                        <div style={{display:"flex" , justifyContent:"flex-end"}}>
                            <Button loading={loading} name="Next"  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                        </div>
                    </div>
                </> : null
            }

            { activeStep === 2 &&
                <>
                    <h2>Select Game</h2>
                    <div className={classes.games}>
                    {
                        game.map(({image , name, border , slug } , index) => 
                        <CircleCard
                            buttonClick={() => handleSelectGame(index , name , slug)} 
                            disabled={index === selectedGame.id ? false : true} 
                            key={index} 
                            image={image}
                            name={name}  
                            border={border}/>
                        )
                    }
                    </div>
                    <div style={{
                        display:"flex",
                        gap:"12px",
                        justifyContent:"flex-end"
                    }}>
                        <Button name="Back" onClick={handleBack}  />       
                        <Button name="Next" onClick={handleNext} backgroundColor="#B00610" />       
                    </div>
                </>
            }
            { activeStep === 3 &&
                <>
                    <h2>Add tournament to existing event</h2>
                    <p>You can add tournaments to already existing events</p>
                    <div style={{
                        display:"flex",
                        marginTop:"16px",
                        justifyContent:"flex-end",
                        gap:"16px"
                    }}>
                        <Button name="Yes" onClick={handleNext} />
                        <Button name="No" onClick={redirectCreatePage} backgroundColor="#B00610" />
                    </div>
                </>
            }
             { activeStep === 4 &&
                <>
                    <h2>Select Event</h2>
                    <Select
                            variant="outlined"
                            className={classes.select}
                            fullWidth
                            onChange={formik.handleChange}
                            name="event_id"
                        >
                            {
                                event.map(({id , name}) =>  <MenuItem key={id} value={id}>{name}</MenuItem>)
                            }
                        
                    </Select>
                    <div style={{display:"flex" , justifyContent:"flex-end" , marginTop:"8px" ,  gap:"12px",}}>
                        <Button name="Back" onClick={handleBack}  />  
                        <Button name="Next" onClick={redirectCreatePage} backgroundColor="#B00610" />
                    </div>
                </>
            }
        </>

    )
}



export default CreateTournament
import React, { useState, useEffect, useContext } from "react";
import Table from "components/Table/Table";
import { useStyles } from "./styles";
import { get } from "lodash";
import { axiosInstance } from "helpers/client";
import { AppContext } from "context/appContext";
import { IconButton } from '@material-ui/core';
import {  MdDelete, MdEdit } from 'react-icons/md'

import CreateProduct from "./CreateProduct";
import Button from "components/Button/Button";
import { handlePage } from "helpers/paginator";
import Modal from "components/Modal/Modal";


export default function ManageProduct() {
  const classes = useStyles();
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
  const [edit, setEdit] = useState({});
  const [rows, setRowsPerPage] = useState(10);
  const [endpoint, setEndpoint] = useState( `api/products?include_category=1&per_page=${rows}`);
  const [ open , setOpen ] = useState(false)
  const [ loading , setLoading ] = useState({table: true , icon: false});

  const { setFeedback } = useContext(AppContext);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit({})
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };


  const deleteProduct = async (slug) => {
    if(!window.confirm("Are you sure you want to delete this product? if its a digital product. All the codes associated with this product will also be deleted")) return
    await axiosInstance.delete(`api/products/${slug}`).then(({data}) => {
      setFeedback({message:data.message , severity:"success"})
      getProducts();
    }).catch(({response}) =>   setFeedback({message:response.data.message , severity:"error"}))
  }

  const getProducts = async () => {
    setLoading({...loading, table:true})
    await axiosInstance
      .get(endpoint)
      .then(function ({ data }) {
        setCollection(data);
      })
      .finally(() => setLoading({...loading , table:false}))
  };

  const schema = [
    {
      label: "ID",
      func: ({ item }) =>  get(item, "slug")
    },
    {
      label: "Name",
      func: ({ item }) =>  get(item, "name")
    },
    {
      label: "Category",
      func: ({ item }) => (get(item, "category.name") ),
    },
    {
      label: "Vendor",
      func: ({ item }) => (get(item, "vendor.name") ),
    },
    {
      label: "Price",
      func: ({ item }) => (get(item, "price")),
    },

    {
      label: "Actions",
      func: ({ item }) => {
        const slug = get(item , 'slug')
            return (
            <div style={{ display:"flex" , gap:"8px" }}>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdDelete size={25} onClick={() =>  deleteProduct(slug)}  />
              </IconButton>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdEdit size={25} onClick={() => {
                  setEdit(item)
                  handleOpen()
                  }}  />
              </IconButton>
            </div>
          )
        }
    },
  ];


  useEffect(() => {
    const url = `api/products?include_category=1&per_page=${rows}`;


    setEndpoint(url)
  }, [rows ]);


  useEffect(() => {
    getProducts();
  }, [endpoint ]);




  return (
    <div className={classes.container}>
      <h2>Manage Products</h2>
      <div className={classes.options}>
          <Button
            classes={{ text: classes.button_text }}
            backgroundColor="#B00610"
            name={`Create Product`}
            onClick={() => handleOpen()}
          />            
      </div>
      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        schema={schema}
        loading={loading.table}
        collection={collection.data}
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
      <Modal open={open} onClose={handleClose}>
          <CreateProduct handleClose={handleClose} edit={edit}  refetch={getProducts} />
      </Modal>
    </div>
  );
}

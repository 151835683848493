import React, { useState, useEffect, useContext } from "react";
import Table from "components/Table/Table";
import { useStyles } from "./styles";
import { get } from "lodash";
import { axiosInstance } from "helpers/client";
import { AppContext } from "context/appContext";
import { Avatar, IconButton, Select } from '@material-ui/core';
import {  MdEdit } from 'react-icons/md'
import { handlePage } from "helpers/paginator";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { BiDotsHorizontalRounded } from "react-icons/bi";
import CartItem from "components/Cart/CartItem";
import { format } from "date-fns";


export default function ListOrder() {
  const classes = useStyles();
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
 
  const [rows, setRowsPerPage] = useState(10);
  const [ filter , setFilter ] = useState({
    status:"all",
  })
  const [endpoint, setEndpoint] = useState( `api/orders?per_page=${rows}`);
  const [ loading , setLoading ] = useState({table: true , icon: false});
  const { setFeedback } = useContext(AppContext);
  const [selectedOrder, setSelectedOrder ] = useState({});

  const [state, setState] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const getOrders = async () => {
    setLoading({...loading, table:true})
    await axiosInstance
      .get(endpoint)
      .then(function ({ data }) {
        setCollection(data);
      })
      .finally(() => setLoading({...loading , table:false}))
  };


  const handleStatus = async ( status) => {
    setLoading({...loading , icon:true});
    await axiosInstance
      .patch(`api/orders/status/update/${selectedOrder?.slug}` ,{
          status
      })
      .then(function ({ data }) {
        setFeedback({message:data.message});
        getOrders();
      })
      .catch(({ response }) => {
          setFeedback({ message: response.data.message, severity: "error" });
      })
      .finally(() => {
        setLoading({...loading , icon:false});
      });
  };


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };



  const schema = [
    {
      label: "ID",
      func: ({ item }) =>  get(item, "slug")
    },
    {
      label: "Type",
      func: ({ item }) =>  get(item, "type")
    },
    {
      label: "Status",
      func: ({ item }) =>  get(item, "status")
    },
    {
      label: "Total",
      func: ({ item }) =>  get(item, "total")
    },

    {
      label: "Actions",
      func: ({ item }) => {
        const slug = get(item , 'slug')
            return (
            <div style={{ display:"flex" , gap:"8px" }}>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdEdit size={25} onClick={() => {
                  setState(true)
                  setSelectedOrder(item)
                  }}  />
              </IconButton>
            </div>
          )
        }
    },
  ];

  const list = () => (


      <div
        className={clsx(classes.list)}
        role="presentation"
  
      >
        <h2>Order #{selectedOrder?.slug}</h2>
        <div className={classes.statusSection}>
          <div className={classes.status}>
            <p>Status: {selectedOrder?.status}</p>
            <p>Date: { format(new Date(selectedOrder.created_at ?? null), "MMM do hh:mm a") }</p>
          </div>
          <BiDotsHorizontalRounded onClick={handleClick} />
          <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleStatus("processed")}>Processed</MenuItem>
          <MenuItem onClick={() => handleStatus("completed")}>Completed</MenuItem>
          <MenuItem onClick={() => handleStatus("cancelled")}>Cancel</MenuItem>
        </Menu>
        </div>
        <div className={classes.group}>
          <div className={classes.subtitle}>Order Summary</div>
          <div className={classes.itemsSection}>
          {
                selectedOrder?.order_items?.map((item, i) => 
                    <CartItem 
                        key={i}
                        slug={item.slug}
                        quantity={item.quantity} 
                        name={item.name} 
                        price={item.total_cost} 
                        image={item.image}
                        type={selectedOrder.type}
                        summary={false} />)
            }

            <div className={classes.detailSection}>
              <p>Subtotal</p>
              <p>{selectedOrder?.total}</p>
            </div>
            <div className={classes.detailSection}>
              <p>Discount</p>
              <p>0</p>
            </div>
            <div className={classes.detailSection}>
              <p>Total</p>
              <p>{selectedOrder?.total}</p>
            </div>
          </div>
    
        </div>
        <div>
          <div className={classes.subtitle}>Customer</div>
          <div className={classes.itemsSection}>
            <div className={classes.customerSection}>
              <Avatar className={classes.img} src={selectedOrder?.customer?.avatar} alt="User"/>
              <div>
                  <p>{selectedOrder?.customer?.display_name}</p>
                  <p>{selectedOrder?.customer?.email}</p>
              </div>
            </div>
              <p>Shipping Address: {
              selectedOrder.type === 'hardware'
              ? selectedOrder?.address 
              : selectedOrder?.customer?.email}</p>
          </div>
    
        </div>
      </div>
  );


  useEffect(() => {
    const url =  ( 
      filter.status === "all" 
        ? `api/orders?per_page=${rows}` 
        : `api/orders?filter[status]=${filter.status}&per_page=${rows}` 
        )
        setEndpoint(url)

  },[filter , rows ])



  useEffect(() => {
    getOrders();
  }, [endpoint ]);




  return (
    <div className={classes.container}>
      <h2>Manage Orders</h2>
      <div className={classes.options}>
      <Select
            variant="outlined"
            className={classes.select}
            name='status'
            value={filter.status}
            defaultValue="all"
            onChange={(e) => setFilter({...filter, [e.target.name]:e.target.value})}
          >
            <MenuItem  value="all">All</MenuItem>
            <MenuItem  value="pending">Pending</MenuItem>
            <MenuItem  value="processed">Proccessed</MenuItem>
            <MenuItem  value="completed">Completed</MenuItem>
            <MenuItem  value="cancelled">Cancelled</MenuItem>

          </Select>
           
      </div>
      <React.Fragment key={"right"}>
          <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
            {list()}
          </Drawer>
      </React.Fragment>

      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        schema={schema}
        loading={loading.table}
        collection={collection.data}
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
     

    </div>
  );
}

import React from 'react'
import { useStyles } from './styles';

import { axiosInstance } from 'helpers/client';
import { useContext } from 'react';
import { AppContext } from 'context/appContext';
import Button from '../Button/Button';
import { WalletContext } from 'context/walletCotext';

const RewardCard = ({data , refetch}) => {
    const classes = useStyles();
    const {  setFeedback } = useContext(AppContext);
    const {  refetch:updateWallet } = useContext(WalletContext);

    const claim = async () => {
        await axiosInstance.put(`api/activity-challenges/rewards/${data?.id}` ).then(({data}) => {
                setFeedback({ message:data.message , severity:"success"})
                refetch()
                updateWallet()
            }).catch(({response}) => {
                setFeedback({ message:response.data.message , severity:"error"})
            })
    }

    return (
        <div className={classes.container}>
            <div>
                <p>Activity Complete</p>
                <p>{data?.challenge.description}</p>
            </div>
            <Button name={`Claim ${data?.value}GC`} onClick={() => claim()}/>
        </div>
    )
}

export default RewardCard;
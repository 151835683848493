import React from 'react'
// import Button from '../Button/Button';
import { useStyles } from './styles';
import { format  } from "date-fns";
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';

const RectangleCard = ({image, prize, start, exclusive, fee, end, currency, description, slot, game, time, ...props}) => {
    const classes = useStyles({exclusive, fee});
    const start_time = new Date(start)
    const end_time =new Date(end)
    const started = format(start_time, "MMM do")
    const ended = format(end_time, "MMM do")
    const { t } = useTranslation()

    const truncateText = (str) => {
        return str.length > 25 ? str.substring(0, 20) + "..." : str;
    }

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });

    return (
        <div>
            <Card sx={{ maxWidth: 286, ':hover': {boxShadow: 20}, [theme.breakpoints.down("sm")] :{
                maxWidth: 390
            }}}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="240"
                image={image}
              />
                <CardContent sx={{ backgroundColor: "#0b0d21", color: "#FFF" }}>
                    <Typography gutterBottom variant="p" component="div">
                        {game ? game.name : ""}
                    </Typography>
                    <Typography variant="body2" color="commons.white">
                        <p className={classes.description}>{truncateText(description)}</p> 
                        <p className={classes.date}>{started} - {ended}</p>
                        <p className={classes.prize}>{t("prize_pot")}: {currency === "USD" ? "$" : currency}{prize}</p>
                    </Typography>
                </CardContent>
                <CardActions sx={{ backgroundColor: "#0b0d21", color: "#FFF" }}>
                    <Button {...props}>
                        {t("View Tournament")}
                    </Button>
                    <p className={classes.tag}>{exclusive ? "Exclusive" : (fee > 0 ? "Paid" :"Free")}</p>
                </CardActions>
            </Card>

            {/* <div className={classes.img} style={{backgroundImage: `url('${image}')`}}></div> */}
            {/* <div className={classes.right}> */}
            {/*     <div className={classes.info}> */}
            {/*         <div className={classes.tagSection}> */}
            {/*             { */}
            {/*                 game ?    <p className={classes.start}>{game.name}</p> : */}
            {/*                 <p className={classes.start}>{t("slot")}: {slot}</p> */}
            {/*             } */}
            {/*                */}
            {/*         </div> */}
            {/*         <p className={classes.description}>{truncateText(description)}</p> */}
            {/*         <p className={classes.date}>{started} - {ended}</p> */}
            {/*         <p className={classes.prize}>{t("prize_pot")}: {currency === "USD" ? "$" : currency}{prize}</p> */}
            {/*     </div> */}
            {/*     <Button name={t("view")} backgroundColor="#0F162D" {...props} /> */}
            {/* </div> */}
        </div>
    )
}

export default RectangleCard;
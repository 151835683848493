import React, { useContext, useState , useEffect } from 'react'
import { useStyles } from './styles';
import {  TextField } from '@material-ui/core';
import { axiosInstance } from '../../helpers/client';
import Button from '../../components/Button/Button';
import { handlePage } from '../../helpers/paginator';
import ForumCard from '../../components/ForumCard/ForumCard';
import Modal from '../../components/Modal/Modal';
import { useRef } from 'react';
import CreateForumPost from '../../components/CreateForumPost/CreateForumPost';
import ForumInterest from '../../components/ForumInterest/ForumInterest';
import { useQuery } from 'react-query';
import { getInterest } from '../../api/interest';
import { AppContext } from '../../context/appContext';
import { useTranslation } from 'react-i18next';





export default function Forum() {

    const classes = useStyles();
    const [ open , setOpen ] = useState("");
    const [edit , setEdit] = useState({});
    const inputRef = useRef(null) 
    const [ filter , setFilter] = useState("")
    const { loggedIn , setFeedback} = useContext(AppContext)
    const { isLoading,  data:interest } = useQuery('interests', getInterest , {
        enabled:loggedIn
    })
    const { t } = useTranslation();
   
    const handleClose = () => {
        setOpen("");
        setEdit({})
        inputRef.current.focus = false;
      };
 
    const [ collection , setCollection] = useState( {
        data:[] , links:{}
    })

    const [endpoint , setEndpoint ] = useState(`api/forum/posts`)
    const [loading , setLoading] = useState({
        posts:true
    })

    const handleEdit = (post) => {
        setOpen("create");
        setEdit(post)

    }

    const getPosts = async () => {
        setLoading({...loading , posts:true})
        await axiosInstance.get(endpoint).then(function ({ data }) {
            setCollection(data);
        }).finally(() => setLoading({...loading , posts:false}))
    };

    const handleDelete = (slug) => {
        if(window.confirm("Are you sure you want to delete this post")){
             axiosInstance.delete(`api/forum/posts/${slug}`).then(({data}) => {
                setFeedback({message:data.message})
                getPosts();
             })
        } 
    }

    const handlePost = (type , post) => {
        switch (type){
            case "edit":
                 handleEdit(post)
                 break;
            case "delete":
                handleDelete(post.slug);
                break;
            default:
                break;
        }

    }

    const handleTags = (tag) => {
        setFilter(tag)
    }
  

    useEffect(() => {
        if(!isLoading && loggedIn) {
            (interest.length  == 0 ) && setOpen("interest") 
        }
    },  [isLoading , interest, loggedIn])

    useEffect(() => {
        getPosts()
   }, [ endpoint])



   useEffect(() => {
        setEndpoint(`api/forum/posts?tag=${filter}`)
   }, [filter])



    return (
        <div className={classes.container}>
            <div className={classes.search}>

                <TextField
                    name="search"
                    InputProps={{
                        className: classes.input_field,

                    }}

                    inputRef={inputRef}
                    onFocus={() => setOpen("create")}
                    placeholder={t("talk_about")}
                    variant="outlined"
                    className={classes.textfield}
                    />
            </div>
            {
                !!filter && 
                <div style={{display:'flex' , justifyContent:"space-between" , marginBottom:"10px"}}>
                    <p>Filter by tag: {filter}</p>
                    <Button  name="Reset" onClick={() => setFilter("")}/>

                </div>
            }
            
            <div className={classes.feedList}>
                {
                    collection.data.map((item, index) => <ForumCard  key={index} {...item} handleTags={handleTags} handlePost={handlePost}/>)
                }
            </div>
            <div>
                {
                        collection.data.length !== 0 &&
                        <div className={classes.pagination}>
                            {
                                collection.links.prev && 
                                <Button name="Prev" onClick={() => handlePage(setEndpoint ,collection , 'prev')}/>
                            }
                             {
                                collection.links.next && 
                                <Button  name="Next" onClick={() => handlePage(setEndpoint ,collection , 'next')}/>
                            }
                        
                        
                        </div>
                }
            </div>
            <Modal open={!!open} onClose={handleClose}>
                {
                    open === "create" &&  <CreateForumPost refetch={getPosts} handleClose={handleClose} edit={edit}/>
                }
                {
                    open === "interest" &&  <ForumInterest handleClose={handleClose} edit={edit}/>
                }
               
            </Modal>
        </div>
    )
}

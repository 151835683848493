import { axiosInstance } from 'helpers/client';
import React , { createContext, useState} from 'react';
import { useEffect } from 'react';


const initial = {
    user:{},
    loggedIn: false,
    setLogin: () => {},
    setUser: () => {},
    feedback:{},
    setFeedback: () => {},
    settings:[]
};

export const AppContext = createContext(initial);

export const AppProvider = ({children}) => {
    const token = localStorage.getItem("token");
    const storageUser =  JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : { role: {} };
    const [ login , setLogin ] = useState(!!token);
    const [ user , setUser ] = useState(storageUser);
    const [ feedback , setFeedback ] = useState({});
    const [settings , setSettings ] = useState(null)


    const getSettings = async () => {
        await axiosInstance.get(`api/configurations`).then(function ({ data }) {
            setSettings(data.data);
          })
      };


      useEffect(() => getSettings(),[])
    return (
        <AppContext.Provider value={{
            user,
            settings,
            feedback,
            setFeedback: (value) => {
                setFeedback(value)
            },
            setUser: (user) => {
                setUser(user)
            },
            loggedIn:login,
            setLogin: (value) => {
                setLogin(value)
            },
        }}>
            {children}
        </AppContext.Provider>
    )
}
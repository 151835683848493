import React, { useContext, useState } from 'react';
import {  Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Button from "components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from 'helpers/client';
import { AppContext } from 'context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import { getCategories, getVendors } from 'api/market';
import { handleCheck } from 'helpers/form';



const CreateProduct = ({  handleClose , edit, refetch}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);
    const { data:categories, isLoading } = useQuery('getCategories', getCategories)
    const { data:vendors, isLoading:isLoadingVendor } = useQuery('getVendors', getVendors);

    const handleSubmit = (data) => {
        isEmpty(edit) ? createProduct(data) : updateProduct(data)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        image: isEmpty(edit) ? Yup.string().required("image is Required") : Yup.string(),
        description:Yup.string().required(),
        price:Yup.number().required(),
        discount_percent:Yup.number(),
        type:Yup.string().required(),
        category_id:Yup.number().required(),
        vendor_id:Yup.number().required(),
    });

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            price: edit.price ?? "",
            image: edit.image ?? "",
            bannerImage:"",
            description: edit.description ?? "",
            discount_percent: edit.discount_percent ?? "",
            vendor_id: edit?.vendor?.id ?? "",
            category_id: edit?.category?.id ?? "",
            in_stock: edit.in_stock ?? true,
            type: edit.type ?? "hardware",
            currency: edit.currency ?? "USD",
            code: edit.code ?? "",
        },

        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("image" , "")
            formik.setFieldValue("bannerImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("image" , baseURL)
            formik.setFieldValue("bannerImage" , e?.target.files[0])
        };
    }



      const createProduct = async (data) => {
        setLoading(true)

        let formData = {...data};

        if(formData.type === "digital" && !!formData.code) {
            
            formData.product_code = {
                code : formData.code,
                currency: formData.currency
            } 
        }

        delete formData.code;

        await axiosInstance.post("api/products/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
           setFeedback({message:response.data.message , severity:"error"})

        })
        .finally(() => {
            setLoading(false)
            handleClose()
        })
    }


    const updateProduct = async (formData) => {
        setLoading(true)
        if(formData.bannerImage === "") delete formData.image
 
        await axiosInstance.put(`api/products/update/${edit.slug}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
            <>
                <h2>{isEmpty(edit) ? t("Create product") : t("Update Product")}</h2>
                <div className={classes.image_section}>
                            <ImageUploader handleFile={handleFile} file={ isEmpty(edit) ? formik.values?.bannerImage :  formik.values?.image} />
                            {formik.errors.image ? (
                                <span className={classes.content__form_error}>{formik.errors.image}</span>
                            ) : null}
                        </div>
                <div  style={{
                    display:"flex",
                    gap:"12px",
                }}>

                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"12px",
                    }}>
                                

                    
                        <div>
                            <TextField onChange={formik.handleChange}  value={formik.values.name}  placeholder={t("name")} variant="outlined" name="name" fullWidth />
                            {formik.touched.name  &&  formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                        </div>
   
                        <div className={classes.input_section}>
                            <p>{t("Category")}</p>
                            <Select
                                className={classes.textfield}
                                inputProps={{ 'aria-label': 'Without label' }}
                                variant="outlined"
                                size="small"
                                name="category_id"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.category_id}
                            >
                                {
                                    !isLoading && (
                                        categories.map(({name , id}, i) => <MenuItem key={i} value={id}>{name}</MenuItem>)
                                    )
                                }
                                
                            </Select>   
                        </div>
                        <div>
                        <p>{t("Discount")}</p>
                            <TextField onChange={formik.handleChange}  value={formik.values.discount_percent} type="number"  placeholder={t("Discount in %")} variant="outlined" name="discount_percent" fullWidth />
                            {formik.touched.discount_percent  &&  formik.errors.discount_percent ? (
                                    <span className={classes.content__form_error}>{formik.errors.discount_percent}</span>
                                ) : null}
                        </div>
                        {
                            formik.values.type === "digital" && <TextField onChange={formik.handleChange}  value={formik.values.currency}  placeholder={t("Currency")} variant="outlined" fullWidth name="currency"  />
                        }
                        
                        <div className={classes.input_section}>
                            <FormControlLabel
                                className={classes.textfield}
                                name="in_stock"
                                onChange={(e) => handleCheck(e, formik)}
                                control={<Checkbox  style={{color:"#fff"}} checked={formik.values.in_stock}/>}
                                label={t("In Stock")}
                                labelPlacement="end"
                                />
                        </div>
                        <div style={{display:"flex" , justifyContent:"flex-end"}}>
                            <Button loading={loading} name={isEmpty(edit) ? t("Create Product") : t("Save") }  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                        </div>
                    </div>
                    <div style={{
                        display:"flex",
                        width:"50%",
                        flexDirection:"column",
                        gap:"12px",
                    }}>
                                


                        <div>
                            <TextField onChange={formik.handleChange}  value={formik.values.price}  placeholder={t("Price")} variant="outlined" name="price" fullWidth />
                            {formik.touched.price  &&  formik.errors.price ? (
                                    <span className={classes.content__form_error}>{formik.errors.price}</span>
                                ) : null}
                        </div>

                        <div className={classes.input_section}>
                            <p>{t("Vendor")}</p>
                            <Select
                                className={classes.textfield}
                                inputProps={{ 'aria-label': 'Without label' }}
                                variant="outlined"
                                size="small"
                                name="vendor_id"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.vendor_id}
                            >
                                {
                                    !isLoadingVendor && (
                                        vendors.map(({name , id}, i) => <MenuItem key={i} value={id}>{name}</MenuItem>)
                                    )
                                }
                                
                            </Select>   
                        </div>

                        <div className={classes.input_section}>
                            <p>{t("Type")}</p>
                            <Select
                                className={classes.textfield}
                                inputProps={{ 'aria-label': 'Without label' }}
                                variant="outlined"
                                size="small"
                                name="type"
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.type}
                            >
                                <MenuItem value="digital">Digital</MenuItem>
                                <MenuItem value="hardware">Hardware</MenuItem> 
                            </Select>   
                        </div>
                        <TextField onChange={formik.handleChange}  value={formik.values.description}  placeholder={t("description")} variant="outlined" fullWidth name="description" minRows="3" multiline />
                        {formik.touched.description  &&  formik.errors.description ? (
                                    <span className={classes.content__form_error}>{formik.errors.description}</span>
                                ) : null}
                        {
                            formik.values.type === "digital" && <TextField onChange={formik.handleChange}  value={formik.values.code}  placeholder={t("Code")} variant="outlined" fullWidth name="code"  />
                        }
                    </div>
                </div>
            </> 
                              
        )
    }



export default CreateProduct
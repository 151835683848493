import { Avatar } from '@material-ui/core';
import React from 'react'
import { AiFillEdit } from 'react-icons/ai';
import { useStyles } from './styles';


export default function ImageUploader({handleFile , file = "", editable = false , id="file"}) {
    const classes = useStyles();
    const src = typeof file === "string" ? file : (window.URL || window.webkitURL).createObjectURL(file)

    return (
      <>
        <input accept="image/*" hidden id={id} type="file" onChange={handleFile}/>
        <label htmlFor={id} style={{position:"relative"}} >
          <Avatar className={classes.placeholderImage} src={src} />
          {
            editable && <span style={{position:"absolute" , bottom:0 , right:"15%"}}><AiFillEdit/></span>
          }
        </label>
      </>
    )
}

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(0, 4),
        color:"#fff",
      
        "& h2": {
            fontSize:"22px"
        }
    },
    root: {
        padding:theme.spacing(0 , 5),
        "& .MuiPaper-root":{
            backgroundColor:"unset"
        },
        '& .MuiStepIcon-root':{
            color: "#fff",

        },
        '& .MuiStepIcon-root.MuiStepIcon-completed' :{
            color: "#fff",
        }
      
      },
      backButton: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      button_text:{
        padding:theme.spacing(1.5 , 2)
      }
  

   
}));

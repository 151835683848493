import React, { useState } from 'react'
import { useStyles } from './styles'
import TeamCard from '../TeamCard/TeamCard';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import CreateTeam from '../CreateTeam/CreateTeam';
import { TiPlus } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';


const Team = ({teams}) => {
    const classes = useStyles()
    const history = useHistory()
    const [ team  ] = useState(teams ?? [])
    const [open, setOpen] = useState(false);
    const { t } = useTranslation()


    const handleOpen = () => {
        setOpen(true);
      };
      
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div className={classes.container}>
            <div>
                { team.length > 0 ?
                    team.map(({ avatar ,  name , description , slug} , index) => (

                        <TeamCard key={index} banner={avatar} name={name} description={description} onClick={() => history.push(`/home/team/${slug}`)}/>
                    )) :
                    <>
                        <p style={{marginBottom:"8px"}}>{t("no_team")}</p>
                        <Button name={t("create_team")} startIcon={<TiPlus size={15} />} onClick={handleOpen}/>
                    </>

                }
            </div>
            <Modal open={open} onClose={handleClose}>
                   <CreateTeam handleClose={handleClose}/>
                </Modal>
        </div>
    )
}

Team.propTypes = {
    teams: PropTypes.array
  };

export default Team

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
  },  
  button_section:{
    display:"flex",
    justifyContent:"flex-end",
    gap:"8px",
    alignItems:"center"
  },
  button_text:{
    padding:theme.spacing(1 , 2)
  },
  content__form_error:{
    fontSize:"12px",
    color:"red",
  },
  select:{
    color:"#5A79A5",
    width:"200px",
    margin:theme.spacing(2, 0),
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5A79A5"
      },
      "& .MuiOutlinedInput-input" :{
        padding: "12px 16px"
    }
  },                                              
}));

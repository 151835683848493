import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { debounce, get, isEmpty } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import { IconButton, MenuItem, Select , CircularProgress} from '@material-ui/core';
import { MdEdit } from 'react-icons/md'
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button/Button";
import { handlePage } from "../../../helpers/paginator";
import ReferralMonetization from "./ReferralMonetization";
import ReferralHistory from "./ReferralHistory";
import { BsThreeDots } from "react-icons/bs";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format } from "date-fns";
import { isNull } from "lodash";


export default function ManageReferral() {
  const classes = useStyles();
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
  const [ open , setOpen ] = useState("");
  const [ search , setSearch ] = useState("");
  const [ filter , setFilter ] = useState({
    status:"monetized" ,from: null, to: new Date()
  })
  const [ edit , setEdit ] = useState({});
  const [rows, setRowsPerPage] = useState(5);
  const [endpoint, setEndpoint] = useState(`api/referral/monetized-referrers?per_page=${rows}`);
  const [ loading , setLoading ] = useState({
    table:true , icons:false
  });

  const { setFeedback , feedback } = useContext(AppContext);

  const handleOpen = (modal) => {
    setOpen(modal);
  };

  const handleClose = () => {
    setOpen("");
    setEdit({})
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };


  const getReferrals = async () => {
    setLoading({ table:true , icons:false})
    await axiosInstance.get(endpoint)
      .then(function ({ data }) {
        setCollection(data);
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoading({ table:false , icons:false}))
  };

  const filterSchema = [
    {
      title:"status",
      options:[
        {
          value:"monetized",
          name:"Monetized"
        },
        {
          value:"default",
          name:"Regular"
        },
      ]
    },

  ]

  const schema = [
    {
      label: "Name",
      func: ({ item }) => get(item, "name")
    },
    {
      label: "Email",
      func: ({ item }) => get(item, "email"),
    },
    {
      label: "Username",
      func: ({ item }) => get(item, "username"),
    },
    {
      label: "Total Referrals",
      func: ({ item }) => get(item, "referrals_count" , 'N/A'),
    },

    {
      label: "Actions",
      func: ({ item }) => {
        return (
          <div style={{ width: "110px" }}>
            {

              !loading.icons ? 
              (

                <>
                {

                filter.status === "monetized" &&
                <IconButton color="inherit" style={{padding:"0"}}>
                  <MdEdit size={25} onClick={() =>  {
                    setEdit(item)
                    handleOpen("monetize")
                    }}   />
                </IconButton>
                }
                <IconButton color="inherit" style={{padding:"0"}}>
                  <BsThreeDots size={25} onClick={() => {
                    setEdit(item)
                    handleOpen("history")
                  }}  />
                </IconButton>
                </>
              )
              :

              <CircularProgress size={25}  color="inherit"/>
            }
          </div>
        )
      }
    },
  ];

  useEffect(() => {

    const completeUrl = filter.status === "monetized" ? 'api/referral/monetized-referrers' : 'api/referral/period';
    const url = new URL(process.env.REACT_APP_HTTPS_ENDPOINT + completeUrl);

    if(isNull(filter.from)) {
      delete url.searchParams.from;
    } else {
      url.searchParams.set('from', format(filter.from,'yyyy-MM-dd'));
    }
    url.searchParams.set('per_page', rows);
    url.searchParams.set('to', format(filter.to,'yyyy-MM-dd'));

    setEndpoint(url.toString())

  },[filter , rows])

  useEffect(() => {
    getReferrals();
  }, [ endpoint ]);

  useEffect(() => {
    if(!isEmpty(feedback)){
      getReferrals();
    }
  }, [feedback ]);

  const searchUsers = debounce(() => {
    const url =  `api/players/search/users?q=${search}`
    setEndpoint(url)
  },2000)

  useEffect(() => {
      searchUsers();
  },[search])



  return (
    <div className={classes.container}>
      <h2>Manage Referrals</h2>
   
      <div style={{display:"flex" , justifyContent:"space-between" , alignItems:"center" }}>
        <div style={{display:"flex" , gap:"8px"}}>
          {
            filterSchema.map(({ options , title} , i) => (
              <Select
                key={i}
                variant="outlined"
                className={classes.select}
                value={filter[title]}
                name="status"
                defaultValue={filter[title]}
                onChange={(e) => setFilter({...filter , status:e.target.value})}
              >
                {
                    options.map(({name , value} , index) =>  <MenuItem key={index} value={value}>{name}</MenuItem> )
                }
              </Select>
            ))
          }
              <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker inputVariant="outlined" value={filter.from} label="From"  className={classes.select} variant="outlined"  onChange={(date) => setFilter({...filter , from:date})} />
                <DatePicker inputVariant="outlined" value={filter.to} label="To"  className={classes.select} variant="outlined"  onChange={(date) => setFilter({...filter , to:date})} />

              </MuiPickersUtilsProvider>
              </div>



        </div>

        <Button
          classes={{ text: classes.button_text }}
          backgroundColor="#B00610"
          name={"Reset Filter"}
          onClick={() => setFilter({...filter , from:null, to:new Date()})}
        />
        <Button
          classes={{ text: classes.button_text }}
          backgroundColor="#B00610"
          name={"Manage Referrals"}
          onClick={() => handleOpen("monetize")}
        />
      </div>
      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        schema={schema }
        collection={collection.data}
        loading={loading.table}
        shortLoading
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
      <Modal open={open === "monetize"} onClose={handleClose}>
         <ReferralMonetization  handleClose={handleClose} edit={edit}  refetch={getReferrals}/>
      </Modal>
      <Modal open={open === "history"} onClose={handleClose}>
         <ReferralHistory  handleClose={handleClose} refetch={getReferrals} username={edit.username} monetized={edit?.monetized_referrer}/>
      </Modal>
    </div>
  );
}

import { makeStyles, } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    
    placeholderImage:{
        height:"100px",
        width:"100px",
        cursor:"pointer"
    }
}));

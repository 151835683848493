import React from 'react'
import { AiFillFire } from 'react-icons/ai';
import { BsEyeSlashFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';


const ProductItem = ({discount = null, slug, price ,inStock, title ,created, image = "https://res.cloudinary.com/aladeen/image/upload/v1669191368/kpdvwlf9aeoyl3qrrbux.jpg"}) => {
    const oldPrice = discount !== null ? Math.round((discount/100) * price) : null
    const classes = useStyles({ oldPrice ,inStock});
    const history = useHistory()

    var weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    const show = (weekAgo < new Date(created) || !inStock)


    
    return (
        <div className={classes.productContainer} onClick={() => history.push(`/home/market/${slug}`)}>
            <div className={classes.image} style={{backgroundImage:`url(${image})`}}>
                {
                    show && (

                    <div className={classes.info}>
                        {inStock ? <AiFillFire size={25} /> : <BsEyeSlashFill  size={25}/> }
                        <p className={classes.newItem}>
                           {inStock ? "New Item" : "Out of Stock"} 
                        </p>
                    </div>
                    )
                }
            </div>
            <div className={classes.titleSection}>
                <p className={classes.title}>{title}</p>
                <div className={classes.priceSection}>
                    <p className={classes.price}>NGN{Math.round(price - oldPrice).toLocaleString()}</p>
                    {
                         !!discount && <p className={classes.oldPrice}>NGN{price}</p>
                    }
                    
                </div>
            </div>
            {
                !!discount &&
                <div className={classes.discount}>
                    {discount}% off
                </div>
            }
        </div>
    )
}

﻿export default React.memo(ProductItem);
import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        display:"flex",
        flex: "1 1 0",
        maxWidth:"150px",
        alignItems:"center",
        flexDirection:"column",
        borderRadius:"8px",
        border:"0.5px solid #5A79A5",
        padding:theme.spacing(2),
        paddingTop:theme.spacing(4),
       
    },
   
    price_section:{
        display:"flex",
        flexWrap:"wrap",
        marginTop:theme.spacing(4),
        gap:theme.spacing(1)
    },
    price:{
        fontSize:"26px",
        fontWeight:400,
        marginTop:"8px",
        fontFamily:"Quicksand",
        color:"#fff"
    },
    info:{
        color: "#fff",
        fontSize:"14px",
        lineHeight: "26px",
        fontWeight:300,
        margin:theme.spacing(1, 0),
        fontFamily:"Quicksand",
    }
}));

import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { debounce, get, isEmpty } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import { Avatar, CircularProgress, ClickAwayListener, Grow, IconButton, InputAdornment, MenuItem, MenuList, Popper, Select, TextField } from '@material-ui/core';
import { MdEdit, MdDelete, MdSearch } from 'react-icons/md'
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { BsThreeDots } from "react-icons/bs";
import AddLeaderboard from "./AddLeaderboard";
import LeaderboardBanner from "./LeaderboardBanner";
import { useGame } from "../../../hooks/useGame";
import { handlePage } from "../../../helpers/paginator";
import { useHistory } from "react-router-dom";
import { Skeleton } from '@material-ui/lab';



export default function ManageLeaderboard() {
  const classes = useStyles();
  const history = useHistory();
  const [ userTournament , setUserTournament] = useState([]);
  const [ collection , setCollection] = useState({
    data:[] , links:{}
  })
  const [endpoint, setEndpoint] = useState( ``);
  const { data:game , isLoading:gameLoading } = useGame('query' , 'getGames' , 'fromGames');
  const [ filter , setFilter ] = useState({
    tournament:"all", type:"user" ,game:""
  })
  const [ open , setOpen ] = useState("");
  const [ tournaments , setTournaments ] = useState([]);
  const [ search , setSearch ] = useState("");
  const [ brand , setBrand ] = useState({})
  const [ edit , setEdit ] = useState({});
  const [rows, setRowsPerPage] = useState(5);
  const [ loading , setLoading ] = useState({
    table:true , icons:false , banner:true
  });


  const { setFeedback } = useContext(AppContext);

  const handleOpen = (modal) => {
    setOpen(modal);
  };

  const handleClose = () => {
    setOpen("");
    setEdit({})
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };



  const deleteLeaderboard =  (item ) => {
    if(!window.confirm("Are you sure you want to delete this user")) return
    setLoading({...loading , icons:true})

    let form = {}

    form.game = filter.game
    form.tournament = filter.tournament.slug
    form.name = item.player.username ?? item.player.slug
    form.type =  filter.type;

    axiosInstance.delete(`api/leaderboards` , {data:form} )
      .then(function ({ data }) {
        setFeedback({message: data.message});
        getLeaderboard();
      })
      .catch(({ response }) => {
          setFeedback({ message: response.data.message, severity: "error" });
      })
      .finally(() => setLoading({...loading , icons:false}));
  };

  

  const getLeaderboard = async () => {
    setLoading({...loading, table:true , icons:false})
    await axiosInstance.get(endpoint)
      .then(function ({ data }) {
        setCollection(data);
      })
      .finally(() => setLoading({...loading, table:false , icons:false}))
  };

  const getConfig = () => {
    setLoading({...loading, banner:true})
    axiosInstance(`api/configurations/show/leaderboard`)
    .then(({data}) => setBrand(data.data.data))
    .finally(() => setLoading({...loading, banner:false}))
  }

  const getLeaderboardTournaments =  () => {
    setLoading({...loading, icons:false})
    const url = filter.game === "" 
    ? `api/leaderboards/tournaments?type=${filter.type}` 
    : `api/leaderboards/tournaments?type=${filter.type}&game=${filter.game}`

    axiosInstance.get(url).then(function ({ data }) {
        setTournaments(data.data);
      })
      .finally(() => setLoading({...loading, table:false , icons:false}))
  };

  
  const [openOption, setOpenOption] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpenOption((prevOpen) => !prevOpen);
  };

  const handleCloseOption = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenOption(false);
  };

  const handleEditLeaderbord = (item) => {
    item.game = filter.game
    item.tournament = filter.tournament
    item.display = item.player.username ?? item.player.name
    item.name = item.player.username ?? item.player.slug
    item.type = filter.type

    setEdit(item)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenOption(false);
    } else if (event.key === 'Escape') {
      setOpenOption(false);
    }
  }


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openOption);
  React.useEffect(() => {
    if (prevOpen.current === true && openOption === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openOption;
  }, [openOption]);

  const filterSchema = [
    {
      title:"type",
      options:[
        {
          value:"user",
          name:"User"
        },
        {
          value:"team",
          name:"Team"
        },
      ]
    },
    
  ]

  const schema = [
    {
      label: "Name",
      func: ({ item }) => {
        const player = get(item, "player");
        const choice = filter.type === 'user' ? player?.username : player?.name;
        const endpoint =  filter.type === 'user' ? `/home/player/${choice}` : `/home/team/${player?.slug}`

        return (
          <div className={classes.imgLogo} onClick={() => history.push(endpoint)}>
            <Avatar src={player?.avatar ?? player?.banner} alt={player?.username} />
            <span>{choice}</span>
          </div>
        )
      }
    },
    {
      label: "Tournaments Played",
      func: ({ item }) => get(item, "tournaments_played"),
    },
    {
      label: "Attendance Points",
      func: ({ item }) => get(item, filter.tournament === "all" ? "total_attendance_points" :"tournament_attendance_points"),
    },
    {
      label: "Tournament Points",
      func: ({ item }) => get(item,  filter.tournament === "all" ? "total" :"tournament_points"),
    },
    {
      label: "GAP",
      func: ({ item }) => get(item,  "overall_points" ),
    },


    {
      label: "Actions",
      func: ({ item }) => {
        return (
          <div style={{ width: "110px" }}>
            {

              !loading.icons ? 
              (

                <>
                  <IconButton color="inherit" style={{padding:"0"}}>
                    <MdEdit size={25} onClick={() =>  {
                      handleEditLeaderbord(item)
                      handleOpen("record")
                      }}   />
                  </IconButton>
                  <IconButton color="inherit" style={{padding:"0"}}>
                    <MdDelete size={25} onClick={() =>  deleteLeaderboard(item)}  />
                  </IconButton>
                </>
              )
              :
              <CircularProgress size={25}  color="inherit"/>
            }
          </div>
        )
      }
    },
  ];

  if(filter.tournament === "all") {
    schema.pop()
  } else {
    schema.splice(schema.length - 2, 1)
  }

  useEffect(() => {
    !gameLoading && setFilter({...filter, game: game[0].slug });
  }, [ gameLoading ]);



  useEffect(() => {
    if(filter.game === "") return
    const url =  ( 
      filter.tournament === "all" 
        ? `api/leaderboards?game=${filter.game}&type=${filter.type}` 
        : `api/leaderboards?game=${filter.game}&type=${filter.type}&tournament=${filter.tournament.slug}` 
        )
        setEndpoint(url)

  },[filter ])


  const searchUsers = debounce(() => {
    const params = new URLSearchParams(endpoint)
    if (search) {
      params.append("search", search)
    } else {
      params.delete("search")
    }
   setEndpoint(unescape(params.toString()))
  },3000)

  useEffect(() => {
      searchUsers();
  },[search])

  const handleTournamentFilter = (value) => {
    setFilter({...filter , tournament:value , game:!!value.games ? value.games[0].slug : filter.game})
  }
  

  useEffect(() => {filter.game !== "" && getLeaderboard()}, [endpoint])
  useEffect(() => {filter.game !== "" && getLeaderboardTournaments()}, [filter.type , filter.game])
  useEffect(() => getConfig(),[])


  return (
    <div className={classes.container}>
      <div className={classes.top_section}>
        {
          isEmpty(brand) ? 
          <>
            <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={150} width={150} className={classes.info_skeleton_rect} animation="wave"/>
           
            <div className={classes.description}>
                <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={20} width={200} className={classes.info_skeleton_rect} animation="wave"/>
                <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={10} width={300} className={classes.info_skeleton_rect} animation="wave"/>
            </div>
          
          </>

          : 
          <>
            <div className={classes.img}  style={{backgroundImage: `url("${brand.banner}")`}} ></div>
            <div className={classes.description}>
                <h3>{brand.title}</h3>
                <p>{brand.description}</p>
                <Button name="edit" onClick={() => {
                  setEdit(brand)
                  handleOpen("banner")}
                  }/>
            </div>
          </>
        }

          <div className={classes.option}>
              <Button name="Upload" />
              <IconButton
                 ref={anchorRef}
                 id="composition-button"
                 aria-controls={openOption ? 'composition-menu' : undefined}
                 aria-expanded={openOption ? 'true' : undefined}
                 aria-haspopup="true"
                
              >
                <BsThreeDots size={25}  onClick={ handleToggle} />
              </IconButton>
              <Popper
                open={openOption}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <ClickAwayListener onClickAway={handleCloseOption}>
                  <MenuList
                    autoFocusItem={openOption}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleCloseOption}>Export</MenuItem>
                    <MenuItem onClick={handleCloseOption}>Reset</MenuItem>
                    <MenuItem onClick={handleCloseOption}>Delete</MenuItem>
                  </MenuList>
                </ClickAwayListener>
            </Grow>
          )}
        </Popper>
          </div>
      </div>
      <div style={{display:"flex" , justifyContent:"space-between" , alignItems:"center" }}>
        <div style={{display:"flex" , gap:"8px"}}>
          {
            filterSchema.map(({ options , title} , i) => (
              <Select
                key={i}
                variant="outlined"
                className={classes.select}
                value={filter[title]}
                name={title}
                defaultValue={filter[title]}
                onChange={(e) => setFilter({...filter , [e.target.name]:e.target.value})}
              >
                {
                    options.map(({name , value} , index) =>  <MenuItem key={index} value={value}>{name}</MenuItem> )
                }
              </Select>
            ))
          }
          <Select
            variant="outlined"
            className={classes.select}
            name='game'
            value={filter.game}
            onChange={(e) => setFilter({...filter , game:e.target.value})}
          >
            {
                !gameLoading && game.map(({name , slug} , index) =>  <MenuItem key={index} value={slug}>{name}</MenuItem> )
            }
          </Select>
          <Select
            variant="outlined"
            className={classes.select}
            name='tournament'
            value={filter.tournament}
            defaultValue="all"
            onChange={(e) => handleTournamentFilter(e.target.value)}
          >
            <MenuItem  value="all">Overall</MenuItem>
            {
                  tournaments.map((item , index) =>  <MenuItem key={index} value={item}>{item.name}</MenuItem> )
            }
          </Select>
          <TextField
            name="search"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              className: classes.input_field,
              startAdornment: (
                <InputAdornment color="#546884">
                  <MdSearch color="#546884" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            className={classes.select}
          />
        </div>

        <Button
          classes={{ text: classes.button_text }}
          backgroundColor="#B00610"
          name={"Add Record"}
          onClick={() => handleOpen("record")}
        />
        
      </div>
      
      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        schema={schema}
        loading={loading.table}
        collection={collection.data}
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
      <Modal open={!!open} onClose={handleClose}>
        {
          open === 'banner' && <LeaderboardBanner handleClose={handleClose} edit={edit} />
        }
         { 
          open === 'record' && <AddLeaderboard handleClose={handleClose} edit={edit} game={game} tournament={userTournament} refetch={getLeaderboard} />
        }
      </Modal>
    </div>
  );
}

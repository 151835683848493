import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from "components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from 'helpers/client';
import { AppContext } from 'context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import ImageUploader from 'components/ImageUploader/ImageUploader';



const CreateVendor = ({  handleClose , edit, refetch}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);
    const handleSubmit = (data) => {
        isEmpty(edit) ? createVendors(data) : updateVendor(data)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        logo: isEmpty(edit) ? Yup.string().required("image is Required") : Yup.string(),
        bannerImage: isEmpty(edit) 
        ? Yup.mixed().required().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)).label("Logo")
        : Yup.mixed().test("fileSize", "Image should be less than 2mb",  (value) => !value || (value && value.size <= 1024 * 1024)).label("Logo"),
    });

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            shipping_fee: edit.shipping_fee ?? "",
            logo: edit.logo ?? "",
            bannerImage:"",
        },

        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("logo" , "")
            formik.setFieldValue("bannerImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("logo" , baseURL)
            formik.setFieldValue("bannerImage" , e?.target.files[0])
        };
    }

    


      const createVendors = async (formData) => {
        setLoading(true)

        await axiosInstance.post("api/vendors/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
           setFeedback({message:response.data.message , severity:"error"})

        })
        .finally(() => {
            setLoading(false)
            handleClose()
        })
    }


    const updateVendor = async (formData) => {
        setLoading(true)

 
        await axiosInstance.put(`api/vendors/update/${edit.slug}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
            <>
                <h2>{isEmpty(edit) ? t("Create Vendor") : t("Update Vendor")}</h2>

                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    gap:"12px",
                }}>
                        <div className={classes.image_section}>
                            <ImageUploader handleFile={handleFile} file={ isEmpty(edit) ? formik.values?.bannerImage :  formik.values?.logo} />
                            {formik.errors.bannerImage ? (
                                <span className={classes.content__form_error}>{formik.errors.bannerImage}</span>
                            ) : null}
                        </div>
                    
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.name}  placeholder={t("name")} variant="outlined" name="name" fullWidth />
                            {formik.touched.name  &&  formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                    </div>
                       
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.shipping_fee}  placeholder={t("Shipping fee")} variant="outlined" name="shipping_fee" fullWidth />
                            {formik.touched.shipping_fee  &&  formik.errors.shipping_fee ? (
                                    <span className={classes.content__form_error}>{formik.errors.shipping_fee}</span>
                                ) : null}
                    </div>
                    <div style={{display:"flex" , justifyContent:"flex-end"}}>
                        <Button loading={loading} name={isEmpty(edit) ? t("Create Vendor") : t("Save") }  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                    </div>
                </div>
            </> 
        )
    }



export default CreateVendor
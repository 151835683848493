import React, { useState , useCallback, useRef, useContext } from 'react'
import DashboardCard from '../../../components/DashboardCard/DashboardCard'
import { MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import { axiosInstance , source} from '../../../helpers/client';
import { useEffect } from 'react';
import { getPreviousDays } from '../../../helpers/date';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import { AppContext } from '../../../context/appContext';
import Numeral from 'react-numeral';
import inflection from 'inflection';

export default function Dashboard() {
    const classes = useStyles();
    const [filter, setFilter] = useState(getPreviousDays(7));
    const [stats, setStats] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [subscriptions, setSubscriptions] = useState(null);
    const {setFeedback, loggedIn, user, setLogin} = useContext(AppContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getActiveSubscriptions();
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
        // getActiveSubscriptions();
    }

    const getStats = useCallback(async () => {
      await axiosInstance.get(`api/reports?start_date=${filter}&end_date=${getPreviousDays(0)}`, {
        start_date: filter
      }).then(({data}) => setStats(data.data))
    }, [filter]);

    const getActiveSubscriptions = async () => {
        await axiosInstance.get(`api/subscriptions/all?page=${page}`).then(function ({data}) {
            setSubscriptions(data);
        }).catch(function ({response}) {
            setFeedback({message: "Error loading active subscription details"});
        });
    }

    useEffect(() => {
        getStats();
        getActiveSubscriptions();
    }, [filter, getStats])
  
    return (
        <div>
            <div className={classes.container}>
                <div className={classes.input_section}>
                    <p>Filter by Date</p>
                    <Select
                        className={classes.textfield}
                        inputProps={{ 'aria-label': 'Without label' }}
                        variant="outlined"
                        size="small"
                        name="prize_type"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    >
                        <MenuItem value={getPreviousDays(7)}>Last 7 days</MenuItem>
                        <MenuItem value={getPreviousDays(30)}>Last 30 days</MenuItem>
                        <MenuItem value={getPreviousDays(365)}>Last Year</MenuItem>
                    </Select>   
                </div>

                <div className={classes.cardSection}>
                    {
                        Object.keys(stats).map((key , index) => <DashboardCard key={index} name={key} data={stats[key]}/>)
                    }
                </div>
            </div>
            <div className={classes.container}>
                <Typography variant="h5" gutterBottom>
                    Subscription Transactions
                </Typography>
                <Grid container spacing={2} columns={16}>
                    <Grid item xs={16}>
                        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: 'transparent'}}>
                          <TableContainer sx={{ maxHeight: 640 }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Email
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Role
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Subscription Plan
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Amount
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Payment Method
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Duration
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    >
                                        Expiration Date
                                    </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subscriptions && subscriptions.data.map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {row.user.name}
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {row.user.email}
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {(row.user.role === "tournament_organizer") ? "Tournament Organizer" : "Gamer"}
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {row.plan.name}
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            $<Numeral
                                                value={row.amount}
                                                format={"0,0.00"}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {(row.payment_method === "mtn_transaction") ? "MTN USSD" : "Wallet"}
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {row.plan.period_value} {inflection.humanize(row.plan.period)}
                                        </TableCell>
                                        <TableCell sx={{ color: "#FFF"}}>
                                            {row.ended_at}
                                        </TableCell>
                                    </TableRow>
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                            {subscriptions && (
                                <TablePagination sx={{ fontWeight: "bold", backgroundColor: '#0F2139', color: "#FFF"}}
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={subscriptions.meta.total}
                                    rowsPerPage={subscriptions.meta.per_page}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                  />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

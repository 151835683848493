import { makeStyles, } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    img:{
        width:"100%",
        height:"60vh",
        backgroundSize:"cover",
        borderRadius:"8px",
        backgroundPosition:"50% 30%",
        [theme.breakpoints.down("sm")]:{
            backgroundPosition:"center",
            width:"100%",
            height:"25vh",
            backgroundSize:"contain",
            borderRadius:"8px",
            backgroundRepeat:"no-repeat"
        }
    },
    carousel:{
        height:"80vh"
    }
 
}));

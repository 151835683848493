import React, { useEffect, useContext } from 'react'
import { axiosInstance } from '../../helpers/client';
import Button from '../../components/Button/Button';
import { useStyles } from './styles';
import { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {AppContext} from '../../context/appContext';

export default function VerifyEmail() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const classes = useStyles();
  const {setLogin} = useContext(AppContext);
  const [loading , setLoading] = useState(false)
  const [feedback , setFeedback] = useState({message:"" , severity:""});
  const token = params.get("token");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFeedback({});
  }

  const resendEmail = () => {
    setLoading(true)
    axiosInstance.post('api/email/resend-verification' ).then(({data}) => {
      setFeedback({message: data.message , severity:"success"})
    }).catch(({response}) => console.log(response)).finally(() => setLoading(false))
  }

  const logout = async () => {
    await axiosInstance.post("api/logout").then(() => {
      const timeoutId = setTimeout(() => {
        setLogin(false);
        localStorage.clear();
        clearTimeout(timeoutId);
        window.location.href = "/login";
      }, 3000);
    });
  }

  const verify = () => {
    setLoading(true)
    axiosInstance.patch(`api/email/verify`, {token}).then(({data}) => {
      setFeedback({message: data.message, severity: "success"});
      window.location.href = "/login";
    }).catch(({response}) => console.log(response)).finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!!token) verify();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.login_section}>
      <h2>Confirm your email address</h2>
        {  
          <p className={classes.info}>A verification email has been sent to the provided email. Log in to your mail an verify your account.</p>
        }
        <Button loading={loading} name="Resend" onClick={() => resendEmail()}/>
      </div>
      <Snackbar open={feedback.message} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={feedback.severity}>
            {feedback.message}
          </MuiAlert>
      </Snackbar>
    </div>
  )
}

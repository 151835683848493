import React from 'react'
import { useStyles } from './styles';
import Button from '../Button/Button';
import coins from '../../asset/svg/coins.svg';
import { axiosInstance } from '../../helpers/client';
import { useContext } from 'react';
import { AppContext } from '../../context/appContext';



export default function BuyCoins({ buy}) {
    const classes = useStyles();
    const { setFeedback } = useContext(AppContext)

     const buyCoins = async (price , amount) => {
        if(!window.confirm("Proceed with the purchase")) return
    
        await axiosInstance
          .post("api/gc-wallet", {
            amount,
          })
          .then(({ data }) => {
            setFeedback({message: data.message});
          })
          .catch(({ response }) => {
            setFeedback({message: response.data.message , severity:"error"});
          });
      };
    const data = [
        {price:1000 , amount:"1"},
        {price:2000 , amount:"2"},
        {price:4000 , amount:"3"},
    ]
    return (
        <>
            <h2>Buy Gamr Coin</h2>
            <p className={classes.info}>Enjoy discounted and easy access to tournaments using GC Coins. Buy Gamr Coins at a bundle and spend for less</p>
            <p>1000GC at $0.99 multiply by 100.</p>

            <div className={classes.price_section}>
                {
                    data.map(({ price , amount}) => 
                        <div className={classes.container}>
                            <img src={coins} alt="coins" />
                            <p className={classes.price}>{price}GC</p>
                            <Button width="100%" hoverColor="red" backgroundColor="#172A44" name={`$${amount}`} onClick={() => buyCoins(price , amount)} />   
                        </div>
                    )
                }
            </div>
        </>
    )
}

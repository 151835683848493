import React from "react";
import { Modal as MuiModal, Backdrop, Fade } from "@material-ui/core";
import { useStyles } from "./styles";


export default function Modal({ open, handClose, children, ...props }) {
  const classes = useStyles();

  return (
    <MuiModal
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...props}
    >
      <Fade in={open}>
        <div className={classes.modal_container}>
            {children}
        </div>
      </Fade>
    </MuiModal>
  );
}

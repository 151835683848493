import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { debounce, get, isEmpty } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import { IconButton, MenuItem, Select , CircularProgress, TextField, InputAdornment} from '@material-ui/core';
import {  MdCheckCircle , MdCancel, MdEdit, MdDelete, MdSearch   } from 'react-icons/md'
import inflection from "inflection";
import Modal from "../../../components/Modal/Modal";
import CreateUser from "./CreateUser";
import Button from "../../../components/Button/Button";
import { handlePage } from "../../../helpers/paginator";


export default function ManageUsers() {
  const classes = useStyles();
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
  const [ open , setOpen ] = useState(false);
  const [ search , setSearch ] = useState("");
  const [ filter , setFilter ] = useState({
    role:"all" , deleted:false , pending:false
  })
  const [ edit , setEdit ] = useState({});
  const [rows, setRowsPerPage] = useState(5);
  const [endpoint, setEndpoint] = useState( !filter.pending 
  ? `api/users?per_page=${rows}` 
  : `api/account/pending?per_page=${rows}`);
  const [ loading , setLoading ] = useState({
    table:true , icons:false
  });

  const { setFeedback , feedback } = useContext(AppContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit({})
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const updateUserStatus = async (username , status) => {
    setLoading({...loading , icons:true})
    await axiosInstance
      .put(`api/account/status/${username}` ,{
          status
      })
      .then(function ({ data }) {
        setFeedback({message: data.message});
      })
      .catch(({ response }) => {
        response?.data?.message !== undefined &&
          setFeedback({ message: response.data.message, severity: "error" });

        response?.data?.errors !== undefined
          ? setFeedback({ message: response.data.errors[0], severity: "error" })
          : setFeedback({ message: response.data.error, severity: "error" });
      })
      .finally(() => setLoading({...loading , icons:false}));
  };

  const deleteUser = async (username ) => {
    if(!window.confirm("Are you sure you want to delete this user")) return
    setLoading({...loading , icons:true})
    await axiosInstance.delete(`api/users/${username}` )
      .then(function ({ data }) {
        setFeedback({message: data.message});
      })
      .catch(({ response }) => {
          setFeedback({ message: response.data.message, severity: "error" });
      })
      .finally(() => setLoading({...loading , icons:false}));
  };

  const getUsers = async () => {
    setLoading({ table:true , icons:false})
    await axiosInstance.get(endpoint)
      .then(function ({ data }) {
        setCollection(data);
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoading({ table:false , icons:false}))
  };

  const filterSchema = [
    {
      title:"role",
      options:[
        {
          value:"gamer",
          name:"Gamer"
        },
        {
          value:"tournament_organizer",
          name:"Tournament Orgnizer"
        },
        {
          value:"admin",
          name:"Admin"
        },
        {
          name:"All",
          value:"all"
        },
      ]
    },
    {
    title:"deleted",
      options:[
        {
          name:"Deleted",
          value:true
        },
        {
          name:"All",
          value:false
        },
      ]
    },
    {
      title:"pending",
      options:[
        {
          name:"Pending Organizers",
          value:true
        },
        {
          name:"All",
          value:false
        },
      ]
    },
  ]

  const schema = [
    {
      label: "Name",
      func: ({ item }) => get(item, "name")
    },
    {
      label: "Email",
      func: ({ item }) => get(item, "email"),
    },
    {
      label: "Username",
      func: ({ item }) => get(item, "display_name"),
    },
    {
      label: "Joined",
      func: ({ item }) => get(item, "joined"),
    },
    {
      label: filter.pending ? "Status" : "Role",
      func: ({ item }) => (
        filter.pending ?
        (get(item, "is_approved") ? "Approved" : "Pending") :
        inflection.titleize(get(item, "role"))
      )
    },
    {
      label: "Phone No",
      func: ({ item }) => get(item, "profile.phone_number" , "N/A"),
    },

    {
      label: "Actions",
      func: ({ item }) => {
        const username = get(item , 'username');
        return (
          <div style={{ width: "110px" }}>
            {

              !loading.icons ? 
              (

                filter.pending  ?
                <>
                  <IconButton color="inherit" style={{padding:"0"}}>
                    <MdCancel size={25} onClick={() =>  updateUserStatus(username, 'declined')}  />
                  </IconButton>
                  <IconButton color="inherit" style={{padding:"0"}}>
                    <MdCheckCircle size={25} onClick={() =>  updateUserStatus(username, 'approved')}  />
                  </IconButton>
                </> :

                <>
                <IconButton color="inherit" style={{padding:"0"}}>
                  <MdEdit size={25} onClick={() =>  {
                    setEdit(item)
                    handleOpen()
                    }}   />
                </IconButton>
                <IconButton color="inherit" style={{padding:"0"}}>
                  <MdDelete size={25} onClick={() =>  deleteUser(username)}  />
                </IconButton>
                </>
              )
              :

              <CircularProgress size={25}  color="inherit"/>
            }
          </div>
        )
      }
    },
  ];

  useEffect(() => {
    const url =  !filter.pending 
    ? ( 
      filter.role === "all" 
        ? `api/users?per_page=${rows}&deleted=${filter.deleted ? 1 : 0 }` 
        :`api/users?per_page=${rows}&role=${filter.role}&deleted=${filter.deleted ? 1 : 0 }`
    )
    : `api/account/pending?per_page=${rows}`
    setEndpoint(url)

  },[filter , rows])

  useEffect(() => {
    getUsers();
  }, [ endpoint ]);

  useEffect(() => {
    if(!isEmpty(feedback)){
        getUsers();
    }
  }, [feedback ]);

  const searchUsers = debounce(() => {
    const url =  `api/players/search/users?q=${search}`
    setEndpoint(url)
  },2000)

  useEffect(() => {
    if(search.length >= 2) {
      searchUsers();
    } 
  },[search])



  return (
    <div className={classes.container}>
      <h2>Manage Users</h2>
   
      <div style={{display:"flex" , justifyContent:"space-between" , alignItems:"center" }}>
        <div style={{display:"flex" , gap:"8px"}}>
          {
            filterSchema.map(({ options , title} , i) => (
              <Select
                key={i}
                variant="outlined"
                className={classes.select}
                value={filter[title]}
                name={title}
                defaultValue={filter[title]}
                onChange={(e) => setFilter({...filter , [e.target.name]:e.target.value})}
              >
                {
                    options.map(({name , value} , index) =>  <MenuItem key={index} value={value}>{name}</MenuItem> )
                }
              </Select>
            ))
          }
          <TextField
            name="search"
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              className: classes.input_field,
              startAdornment: (
                <InputAdornment color="#546884">
                  <MdSearch color="#546884" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            className={classes.select}
          />
        </div>

        <Button
          classes={{ text: classes.button_text }}
          backgroundColor="#B00610"
          name={"Create User"}
          onClick={() => handleOpen()}
        />
      </div>
      <Table
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        total={100}
        schema={schema }
        collection={collection.data}
        loading={loading.table}
        shortLoading
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
      />
      <Modal open={open} onClose={handleClose}>
          <CreateUser handleClose={handleClose} edit={edit} />
      </Modal>
    </div>
  );
}

import React from 'react';
import { useStyles } from './styles'

const ProfileCard = ({icon , title , info ,color}) => {
    const classes = useStyles(); 
    return (
        <div className={classes.box} >
            <div className={classes.box_section}>
                <p style={{ fontSize:"10px" ,fontFamily:"Quicksand"}}>{title}</p>
                <p style={{color  , fontSize:"18px"}}>{info}</p>
            </div>
            {icon}
        </div>
    )
}

export default ProfileCard
import React, { createContext, useState } from "react";



const initial = {
    selectedTournament:{},
    changeTournament: (tournament) => {
        initial.selectedTournament = tournament
    }
}
export const TournamentContext = createContext(initial);

const  TournamentStateProvider = ({ children }) => {

    const [selectedTournament, setSelectedTournament] = useState({});
  
    return (
      <TournamentContext.Provider
        value={{
          selectedTournament,
          changeTournament: (tournament) => {
            setSelectedTournament(tournament);
          },
        }}
      >
            {children}
      </TournamentContext.Provider>
    );
  }
  
  export default TournamentStateProvider;
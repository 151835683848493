import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0, 1),
        color: "#fff",
        "& h2": {
            fontSize:"22px"
        },
        [theme.breakpoints.down("sm")]:{
            padding: 0,
            boxSizing: "border-box",
            paddingBottom: "16px"
        }
    },
    h3: {
        fontSize: "24px"
    },
    h4: {
        fontSize: "18px"
    },
    p: {
        fontSize: "14px",
        marginTop: "4px",
    },
    muted: {
        fontSize: "14px",
        marginTop: "4px",
        color: "#454545",
    },
    btnRed: {
        padding: "0.8rem",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "700",
        backgroundColor: "#FF0E10",
        width: "20%"
    },
    btnMTN: {
        padding: "0.8rem",
        color: "#000",
        fontSize: "12px",
        fontWeight: "700",
        backgroundColor: "#FFCC08",
        width: "100%"
    },
    btnPrimary: {
        padding: "0.8rem",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "700",
        width: "100%"
    },
    inputText: {
        color: "#fff",
    },
    inputAdorment: {
        color: "rgb(209 246 170)",
    },
    warning: {
        color: "#FF0E10",
    },
    subcriptionBox: {

    },
    subscriptionAmount: {

    },
    walletBalance: {
        width: '400px', // Set the desired width
        margin: 'auto', 
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.10)',
    },
    icon: {
        position: "relative",
        fontSize: "18px",
        top: "4px",
        marginRight: "4px"
    },
    amount: {
        fontWeight: 700,
    },
    subscriptionAmount: {
        width: '400px', // Set the desired width
        margin: 'auto', // Center the modal horizontally
        padding: '20px',
        color: "green",
        fontSize: "28px",
        fontWeight: 700,
        textAlign: "center"
    },
    divider: {
        border: "0.5px solid #888",
        marginTop:"18px",
    },
}));

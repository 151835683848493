import React from 'react'
import { useStyles } from './styles';

const CircleCard = ({image , name , border,  buttonClick, disabled }) => {
    const classes = useStyles();
    return (
        <div onClick={buttonClick} style={{
            width:"fit-content",
            opacity: disabled ? 0.2 : 1,
        }}>
            <div className={classes.container} style={{
                border: `1px solid ${border}`,
              
            }}>
                <div className={classes.img} style={{backgroundImage: `url('${image}')`}}></div>
            </div>
            <p className={classes.text}>{name}</p>
        </div>
    )
}

export default CircleCard;
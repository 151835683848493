import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {  useParams } from 'react-router-dom';
import { CartContext } from '../../context/cartContext';

import { axiosInstance } from '../../helpers/client';
import Button from '../Button/Button';
import ProductItem from './ProductItem';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


export default function ShowProduct() {
    const classes = useStyles();
    const history = useHistory();
    const [product, setProduct] = useState({})
    const [ loading , setLoading ] = useState(true)
    const { productSlug } = useParams();
    const { addItem , items} = useContext(CartContext)


    const get = () => {
        axiosInstance.get(`api/products/${productSlug}?related_products=1` ).then(({data}) => {
            setProduct(data.data)
        }).finally(() => setLoading(false))
    }

    const isProductInCart = () => {
        const arr = [...items];
        const obj = arr.findIndex(el => el.slug === productSlug);

        return obj === -1 ? false : true;
    }

    const handleButton = (product) => {
       

        if (!isProductInCart()) {
            addItem(product)
        } else {
            history.push('/home/cart');
        }

    }

    useEffect(() => get(),[productSlug]);


    return (
        <div className={classes.container}>
           {
            !loading && (
                !isEmpty(product) ? (
                <div>

                    <div className={classes.showSection}>
                        <div className={classes.singleImage} style={{backgroundImage:`url(${product.image})`}}></div>
                        <div className={classes.descriptionSection}>
                            <h1>{product.name}</h1>
                            <p>{product.description}</p>
                            <Button onClick={() => handleButton(product)} name={isProductInCart() ? "Checkout" : "Add to Cart"}  backgroundColor="#B00610" />
                            <div>
                                <div className={classes.extraInfo}>
                                    <p>Vendor</p>
                                    <p>{product?.vendor?.name ?? 'Gamr'}</p>
                                </div>
                                <div className={classes.extraInfo}>
                                    <p>Price</p>
                                    <p>{product.price}</p>
                                </div>
                                <div className={classes.extraInfo}>
                                    <p>Type</p>
                                    <p>{product?.type}</p>
                                </div>
                                <div className={classes.extraInfo}>
                                    <p>Date</p>
                                    <p>{format(new Date( product.created_at) , 'LLLL Y')}</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{marginBottom:"64px"}}>
                        <h3>Related</h3>
                        <div className={classes.listContainer}>
                            {
                                product.related.map((data, i) => {
                                    return (

                                    <ProductItem
                                        key={i}
                                        slug={data.slug}
                                        inStock={data.in_stock}
                                        title={data.name} 
                                        price={data.price}
                                        discount={data.discount_percent}
                                        created={data.created_at}
                                        image={data.image}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                ) : <h3>Not Found</h3>
            )
           }
        </div>
    )
}



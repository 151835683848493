import { Avatar,  TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useStyles } from './styles'
import Button from '../../components/Button/Button';
import {IoGameController , IoTrophy } from 'react-icons/io5';

import { a11yProps, TabPanel } from '../Tournament/Tournament';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TiPlus } from 'react-icons/ti';
import inflection from 'inflection';
import { useHistory, useParams } from 'react-router';
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import Banner from '../../asset/img/profile.png';

import { Skeleton } from '@material-ui/lab';
import MemberCard from '../../components/MemberCard/MemberCard';
import Modal from '../../components/Modal/Modal';
import Invitation from '../../components/Invitation/Invitation';
import TransactionCard from '../../components/TransactionCard/TransactionCard';
import { FaUserPlus } from 'react-icons/fa'
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import { useFormik } from "formik";
import * as Yup from "yup";
import {  MdEdit } from 'react-icons/md';
import CreateTeam from '../../components/CreateTeam/CreateTeam';
import { GiRank3 } from 'react-icons/gi';




export default function TeamProfile() {
    const classes = useStyles(); 
    const history = useHistory(); 
    const [ teamInfo , setTeamInfo ] = useState({
        name: "",
        username:""
    })
    const [wallet, setWallet] = useState({
        amount: 0,
        currency: "USD",
        gc_amount: 0,
      });
    const [ pendingMember , setPendingMember ] = useState([])
    const [value, setValue] = useState(0);
    const { setFeedback , feedback} = useContext(AppContext)
    const { team } = useParams();
    const [ loading , setLoading ] = useState({
        account:false , fund:false , join:false
    })
    const [ isMember , setIsMember ] = useState({member:false , role:"guest"})
    const [ transactions , setTransactions ] = useState([])
    const [ open , setOpen ] = useState({
        invite:false , fund:false , edit:false
    })

    const fundWallet = async () => {
        setLoading({...loading , fund:true})
        await axiosInstance.post(`api/teams/wallet/fund/${team}` , {
            currency:"USD",
            amount: formik.values.amount
        }).then(({data}) => {
            toggleModal('fund' , false)
            setFeedback({message:data.message});

        }).finally(() =>   setLoading({...loading , fund:false}))
    }

    const validationSchema = Yup.object().shape({
        amount: Yup.number().required().positive().integer().label("Amount"),
    });

    const formik = useFormik({
        initialValues: {
            gateway: "paystack",
            amount: "",
        },
        onSubmit: fundWallet,
        validationSchema:validationSchema
    });

    const memberOptions = [
        {
            name: "View",
        },
        {
            name:"Promote",
            action:"change-role",
        },
        {
            name:"Demote",
            action:"change-role",
        },
        {
            name:"Kick",
            action:"remove"
        },
    ]

    const rank = ['member' , 'co-leader' , 'leader'];

    const pendingMemberOptions = [
        {
            name:"View"
        },
        {
            name:"Accept",
            action:"approve",
        },
        {
            name:"Decline",
            action:"remove"
        },
    ]


    const getWalletInfo = async () => {
        await axiosInstance
          .get(`api/teams/wallet/${team}`)
          .then(function ({ data }) {
            setWallet(data.data);
          })
      };


    const toggleModal = ( name , value) => {
        setOpen({...open , [name]:value});
    };
    

    const getTeam = async () => {
        setLoading({...loading , account:true })
        await axiosInstance.get(`api/teams/show/${team}?team_status=1`).then(({data}) => {
            let filteredArray = {...data.data};

            filteredArray.members = filteredArray.members.filter(({member}) => member.role !== 'pending_member');

            if(filteredArray.is_team_member) setIsMember({member: true ,  role: filteredArray.role});
            setTeamInfo(filteredArray)
        }).finally(() =>   setLoading({...loading , account:false , join:false}))
    }

    const getPendingMember = async () => {
        await axiosInstance.get(`api/teams/members/pending/${team}`).then(({data}) => {
            setPendingMember(data.data)
        }).catch(({response}) => console.log(response))
    }

    const requestToJoin = async () => {
        setLoading({...loading , join:true})
        await axiosInstance.post(`api/teams/members/request/${team}`).then(function ({ data }) {
            setFeedback({message:data.message});
          })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"}) 
        }).finally(() =>   setLoading({...loading , join:false}));
    }

    const teamActions = async (action , username = null , role = null) => {
        await axiosInstance.post(`api/teams/members/${action}/${team}` , {
            username,
            role
        }).then(function ({ data }) {
            setFeedback({message:data.message});
          })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"}) 
        })
    }

  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleOption = (name , action , username , role) => {
        let promote = "";
        const index = rank.findIndex((item) => item === role);

        if(name === "Promote")  promote =  rank[index + 1];
        if( name === "Demote")  promote =  rank[index - 1];

        switch(name){
            case "View":
                return history.push(`/home/player/${username}`);
            default:
                return  teamActions(action , username , promote);
        }
    }

    const getTrasactions  = async () => {
        await axiosInstance.get(`api/teams/wallet/transactions/${team}`).then(({data}) => {
            setTransactions(data.data)
        })
    }


    useEffect(() => {
        getTeam()
        if(isMember.member){
            getWalletInfo()
            getPendingMember()
            getTrasactions()
        }
    },[isMember.member ])

    useEffect(() => {

        if(isMember.member){
            getWalletInfo()
            getTrasactions()
            getTeam()
        }
    },[feedback , isMember.member])

    return (
        <div className={classes.container}>
            <div className={classes.header} style={{backgroundImage:`url(${teamInfo.banner ?? Banner})`}}>
                {
                      loading.account
                      ? (
                        <div style={{display:"flex"}}>
                          <Skeleton style={{backgroundColor:"#0F2139",marginRight:"8px"}} variant="circle" width={80} height={80} animation="wave" />
                          <div className={classes.card_info}>
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={160} height={40}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={150} height={20}  animation="wave" />
                          </div>
                        </div>
                      )
                      
                      :
                <div className={classes.profile}>
                    <div className={classes.image_section}>
                        <Avatar src={teamInfo.avatar} alt={teamInfo.name}  className={classes.avatar} />
                        <div className={classes.name_section}>
                            <h2>{inflection.titleize(teamInfo.name)}</h2>
                            <p> {teamInfo.location}</p>
                        </div>
                    </div>
                    {
                        isMember.member ? (
                            <div>
                                {
                                   (teamInfo && teamInfo.is_leader) &&
                                    <span><MdEdit onClick={() => toggleModal('edit', true)}/></span>
                                }
                                <p>{wallet.currency} {wallet.amount}</p>
                                <p>GC {wallet.gc_amount}</p>
                            </div>
                        ) : null
                    }
                   
                </div>
                }
                <div className={classes.button_section}>
                    <div className={classes.button_section_right}>
                        {
                            (isMember.member ) && 
                            <>
                                {
                                     teamInfo.is_leader && 
                                    <Button 
                                        name="Invite" 
                                        startIcon={<FaUserPlus size={15} />}
                                        onClick={() => toggleModal('invite' , true)} /> 
                                }
                                <Button 
                                    name="Fund" 
                                    backgroundColor="#B00610" 
                                    startIcon={<TiPlus size={15} />}
                                    onClick={() => toggleModal('fund' , true)} /> 
                            </>
                        }
                    </div>
                    {
                        !isMember.member && 

                        <Button 
                            loading={loading.join}
                            name="Join" 
                            style={{marginLeft:"8px" , backgroundColor:"#B00610"}}
                            startIcon={<TiPlus size={15} />}
                            onClick={() => requestToJoin()} /> 
                    }
                </div>
            </div>

            <div className={classes.trophy_section}>
                {
                    loading.account ? 
                    <>
                    <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={340} height={130}  animation="wave" />
                    <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={340} height={130}  animation="wave" />
                    </>

                    :
                    <>
                    <ProfileCard  title="Tournaments played" info={teamInfo.team_stats?.tournaments_played} color="#6EC5E9" icon={<IoGameController color="#546884" size={50}/>} />
                    <ProfileCard title="Wins" info={teamInfo.team_stats?.tournament_wins} color="#E8454E" icon={<IoTrophy color="#546884" size={50}/>} />
                    <ProfileCard title="Level" color="#DD9F3A" info={teamInfo.team_stats?.rating} icon={<GiRank3 color="#546884" size={50}/>} />
                    </>
                }
            </div>

            <div>
                <Tabs value={value} onChange={handleChange}  variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab className={classes.tab_label} label="Members" {...a11yProps(0)} />
                  
                   
                    <Tab className={classes.tab_label} label="Stats" {...a11yProps(1)} />
                    {
                        isMember.member && <Tab className={classes.tab_label} label="Pending Request" {...a11yProps(2)} />
                    }
                    {    
                        isMember.member &&  <Tab className={classes.tab_label} label="Transactions" {...a11yProps(3)} />
                    }
                </Tabs>
            </div>
            <TabPanel value={value} index={0}> 
                <div className={classes.members_section}>   
                    {
                        teamInfo.members && teamInfo.members.map(({avatar , username , display_name , member , joined }, index) => (
                            <MemberCard 
                                key={index}
                                handleOption={handleOption} 
                                avatar={avatar} 
                                joined={joined} 
                                display_name={display_name}
                                username={username} 
                                role={member?.role} 
                                options={memberOptions}
                                isMember={isMember}
                                />
                        ))
                    }
                </div>         
            </TabPanel>
            <TabPanel value={value} index={1}>
                <p>No Stats to Display</p>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className={classes.members_section}>   
                    {
                        pendingMember && pendingMember.map(({avatar , username , member , joined ,display_name} , index) => (
                            <MemberCard key={index} handleOption={handleOption} display_name={display_name} avatar={avatar} joined={joined} username={username} role={member?.role} options={pendingMemberOptions}  isMember={isMember}/>
                        ))
                    }
                </div> 
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div style={{display:"flex", gap:"8px" , flexWrap:"wrap" , padding:"8px 0px"}}>
                        {
                            transactions.length < 1 ? <p> No Transactions</p> :
                            transactions.map((item , index) => 
                            <TransactionCard
                                key={index}
                                amount={item.amount} 
                                comment={item.comment}
                                currency={item.currency} 
                                type={item.transaction_type} 
                                paymentMethod={item.payment_method} 
                                isCredit={item.is_credit} 
                                status={item.status} 
                                reference={item.transaction_reference} 
                                date={item.transaction_date} />
                            )
                        }  
                    </div>
            </TabPanel>
           
            <Modal open={open.invite} onClose={() => toggleModal('invite' , false)}>
                <Invitation slug={team} type="team"/>
            </Modal>
            <Modal open={open.edit} onClose={() => toggleModal('edit' , false)}>
                <CreateTeam edit={teamInfo} handleClose={() => toggleModal('edit' , false)}/>
            </Modal>
            <Modal open={open.fund} onClose={() => toggleModal('fund' , false)}>
                <div className={classes.tab_section}>
                    <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "8px 0px",
                    }}
                    >
                    <TextField
                        required
                        placeholder="Amount"
                        variant="outlined"
                        name="amount"
                        type="number"
                        onChange={formik.handleChange}
                    />
                        { formik.errors.amount ? (
                                    <span className={classes.content__form_error}>{formik.errors.amount}</span>
                                ) : null}
                    <Button
                        classes={{ text: classes.button_text }}
                        name="Continue"
                        loading={loading.fund}
                        backgroundColor="#B00610"
                        className={classes.create_button}
                        onClick={formik.handleSubmit}
                    />
                    </div>            
                </div>
            </Modal>
        </div>
    )
}

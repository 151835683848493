import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  container:{
    minHeight:"100vh",
    display:"flex",
    background: "linear-gradient( #0e1028 , #070515)",
    flexDirection:"column",
    width:"100%",
    color:"#fff"
  },
  login_section:{
    flex: "1 1 auto",
    maxWidth:"420px",
    "& h2": {
      marginBottom:0,
      fontSize:"30px"
    },
    [theme.breakpoints.down("sm")]:{
      width:"300px",
      padding:theme.spacing(0,1)
    },
  },
  info:{
    margin: theme.spacing(1,0),
  },
    info_top:{
      margin: theme.spacing(2 , 0)
    },
    title:{
      marginBottom: theme.spacing(1)
    },
    
    icons:{
        display:"flex",
        margin: theme.spacing(2,0),
        gap:theme.spacing(2),
        alignItems:"center"
    },
    input:{
      display:"flex",
      flexDirection:"column",
      gap:theme.spacing(1),
  },
  input_container:{
    display:"flex",
    flexDirection:"column",
    '& .Mui-disabled':{
      color:"white"
    },
    gap:theme.spacing(0.5),
},
    content__form_error:{
      fontSize:"12px",
      color:"red",
    },
    textfield:{
        borderRadius:"8px",
        color:"#fff",
        '& label.Mui-focused': {
            color: 'white',
          },
          "& .MuiInputBase-root ":{
              color:"white"
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#5A79A5',
            },
            '&:hover fieldset': {
              borderColor: '#5A79A5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
    },
    join:{
        marginTop:theme.spacing(2),
    } ,
    button_text:{
      padding:theme.spacing(1.5 , 2)
    }

}));

import React, { useContext, useEffect, useState} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useStyles } from './styles';
import { useHistory, useLocation } from 'react-router';
import { GiRank3 } from 'react-icons/gi'
import { MdHome, MdRssFeed } from "react-icons/md";
import { TournamentContext } from '../../context/tournamentContext';
import { axiosInstance } from '../../helpers/client';
import { AiOutlineShoppingCart} from 'react-icons/ai';
import { BiVideoRecording } from 'react-icons/bi';
import { FaUser, FaCrown, FaRegUser } from 'react-icons/fa';
import { IoGameControllerOutline, IoGameController, IoTrophy } from 'react-icons/io5';
import { t } from 'i18next';


export default function BottomNav() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { selectedTournament } = useContext(TournamentContext)
  const [ _ , setTournamentSlug ] = useState("")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const getSingleTournament = async() => {
    await axiosInstance.get(`api/tournaments` ).then(function ({data}) {
        setTournamentSlug(data.data[0]?.slug ?? "")
    });
  }

  const links = [
    {
      name: "home",
      icon: <MdHome size={25} />,
      route: "/home",
    },
    {
      name: "Gamr Pro",
      icon: <FaCrown size={25} />,
      route: "/home/subscription",
    },
    {
      name: "tournament",
      icon: <IoTrophy  size={25} />,
      route: `/home/tournament`,
    },
    {
      name: "feed",
      icon: <MdRssFeed size={25} />,
      route: "/home/forum",
    },
    {
      name: "shop",
      icon: <AiOutlineShoppingCart size={25} />,
      route: "/home/market"
    },
    {
      name: "Play",
      icon: <IoGameControllerOutline size={25} />,
      route: "/home/online",
    },
  ];

  const active = links.filter((link) => link.route === location.pathname || location.pathname.split('/')[2] == link.name.toLowerCase())[0]?.name
  const [value, setValue] = useState(active);


  useEffect(() => {
    getSingleTournament()
  },[])
   
  useEffect(() => {
      setTournamentSlug(selectedTournament.slug)
  },[selectedTournament])

    useEffect(() => {
        setValue(active)
    }, [active])

  return (
    <BottomNavigation value={value} onChange={handleChange} className={classes.bottomnav}>
        {
            links.map(({icon , name , route}, index) =>  <BottomNavigationAction key={index} onClick={() => history.push(`${route}`)} label={t(name)} value={name} icon={icon} />)
        }
    </BottomNavigation>
  );
}
import React, { useEffect, useState } from 'react';
import GoogleMap from 'google-map-react';
import MapMarker from '../MapMarker/MapMarker';
import { mapStyle } from './styles';
import { axiosInstance } from '../../helpers/client';



const SimpleMap = (props) => {

    const [center , setCenter] = useState({
        lat: 6.4279911 , lng: 3.4085764
    });
    const [event , setEvent ] = useState([])
    const [tournaments , setTournaments ] = useState([])

    const onChildClick = (key, {lat , lng}) => {
        setCenter({lat: Number(lat) , lng: Number(lng)});
        setEvent(
            event.map((task ,index ) => {
                if (key === index.toString()) {
                task.show = !task.show;
                }

            return task;
        }));
    }

    const boundsChange = (center , zoom) => {
        setCenter(center);
    }

    const getEvents = async () => {
        await axiosInstance.get("api/events").then(function ({ data }) {
            const obj = data.data;
            const newArr = obj.map((item) => ({...item, show:false}));
            const filteredArray = [];
            // sort events to only show the one event for a particular location
            // if there are two events in a location , it'll show the latest one
            newArr.forEach((event) => {
                const { id , lat , lng } = event;
                for(var i = 0; i < newArr.length; i++) {
                    if (newArr[i].lng === lng && newArr[i].lat === lat) {
                        filteredArray.push(id > newArr[i].id ? event : newArr[i])
                    }
                }
            })
            const unique = filteredArray.filter((value , index , self) => self.indexOf(value) === index)
            setEvent(unique);
            const location = {};
            location.lat = Number(newArr[0].lat);
            location.lng = Number(newArr[0].lng);

            setCenter(location)
        })
        .catch(({ response }) => console.log(response));
    };

    console.log(props);

    useEffect(() => {
        // getEvents();
        // getTournaments();
    }, [])

    const key = process.env.REACT_APP_GOOGLE_API_KEY;

    return (
        <div>
            {
                tournaments && (
                    <div style={{ height: '50vh', width: '100%', background: `url(${props.tournament.banner})`, backgroundPosition: 'center', backgroundSized: 'contain', }}></div>
                )
            }
        </div>
    );
}

export default SimpleMap;

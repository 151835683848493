import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(0, 4),
        color:"#fff",
        "& h2": {
            fontSize:"22px"
        }
    },

   
}));

import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { get } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import inflection from "inflection";
import { IconButton, MenuItem, Select } from '@material-ui/core';
import {  MdEdit } from 'react-icons/md';
import { VscDebugRestart } from 'react-icons/vsc';
import { BsStopFill , BsPlayFill } from 'react-icons/bs'
import Modal from "../../../components/Modal/Modal";
import ManageScores from "./ManageScores";
import { handlePage } from "../../../helpers/paginator";
import { t } from "i18next";



export default function Matches() {
  const classes = useStyles();
  const [selectedTournament, setSelectedTournament] = useState("");
  const [edit, setEdit] = useState({});
  const [ collection , setCollection] = useState({
    data:[] , links:{}
})
  const [page, setPage] = useState(0);
  const [rows, setRowsPerPage] = useState(5);
  const [endpoint , setEndpoint ] = useState()
  const [ open , setOpen ] = useState(false);
  const [ match , setMatch ] = useState({});
  const [ tournaments , setTournaments ] = useState([])
  const [ loading , setLoading ] = useState(false);

  const { setFeedback , feedback } = useContext(AppContext);

  const handleChange = (event) => {
    setSelectedTournament(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit({})
  };

  const handleUpdate = (data) => {
    setMatch(data)
    handleOpen()
  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getTournaments = async () => {
    const url = new URL(`${process.env.REACT_APP_HTTPS_ENDPOINT}api/tournaments/user`);
  
  const params = {
    "sort[field]": "date_started",
      "sort[direction]": "ASC",
  };
  Object.keys(params)
      .forEach(key => url.searchParams.append(key, params[key]));
    await axiosInstance.get(url ).then(function ({ data }) {
        setTournaments(data.data);
        setSelectedTournament(data.data[0].slug)
    })
    .catch(({ response }) => console.log(response));
};

  const handleMatches = async (type , slug) => {
    await axiosInstance.post(`api/matches/${type}/${slug}`).then(function ({ data }) {
        setFeedback({message: data.message});
      })
      .catch(({ response }) => {
          setFeedback({ message: response.data.message, severity: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNextMatch = ( data , player) => {
    if(data[player]?.data?.display_name != null) return data[player]?.data?.display_name;

    const previosLoser = `${player}_is_prereq_match_loser`;
    const previosMatch = `${player}_prereq_match_id`;
    const matchType = data.match[previosLoser] ? "Loser of" : "Winner of";
    const matchData = collection.data.filter((item) => item?.data?.match?.id == data?.match[previosMatch])

    return `${matchType} ${matchData[0]?.data?.match?.identifier}`
  }


  const getMatches = async () => {
    await axiosInstance
      .get(`api/matches/${selectedTournament}`)
      .then(function ({ data }) {
        setCollection(data)
      })
      .catch(({ response }) => console.log(response));
  };

  const schema = [
    {
      label: "ID",
      func: ({ item }) => {
          const data = get(item, "data") ;
          return data?.match?.identifier 
      },
    },
    {
        label: "Player 1",
        func: ({ item }) => {
            const data = get(item, "data");
            return getNextMatch(data , 'player1')
        },
    },
    {
        label: "Player 2",
        func: ({ item }) => {
            const data = get(item, "data") ;
            return getNextMatch(data , 'player2')
        },
    },
    {
        label: t("status"),
        func: ({ item }) => get(item, "status")
    },
    {
        label: t("scores"),
        func: ({ item }) => {
            const data = get(item, "data") ;
            return data.match.scores_csv
        }
      },

    {
      label: t("actions"),
      func: ({ item }) =>  {
        const slug = get(item , 'slug');
       
            return (
            <div style={{ display:"flex" , gap:"8px" }}>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdEdit size={25} onClick={() => handleUpdate(item)}  />
              </IconButton>
              <IconButton color="inherit" style={{padding:"0"}}>
                <VscDebugRestart size={25} onClick={() => handleMatches('re-open' , slug)}  />
              </IconButton>
              <IconButton color="inherit" style={{padding:"0"}}>
                {
                  get(item , 'data').match.underway_at === null ? 
                  <BsPlayFill size={25} onClick={() => handleMatches('mark-underway',slug)}  />
                  :
                  <BsStopFill size={25} onClick={() => handleMatches('unmark-underway' , slug)}  />
                }
              </IconButton>
            </div>
          )
        }
    },
  ];
  useEffect(() => {
    getTournaments();
  }, []);

  useEffect(() => {
    getMatches()
  },[ selectedTournament , feedback])


  return (
    <div className={classes.container}>
      <h2>{t("manage_matches")}</h2>
      <Select
            variant="outlined"
            className={classes.select}
            value={selectedTournament}
            defaultValue={selectedTournament}
            onChange={handleChange}
        >
            {
                tournaments.map(({slug , name}, i) => <MenuItem key={i} value={slug}>{inflection.titleize(name)}</MenuItem>)
            }
        </Select>
        <Table   
            handlePrev={() => handlePage(setEndpoint ,collection , 'prev')} 
            handleNext={() => handlePage(setEndpoint ,collection , 'next')} 
            total={100} 
            schema={schema}  
            collection={collection.data} 
            links={collection.links} 
            paginate={false}
            page={page} 
            handleChangeRowsPerPage={handleChangeRowsPerPage} 
            rowsPerPage={rows}/>
        <Modal open={open} onClose={handleClose}>
          <ManageScores handleClose={handleClose} slug={match?.slug} player1={match?.data?.player1} player2={match?.data?.player2}  />
      </Modal>
    </div>
  );
}

import { Skeleton } from '@material-ui/lab';
import inflection from 'inflection';
import React, { useEffect, useState } from 'react'
import Feed from '../../components/Feed/Feed';
import { axiosInstance } from '../../helpers/client';
import { useStyles } from './styles';


export default function FeedPage() {
    const classes = useStyles();
    const [ feed , setFeed ] = useState([])
    const [ loading , setLoading ] = useState(false)

    const feedLoader = [ 1 , 2 , 3];

    const getSubscriptions = async () => {
        setLoading(true)
        await axiosInstance.get('api/feeds').then(function ({ data }) {
            setFeed(data.data);
          })
          .catch(({ response }) => console.log(response))
          .finally(() => setLoading(false))
      };

      useEffect(() => {
        getSubscriptions()
      },[])

    return (
        <div className={classes.container} >
            <h2>Feeds</h2>
            {
                loading ? 

                feedLoader.map((_ , index) => 
                    <div style={{display:"flex" , width:"100%"}} key={index}>
                        <Skeleton style={{backgroundColor:"#0F2139",marginRight:"8px"}} variant="circle" width={50} height={50} animation="wave" />
                        <div className={classes.card_info}>
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={100} height={20}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={150} height={20}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect"  height={100}  width={700}  animation="wave" />
                        </div>
                    </div>
                ) 

                :
                (
                    feed.length > 0 ? (

                        feed.map(({ created , user, content , action} , index) => 
                            <Feed key={index} image={user?.avatar} date={created} name={inflection.titleize(action)} content={content}/>
                        )
                    ) : (
                        <p>Follow players or participate in tournaments to get feeds</p>
                    )
                )

            }
        </div>
    )
}

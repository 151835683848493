import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:theme.spacing(0, 5),
        color:"#fff",
       
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(1)
        }
        
    },
   
    info_skeleton_img:{
        height:"120px",
        width:"120px",
      [theme.breakpoints.down("xs")]: {
        height:"90px",
        width:"90px",
      },
    },
    textfield:{
        flex: '1 1 0',
        width:'500px',
        borderRadius:'10px',
        backgroundColor:"rgba(217, 217, 217, 0.05)",
        border:"none",
        [theme.breakpoints.down("sm")]: {
            width:"320px",
          },


    },
    search:{
        display:"flex",
        flexDirection:'column',
        alignItems:"center",
        margin:theme.spacing(6)
    },
    backImg:{
        height:"25vh",
        backgroundSize:"contain",
        backgroundPosition:"center",
        width:"100%",
        marginBottom:"40px"
    },
    feedList:{
        display:'flex',
        flexDirection:'column',
        gap:'10px',
    },
    tagSection:{
        display:"flex",
        alignSelf:"center",

        gap:'8px',
    },
    pagination:{
        margin: theme.spacing(3 , 0),
        display:'flex',
        gap:"8px"
    },
    title:{
       fontSize:"40px"
    },
    select:{
        color:"#5A79A5",
        width:"140px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#5A79A5"
          },
          "& .MuiOutlinedInput-input" :{
            padding: "12px 16px"
        }
    },
    img:{
        minWidth:"50px",
        height:"50px",  
        marginRight: theme.spacing(1.5),
    },
    commentSection:{
        gap:"16px",
        display:'flex',
        flexDirection:"column",
        margin: theme.spacing(4 , 0)
    },
    description:{
        lineHeight:1.75
    },
    name:{
        color:"#546884",
        fontSize:'14px',
        marginBottom:"4px"
    },
    time:{
        fontSize:"10px"
    },
    filter:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
    },
    line:{
        borderLeft: '1px solid   rgba(242, 242, 243, 0.18)',
        height: '100%',
        marginLeft:"50%"

    },
    reply:{
        color:" #546884",
        fontSize:"14px",
        cursor:'pointer'
    }
    ,
    topicContainer:{
        flex: '1 1 0',
        padding: theme.spacing(4),
        display:'flex',
        gap:"24px",
        justifyContent:"center",
        cursor:"pointer",
        borderRadius:'10px',
        backgroundColor:"rgba(217, 217, 217, 0.05)",
    },
    replied:{
        flex: '1 1 0',
        padding: theme.spacing(1),
        cursor:"pointer",
        fontSize:"12px",
        borderRadius:'10px',
        background: "rgba(0, 0, 0, 0.11)",
        display: '-webkit-box',
        maxWidth: '100%',
        margin: '8px auto',
        color: 'rgba(255, 255, 255, 0.5)',
        WebkitBoxOrient:"vertical",
        WebkitLineClamp:'2',
        overflow: "hidden",
        lineHeight: 1.75,
        textOverflow:"ellipsis",
        textDecoration:"none"
    },
    topicInfo:{
        display:'flex',
        flexGrow:1,
        flexDirection:"column",
        gap:"16px"
    },
    shareGradient:{
        // background:"linear-gradient(135deg, #FD6585 0%, #0D25B9 100%)",
        height:"150px",
        display:'flex',
        justifyContent:'center',
        backgroundSize:"cover",
        alignItems:"flex-end"
    },
    copySection:{
        display:"flex",
        width:"100%",
        justifyContent:'space-between',
        margin:"16px 0",
    },
    copyInfo:{
        background: `rgba(255, 255, 255, 0.02)`,
        borderRadius:'10px',
        gap:'16px',
        display:'flex',
        padding:"8px",
    },
    shareTitle:{
        fontSize:'14px',
        fontWeight:700
    },
    shareDescription:{
        fontSize:'12px',

    },
    details:{
        overflow:"hidden",
        whiteSpace: "nowrap",
        textOverflow:"ellipsis",
        width:"160px"
    },
    iconSection:{
        display:'flex',
        justifyContent:'space-between',
        margintop:"12px "
    },
    replySection:{
        [theme.breakpoints.down("sm")]:{

            marginBottom:"80px"
        }
    
    },
    topicImg:{
        minWidth:"64px",
        height:"64px",  
    },
    topicDescription:{
        fontSize:"16px"
    },
    topicTitle:{
        fontSize:"24px",
        fontWeight:700
    },
    topicCount:{
        fontSize:"12px",
        alignSelf:"center",
        color: '#546884'
    }
}));

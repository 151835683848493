import React from 'react'
import NavBar from '../../components/NavBar/NavBar'
import { useStyles } from './styles'

export default function PrivacyPolicy() {
    const classes = useStyles()
  return (
      <>
        <NavBar />
        <div className={classes.container}>
            <ol className={classes.list}>
            <h1>PRIVACY POLICY</h1>
<p dir="ltr">
    Effective date: 02/01/2022
</p>
<p dir="ltr">
    Updated: 4/3/2024
</p>
<br/>
<h2 dir="ltr">
    Introduction
</h2>
<p dir="ltr">
    Welcome to Gamr Inc. Gamr Inc. (“us”, “we”, or “our”) operates
    https://gamr.africa and Gamr mobile application (hereinafter referred to as
    “Service”). Our Privacy Policy governs your visit to https://gamr.africa and
    Gamr mobile application, and explains how we collect, safeguard and disclose
    information that results from your use of our Service. We use your data to
    provide and improve Service. By using Service, you agree to the collection
    and use of information in accordance with this policy. Unless otherwise
    defined in this Privacy Policy, the terms used in this Privacy Policy have
    the same meanings as in our Terms and Conditions. Our Terms and Conditions
    (“Terms”) govern all use of our Service and together with the Privacy Policy
    constitutes your agreement with us (“agreement”).
</p>
<br/>
<h2 dir="ltr">
    Definitions
</h2>
<p dir="ltr">
    SERVICE means the https://gamr.africa website and Gamr mobile application
    operated by Gamr Inc
</p>
<br/>
<p dir="ltr">
    PERSONAL DATA means data about a living individual who can be identified
    from those data (or from those and other information either in our
    possession or likely to come into our possession).
</p>
<br/>
<p dir="ltr">
    USAGE DATA is data collected automatically either generated by the use of
    Service or from Service infrastructure itself (for example, the duration of
    a page visit)
</p>
<br/>
<p dir="ltr">
    COOKIES are small files stored on your device (computer or mobile device).
</p>
<br/>
<p dir="ltr">
    DATA CONTROLLER means a natural or legal person who (either alone or jointly
    or in common with other persons) determines the purposes for which and the
    manner in which any personal data are, or are to be, processed. For the
    purpose of this Privacy Policy, we are a Data Controller of your data.
</p>
<br/>
<p dir="ltr">
    DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who
    processes the data on behalf of the Data Controller. We may use the services
    of various Service Providers in order to process your data more effectively
</p>
<br/>
<br/>
<br/>
<br/>
<p dir="ltr">
    DATA SUBJECT is any living individual who is the subject of Personal Data.
</p>
<br/>
<p dir="ltr">
    THE USER is the individual using our Service. The User corresponds to the
    Data Subject, who is the subject of Personal Data.
</p>
<br/>
<h2 dir="ltr">
    Information Collection and Use
</h2>
<p dir="ltr">
    We collect several different types of information for various purposes to
    provide and improve our Service to you.
</p>
<br/>
<h2 dir="ltr">
    Types of Data Collected
</h2>
<p dir="ltr">
    Personal Data
</p>
<p dir="ltr">
    While using our Service, we may ask you to provide us with certain
    personally identifiable information that can be used to contact or identify
    you (“Personal Data”). Personally identifiable information may include, but
    is not limited to:
</p>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Email address
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            First name and last name
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Phone number
        </p>
    </li>
</ul>
<br/>
<p dir="ltr">
    We may use your Personal Data to contact you with newsletters, marketing or
    promotional materials and other information that may be of interest to you.
    You may opt out of receiving any, or all, of these communications from us by
    following the unsubscribe link
</p>
<br/>
<h2 dir="ltr">
    Usage Data
</h2>
<p dir="ltr">
    We may also collect information that your browser sends whenever you visit
    our Service or when you access Service by or through a mobile device (“Usage
    Data”).
</p>
<br/>
<p dir="ltr">
    This Usage Data may include information such as your computer's Internet
    Protocol address (e.g. IP address), browser type, browser version, the pages
    of our Service that you visit, the time and date of your visit, the time
    spent on those pages, unique device identifiers and other diagnostic data.
</p>
<br/>
<p dir="ltr">
    When you access Service with a mobile device, this Usage Data may include
    information such as the type of mobile device you use, your mobile device
    unique ID, the IP address of your mobile device, your mobile operating
    system, the type of mobile Internet browser you use, unique device
    identifiers and other diagnostic data.
</p>
<br/>
<h2 dir="ltr">
    Location Data
</h2>
<p dir="ltr">
    We may use and store information about your location if you give us
    permission to do so (“Location Data”). We use this data to provide features
    of our Service, to improve and customize our Service.
</p>
<br/>
<p dir="ltr">
    You can enable or disable location services when you use our Service at any
    time by way of your device settings.
</p>
<br/>
<h2 dir="ltr">
    Tracking Cookies Data
</h2>
<p dir="ltr">
    We use cookies and similar tracking technologies to track the activity on
    our Service and we hold certain information.
</p>
<br/>
<p dir="ltr">
    Cookies are files with a small amount of data which may include an anonymous
    unique identifier. Cookies are sent to your browser from a website and
    stored on your device. Other tracking technologies are also used such as
    beacons, tags and scripts to collect and track information and to improve
    and analyze our Service.
</p>
<br/>
<p dir="ltr">
    You can instruct your browser to refuse all cookies or to indicate when a
    cookie is being sent. However, if you do not accept cookies, you may not be
    able to use some portions of our Service.
</p>
<br/>
<p dir="ltr">
    Examples of Cookies we use:
</p>
<br/>
<p dir="ltr">
    Session Cookies: We use Session Cookies to operate our Service.
</p>
<p dir="ltr">
    Preference Cookies: We use Preference Cookies to remember your preferences
    and various settings.
</p>
<p dir="ltr">
    Security Cookies: We use Security Cookies for security purposes.
</p>
<p dir="ltr">
    Advertising Cookies: Advertising Cookies are used to serve you with
    advertisements that may be relevant to you and your interests.
</p>
<br/>
<h2 dir="ltr">
    Use of Data
</h2>
<p dir="ltr">
    Gamr Inc. uses the collected data for various purposes:
</p>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            to provide and maintain our Service;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to notify you about changes to our Service;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to allow you to participate in interactive features of our Service
            when you choose to do so;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to provide customer support;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to gather analysis or valuable information so that we can improve
            our Service;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to monitor the usage of our Service;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to detect, prevent and address technical issues;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to fulfill any other purpose for which you provide it;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to provide you with notices about your account and/or subscription,
            including expiration and renewal notices, email-instructions, etc.;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            to provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            in any other way we may describe when you provide the information
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            for any other purpose with your consent
        </p>
    </li>
</ul>
<br/>
<h2 dir="ltr">
    Retention of Data
</h2>
<p dir="ltr">
    We will retain your Personal Data only for as long as is necessary for the
    purposes set out in this Privacy Policy. We will retain and use your
    Personal Data to the extent necessary to comply with our legal obligations
    (for example, if we are required to retain your data to comply with
    applicable laws), resolve disputes, and enforce our legal agreements and
    policies.
</p>
<br/>
<p dir="ltr">
    We will also retain Usage Data for internal analysis purposes. Usage Data is
    generally retained for a shorter period, except when this data is used to
    strengthen the security or to improve the functionality of our Service, or
    we are legally obligated to retain this data for longer time periods.
</p>
<br/>
<h2 dir="ltr">
    Transfer of Data
</h2>
<p dir="ltr">
    Your information, including Personal Data, may be transferred to – and
    maintained on – computers located outside of your state, province, country
    or other governmental jurisdiction where the data protection laws may differ
    from those of your jurisdiction.
</p>
<br/>
<p dir="ltr">
    If you are located outside the United States and choose to provide
    information to us, please note that we transfer the data, including Personal
    Data, to the United States and process it there.
</p>
<br/>
<p dir="ltr">
    Your consent to this Privacy Policy followed by your submission of such
    information represents your agreement to that transfer.
</p>
<br/>
<p dir="ltr">
    Gamr Inc. will take all the steps reasonably necessary to ensure that your
    data is treated securely and in accordance with this Privacy Policy and no
    transfer of your Personal Data will take place to an organisation or a
    country unless there are adequate controls in place including the security
    of your data and other personal information.
</p>
<br/>
<h2 dir="ltr">
    Disclosure of Data
</h2>
<p dir="ltr">
    We may disclose personal information that we collect, or you provide:
</p>
<br/>
<h2 dir="ltr">
    Disclosure for Law Enforcement.
</h2>
<p dir="ltr">
    Under certain circumstances, we may be required to disclose your Personal
    Data if required to do so by law or in response to valid requests by public
    authorities.
</p>
<br/>
<h2 dir="ltr">
    Business Transaction.
</h2>
<p dir="ltr">
    If we or our subsidiaries are involved in a merger, acquisition or asset
    sale, your Personal Data may be transferred.
</p>
<br/>
<p dir="ltr">
    Other cases. We may disclose your information also:
</p>
<p dir="ltr">
    to our subsidiaries and affiliates;
</p>
<br/>
<p dir="ltr">
    Security of Data
</p>
<p dir="ltr">
    The security of your data is important to us but remember that no method of
    transmission over the Internet or method of electronic storage is 100%
    secure. While we strive to use commercially acceptable means to protect your
    Personal Data, we cannot guarantee its absolute security.
</p>
<br/>
<p dir="ltr">
    Your Data Protection Rights Under General Data Protection Regulation (GDPR)
</p>
<br/>
<p dir="ltr">
    If you are a resident of the European Union (EU) and European Economic Area
    (EEA), you have certain data protection rights, covered by GDPR. – See more
    at https://eur-lex.europa.eu/eli/reg/2016/679/oj
</p>
<br/>
<p dir="ltr">
    We aim to take reasonable steps to allow you to correct, amend, delete, or
    limit the use of your Personal Data.
</p>
<br/>
<p dir="ltr">
    If you wish to be informed what Personal Data we hold about you and if you
    want it to be removed from our systems, please email us at
    legal@gamr.africa.
</p>
<br/>
<p dir="ltr">
    In certain circumstances, you have the following data protection rights:
</p>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            the right to access, update or to delete the information we have on
            you;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            the right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            the right to object. You have the right to object to our processing
            of your Personal Data
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            the right of restriction. You have the right to request that we
            restrict the processing of your personal information;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            the right to data portability. You have the right to be provided
            with a copy of your Personal Data in a structured, machine-readable
            and commonly used format;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            the right to withdraw consent. You also have the right to withdraw
            your consent at any time where we rely on your consent to process
            your personal information;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not be able to
            provide Service without some necessary data.
        </p>
    </li>
</ul>
<br/>
<p dir="ltr">
    You have the right to complain to a Data Protection Authority about our
    collection and use of your Personal Data. For more information, please
    contact your local data protection authority in the European Economic Area
    (EEA).
</p>
<br/>
<p dir="ltr">
    Your Data Protection Rights under the California Privacy Protection Act
    (CalOPPA)
</p>
<br/>
<p dir="ltr">
    CalOPPA is the first state law in the nation to require commercial websites
    and online services to post a privacy policy. The law’s reach stretches well
    beyond California to require a person or company in the United States (and
    conceivable the world) that operates websites collecting personally
    identifiable information from California consumers to post a conspicuous
    privacy policy on its website stating exactly the information being
    collected and those individuals with whom it is being shared, and to comply
    with this policy. – See more at:
    <a
        href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-%20act-caloppa-3/"
    >
        https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-%20act-caloppa-3/
    </a>
</p>
<br/>
<p dir="ltr">
    According to CalOPPA we agree to the following:
</p>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            users can visit our site anonymously;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            our Privacy Policy link includes the word “Privacy”, and can easily
            be found on the page specified above on the home page of our
            website;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            users will be notified of any privacy policy changes on our Privacy
            Policy Page;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            users are able to change their personal information by emailing us
            at legal@gamr.africa.
        </p>
    </li>
</ul>
<br/>
<p dir="ltr">
    Our Policy on “Do Not Track” Signals:
</p>
<br/>
<p dir="ltr">
    We honor Do Not Track signals and do not track, plant cookies, or use
    advertising when a Do Not Track browser mechanism is in place. Do Not Track
    is a preference you can set in your web browser to inform websites that you
    do not want to be tracked.
</p>
<br/>
<p dir="ltr">
    You can enable or disable Do Not Track by visiting the Preferences or
    Settings page of your web browser.
</p>
<br/>
<h2 dir="ltr">
    Service Providers
</h2>
<p dir="ltr">
    We may employ third party companies and individuals to facilitate our
    Service (“Service Providers”), provide Service on our behalf, perform
    Service-related services or assist us in analysing how our Service is used.
</p>
<br/>
<p dir="ltr">
    These third parties have access to your Personal Data only to perform these
    tasks on our behalf and are obligated not to disclose or use it for any
    other purpose.
</p>
<br/>
<h2 dir="ltr">
    Analytics
</h2>
<p dir="ltr">
    We may use third-party Service Providers to monitor and analyze the use of
    our Service.
</p>
<br/>
<h2 dir="ltr">
    Google Analytics
</h2>
<p dir="ltr">
    Google Analytics is a web analytics service offered by Google that tracks
    and reports website traffic. Google uses the data collected to track and
    monitor the use of our Service. This data is shared with other Google
    services. Google may use the collected data to contextualise and personalise
    the ads of its own advertising network.
</p>
<br/>
<p dir="ltr">
    For more information on the privacy practices of Google, please visit the
    Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
</p>
<br/>
<p dir="ltr">
    We also encourage you to review Google's policy for safeguarding your data:
    https://support.google.com/analytics/answer/6004245.
</p>
<br/>
<h2 dir="ltr">
    Firebase
</h2>
<p dir="ltr">
    Firebase is an analytics service provided by Google Inc.
</p>
<br/>
<p dir="ltr">
    You may opt-out of certain Firebase features through your mobile device
    settings, such as your device advertising settings or by following the
    instructions provided by Google in their Privacy Policy:
    https://policies.google.com/privacy?hl=en
</p>
<br/>
<p dir="ltr">
    For more information on what type of information Firebase collects, please
    visit the Google Privacy Terms web page:
    https://policies.google.com/privacy?hl=en
</p>
<br/>
<h2 dir="ltr">
    CI/CD tools
</h2>
<p dir="ltr">
    We may use third-party Service Providers to automate the development process
    of our Service.
</p>
<br/>
<br/>
<h2 dir="ltr">
    GitHub
</h2>
<p dir="ltr">
    GitHub is provided by GitHub, Inc.
</p>
<br/>
<p dir="ltr">
    GitHub is a development platform to host and review code, manage projects,
    and build software.
</p>
<br/>
<p dir="ltr">
    For more information on what data GitHub collects for what purpose and how
    the protection of the data is ensured, please visit GitHub Privacy Policy
    page: https://help.github.com/en/articles/github-privacy-statement.
</p>
<br/>
<h2 dir="ltr">
    AppsFlyer
</h2>
<br/>
<p dir="ltr">
    AppsFlyer receives the following anonymised or pseudonymised data from Gamr:
</p>
<br/>
<p dir="ltr">
    Registration Event:
</p>
<p dir="ltr">
    Limited data: A random identifier (instead of email) and User role (e.g.,
    Player, Organizer)
</p>
<br/>
<p dir="ltr">
    Login Event:
</p>
<p dir="ltr">
    Limited data: A random identifier (instead of email)
</p>
<br/>
<p dir="ltr">
    Other Events:
</p>
<p dir="ltr">
    Type of event: (e.g., Notification click, Tournament registration, etc.)
</p>
<p dir="ltr">
    Non-identifiable details: Tournament name (anonymized), Tournament slug
    (unique identifier but not tied to personal information), Post type, Post
    slug (anonymized), and Post title (anonymized).
</p>
<br/>
<p dir="ltr">
    Why We Share This Data:
</p>
<br/>
<p dir="ltr">
    We share this data with AppsFlyer to:
</p>
<br/>
<ul>
    <li dir="ltr">
        <p dir="ltr">
            Measure the effectiveness of our marketing campaigns.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Analyze user acquisition and in-app behavior.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Optimize the Gamr app for a better user experience.
        </p>
    </li>
</ul>
<br/>
<p dir="ltr">
    Data Security:
</p>
<br/>
<p dir="ltr">
    AppsFlyer uses industry-standard security practices to protect your data.
    You can learn more about AppsFlyer's security measures here:
    https://www.appsflyer.com/legal/privacy-policy/
</p>
<br/>
<p dir="ltr">
    Opting Out:
</p>
<br/>
<p dir="ltr">
    While Gamr does not currently offer a direct opt-out for AppsFlyer data
    collection, you may be able to limit data collection through your device
    settings us at legal@gamr.africa
</p>
<br/>
<br/>
<p dir="ltr">
    Payments
</p>
<p dir="ltr">
    We may provide paid products and/or services within Service. In that case,
    we use third-party services for payment processing (e.g. payment
    processors).
</p>
<br/>
<p dir="ltr">
    We will not store or collect your payment card details. That information is
    provided directly to our third-party payment processors whose use of your
    personal information is governed by their Privacy Policy. These payment
    processors adhere to the standards set by PCI-DSS as managed by the PCI
    Security Standards Council, which is a joint effort of brands like Visa,
    Mastercard, American Express and Discover. PCI-DSS requirements help ensure
    the secure handling of payment information.
</p>
<br/>
<p dir="ltr">
    The payment processors we work with are:
</p>
<br/>
<h2 dir="ltr">
    Paystack
</h2>
<p dir="ltr">
    Their Privacy Policy can be viewed at: <a href="https://paystack.com/terms">
    https://paystack.com/terms</a>
</p>
<br/>
<h2 dir="ltr">
    Flutterwave
</h2>
<p dir="ltr">
    Their Privacy Policy can be viewed at: <a href="https://flutterwave.com/ng/terms">
        https://flutterwave.com/ng/terms
    </a>
</p>
<br/>
<h2 dir="ltr">
    PayPal
</h2>
<p dir="ltr">
    Their Privacy Policy can be viewed at: <a href="https://www.paypal.com/us/legalhub/privacy-full">
        https://www.paypal.com/us/legalhub/privacy-full
    </a>
</p>
<br/>
<h2 dir="ltr">
    Children's Privacy
</h2>
<p dir="ltr">
    Our Services are not intended for use by children under the age of 13
    (“Children”).
</p>
<br/>
<p dir="ltr">
    We do not knowingly collect personally identifiable information from
    Children under 13. If you become aware that a Child has provided us with
    Personal Data, please contact us. If we become aware that we have collected
    Personal Data from Children without verification of parental consent, we
    take steps to remove that information from our servers
</p>
<br/>
<p dir="ltr">
    Changes to This Privacy Policy
</p>
<p dir="ltr">
    We may update our Privacy Policy from time to time. We will notify you of
    any changes by posting the new Privacy Policy on this page.
</p>
<br/>
<p dir="ltr">
    We will let you know via email and/or a prominent notice on our Service,
    prior to the change becoming effective and update “effective date” at the
    top of this Privacy Policy
</p>
<br/>
<p dir="ltr">
    You are advised to review this Privacy Policy periodically for any changes.
    Changes to this Privacy Policy are effective when they are posted on this
    page.
</p>
<br/>
<h2 dir="ltr">
    Contact Us
</h2>
<p dir="ltr">
    If you have any questions about this Privacy Policy, please contact us:
</p>
<p dir="ltr">
    By email: legal@gamr.africa
</p>
<div>
    <br/>
</div>
            </ol>
        </div>
      
      </>
  )
}

import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import './App.css';
import Home from './pages/Home/Home';
import { ThemeProvider } from '@material-ui/styles';
import theme from './context/theme';
import Login from './pages/Login/Login';
import Password from './pages/Password/Password';
import PhoneNumber from './pages/PhoneNumberVerification/PhoneNumberVerification';
import Register from './pages/Register/Register';
import TournamentStateProvider from './context/tournamentContext';
import React, { useContext, useEffect } from "react";
import { AppContext } from "./context/appContext";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermOfUse from "./pages/TermOfUse/TermOfUse";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from 'react-ga';
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import CartStateProvider from "./context/cartContext";
import WalletStateProvider from "context/walletCotext";
import Account from './pages/Account/Account';
import OnlineGame from './pages/OnlineGame/OnlineGame';
import LoadGame from './pages/LoadGame/LoadGame';

const TRACKING_ID = "UA-222908065-1"; // OUR_TRACKING_ID // =>
ReactGA.initialize(TRACKING_ID);

export const AdminRoute = ({ children, ...props}) => {
  const { loggedIn } = useContext(AppContext)

    return (
      loggedIn ? 
          <Route {...props} >{children}</Route> 
      : <Redirect to="/home" />
  )
}



function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>

    <TournamentStateProvider>
      <WalletStateProvider>
      <CartStateProvider>

      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" >
                <Redirect to="/home" />
            </Route>
            <Route path="/home">
                <Home />
            </Route>
            <Route exact path={"/password/policy"}>
                <Password />
            </Route>
            <Route exact path={"/verify/phone"}>
                <PhoneNumber />
            </Route>
            <Route exact path={"/account/policy"}>
                <Account />
            </Route>
            <AdminRoute path="/admin">
                <Home />
            </AdminRoute>
            <Route exact path={"/login"}>
              <Login />
            </Route>
            <Route exact path={"/register"}>
              <Register />
            </Route>
            <Route  path={"/verify-email"}>
                    <VerifyEmail />
                </Route>
            <Route exact path={"/forgot-password" }>
              <PasswordReset />
            </Route>
            <Route exact path={"/privacy-policy"}>
              <PrivacyPolicy />
            </Route>
            <Route exact path={"/term-of-use"}>
              <TermOfUse />
            </Route>
            <Route exact path={"/forgot-password/:token"}>
              <PasswordReset />
            </Route>
            <Route exact path={"/active/online"}>
              <LoadGame />
            </Route>
            
          </Switch>
        </Router>
        </ThemeProvider>
      </CartStateProvider>
      </WalletStateProvider>
      </TournamentStateProvider>
    </QueryClientProvider>
  );
}

export default App;

import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './styles';
import SmallCard from '../SmallCard/SmallCard';
import Facebook from '../../asset/img/facebook.png';
import Discord from '../../asset/img/discord-icon.png';
import Youtube from '../../asset/img/youtube.png';
import Button from '../Button/Button';
import { TiPlus } from 'react-icons/ti';
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import Modal from '../Modal/Modal';
import { format } from 'date-fns';
import { TournamentContext } from '../../context/tournamentContext';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import CreateTeam from '../CreateTeam/CreateTeam';
import { useTranslation } from 'react-i18next';

const RightSideBar = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [ loading , setLoading ] = useState({team:false , cards:true})
    const {  loggedIn } = useContext(AppContext);
    const { changeTournament } = useContext(TournamentContext);
    const [ tournaments , setTournaments] = useState([])

    const handleOpen = () => {
        setOpen(true);
      };
      
    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (props) => {
        changeTournament(props)
        history.push(`/home/tournament/${props.slug}`)
    }

    const getTournaments = async() => {
        setLoading({...loading , cards:true})
        await axiosInstance.get(`api/tournaments?per_page=3&sort[direction]=DESC&sort[field]=date_created` ).then(function ({data}) {
          setTournaments(data.data)
      })
      .finally(() => setLoading({...loading , cards:false}))
    }

    const feedloader = [1 , 2 ,3];

    const displayLoader = () => {
       return feedloader.map((_ , index) =>
                (
                    <div style={{display:"flex"}} key={index}>
                        <Skeleton style={{backgroundColor:"#0F2139",marginRight:"8px"}} variant="circle" width={50} height={50} animation="wave" />
                        <div className={classes.card_info}>
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={120} height={15}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={110} height={10}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={100} height={10}  animation="wave" />
                        </div>
                  </div>
                )
            )
    }


useEffect(() => {
    getTournaments()
}, [])

    return (
        <div className={classes.container}>
            <h2>{t("top_tournaments")}</h2>
            <div className={classes.top_tournamenrt_section}>
                { !loading.cards ? 
                    tournaments.map((item, index) => (
                        <SmallCard 
                            key={index} 
                            name={item.name}  
                            currency={item.currency}
                            date={`${format(new Date(item.started_at) , 'MMM do')} - ${format(new Date(item.ended_at) , 'MMM do')}`}  
                            price={item.prize_pool} 
                            image={item.banner} 
                            onClick={() => handleClick(item)}/>
                    ))

                    : displayLoader()
                }
            </div>
            <hr/>

            <div className={classes.community}>
                <h2>{t("join_our_community")}</h2>
                <div className={classes.icons}>
                    <a target="_blank" href="https://discord.gg/Dh6Cc9QuUU"><img src={Discord} height="40px" width="40px" alt="logo" /></a>
                    <a target="_blank" href='https://www.youtube.com/channel/UC6gf7wjor7B2--4vWcX_RRQ' ><img src={Youtube} width="100px" alt="logo" /></a>
                    <img src={Facebook} alt="logo" height="30px" width="30px" />
                </div>
            </div>
            <hr/>

            <div className={classes.community}>
                {
                     loggedIn && 
                     <>
                        {/* <h2>Your Team</h2>
                        <div className={classes.icons}>
                            
                        </div> */}
                    
                        <Button width="100%" name={t("create_team")} startIcon={<TiPlus size={15} />} onClick={handleOpen}/>
                     </>
                }
                <Modal open={open} onClose={handleClose}>
                   <CreateTeam handleClose={handleClose}/>
                </Modal>
            </div>
            <hr/>
        </div>
    )
}

export default RightSideBar;
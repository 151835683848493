import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container:{
        padding: theme.spacing(2),
        display: "flex",
        color:"#fff",
        gap:theme.spacing(1),
        flexWrap:"wrap",
        [theme.breakpoints.down("sm")] :{
            
        }
    },
    
}));

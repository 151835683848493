import { CircularProgress, IconButton} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { get, isEmpty } from "lodash";
import { axiosInstance } from "../../../helpers/client";
import { AppContext } from "../../../context/appContext";
import Button from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import { MdDelete , MdEdit } from 'react-icons/md'
import { handlePage } from "../../../helpers/paginator";
import CreateForum from "./CreateForum";



export default function ManageForum() {
  const classes = useStyles();
  const [edit, setEdit] = useState({});
  const [collection, setCollection] = useState({
    data: [],
    links: {},
  });
  const [page, setPage] = useState(0);
  const [rows, setRowsPerPage] = useState(5);
  const [endpoint, setEndpoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { setFeedback , feedback } = useContext(AppContext);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit({})
  };

  
  const deleteTag =  (tag) => {
    setLoading({...loading , icon:true})
    axiosInstance.delete(`api/forum/tags/${tag}`).then( ({ data }) =>  {
        setFeedback({message:data.message , severity:"success"})
    })
    .catch(({ response }) => {
        response.data.status === 400 
        ? setFeedback({message:response.data.errors[0] , severity:"error"})
        : setFeedback({message:response.data.error , severity:"error"})
    })
    .finally(() => setLoading({...loading , icon:false}))
}
  

  const getTags = async () => {
    setLoading({...loading, table:true})
    await axiosInstance
      .get(endpoint ?? `api/forum/tags`)
      .then(function ({ data }) {
        setCollection(data);
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoading({...loading , table:false , icon:false}))
  };


  useEffect(() => {
    if(!isEmpty(feedback)){
      getTags();
    }
  }, [feedback ]);

  const schema = [
    {
      label: "Name",
      func: ({ item }) => {
        return get(item, "name");
      },
    },

    {
      label: "Actions",
      func: ({ item }) => (
        <div >
           <IconButton color="inherit">
               <MdEdit size={25} onClick={() => {
                 setEdit(item)
                 handleOpen()
                 }} />
            </IconButton>
            <IconButton color="inherit">
              {
                loading.icon ? <CircularProgress color="inherit" size={25}/> :
                <MdDelete size={25} onClick={() =>   deleteTag(get(item , 'name'))} />
              }
            </IconButton>
        </div>
      ),
    },
  ];


  useEffect(() => {
    getTags();
  }, [rows ]);

  return (
    <div className={classes.container}>
      <h2>Manage Tags</h2>
      <div className={classes.button_section}>
        <Button
          classes={{ text: classes.button_text }}
          backgroundColor="#B00610"
          name={"Create Tag"}
          onClick={() => handleOpen()}
        />
      </div>
      <Table
        handleNext={() => handlePage(setEndpoint ,collection , 'next')}
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')}
        total={100}
        schema={schema}
        shortLoading={true}
        loading={loading.table}
        collection={collection.data}
        links={collection.links}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rows}
        paginate={false}
      />
      <Modal open={open} onClose={handleClose}>
          <CreateForum handleClose={handleClose} edit={edit} />
      </Modal>
    </div>
  );
}

import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import inflection from 'inflection';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { axiosInstance } from '../../../helpers/client';
import { useStyles } from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { AppContext } from '../../../context/appContext';
import { MdAdd, MdCancel } from 'react-icons/md';
import Button from '../../../components/Button/Button';

export default function AdminSettings() {
  const classes = useStyles();
  const [ settings , setSettings ] = useState([])
  const [ banners , setBanners ] = useState({})
  const [ market , setMarket ] = useState([])
  const { setFeedback } = useContext(AppContext)
  const [ loading , setLoading ] = useState({
      settings:true , add:false , delete:null
  })

  const validationSchema = Yup.object().shape({
    livestream_embed_link:Yup.string().required().label('Location'),
    members_limit:Yup.number().positive().max(12).label('Members limit')
});

const validationConfig = Yup.object().shape({
    min_withdrawal: Yup.number().positive().min(1).label('Min Value'),
    max_withdrawal:Yup.number().positive().min(10).label('Max Value'),
    wallet_funding_transaction_fee:Yup.number().positive().label('Wallet Funding fee'),
    wallet_funding_transaction_fee_type: Yup.string().label('Wallet Funding Type'),
    admin_notification_email: Yup.string().label('Admin Email'),
    referral_reward_percentage: Yup.number().positive().max(50).label('Referral Reward Percent'),
})

const validationBannerSchema = Yup.object().shape({
    avatarImage: Yup.mixed().required(),
    link:Yup.string().required().url().label('Link'),
});

const validationMarketSchema = Yup.object().shape({
    avatarImage: Yup.mixed().required(),
    cta_link:Yup.string().required().url().label('Link'),
    description: Yup.string().required(),
    title: Yup.string().required(),
});

const getSettings = async () => {
    setLoading({...loading, settings:true})
    await axiosInstance.get(`api/configurations`).then(function ({ data }) {
        const arr = data.data
        setSettings(data.data);

        const exclude = ['in_game_coin_rate','livestream_embed_link','livestream_embed_link','leaderboard','market','app_banners']
        arr.map(({name , data:value}) => {
            if(exclude.includes(name)) return false;
            return formikConfig.setFieldValue(name, value)
        })

        setBanners( data.data.find((item) => item.name === "app_banners")?.data)

        const marKetData = data.data.find((item) => item.name === "market");
        setMarket(marKetData.data)
      })
      .finally(() => setLoading({...loading , settings:false}))
  };



const handleBanner = (data , type) => {
    setLoading({...loading , add:true})

    const formData = {};
    
    if(type === "banners") {
        formData.app_banners = data.avatar
        formData.app_banner_link = data.link  
    }

    if(type === "market-banners") {
        formData.market_banner = data.avatar
        formData.cta_link = data.cta_link  
        formData.title = data.title  
        formData.description = data.description  
    }


    axiosInstance.post(`api/configurations/${type}/store` , formData ,

    ).then( ({ data }) =>  {
        setFeedback({message:data.message , severity:"success"})
    })
    .catch(({ response }) => {
        setFeedback({message:response.data.message , severity:"error"})
    })
    .finally(() => {
        setLoading({...loading , add:false})
        getSettings()
    })
}

const updateSettings = async (form) =>{
    setLoading({...loading, update:true})
    await axiosInstance.put(`api/configurations/update` ,{
        ...form
       
    })
      .then(function ({ data }) {
        setFeedback({message:data.message , severity:"success"})
      })
      .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}))
      .finally(() => {
          setLoading({...loading , update:false})
          getSettings()
      })
}

const formik = useFormik({
    initialValues: {
       livestream_embed_link:""
    },
    onSubmit: updateSettings,
    validationSchema:validationSchema
});

const formikConfig = useFormik({
    initialValues: {
        min_withdrawal: settings[0]?.min_withdrawal ?? "",
        max_withdrawal:settings[1]?.max_withdrawal ??"",
        wallet_funding_transaction_fee:settings[2]?.wallet_funding_transaction_fee ??"",
        wallet_funding_transaction_fee_type:settings[3]?.wallet_funding_transaction_fee_type ??"",
        admin_notification_email:settings[4]?.admin_notification_email ??"",
        referral_reward_percentage:settings[8]?.referral_reward_percentage ??""
    },
    onSubmit: updateSettings,
    validationSchema:validationConfig
});

const formikBanner = useFormik({
    initialValues: {
       avatar:"",
       avatarImage:"",
       link:"",
    },
    onSubmit:(data) => handleBanner(data, "banners"),
    validationSchema:validationBannerSchema
});

const formikMarket = useFormik({
    initialValues: {
        avatar:"",
        avatarImage:"",
        cta_link:"",
        description:"",
        title:""
    },
    onSubmit: (data) =>  handleBanner(data, "market-banners"),
    validationSchema:validationMarketSchema
});

  const handleFile = (e , handler) => {
   
    let reader = new FileReader();
    let baseURL = "";

    try {
        reader.readAsDataURL(e?.target?.files[0]);
    } catch (e) {
        handler.setFieldValue("avatar" , "")
        handler.setFieldValue("avatarImage" , "")

        return false;
    }
    reader.onload = () => {
        baseURL = reader.result;
        handler.setFieldValue("avatar" , baseURL)
        handler.setFieldValue("avatarImage" , e?.target.files[0])

    };
}

const removebanner = async (type, id) => {
    setLoading({...loading, delete:id})
    await axiosInstance.delete(`api/configurations/${type}/delete/${id}` ,)
        .then(function ({ data }) {
            setFeedback({message:data.message , severity:"success"})

        })
        .catch(({ response }) => setFeedback({message:response.data.message , severity:"error"}))
        .finally(() => {
            setLoading({...loading , delete:null})
            getSettings()
    })
}


  useEffect(() => {
      getSettings()
  },[])


  
  useEffect(() => {
    console.log(formikBanner.errors)
},[formikBanner.errors])



  return (
    <div className={classes.container}>
        <h2>Settings</h2>
        {
             loading.settings ? 
             <div style={{display:"flex" , justifyContent:"center" , alignItems:"center" , height:"100vh" , color:"#fff"}}>
                 <CircularProgress color='inherit' size={25} /> 
             </div>
             :
        <div className={classes.input_section}>
            {
                loading.settings ? 
                <div style={{display:"flex" , justifyContent:"center" , alignItems:"center" , height:"100vh" , color:"#fff"}}>
                    <CircularProgress color='inherit' size={25} /> 
                </div>
                :
                (
                    <>
                        {
                            Object.keys(formikConfig.values).map((name, index) => {                                
                                return (
                                    name === "wallet_funding_transaction_fee_type" ?
                                    <FormControl  className={classes.textfield} key={index} >
                                    <InputLabel id="select">{inflection.titleize(name)}</InputLabel> 
                                    <Select
                                       labelId="select"
                                       
                                        variant="outlined" 
                                        name="wallet_funding_transaction_fee_type"
                                        onChange={formikConfig.handleChange}
                                        value={formikConfig.values[name]} 
                                        label={inflection.titleize(name)}
                                    >
                                        <MenuItem  value="percent">Percent</MenuItem> 
                                        <MenuItem  value="currency">Currency</MenuItem> 
                                    </Select>
                                    </FormControl>
                                    
                                    : 
                                    <>
                                    
                                    <TextField 
                                        key={index}
                                        
                                        className={classes.textfield} 
                                        variant="outlined" 
                                        onChange={formikConfig.handleChange}
                                        value={formikConfig.values[name]} 
                                        label={inflection.titleize(name)} 
                                        name={name}/>
                                          { formikConfig.errors[name] ? (
                                        <span className={classes.content__form_error}>{formikConfig.errors[name]}</span>
                                    ) : null} 
                                    </>
                                )
                            })
                        }
                        
                        {
                            
                            Object.keys(settings[5].data).map((item ) => 
                            <TextField 
                                key={item}
                                className={classes.textfield} 

                                variant="outlined" 
                                value={settings[5].data[item]} 
                                label={inflection.titleize(item)} 
                                name={item}/>)
                        }
                         {/* <TextField 
                                className={classes.textfield} 
                                variant="outlined"
                                onChange={formik.handleChange}
                                defaultValue={settings[7] && settings[7]?.data} 
                                label={inflection.titleize("livestream_embed_link")} 
                                name="livestream_embed_link"/> */}
                    </>
                )
            }
            <Button  name="Save" loading={loading.update} onClick={formikConfig.handleSubmit}/>
            
        </div>
        }
        <div>
            <h4>Banners</h4>
            <div className={classes.bannerSection}>

                {
                     Object.keys(banners).map((item, i ) => 
                    <div key={i} style={{display:"flex" , flexDirection:"column" , alignItems:"center" }}>
                        <div className={classes.bannerItem} style={{backgroundImage:`url(${banners[item]})`}}/>
                        
                        {
                            loading.delete === item ? <CircularProgress color="inherit" size={25} style={{marginTop:"8px"}} /> :
                            <IconButton>
                                <MdCancel size={30} color="red" onClick={() => removebanner("banners", item)}/>   
                            </IconButton>
                        }
                    </div>
                    )
                }
            </div>
            <div style={{display:"flex" , gap:"8px"}}>
                <div>
                    {/* <label for="file" style={{position:"relative"}} >
                        <div className={classes.bannerItem} style={{cursor:"pointer"}}>
                            {
                                loading.add ? <CircularProgress color="inherit" /> : <MdAdd size={50}/> 
                            }
                            
                        </div>
                    </label> */}
                </div>
                <div style={{display:'flex'}}>
                    <div style={{display:"flex" , flexDirection:"column"}}>
                        <input accept="image/*"  id="file" type="file" onChange={(e) => handleFile(e, formikBanner)}/>
                        { (formikBanner.errors.avatarImage &&  formikBanner.touched.avatarImage) ? (
                                        <span className={classes.content__form_error}>{formikBanner.errors.avatarImage}</span>
                                    ) : null}
                    </div>

                    <div  style={{display:"flex" , flexDirection:"column"}}>
                        <TextField 
                            className={classes.textfield} 
                            variant="outlined" 
                            style={{width:300}}
                            onChange={formikBanner.handleChange('link')}
                            label="Link" 
                        />
                            { formikBanner.errors.link ? (
                                    <span className={classes.content__form_error}>{formikBanner.errors.link}</span>
                                ) : null}
                    </div>
                    
                </div>
            </div>
            <Button loading={loading.add}  name="Save" onClick={formikBanner.handleSubmit}/>
        </div>
        <div>
            <h4>Market Banners</h4>
            <div className={classes.bannerSection}>

                {
                     market.map((item, i ) => 
                    <div key={i} style={{display:"flex" , flexDirection:"column" , alignItems:"center" }}>
                        <div className={classes.bannerItem} style={{backgroundImage:`url(${item.url})`}}/>
                        
                        {
                            loading.delete === item ? <CircularProgress color="inherit" size={25} style={{marginTop:"8px"}} /> :
                            <IconButton>
                                <MdCancel size={30} color="red" onClick={() => removebanner("market-banners", item.id)}/>   
                            </IconButton>
                        }
                    </div>
                    )
                }
            </div>
            <div style={{display:"flex" , gap:"8px"}}>
                <div>
                    {/* <label for="file" style={{position:"relative"}} >
                        <div className={classes.bannerItem} style={{cursor:"pointer"}}>
                            {
                                loading.add ? <CircularProgress color="inherit" /> : <MdAdd size={50}/> 
                            }
                            
                        </div>
                    </label> */}
                </div>
                <div style={{display:'flex'}}>
                    <div style={{display:"flex" , flexDirection:"column"}}>
                        <input accept="image/*"  id="market" type="file" onChange={(e) => handleFile(e, formikMarket)}/>
                        { formikMarket.errors.avatarImage ? (
                                        <span className={classes.content__form_error}>{formikMarket.errors.avatarImage}</span>
                                    ) : null}
                    </div>

                    <div  style={{display:"flex" , flexDirection:"column"}}>
                        <TextField 
                            className={classes.textfield} 
                            variant="outlined" 
                            style={{width:300}}
                            onChange={formikMarket.handleChange('cta_link')}
                            label="Link" 
                        />
                            { formikMarket.errors.cta_link ? (
                                    <span className={classes.content__form_error}>{formikMarket.errors.cta_link}</span>
                                ) : null}
                    </div>
                    <div  style={{display:"flex" , flexDirection:"column"}}>
                        <TextField 
                            className={classes.textfield} 
                            variant="outlined" 
                            style={{width:300}}
                            onChange={formikMarket.handleChange('title')}
                            label="Title" 
                        />
                            { formikMarket.errors.title ? (
                                    <span className={classes.content__form_error}>{formikMarket.errors.title}</span>
                                ) : null}
                    </div>
                    <div  style={{display:"flex" , flexDirection:"column"}}>
                        <TextField 
                            className={classes.textfield} 
                            variant="outlined" 
                            style={{width:300}}
                            onChange={formikMarket.handleChange('description')}
                            label="Description" 
                        />
                            { formikMarket.errors.description ? (
                                    <span className={classes.content__form_error}>{formikMarket.errors.description}</span>
                                ) : null}
                    </div>
                    
                </div>
            </div>
            <Button loading={loading.add}  name="Save" onClick={formikMarket.handleSubmit}/>
        </div>

    </div>
  )
}

import React, { useState } from 'react'
import Modal from '../Modal/Modal'
import { axiosInstance } from "../../helpers/client";
import { useEffect } from 'react';


export default function BankDetailModal({user, ...props}) {
    const [detail , setDetail ] = useState({});

    const getBankDetail = () => {
        axiosInstance.get(`api/userbank/active/${user.username}`).then(({data}) => {
            setDetail(data.data)
        }).catch((error) => console.log(error) )
    }

    useEffect(() => getBankDetail(), [])

    return (
        <Modal {...props} >
            <div   style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "8px 0px",
                    }}>
                <h2>{user.name}</h2>
                <p>{detail?.bank_name}</p>
                <p>{detail?.account_number}</p>
                <p>{detail?.routing_number}</p>
                <p>{detail?.beneficiary_name}</p>
                <p>{detail?.currency}</p>

            </div>
        </Modal>
    )
}

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        height:"50px",
        width:"50px",
        borderRadius:"100%",
        backgroundPosition:"center",
        backgroundSize:"cover",
        position:"relative"
    },
    notify:{
      position:"absolute",
      top:0,
      right:0,
      height:"16px",
      width:"16px",
      color:"#fff",
      fontSize:"8px",
      border:"2px solid #232F3D",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      backgroundColor:"#E8454E",
      borderRadius:"100%",
    }
}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(0 , 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    },
    [theme.breakpoints.down("sm")]:{
      marginBottom: theme.spacing(8)
    }
}, 
filter:{
  display:"flex",
  justifyContent:"space-between"
},
top_section:{
  display:'flex',
  gap:"32px",
},
description:{
  flex:1,
  gap:"16px",
  display:"flex",
  flexDirection:"column",
  alignItems:"flex-start",
},
imgLogo:{
  display:"flex",
  alignItems:"center",
  gap:"8px",
  cursor:"pointer"
},
filterSection:{
  display:"flex",
  gap:"8px",
},
img:{
  height:"200px",
  width:"200px",
  backgroundSize:"cover",
  backgroundPosition:"center"
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  },
  [theme.breakpoints.down("sm")]:{
    width:"33%"
  }
},                                              
}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        flex: "1 1 0",
        background: "linear-gradient( #0e1028 , #070515)",
        color:"#fff",
        padding: theme.spacing(8),
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]:{
            padding:theme.spacing(1)
        },
        '& h1':{
            textAlign:"center"
        },
        '& p': {
            padding: "20px"
        },
        "&::-webkit-scrollbar": {
            display:"none",
          },
    },
    list:{
        '& li':{
            marginBottom:theme.spacing(2)
        }
    }
}));

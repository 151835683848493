import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        padding:theme.spacing(2),
        color:"#fff",
        "& h2": {
            fontSize:"22px"
        },
        [theme.breakpoints.down("sm")] :{
            padding:theme.spacing(1)
        }
    },
    game_section:{
        margin: theme.spacing(4, 0),
    },
    games:{
        display:"flex",
        overflowX:"scroll",
        gap: theme.spacing(10),
        "&::-webkit-scrollbar":{
            height:"10px"
        },
        [theme.breakpoints.down("lg")] :{
            gap:theme.spacing(4)
        }

    },
    info_skeleton:{

        
        // border: "1px solid #172A44",
        [theme.breakpoints.down("sm")] :{
            width:"100px"
        }
    },
    info_skeleton_rect:{
        width:"360px",
        flex: "1 1 auto",
        borderRadius:theme.spacing(1)
    },
    load_tournament:{
        display:"flex",
        flexWrap:"wrap",
        gap: "10px"
    },
    load_tournament_section:{
        marginBottom: theme.spacing(6)
    },
    title:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& P": {
            fontSize:"14px",
            cursor:"pointer",
            color:"#6EC5E9"
        },
        
    },
}));

import {  TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CartContext } from '../../context/cartContext';
import Button from '../Button/Button';
import CartItem from './CartItem';
import { useStyles } from './styles';
import * as Yup from "yup";
import PaymentModal from './PaymentModal';





export default function Cart() {
    const classes = useStyles();
    const history = useHistory();
    const { items, total } = useContext(CartContext)
    const searchParams = new URLSearchParams(window.location.search);
    const paymentType =  searchParams.get("paymentType");
    const [open, setOpen] = useState(paymentType === "credit" ?? false)  


    const validationSchema = Yup.object().shape({
        type: Yup.string(),
        email: Yup.string().when('type',{
                is: "digital",
                then: Yup.string().required("Email is required")
            }
        )
    });

    const handleClose = () => {
        setOpen(false)
        searchParams.delete("paymentType")
    }

    const handleSubmit = () => {

        if(items[0].type !== 'digital') {
            return history.push('/home/checkout');
        }

        setOpen(true)
    }

    
    const formik = useFormik({
        initialValues:{
            email: "",
            type:items[0]?.type ?? "digital",
            fee: 0
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema

    })

    return (
        
        <div className={classes.container}>
            <h1>My Cart</h1>
            {(items.length > 0 && items[0].type === "digital") &&
                <div className={classes.AddEmail}>
                    <p>Enter the email address we should send your product to below</p>
                    <TextField placeholder="email" variant="outlined" value={formik.values.email} name="email" onChange={formik.handleChange} style={{width:"50%"}}/>
                    {formik.touched.email  && formik.errors.email ? (
                                        <span className={classes.content__form_error}>{formik.errors.email}</span>
                                    ) : null}
                </div>
            }

            <div className={classes.mainSection}>
                {
                   
                    <div className={classes.itemSection}>
                        { items.length > 0 ?
                            items.map((item, i) => 
                                <CartItem 
                                    key={i}
                                    slug={item.slug}
                                    quantity={item.quantity} 
                                    name={item.name} 
                                    price={item.price} 
                                    image={item.image}
                                    type={item.type}
                                    />) : <p>Your Cart is Empty</p>
                        }

                    </div>

                    
                }
                <div className={classes.summarySection}>
                    <h2>Product Summary</h2>
                    <div className={classes.summaryItem}>
                        <span>Price</span>
                        <span>NGN{total}</span>
                    </div>


                    <div className={classes.summaryItem}>
                        <span>Discount</span>
                        <span>0%</span>
                    </div>
                    <div className={classes.summaryItem}>
                        <span>Total Price</span>
                        <span>NGN{total}</span>
                    </div>
                    <Button 
                        disabled={items.length === 0} 
                        name={ `Checkout`} 
                        backgroundColor="#B00610" 
                        onClick={formik.handleSubmit}/>
                </div>
            </div>
            <PaymentModal open={open} handleClose={handleClose} formik={formik} />

        </div>
    )
}



import { makeStyles, } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    title:{
        fontSize: "14px",
        color: "white",
        marginBottom: "18px",
        marginTop: "18px",
        marginLeft: "6px"
    },
    Icon: {
        position: "relative",
        fontSize: "24px",
        top: "4px",
        marginRight: "6px"
    }
}));

import { Avatar } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useStyles } from './styles'
import Button from '../../components/Button/Button';
import {IoGameController , IoTrophy } from 'react-icons/io5';
import { a11yProps, TabPanel } from '../Tournament/Tournament';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import inflection from 'inflection';
import { useHistory, useParams } from 'react-router';
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import { format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import TeamCard from '../../components/TeamCard/TeamCard';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import { isEmpty } from 'lodash';
import { GiRank3 } from 'react-icons/gi';



export default function PrayerProfile() {
    const classes = useStyles(); 
    const history = useHistory(); 
    const [ user , setUser ] = useState({
        name: "",
        username:""
    })
    const [value, setValue] = useState(0);
    const { setFeedback ,feedback, loggedIn} = useContext(AppContext)
    const { player } = useParams();
    const [ loading , setLoading ] = useState({
        account:false , follow:false
    })
    const [ followers , setFollwers ] = useState([])


    const getPlayer = async () => {
        setLoading({...loading , account:true})
        await axiosInstance.get(`api/players/${player}?follow_status=1`).then(({data}) => {
            setUser(data.data)
        }).finally(() =>  setLoading({account:false , follow:false}));
    }

    const getFollowers = async () => {
        await axiosInstance.get(`api/players/${player}/followers`).then(({data}) => {
            setFollwers(data.data)
        }).catch(({response}) => console.log(response))
        .finally(() =>  setLoading({account:false , follow:false}));
    }

    const followOrUnfollowPlayer = async (value) => {
        if(!loggedIn) return history.push('/login')
        setLoading({...loading , follow:true});
        await axiosInstance.post(`api/connections/${value}`, {
            username:player
        }).then(function ({ data }) {
            setFeedback({message:data.message});
          })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"}) 
        }).finally(() =>  setLoading({...loading , follow:false}));
    }

  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        getPlayer()
        if(loggedIn){

            getFollowers()
        }
    },[player ])

    useEffect(() => {
        if(!isEmpty(feedback)){
            getPlayer()
            if(loggedIn)getFollowers()
        }
    },[feedback , loggedIn])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                {
                      loading.account
                      ? (
                        <div style={{display:"flex"}}>
                          <Skeleton style={{backgroundColor:"#0F2139",marginRight:"8px"}} variant="circle" width={80} height={80} animation="wave" />
                          <div className={classes.card_info}>
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={160} height={40}  animation="wave" />
                            <Skeleton style={{backgroundColor:"#0F2139"}} variant="text" width={150} height={20}  animation="wave" />
                          </div>
                        </div>
                      )
                      
                      :
                <div className={classes.image_section}>
                    <Avatar src={user.avatar} alt={user.username}  className={classes.avatar} />
                    <div className={classes.name_section}>
                        <h2>{inflection.titleize(user.username)}</h2>
                        <p>Joined: {user.joined && format(new Date(user.joined) , 'MMM do yyyy')}</p>
                    </div>
                </div>
                }
                <div className={classes.button_section}>
                    <Button 
                        loading={loading.follow}
                        name={user?.is_following ? 'Unfollow' : "Follow"} 
                        backgroundColor="#B00610" 
                        onClick={() => followOrUnfollowPlayer(user?.is_following ? 'unfollow' : "follow")} /> 
                </div>
            </div>

            <div className={classes.trophy_section}>
                <ProfileCard  title="Tournaments played" info={user.player_stats?.tournaments_played} color="#6EC5E9" icon={<IoGameController color="#546884" size={50}/>} />
                <ProfileCard title="Wins" info={user.player_stats?.tournament_wins} color="#E8454E" icon={<IoTrophy color="#546884" size={50}/>} />
                <ProfileCard title="Level" color="#DD9F3A" info={user.player_stats?.rating} icon={<GiRank3 color="#546884" size={50}/>} />
            </div>

            <div>
                <Tabs value={value} onChange={handleChange}  variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab className={classes.tab_label} label="Match Stats" {...a11yProps(0)} />
                    <Tab className={classes.tab_label} label="Team" {...a11yProps(1)} />
                    <Tab className={classes.tab_label} label="Achievements" {...a11yProps(2)} />
                    {
                        loggedIn &&  <Tab className={classes.tab_label} label="Followers" {...a11yProps(3)} />
                    }
                   
                </Tabs>
            </div>
            <TabPanel value={value} index={0}> 
                <p>No Stats</p>
                {/* <div className={classes.gameplayed_section}>   
                    <div className={classes.gameplayed_box}>
                        <div className={classes.img} style={{backgroundImage: `url('${image}')`}}></div>
                        <div className={classes.right}>
                            <p>{inflection.titleize(user.username)} played a Counter-Strike: Global Offensive game in the tournament Marathon Aim Premium</p>
                            
                            <p>Date: Dec 6th, 2020 - Jan 1st, 2021</p>
                        </div>
                    </div>
                </div>          */}
            </TabPanel>
            <TabPanel value={value} index={1}>
            {
                   user.teams && user.teams.map(({banner , name , description , slug}, i) => (

                        <TeamCard key={i} banner={banner} name={name} description={description} onClick={() => history.push(`/home/team/${slug}`)}/>
                    ))
                }
            </TabPanel>
            <TabPanel value={value} index={2}>
                <p>No Achievements</p>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div className={classes.followers}>
                 {
                     followers.map(({username}, i) => <TeamCard key={i} name={username}  onClick={() => history.push(`/home/player/${username}`)}/>)
                 }
               </div>
            </TabPanel>

           
        </div>
    )
}

import React, { useContext, useState } from 'react';
import { TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';




const CreateForum = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);


    const handleSubmit = (value) => {
        isEmpty(edit) ? createTag(value) : updateTag(value)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
       
    });

    

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });



    const createTag = async (form) => {
        setLoading(true)


        await axiosInstance.post("api/forum/tags" , form ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }


    const updateTag = async (form) => {
        setLoading(true)

        await axiosInstance.put(`api/forum/tags/${edit.name}` , form ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
                <>
                    <h2>Manage Tag</h2>
                    <div style={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"12px",
            
                    }}>

                        <div >
                            <TextField onChange={formik.handleChange} placeholder={`Name`} variant="outlined" name="name" value={formik.values.name} fullWidth />
                            {formik.touched.name  && formik.errors.name ? (
                                    <span className={classes.content__form_error}>{formik.errors.name}</span>
                                ) : null}
                        </div>
                       

                        <div style={{display:"flex" , justifyContent:"flex-end"}}>
                            <Button loading={loading} name={isEmpty(edit) ? "Create" : "Update"}  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                        </div>
                    </div>
                </> 
    )
}



export default CreateForum
import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
  
  modal_container:{
    height:"60px",
    backgroundColor:"#0A091A",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start",
    color:"white",
    boxSizing:"border-box"
},
paper:{
    backgroundColor:"unset"

},
games:{
  display:"flex",
  flexWrap:"wrap",
  justifyContent:"space-between",
  marginBottom:theme.spacing(2)
},
image_section:{
  display:"flex",
  flexDirection:"column",
  alignItems:"flex-start"
  
},
content__form_error:{
  fontSize:"12px",
  color:"red",
},
date:{
  display:"flex",
  flexDirection:'column',
  gap:theme.spacing(0.5)
},
search_section:{
    flexGrow:1,
    padding:"8px 0px",
    boxSizing:"border-box",
},
input_field:{
    color:"#546884"
},
textfield:{
    color:"#5A79A5",
    '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#5A79A5',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#5A79A5',
        },
        '&:hover fieldset': {
          borderColor: '#5A79A5',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5A79A5',
        },
      },
},

modal: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
create_section:{
  display:"flex",
  justifyContent:"space-between",
},
modal_container: {
  backgroundColor: "#0F2139",
  borderRadius:"8px",
  maxWidth:"600px",
  minWidth:"400px",
  padding: theme.spacing(3),
  color:"#fff",
  "& h2":{
      fontSize:"26px",
      marginBottom: "8px",
      marginTop: "0px"
  },
  
},
button_text:{
  padding:theme.spacing(1 , 2)
},
input_section:{
margin:theme.spacing(2, 0),
},
select:{
color:"#5A79A5",

"& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#5A79A5"
  },
  "& .MuiOutlinedInput-input" :{
    padding: "12px 16px"
}
}, 
"@media only screen and (max-width: 768px)":{
search_section:{
    display:"none",
},
container:{
  justifyContent:"space-between"
}
},
  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
},  
button_section:{
    display:"flex",
    justifyContent:"flex-end",
    alignItems:"center"
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  }
},                                              
}));

import inflection from 'inflection';
import { debounce } from 'lodash';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import RectangleCard from '../../components/RectangleCard/RectangleCard';
import TeamCard from '../../components/TeamCard/TeamCard';
import { TournamentContext } from '../../context/tournamentContext';
import { axiosInstance } from '../../helpers/client';
import { useStyles } from './styles'


export default function Search() {
    const history = useHistory();
    const classes = useStyles();
    const { changeTournament } = useContext(TournamentContext)
    const [ teams , setTeams ] = useState([]);
    const [ users , setUsers ] = useState([]);
    const [ tournaments , setTournaments ] = useState([]);
    const searchParams = new URLSearchParams(window.location.search).get("q");

    const handleTournamentClick = (props) => {
        changeTournament(props);
        history.push(`/home/tournament/${props.slug}`);
    }

    const searchPlayers = debounce( async () => {
        await axiosInstance.get(`api/players/search/users?q=${searchParams}`)
        .then(({data}) => setUsers(data.data))
    }, 2000)

    const searchTeams = debounce( async () => {
        await axiosInstance.get(`api/players/search/teams?q=${searchParams}`)
        .then(({data}) => setTeams(data.data))
    }, 2000)

    const searchTournaments = debounce( async () => {
        await axiosInstance.get(`api/tournaments/search?q=${searchParams}`)
        .then(({data}) => setTournaments(data.data))
    }, 2000)

    useEffect(() => {
        searchTeams()
        searchPlayers()
        searchTournaments()
    },[searchParams])

    return (
        <div className={classes.container}>
            {
                teams.map(({avatar , name , description , slug}, i) => (
                        <TeamCard key={i} banner={avatar} name={name} description={description} onClick={() => history.push(`/home/team/${slug}`)}/>
                ))
            }
            {
                users.map(({username , avatar}, i) => <TeamCard key={i} banner={avatar} name={username}  onClick={() => history.push(`/home/player/${inflection.dasherize(username)}`)}/>)
            }
            {
                tournaments.map(({name, games, banner , prize_pool, started_at , ended_at , id , slug}, i) => 
                <RectangleCard
                    key={i}
                    description={name}
                    game={games[0]}
                    start={started_at} 
                    end={ended_at} 
                    prize={prize_pool} 
                    image={banner}
                    onClick={() => handleTournamentClick({id ,name, banner  , prize_pool, started_at , ended_at , slug})} />
                )
            }
            {
                (teams.length === 0 && users.length === 0 && tournaments.length === 0) && <h2>No Result Found</h2>
            }
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { TextField } from '@material-ui/core';
import { axiosInstance } from '../../helpers/client';
import Button from '../Button/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppContext } from '../../context/appContext';
import { t } from 'i18next';




export default function Invitation({slug , type , team = false}) {
    const [ search , setSearch ] = useState('');
    const [ users , setUsers ] = useState([]);
    const [ loading , setLoading ] = useState(false);
    const { setFeedback } = useContext(AppContext);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearch(value);
    };

    const getUsers = async () => {
        await axiosInstance.get(`api/players/search/users?q=${search}`).then(({data}) => {
            setUsers(data.data)
        })
    }

    const getTeams = async () => {
        await axiosInstance.get(`api/players/search/teams?q=${search}`).then(({data}) => {
            setUsers(data.data)
        })
    }

    const inviteUser = async () => {
        setLoading(true)
        let teamSlug = ""
        if(team) teamSlug = users[0].slug

        const endpoint = type === 'team' ? 'teams/members' : 'tournaments/invitations';
        let form = team ? {team:teamSlug} : {username:search}
        await axiosInstance.post(`api/${endpoint}/invite/${slug}` , form ).then(({data}) => {
            setFeedback({message: data.message})
        }).catch(({response}) => {
           response.status == 422 ? setFeedback({message: response.data.errors.username[0] , severity:"error"})
            : setFeedback({message: response.data.message , severity:"error"})
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        team ? getTeams() : getUsers()
    } ,[search])


    return (
        <>
        <h2> {team ? t("invite_team") : t("invite_friend")}</h2>
        <div style={{display:'flex', gap:"8px"}}>
            {
                team ? 
                <Autocomplete
                    id="combo-box-demo"
                    onInputChange={(e , v) => setSearch(v)}
                    options={users}
                    getOptionLabel={(option) => !team ? option.username : option.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} onChange={handleChange} variant="outlined" />}
                    />

                    :
                    <TextField placeholder={t("username")}  variant="outlined" name="location" onChange={handleChange} fullWidth/>

            }
            <Button 
                loading={loading}
                onClick={inviteUser}
                name={t("confirm")} 
                margin="0px 16px"
                backgroundColor="#172A44" 
                />
        </div>
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './styles';
import Button from '../Button/Button';
import { useFormik } from "formik";
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import { AiOutlinePlusCircle , AiOutlineMinusCircle } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { getAllnterest } from '../../api/interest';
import { CircularProgress } from '@material-ui/core';
import * as Yup from "yup";


export default function ForumInterest({edit = {} , handleClose , topic}) {
    const classes = useStyles();
    const [ loading , setLoading ] = useState({team:false , cards:true})
    const { setFeedback} = useContext(AppContext);
    const { isLoading , data:tags } = useQuery('getAllInterests' , getAllnterest);
    const { data } = useQuery('getInterests' , getAllnterest);
    const [ interests, setInterest ] = useState([])

    const validationSchema = Yup.object().shape({
        interests: Yup.array().of(Yup.string()).min(1).required(),
    });


    const addInterest = async (form) => {
        setLoading({...loading , team:true})

        await axiosInstance.post(`api/forum/interests` , form ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading({...loading , team:false}))
    }

    

    useEffect(() => {
        if(!isLoading) {
            const interestArray = [];
            data.map(({ name }) => interestArray.push(name))
            setInterest(interestArray)

        }
    }, [data])



    const formik = useFormik({
        initialValues: {
            interests,
        },
        onSubmit: addInterest,
        validationSchema,
    });

    const handleTags = (tag) => {
        let tags = [...formik.values.interests]

        if(tags.includes(tag)) {
            tags.splice(tags.indexOf(tag) , 1)
        } else {
            tags.push(tag);
        }

        formik.setFieldValue('interests' , tags)
    }



  return (
    <>
        <div className={classes.container}>
            <div className={classes.heroSection} >
                <h2>Select your Topic of Interests</h2>
            </div>
            <div className={classes.tagSection}>
                {
                    isLoading 
                    ?  <div style={{display:"flex" , width:"100%", justifyContent:"center" , alignItems:"center" , color:"#fff"}}>
                        <CircularProgress color='inherit' size={25} /> 
                    </div>
                    : tags.map(({name:item}, i) => (
                        <div key={i} className={classes.interest} style={{backgroundColor: formik.values.interests.includes(item) &&  "#070515"}} onClick={() => handleTags(item)}>
                            <p>{item}</p>
                            {
                                !formik.values.interests.includes(item)
                                ? <AiOutlinePlusCircle size={25} />
                                : <AiOutlineMinusCircle size={25} />
                            }
                            
                        </div>
                    ))
                }
                     {formik.touched.interests  && formik.errors.interests ? (
                    <span className={classes.content__form_error}>{formik.errors.interests}</span>
                ) : null}
            </div>
            <div className={classes.buttonSection}>
                <Button name="Save"  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit} loading={loading.team}/>
            </div>
        </div>
    </>
  );
}

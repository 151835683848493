import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { axiosInstance } from '../../../helpers/client';
import CreateTournamentPage from '../../CreateTournament/CreateTournamentPage';
import { CircularProgress } from '@material-ui/core';



export default function ViewTournament() {
    const { slug }  = useParams();
    const [ loading , setLoading ] = useState(true) 
    const [ tournament , setTournament ] = useState({})
   

    const getTournamentDetail = async () => {
        setLoading(true)
        await axiosInstance.get( `api/tournaments/show/${slug}`).then(function ({ data }) {
            setTournament(data.data);
        })
        .finally(() => setLoading(false))
    };


    useEffect(() => {
        getTournamentDetail()
    },[])
    return (
        <>
        {
            !loading ? <CreateTournamentPage edit={tournament}/>
            : 
            <div style={{display:'flex' , justifyContent:'center' , alignItems:"center", color:"#fff" , height:"100vh"}}>
                <CircularProgress color='inherit'/>
            </div>
        }
        </>
    )
}

import { Avatar, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import { debounce, get, isEmpty } from "lodash";
import { axiosInstance } from "../../helpers/client";
import { useHistory } from 'react-router-dom';
import { useGame } from '../../hooks/useGame';
import { handlePage } from '../../helpers/paginator';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function OnlineGame() {
    const classes = useStyles()
    const history = useHistory();
    const { t } = useTranslation();

    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#1A2027',
      ...theme.typography.body2,
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }));

    const games = [
      {
        "id": 11233,
        "title": "Element Blocks",
        "image": "https://elementblocks.h5games.usercontent.goog/v/79834f3b-dae6-4966-981f-3453fec52569/high_res_tall.jpg",
        "link": "https://play.famobi.com/wrapper/element-blocks/A1000-10"
      },
      {
        "id": 12433,
        "title": "Fruit Crush Frenzy",
        "image": "https://res.cloudinary.com/delino12/image/upload/v1719484823/fruitfenzy.jpg",
        "link": "https://play.famobi.com/wrapper/fruit-crush-frenzy/A1000-10"
      },
      {
        "id": 33322,
        "title": "Word Search Classic",
        "image": "https://res.cloudinary.com/delino12/image/upload/v1719485009/wordsearch.jpg",
        "link": "https://play.famobi.com/wrapper/word-search-classic/A1000-10"
      }
    ];

    const handleGameLoad = (gameId) => {
      if(gameId !== ""){
        window.location.href = `/active/online?gameId=${gameId}`;
      }
    }

    return (
      <div className={classes.container} >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }} sx={{flexDirection: { xs: "column", md: "row"}, backgroundColor: "#0e1028" }} >
              {games.map((game, index) => (
                <Grid item xs={12} sm={12} md={4} key={index} sx={{ flexDirection: { xs: "column", md: "row"} }}>
                  <Item>
                    <Card sx={{ maxWidth: "100%", backgroundColor: "transparent" }}>
                      <CardActionArea sx={{ backgroundColor: "#0e1028" }} onClick={() => handleGameLoad(game.id)}>
                        <CardMedia
                          component="img"
                          height="440"
                          image={game.image}
                          alt={game.title}
                          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div" sx={{ color: "#fff"}}>
                            {game.title}
                          </Typography>
                          {/* <Typography variant="body2" color="text.secondary"> */}
                          {/*   <Button onclick="" name="Play Now" /> */}
                          {/* </Typography> */}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>
      </div>
    )
}

import React, {  useState } from "react";
import { useStyles } from "./styles";
import { axiosInstance } from "../../helpers/client";
import {  List, ListItem, ListItemText } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Button from "../Button/Button";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import { t } from "i18next";

export default function Notification({ notification , refetch , fetching}) {
  const classes = useStyles();
  const { setFeedback } = useContext(AppContext)
  const [ loading , setLoading ] = useState({
    notifications:true , mark:false , join:false
  })


  const markAsRead = async (id) => {
    await axiosInstance
      .post(`api/notifications/read/${id}`)
      .then(() => refetch())
  };

  const acceptOrDeclineRequest = async (teamSlug , id, accept) => {
    setLoading({...loading , join:true})
    await axiosInstance.post(`api/teams/members/${accept ? 'request' : 'decline'}/${teamSlug}`).then(function ({ data }) {
        setFeedback({message:data.message});
        markAsRead(id)
      })
    .catch(({ response }) => {
        setFeedback({message:response.data.message , severity:"error"}) 
    }).finally(() =>   setLoading({...loading , join:false}));
  }



  
  const readAll = async () => {
    if(notification.length === 0) return
    setLoading({...loading , mark:true})
    await axiosInstance
      .post(`api/notifications/read-all`)
      .then(() => refetch())
      .finally(() =>  setLoading({...loading , mark:false}))
  };

 
  return (
    <div className={classes.container}>
      <List className={classes.not_container}>
          { !fetching ?
            (

              notification.length > 0 ? 
              (
                notification.map((item, index) => (
                    <ListItem
                    button
                    key={index}
                    className={classes.notification_item}
                    onClick={() => item.type !== "NewTeamInvitation" && markAsRead(item.id)}
                    >
                    <ListItemText className={classes.text}>
                      <p>{item.data.message}</p>
                      {
                        item.type === "NewTeamInvitation" && 
                        <div className={classes.team_invite_section}>
                            <Button name="Accept" loading={loading.join} onClick={() => acceptOrDeclineRequest(item.data.team.slug , item.id , true)}/>  
                            <Button name="Decline"  backgroundColor="#B00610" loading={loading.join} onClick={() => acceptOrDeclineRequest(item.data.team.slug , item.id , false)}/>
                        </div>
                      }
                        
                    </ListItemText>
                    </ListItem>
                ))
              ) :
              <ListItem button className={classes.notification_item}>
                <ListItemText className={classes.text}>
                  {t("no_notification")}
                </ListItemText>
              </ListItem>
            )
            :
            <ListItem button className={classes.notification_item}>
                <ListItemText className={classes.text}>
                    <Skeleton  variant="text"  height={40}  animation="wave" />
                    <Skeleton  variant="text"  height={40}  animation="wave" />
                    <Skeleton  variant="text"  height={40}  animation="wave" />
                    <Skeleton  variant="text"  height={40}  animation="wave" />

                </ListItemText>
              </ListItem>
          }
      </List>
      <Button name={t("mark_all")} loading={loading.mark} onClick={() => readAll()}/>
    </div>
  );
}

import React from 'react'
import Carousel from 'react-material-ui-carousel';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getMarketBanners, getProducts } from '../../api/market';
import Button from '../Button/Button';
import ProductItem from './ProductItem';
import { useStyles } from './styles';



export default function Market() {
    const classes = useStyles();
    const history = useHistory();
    const { isLoading , data:categories } = useQuery('getProducts' , getProducts);
    const { isLoading:marketLoading , data:banners } = useQuery('getMarketBanners', getMarketBanners)
   

    return (
        <>
        <Carousel stopAutoPlayOnHover={false} indicators={false}>
            {
                !marketLoading &&
                banners.data.map(({ description , cta_link, title, url } , i) => (

                <div key={i} className={classes.heroSection}>
                    <div className={classes.heroImage} style={{backgroundImage: `url(${url})`}} >
                    </div>
                    <div className={classes.heroDescription}>
                        <h1>{title}</h1>
                        <p>{description}</p>
                        <div style={{display:'flex', gap:"16px"}}>
                            <Button onClick={() => window.location.replace(cta_link)} name={`Buy now!`} backgroundColor="#B00610" />
                            {/* <Button  name={`Add to Wishlist`} backgroundColor="rgba(255, 255, 255, 0.1)" /> */}
                        </div>
                    </div>
                </div>
                ))                
            }
        </Carousel>
        <div className={classes.container}>
            {
                !isLoading && (
                    categories.map(({name , products}) => {


                        return (
                            <div style={{marginBottom:"64px"}}>
                                <h3>{name}</h3>
                                <div className={classes.listContainer}>
                                    {
                                        products.map((data, i) => {
                                            return (

                                                <ProductItem
                                                    key={i}
                                                    slug={data.slug}
                                                    inStock={data.in_stock}
                                                    title={data.name} 
                                                    price={data.price}
                                                    discount={data.discount_percent}
                                                    created={data.created_at}
                                                    image={data.image}/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                ) 
            }
            
        </div>
        </>
    )
}



import React, {  useState } from 'react';
import { useStyles } from './styles';
import {  Snackbar, TextField } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { axiosInstance } from '../../helpers/client';
import PasswordField from '../../components/PasswordField/PasswordField';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import logo from '../../asset/img/logo.png'




const PasswordReset = () => {
    const classes = useStyles();
    const history =  useHistory()
    const { token } = useParams();
    const [ feedback , setFeedback ] = useState({
        message:"" , severity:""
    });
    const [ loginError , setLoginError] = useState()
    const [ loading , setLoading ] = useState(false);
    const { t } = useTranslation();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        
        setFeedback({});
    }
  

    const handleSubmit = async ({ email , password , password_confirmation }) => {
        !!token ? verify( password , password_confirmation) : requestReset(email)
    }

    const requestReset = async( email) => {
        setLoading(true)
        await axiosInstance.post("api/password/request" , {
            email
        }).then(({data}) => setFeedback({message: data.message , severity:"success"}))
        .finally(() => setLoading(false))
    }

    const verify = async (password , password_confirmation) => {
        setLoginError("")
        setLoading(true)
        await axiosInstance.post(`api/password/reset/${token}` , {
        password,
        password_confirmation
    } ).then(({data}) => {
        setFeedback({message:data.message, severity:"success"})
                       
        }).catch(({response}) => {
            setFeedback({message:response.data.message , severity:"error"})
        })
        .finally(() =>  setLoading(false))
    }

    

    const validationSchema = () => {
        if(!!token){
            return Yup.object().shape({
                password: Yup.string().required("No password provided.").min(8, "Password is too short - should be 8 chars minimum.")
                .matches(/(?=.*[0-9])/, "Password must contain a number."),
                password_confirmation: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match').label("Password Confirmation")
            });
        } else {
            return Yup.object().shape({
                email: Yup.string().email().required().label("Email"),
            });
        }
    }

    const formik = useFormik({
        initialValues:{
            email:"",
            password:"",
            password_confirmation:""
        },
        onSubmit:handleSubmit,
        validationSchema:validationSchema
    })


    return (
        <div className={classes.container} >
            <div style={{display:'flex', justifyContent:"flex-end",paddingRight:"20px"}}>
                <LanguageSwitch />
            </div>
            <div style={{display:"flex" , justifyContent:"center"}}>

                <div className={classes.login_section}>
                    <h2>Reset  <img onClick={() => history.push('/home')} style={{marginBottom:"-8px", cursor:"pointer"}} src={logo} alt="logo" width="100px" /> Password</h2>
                    {
                        !!token ?
                        <p className={classes.info}>Enter your new password.</p>
                        :
                        <p className={classes.info}>{t("reset")}</p>
                    }
                        <>
                        <div className={classes.input}>
                            {
                                !!token ? (
                                    <>
                                    <div className={classes.input_container}>
                                <PasswordField onChange={formik.handleChange} name="password"  variant="outlined"  />
                                {formik.errors.password && (
                                    <span className={classes.content__form_error}>{formik.errors.password}</span>
                                    )}
                            </div><div className={classes.input_container}>
                                <PasswordField   onChange={formik.handleChange} name="password_confirmation"  variant="outlined" confirm/>
                                {formik.errors.password_confirmation && (
                                    <span className={classes.content__form_error}>{formik.errors.password_confirmation}</span>
                                    )}
                            </div>
                            </>
                                ) : (

                            <div className={classes.input_container}>
                                <TextField className={classes.textfield}  onChange={formik.handleChange} name="email"  variant="outlined" label="Email" />
                                {formik.errors.email && (
                                    <span className={classes.content__form_error}>{formik.errors.email}</span>
                                    )}
                            </div>
                                )
                            }
                            <div className={classes.input_container}>
                            <Button loading={loading} backgroundColor="#B00610"  name={t("reset_title")} classes={{text:classes.button_text}} onClick={formik.handleSubmit}/>
                            {loginError && (
                                <span className={classes.content__form_error}>{loginError}</span>
                                )}
                            </div>
                        </div>
                        </>
        
                    <p className={classes.join}> <Link style={{
                        color:"#17a2b8"
                    }} to="/register">{t("register")}</Link>  <Link style={{
                        color:"#17a2b8"
                    }} to="/login">{t("login")}</Link> </p>

                </div>
            </div>
            <Snackbar open={!!feedback.message} autoHideDuration={6000} onClose={handleClose}  key={"bottom" + "left"}  anchorOrigin={{ vertical:"bottom", horizontal:"left" }}>
                <MuiAlert  elevation={6} variant="filled" onClose={handleClose} severity={feedback.severity}>
                        {feedback.message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default PasswordReset
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    "& .MuiBox-root": {
      padding: 0,
    },
  },
  containerMd: {
      padding: theme.spacing(0, 2),
      color: "#fff",
      "& h2": {
          fontSize:"22px"
      },
      [theme.breakpoints.down("sm")]:{
          padding: 0,
          boxSizing: "border-box",
          paddingBottom: "16px"
      }
  },
  main_info_cards: {
    display: "flex",
    flex: 1.5,
    gap: "10px",
    flexWrap: "wrap",
  },
  rules:{
    
  },
  p: {
    fontWeight: 400,
    fontSize: "14px",
    [theme.breakpoints.down("xs")]:{
        fontSize:"12px",
    }
  },
  danger: {
    color: "#F00"
  },
  bracketIframe: {
    width: '100%',
    minHeight: '700px',
    border: 'none',
  },

  root: {
    flexGrow: 1,
    color: "#fff",
    padding: theme.spacing(4, 0),
    "& span": {
      fontFamily: "Quicksand",
      fontWeight:700,
      fontSize: "18px",
    },
  },
  tab_label: {
    minWidth: "120px",
  },
  info_skeleton_img:{
      height:"100px",
      width:"100px",
    [theme.breakpoints.down("xs")]: {
      height:"65px",
      width:"65px",
    },
  },
  info_skeleton: {
      height:"80px",
      width:"200px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
    },
  },
  price_skeleton: {
    borderRadius: theme.spacing(1),
    width: "40%",
    background: "rgba(23, 42, 68, 0.1)",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  img: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginRight: "8px",
    [theme.breakpoints.down("sm")]: {
      width: "65px",
      height: "65px",

    },
  },
  card_info: {
    display: "flex",
    flexDirection: "column",
  },
  card_info_name: {
    fontWeight: 700,
    fontFamily: "Quicksand",
    fontSize: "24px",
    [theme.breakpoints.down("xs")]:{
      fontSize:"16px",
  }
  },
  start_time:{
    marginBottom:"12px",
    [theme.breakpoints.down("sm")]:{
        fontSize:"10px",   
    }
  },
  enroll_button_section:{
    display:"flex", 
    gap:"8px" , 
    alignItems:"center",
    boxSizing:"border-box",
    [theme.breakpoints.down("sm")]:{
      flexDirection:"column" , 
 
  }

  },
  card_info_date: {
    fontSize: "12px",
    fontWeight: 700,
    marginTop: "8px",
    fontFamily: "Quicksand",
  },
  price: {
    fontSize: "12px",
    fontWeight: 300,
    marginTop: "8px",
    fontFamily: "Quicksand",
    color: "#00B628",
  },
  info_section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  info_section_price: {
    borderRadius: theme.spacing(1),
    
  },
  title_section: {
    display: "flex",
    justifyContent: "space-between",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal_container: {
    backgroundColor: "#0F2139",
    borderRadius: "8px",
    width: "400px",
    padding: theme.spacing(6),
    color: "#fff",
    "& h2": {
      fontSize: "26px",
      margin: "0px",
    },
  },
  extra_info: {
    marginBottom: theme.spacing(1),
    fontSize: "12px",
    fontFamily: "Quicksand",
    fontWeight:300,
  },
  team_info_section: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
  },
  prize_container: {
    display: "flex",
    padding: "16px 0px",
  },
  divider: {
    backgroundColor: "#172A44",
  },
  container__enroll: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
  },
  scroll_box: {
    width: "100%",
    flexShrink: 0,
  },
  subcription_box: {
    margin: theme.spacing(2 , 0),
    display: "flex",
    justifyContent: "space-between",
  },
  wallet_confirm_box: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  main_info_section: {
    display: "flex",
    gap: "12px",
  },
  "@media only screen and (max-width: 768px)": {
    main_info_section: {
      flexDirection: "column",
    },
  },
}));

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        flex: "1 1 0",
        background: "linear-gradient( #050310 , #070515)",
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]:{
            width:"100%",
        },
        "&::-webkit-scrollbar": {
            display:"none",
          },
    },
}));

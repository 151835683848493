import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    container:{
        width:"600px",
        flex:"1 1 0",
        // backgroundColor:"#0B0D21",
        color:"white",
        boxSizing:"border-box",
        fontFamily:"Quicksand",
        "& hr": {
            border: "1px solid rgba(90, 121, 165, 0.12)"
        },
        [theme.breakpoints.down("sm")]:{
          width:"320px"
        }
    },
    heroSection:{
      height:"25vh",
      width:"100%",
      background:"linear-gradient(135deg, #FD6585 0%, #0D25B9 100%)",
      backgroundSize:"cover",
      marginBottom:"32px",
      display:"flex",
      justifyContent:"center",
      alignItems:"flex-end",
      "& h2": {
        fontSize:"40px",
        textAlign:"center"
    },
    },
    tagSection:{
      display:"flex",
      flexWrap:"wrap",
      gap:"20px",
      marginBottom:"32px"
    },
    interest:{
      padding: theme.spacing(1 , 2),
      border: "1px solid #FFFFFF",
      borderRadius: "20px",
      gap:"20px",
      display:'flex',
      alignItems:"center",
      cursor:"pointer"
    },
    buttonSection:{
      display:"flex",
      justifyContent:"flex-end"
    }
}));

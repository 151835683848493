import { Avatar, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import { debounce, get, isEmpty } from "lodash";
import { axiosInstance } from "../../helpers/client";
import { useHistory } from 'react-router-dom';
import { useGame } from '../../hooks/useGame';
import { handlePage } from '../../helpers/paginator';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';


export default function Leaderboard() {

    const classes = useStyles()
    const history = useHistory();
    const [ filter , setFilter ] = useState({
      tournament:"all", type:"user" ,game:""
    })
    const { t } = useTranslation();

    const [ collection , setCollection] = useState( {
        data:[] , links:{}
      })

    const [endpoint, setEndpoint] = useState( ``);
    const { data:game , isLoading:gameLoading } = useGame('query' , 'getGames' , 'fromGames');

    const [ tournaments , setTournaments ] = useState([]);
    const [ search , setSearch ] = useState("");
    const [ brand , setBrand ] = useState({})
    const [rows, setRowsPerPage] = useState(5);
    const [ loading , setLoading ] = useState({
      table:true , icons:false , banner:true
    });

  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
    };

  
    const getLeaderboardTournaments =  () => {
      setLoading({...loading, icons:false})
      const url = filter.game === "" 
      ? `api/leaderboards/tournaments?type=${filter.type}` 
      : `api/leaderboards/tournaments?type=${filter.type}&game=${filter.game}`

      axiosInstance.get(url).then(function ({ data }) {
          setTournaments(data.data);
        })
        .finally(() => setLoading({...loading, table:false , icons:false}))
    };
  
    const getLeaderboard = async () => {
      setLoading({...loading, table:true , icons:false})
      await axiosInstance.get(endpoint)
        .then(function ({ data }) {
          setCollection(data);
        })
        .finally(() => setLoading({...loading, table:false , icons:false}))
    };
  
    const getConfig = () => {
      setLoading({...loading, banner:true})
      axiosInstance(`api/configurations/leaderboard`)
      .then(({data}) => setBrand(data.data))
      .finally(() => setLoading({...loading, banner:false}))
    }
  


      const schema = [
        {
          label: t("name"),
          func: ({ item }) => {
            const player = get(item, "player");
            const choice = filter.type === 'user' ? player?.username : player?.name;
            const endpoint =  filter.type === 'user' ? `/home/player/${choice}` : `/home/team/${player?.slug}`
    
            return (
              <div className={classes.imgLogo} onClick={() => history.push(endpoint)}>
                <Avatar src={player?.avatar ?? player?.banner} alt={player?.username} />
                <span>{choice}</span>
              </div>
            )
          }
        },
        {
          label: t("tournament_played"),
          func: ({ item }) => get(item, "tournaments_played"),
        },
        {
          label: t("attendance_points"),
          func: ({ item }) => get(item, filter.tournament === "all" ? "total_attendance_points" :"tournament_attendance_points"),
        },
        {
          label: t("tournament_points"),
          func: ({ item }) => get(item,  filter.tournament === "all" ? "total" :"tournament_points"),
        },
        {
          label: "GAP",
          func: ({ item }) => get(item,  "overall_points" ),
        },
      ];    

      if(filter.tournament !== "all") schema.pop()
     

      
  useEffect(() => {
    !gameLoading && setFilter({...filter, game: game[0].slug });
  }, [ gameLoading ]);



  useEffect(() => {
    if(filter.game === "") return
    const url =  ( 
      filter.tournament === "all" 
        ? `api/leaderboards?game=${filter.game}&type=${filter.type}` 
        : `api/leaderboards?game=${filter.game}&type=${filter.type}&tournament=${filter.tournament.slug}` 
    )
    setEndpoint(url)

  },[filter ])

  const handleTournamentFilter = (value) => {
    setFilter({...filter , tournament:value , game:!!value.games ? value.games[0].slug : filter.game})
  }


  const searchUsers = debounce(() => {
    const params = new URLSearchParams(endpoint)
    if (search) {
      params.append("search", search)
    } else {
      params.delete("search")
    }
   setEndpoint(unescape(params.toString()))
  },3000)

  useEffect(() => {
      searchUsers();
  },[search])
  

  // useEffect(() => {filter.game !== "" && getLeaderboard()}, [endpoint])
  useEffect(() => {filter.game !== "" && getLeaderboardTournaments()}, [filter.type , filter.game])
  useEffect(() => getConfig(),[])

    
    return (
        <div className={classes.container}>
          <div className={classes.top_section}>
            {
              isEmpty(brand) ? 
              <>
                <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={150} width={150} className={classes.info_skeleton_rect} animation="wave"/>
                <div className={classes.description}>
                    <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={20} width={200} className={classes.info_skeleton_rect} animation="wave"/>
                    <Skeleton style={{backgroundColor:"#0F2139"}} variant="rect" height={10} width={300} className={classes.info_skeleton_rect} animation="wave"/>
                </div>
              </>

              : 
              <>
                <div className={classes.img}  style={{backgroundImage: `url("${brand.banner}")`}} ></div>
                <div className={classes.description}>
                    <h3>{brand.title}</h3>
                    <p>{brand.description}</p>
                </div>
              </>
            } 
          </div>
            <div style={{display:"flex" , justifyContent:"space-between" , alignItems:"center" }}>
              <div className={classes.filterSection} >
                <Select
                  variant="outlined"
                  className={classes.select}
                  value={filter.type}
                  name="type"
                  defaultValue={filter.type}
                  onChange={(e) => setFilter({...filter , type:e.target.value})}
                >
                  <MenuItem  value="user">User</MenuItem>
                  <MenuItem  value="team">Team</MenuItem>
                </Select>
                <Select
                  variant="outlined"
                  className={classes.select}
                  name='game'
                  value={filter.game}
                  onChange={(e) => setFilter({...filter , game:e.target.value})}
                >
                  {
                      !gameLoading && game.map(({name , slug} , index) =>  <MenuItem key={index} value={slug}>{name}</MenuItem> )
                  }
                </Select>
                <Select
                  variant="outlined"
                  className={classes.select}
                  name='tournament'
                  value={filter.tournament}
                  defaultValue="all"
                  onChange={(e) => handleTournamentFilter(e.target.value)}
                >
                  <MenuItem  value="all">Overall</MenuItem>
                  {
                      tournaments.map((item , index) =>  <MenuItem key={index} value={item}>{item.name}</MenuItem> )
                  }
                </Select>
            </div>
          </div>
        <Table   
            handlePrev={() => handlePage(setEndpoint ,collection , 'prev')} 
            handleNext={() => handlePage(setEndpoint ,collection , 'next')} 
            total={100} 
            schema={ schema }  
            collection={collection.data} 
            links={collection.links} 
            loading={loading.leaderboard}
            shortLoading={true} 
            handleChangeRowsPerPage={handleChangeRowsPerPage} 
            rowsPerPage={rows}/>
        </div>
    )
}

import React, { useContext, useState } from 'react';
import { useStyles } from './styles';
import {  TextField } from '@material-ui/core';
import Button from '../../components/Button/Button';
import { Link, useHistory } from 'react-router-dom';
import  { Formik  }from 'formik'
import * as Yup from 'yup';
import { axiosInstance } from '../../helpers/client';
import { AppContext } from '../../context/appContext';
import { handleSocial } from '../Login/Login';
import PasswordField from '../../components/PasswordField/PasswordField';
import PhoneNumberField from '../../components/PhoneNumberField/PhoneNumberField';
import Logo from '../../asset/img/google-icon.png'
import Discord from '../../asset/img/discord-icon.png'
import Facebook from '../../asset/img/facebook.png'
import Twitch from '../../asset/img/twitch-icon.png'
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import logo from '../../asset/img/logo.png'

const Register = () => {
    const history = useHistory();
    const classes = useStyles();
    const {setLogin, setUser} = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const referralCode = params.get("referral");
    const {t} = useTranslation();
    const [countryCode, setCountryCode] = useState("234");

    const handleChangeCountryCode = (e) => {
        setCountryCode(e.target.value);
    }

    const handleSubmit = async ({role , name , username , email, password , phone_number ,referred_by } , {setFieldError}) => {
        setLoading(true);
        await axiosInstance.post('api/register',{
            username,
            password,
            name,
            password_confirmation:password,
            email,
            phone_number: `+${countryCode}${phone_number}`,
            referred_by,
            role
        }).then(function ({data}) {
            localStorage.setItem("token" , data.data.token);
            localStorage.setItem("user" , JSON.stringify(data.data.user));
            setUser(data.data.user);
            setLogin(true)
            window.location.href = "/verify-email"
        })
        .catch(function ({response}) {
            const errors = response.data.errors;
            Object.keys(errors).map((error) => setFieldError(error , errors[error][0]))
        })
        .finally(() => { 
            setLoading(false)
        });
    }

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required.").min(1, "Name is Too Short."),
        username: Yup.string().required("Username is Required.").min(1, "Username is Too Short."),
        referred_by: Yup.string().label("Referrer Username"),
        phone_number:  Yup.string().required().matches(phoneRegExp, 'Phone number is not valid').label('Phone Number'),
        email: Yup.string().email().required("Email is Required."),
        password: Yup.string().required("No password provided.").min(8, "Password is too short - should be 8 chars minimum.")
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    
    return (
        <div className={classes.container} >
             <div style={{display:'flex', justifyContent:"flex-end",paddingRight:"20px"}}>
                <LanguageSwitch />
            </div>
            <div style={{display:"flex" , justifyContent:"center"}}>

            <div className={classes.login_section}>
                <h2  className={classes.title} >{t("welcome_to")}  <img onClick={() => history.push('/home')} style={{marginBottom:"-8px", cursor:"pointer"}} src={logo} alt="logo" width="100px" /></h2>
                <p className={classes.info_top}>{t("join_our_community")}</p>
                
                <Formik
                    initialValues={{ name: "",username:"" ,phone_number:"", email:"", password:"" , password_confirmation:"", role:"gamer" , referred_by: referralCode ?? ""}}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                {({ handleSubmit, handleChange, errors , values ,touched }) => (
                    <>
                    <div className={classes.input}>
                        <div className={classes.input_container}>
                            <TextField  className={classes.textfield}  onChange={handleChange("name")} name="name"  variant="outlined" label={t("name")} />
                            {touched.name && errors.name && (
                                <span className={classes.content__form_error}>{errors.name}</span>
                                )}
                        </div>
                        <div className={classes.input_container}>
                            <TextField className={classes.textfield}  onChange={handleChange("username")} name="username"  variant="outlined" label={t("username")} />
                            {touched.username && errors.username && (
                            <span className={classes.content__form_error}>{errors.username}</span>
                            )}
                        </div>
                        <div className={classes.input_container}>
                            <TextField className={classes.textfield}  onChange={handleChange("email")} name="email"  variant="outlined" label="Email" />
                            {touched.email && errors.email && (
                                <span className={classes.content__form_error}>{errors.email}</span>
                                )}
                        </div>
                        <div className={classes.input_container}>
                            {/* <TextField className={classes.textfield}  onChange={handleChange("phone_number")} name="phone_number"  variant="outlined" label={t("number")} /> */}
                            <PhoneNumberField countryCode={countryCode} handleCountryCode={handleChangeCountryCode} className={classes.textfield} onChange={handleChange("phone_number")} name="phone_number"  variant="outlined" label={t("number")} />

                            {touched.phone_number && errors.phone_number && (<span className={classes.content__form_error}>{errors.phone_number}</span>)}
                        </div>
         
                        <div className={classes.input_container}>
                            <PasswordField onChange={handleChange("password")}  />
                        
                            {touched.password && errors.password && (
                                <span className={classes.content__form_error}>{errors.password}</span>
                                )}
                        </div>
                        <div className={classes.input_container}>
                            <TextField className={classes.textfield} value={values.referred_by} onChange={handleChange("referred_by")} name="referred_by" disabled={!!referralCode}  variant="outlined" label={t("referrer_username")} />
                            {touched.referred_by && errors.referred_by && (
                            <span className={classes.content__form_error}>{errors.referred_by}</span>
                            )}
                        </div>
                        <div className={classes.input_container}>
                            <Button loading={loading} classes={{text:classes.button_text}} backgroundColor="#B00610" name={t("register")} onClick={handleSubmit}/>
                        </div>
                    </div>
                    </>
                    )}
                </Formik>
                <p className={classes.info}>{t("or_sign_up")}</p>
                <div className={classes.icons}>
                <img style={{cursor:"pointer"}} src={Logo} height="35px" width="35px"  alt="google logo" onClick={() => handleSocial("google")}/>
                    <img style={{cursor:"pointer"}} src={Discord} height="40px" width="40px"  alt="logo"  onClick={() => handleSocial("discord")}/>
                    <img style={{cursor:"pointer"}} src={Twitch} height="40px" width="40px"  alt="logo"  onClick={() => handleSocial("twitch")}/>
                    <img style={{cursor:"pointer"}} src={Facebook} alt="logo" height="30px" width="30px"  onClick={() => handleSocial("facebook")}/>
                </div>
                <p className={classes.join}>{t("already_have_account")}<Link style={{
                    color:"#17a2b8"
                }} to="/login">{t("login")}</Link> </p>
            </div>
            </div>
        </div>
    )
}

export default Register
import React, { useContext, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import {  MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Textfield from '../../../components/Common/Textfield';
import Select from '../../../components/Common/Select';




const CreateAchievement = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);


    const handleSubmit = (e) => {
        console.log(e);
        isEmpty(edit) ? createActivity(e) : updateActivity(e)
    }

    const validationSchema = Yup.object().shape({
        activity: Yup.string().required("Activity is Required"),
        frequency: Yup.string().required(),
        value: Yup.number().positive().required(),
        number: Yup.number().positive().required(),
        description: Yup.string().required(),
        starts_at: Yup.date().required().label('Start Date'),
        ends_at: Yup.date().required().label('End Date').min(Yup.ref('starts_at'),'End Date field must be later than Start Date'),
    });



    const activityType = {
        'post': 'Post',
        'comment':'Comment',
        'like_post':'Like Post',
        'like_comment':'Like Comment',
        'purchase':'Purchase',
        'join_tournament':'Join Tournament',
        'share_tournament':'Share Tournament',
        'share_post':'Share Post',
    };

    const frequency = {
        'daily': 'Daily',
        'weekly':'Weekly',
        'monthly':'Monthly',
        'yearly':'Yearly',
    };

    

    const formik = useFormik({
        initialValues: {
            activity: edit.activity ?? "",
            frequency: edit.frequency ?? "",
            value: edit.value ?? "",
            number: edit.number ?? "",
            description: edit.description ?? "",
            starts_at: edit.starts_at ?? new Date(),
            ends_at: edit.ends_at ?? new Date(),
            is_for_first_users: edit.is_for_first_users ?? true,
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });



    


    const createActivity = async (data) => {

        setLoading(true)
        await axiosInstance.post("api/activity-challenges" , data ).then(function ({ data }) {
            setFeedback({message:data.message , severity:"success"});
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
            setLoading(false)
        });
    }


    const updateActivity = async (formData ) => {
        setLoading(true)
        

        await axiosInstance.put(`api/activity-challenges/${edit.id}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
        <>
            <h2>Activity</h2>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"12px",

            }}>

                <Select
                    full
                    formik={formik}
                    info="Challenge activity type"
                    name="activity"
                    onChange={(e) => formik.setFieldValue('activity', e.target.value)}
                >
                    {
                            Object.keys(activityType).map((item , i) => <MenuItem key={i} value={item}>{activityType[item]}</MenuItem> )
                    }
                </Select>
                <Select
                    full
                    info="How often the challenge can be completed"
                    formik={formik}
                    name="frequency"
                    onChange={(e) => formik.setFieldValue('frequency', e.target.value)}
                >
                    {
                            Object.keys(frequency).map((item , i) => <MenuItem key={i} value={item}>{frequency[item]}</MenuItem> )
                    }
                </Select>
                <Textfield full info="The amount rewarded to users who complete the challenge" formik={formik} onChange={formik.handleChange} value={formik.values.value} placeholder="Value"  name="value" fullWidth />
                <Textfield full info="The amount of times the activity must be completed to complete the challenge. If `is_for_first_users` is true, this is the number of users who can complete the challenge"  formik={formik} onChange={formik.handleChange} value={formik.values.number} title="Number"  name="number" fullWidth />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className={classes.date}>
                        <DateTimePicker label="Start Time" inputVariant="outlined" value={formik.values.starts_at} onChange={(date) => formik.setFieldValue('starts_at' , date)} fullWidth/>
                        {formik.touched.starts_at && formik.errors.starts_at ? (
                            <span className={classes.content__form_error}>{formik.errors.starts_at}</span>
                        ) : null}
                    </div>
                    <div className={classes.date} >
                        <DateTimePicker label="End Time" inputVariant="outlined" value={formik.values.ends_at} onChange={(date) => formik.setFieldValue('ends_at' , date)} fullWidth/>
                        {formik.touched.ends_at &&  formik.errors.ends_at ? (
                            <span className={classes.content__form_error}>{formik.errors.ends_at}</span>
                        ) : null}
                    </div>
                </MuiPickersUtilsProvider>
                <Textfield full formik={formik} onChange={formik.handleChange} value={formik.values.description} placeholder="Brief description about Game"  fullWidth name="description" minRows="3" multiline />
                <FormControlLabel
                    className={classes.textfield}
                    value={formik.values.is_for_first_users}
                    name="is_for_first_users"
                    onChange={(e) => formik.setFieldValue("is_for_first_users" , e?.target.checked)}
                    control={<Checkbox checked={formik.values.is_for_first_users}  style={{color:"#fff"}}/>}
                    label="is only for the first users to complete it"
                    labelPlacement="end"
                    />
                <div style={{display:"flex" , justifyContent:"flex-end"}}>
                    <Button loading={loading} name="Save"  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                </div>
            </div>
        </> 
    )
}



export default CreateAchievement
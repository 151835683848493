import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    container:{
        backgroundColor:"#0B0D21",
        padding:"0px 16px",
        color:"white",
        boxSizing:"border-box",
        fontFamily:"Quicksand",
        "& hr": {
            border: "1px solid rgba(90, 121, 165, 0.12)"
        },
        [theme.breakpoints.down("sm")]:{
          display:"none"
        }
    },community:{
        marginTop:theme.spacing(2),
    },
    card_info: {
      display: "flex",
      flexDirection: "column",
    },
    top_tournamenrt_section:{
        display:"flex",
        flexDirection:'column',
        gap:theme.spacing(4)
    },

    icons:{
      display:"flex",
      width:"80%",
      margin: theme.spacing(2,0),
      justifyContent:"space-between",
      alignItems:"center"
    },
    image_section:{
      display:"flex",
      flexDirection:"column",
      alignItems:"flex-start"
      
  },
  content__form_error:{
    fontSize:"12px",
    color:"red",
  },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      create_section:{
        display:"flex",
        gap:"12px",
        flexDirection:"column",
        width:"600px",
        [theme.breakpoints.down("sm")]:{
          width:"320px",

        }
      },
      modal_container: {
        backgroundColor: "#0F2139",
        borderRadius:"8px",
        width:"400px",
        padding: theme.spacing(3),
        color:"#fff",
        "& h2":{
            fontSize:"26px",
            marginBottom: "8px",
            marginTop: "0px"
        },
        
    },
    create_button:{
      alignSelf:"flex-end"
    },
}));

import { axiosInstance } from "helpers/client";
import React, { createContext, useState } from "react";




const initial = {
    wallet:{},
    refetch: () => {}
}
export const WalletContext = createContext(initial);

const  WalletStateProvider = ({ children }) => {

    const [wallet, setWallet] = useState({
      amount: 0,
      currency: "USD",
      gc_amount: 0,
    });

    const getWalletInfo = async () => {
      await axiosInstance
        .get("api/wallet")
        .then(function ({ data }) {
          setWallet(data.data);
        })
        .catch(({ response }) => console.log(response));
    };



  
    return (
      <WalletContext.Provider
        value={{
          wallet,
          refetch:() =>  getWalletInfo()
        }}
        
      >
            {children}
      </WalletContext.Provider>
    );
  }
  
  export default WalletStateProvider;
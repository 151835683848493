import React, { useContext, useEffect, useState } from "react";
import { useHistory , useLocation} from "react-router-dom";
import { useStyles } from "./styles";
import Logo from "../../asset/img/logo.png";
import {
  Avatar,
  IconButton,
  Popover,
  TextField,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../Button/Button";
import { BiLogOut } from "react-icons/bi";
import { IoWalletOutline } from "react-icons/io5";
import { AppContext } from "../../context/appContext";
import { axiosInstance } from "../../helpers/client";
import Wallet from "../Wallet/Wallet";
import CreateTournament from "../CreateTournament/CreateTournament";
import { RiAdminFill } from 'react-icons/ri'
import Modal from "../Modal/Modal";
import { t } from "i18next";
import NotificationIcon from "../Notification/NotificationIcon";
import CartIcon from "../Cart/CartIcon";
import { WalletContext } from "context/walletCotext";
import Deposit from "../Payment/Deposit";
import BadgeCard from "../BadgeCard/BadgeCard";


const NavBar = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  const token = localStorage.getItem("token");
  const searchParams = new URLSearchParams(window.location.search);
  const response = JSON.parse(searchParams.get("response"));
  const reference = searchParams.get("transaction_id") ?? response?.id;
  const paymentType =  searchParams.get("paymentType") ?? "credit";
  const paypalToken =  searchParams.get("token");
  const payerID =  searchParams.get("PayerID");
  const gateway =  searchParams.get("gateway") ?? "flutterwave";
  const [open, setOpen] = useState({
    coins:false , payment:false , tournament:false , withdraw:false
  });
  const [ query , setQuery ] = useState("");
  const { wallet , refetch} = useContext(WalletContext);
  const adminType = location.pathname.split('/')[1].toString() === "admin";
  const { loggedIn, user, setLogin } = useContext(AppContext);


  const tryNewDashboard = (e) => {
    // e.preventDefault();
    history.push('/new/admin');
  }

  const goToSubscription = (e) => {
    history.push('/home/subscription');
  }

  const verifyPayment = async (reference) => {
    await axiosInstance
      .post("api/wallet", {
        reference,
        channel: gateway,
      })
      .then(() => {
        refetch();
      })
      .catch(({ response }) => {
        console.log(response.data);
      });
  };

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);


  useEffect(() => {
    if (reference && paymentType === "credit") {
      verifyPayment(reference);
    }
  }, [reference]);

  useEffect(() => {
    if (paypalToken && payerID) {
      verifyPayment(paypalToken);
    }
  }, [reference]);

  const toggleModal = ( name , value) => {
    setOpen({...open , [name]:value});
  };


  const logout = async () => {
    await axiosInstance.post("api/logout").then(() => {
      setLogin(false);
      localStorage.clear();
      window.location.href = "/home";
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handlePopClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    const params = new URLSearchParams()
    if (query) {
      params.append("q", query)
    } else {
      params.delete("q")
    }
    history.push({search: params.toString()})
  }, [query, history])



  return (
    <div className={classes.container}>
      <div className={classes.img_section} onClick={() => history.push("/home")}>
        <img src={Logo} width="100px" alt="logo" />
      </div>
      <div className={classes.search_section}>
        <TextField
          name="search"
          onChange={(e) => setQuery(e.target.value)}
          onBlur={() => history.push('/home/search')}
          InputProps={{
            className: classes.input_field,
            startAdornment: (
              <InputAdornment position="start" >
                <SearchIcon  />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          className={classes.textfield}
        />

        {loggedIn ? (
          <>
            <BadgeCard onClick={goToSubscription} name={user.is_pro ? "Gamr Pro" : "Gamr Free"} />
            
            {
              adminType ? (
                <BadgeCard onClick={tryNewDashboard} name="Try the new dashboard" />
              ) : <div></div>
            }
            
          </>
        ) : (
          <>
            <BadgeCard name="Join Gamr Pro for Just $1.99" />
          </>
        )}
      </div>

      
      {loggedIn ? (
        <>
          <div className={classes.logged_in}>
              <Button
                
                name={ adminType ? "Play" : "Organize"}
                onClick={() => history.push( adminType ? "/home" : "/admin/tournament")}
                startIcon={<RiAdminFill className={classes.icon} />}
              />
          </div>
          <div style={{display:"flex" }}>
            {/* <LanguageSwitch /> */}
            <CartIcon />

            <IconButton color="inherit" >
              <IoWalletOutline
                size={25}
                aria-describedby={id}
                onClick={handleClick}
               
              />
              <Popover
                classes={{
                  paper: classes.paper,
                }}
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handlePopClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Wallet
                  currency={wallet?.currency}
                  amount={wallet?.amount}
                  wallet={wallet}
                  handleDepositPayment={() => toggleModal('payment' , true)}
                />
              </Popover>
            </IconButton>
            <NotificationIcon />
            <IconButton color="inherit" onClick={logout}>
              <BiLogOut size={25}  />
            </IconButton>
            
            <div
              className={classes.profile_section}
              onClick={() => history.push("/home/profile")}
            >
              <Avatar src={user.avatar} alt={user.username} className={classes.profile}/>
            </div>
          </div>
        </>
      ) : (
        <div className={classes.auth}>
          {/* <LanguageSwitch /> */}
          <CartIcon />
          <Button name={t("login")} onClick={() => history.push(`/login`)} />
          <Button
            name={t("join")}
            margin="0px 8px"
            backgroundColor="#B00610"
            onClick={() => history.push(`/register`)}
          />
        </div>
      )}
     
      <Modal open={open.tournament} onClose={() => toggleModal('tournament' , false)}>
          <CreateTournament handleClose={() => toggleModal('tournament', false)} />
      </Modal>
      <Deposit open={open.payment} onClose={() => toggleModal('payment' , false)}/>
    </div>
  );
};

export default NavBar;

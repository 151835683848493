import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
      container:{
        padding:theme.spacing(4),
        color:"#fff"
      },
      cartItem:{
        borderRadius:"10px",
        display:"flex",
        padding:theme.spacing(2, 2.5),
        height:"200px",
        gap:"24px",
        backgroundColor:"rgba(7, 13, 35, 0.5)"
      },
      counterSection:{
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"column",
        alignItems:"flex-end",
        [theme.breakpoints.down("sm")]:{
          fontSize:"12px"
        }

      },
      type:{
        padding:theme.spacing(1 , 2),
        background: "rgba(255, 255, 255, 0.05)",
        borderRadius: "4px",
        textTransform:"uppercase",
        fontSize:"8px",
        alignSelf:"flex-end"
      },
      cartItemImage:{
        width:"150px",
        height:"150px",
        backgroundSize:"cover",
        [theme.breakpoints.down("sm")]:{
          width:"50px",
          height:"50px",
        }
      },
      productInfo:{
        fontSize:"24px",
         flex:"1 1 0",
         [theme.breakpoints.down("sm")]:{
          fontSize:"16px"
        }
      },
      remove:{
        color:"red",
        cursor:"pointer"
      },
      mainSection:{
        display:"flex",
        gap:"16px",
        [theme.breakpoints.down("sm")]:{
          flexDirection:"column"
        }
      },
      line:{
        border: "1px solid rgba(255, 255, 255, 0.06)",
        margin: theme.spacing(2, 0)
      },
      itemSection:{
        width:"70%",
        display:"flex",
        gap:"8px",
        flexDirection:"column",
        [theme.breakpoints.down("sm")]:{
          width:"100%",
        }
      },
      walletSection:{
        padding:"24px",
        display:'flex',
        justifyContent:"space-between",
        borderRadius:"10px",
        backgroundColor:"rgba(16, 16, 34, 0.42)",
        margin: theme.spacing(2, 0)
      },
      info:{
        display:"flex",
        flexDirection:"column",
        gap:"8px",
        marginBottom:"32px"
      },
      notification_count:{
        padding:"4px",
        borderRadius:"50%",
        backgroundColor:"red",
        fontSize:"8px",
        textAlign:"center",
        position:"absolute",
        top:0,
        right:0,
      },
      summarySection:{
        display:"flex",
        flexDirection:"column",
        marginBottom:"24px",
        flexGrow:1,
        gap:"24px",
        "& h2":{
          marginBottom:"16px"
        }
      },
      paymentSection:{
        display:"flex",
        flexDirection:"column",
      },
      paymentButtonSection:{
        display:"flex",
        gap:"8px",
        flexDirection:"column",
      },
      AddEmail:{
        display:'flex',
        flexDirection:"column",
        gap:"8px",
        marginBottom:"8px",
      },
      summaryItem:{
        display:"flex",
        justifyContent:"space-between"
      }
   ,formSection:{
    display:"flex",
    gap:"16px",
   
    // flexWrap:"wrap"
   },
   text:{
      flexShrink:0,
      width:"100%",
   },
   textSection:{
    display:"flex",
    flexDirection:"column",
    marginBottom:"8px",
    gap:"8px",
    width:"50%"
   },
   content__form_error:{
    color:"red",
    fontSize:"12px"
   }
}));

import { makeStyles, } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    containerBorderRed:{
        borderRadius: theme.spacing(1),
        width:"88%",
        minHeight:"420px",
        flex:"1 1 auto",
        padding: theme.spacing(2, 4),
        color:"white",
        background: "rgba(23, 42, 68, 0.1)",
        border: "1px solid #FF0E10",
        [theme.breakpoints.down("sm")] : {
            width:"78%"
        }
    },
    container:{
        borderRadius: theme.spacing(1),
        width:"88%",
        minHeight:"280px",
        flex:"1 1 auto",
        padding: theme.spacing(2, 4),
        color:"white",
        background: "#0B0D21",
        [theme.breakpoints.down("sm")] : {
            width:"78%"
        }
    },
    containerSm:{
        borderRadius: theme.spacing(1),
        width:"88%",
        minHeight:"80px",
        flex:"1 1 auto",
        padding: theme.spacing(2, 4),
        color:"white",
        background: "#0B0D21",
        [theme.breakpoints.down("sm")] : {
            width:"78%"
        }
    },
    title:{
        fontSize: "18px",
        fontWeight: 700,
        color: "white",
        marginBottom: "18px",
        marginTop: "18px"
    },
    subTitle:{
        fontSize: "14px",
        fontWeight: 400,
        color: "white",
        marginBottom: "18px",
        marginTop: "18px"
    },
    headerTitle:{
        fontSize: "14px",
        fontWeight: 700,
        color: "#00ff99",
        marginBottom: "18px",
        marginTop: "18px"
    },
    divider: {
        border: "0.5px solid #888",
        marginTop:"18px",
    },
    detail:{
        fontSize:"12px",
        fontFamily:"Quicksand"
    },
    listItemWrapper: {
        display: "flex",
        padding: theme.spacing(1, 1),
    },
    listIcon: {
        position: "relative",
        fontSize: "18px",
        top: "4px",
        marginRight: "4px"
    },
    listItem:{
        fontSize:"14px",
        fontFamily:"Quicksand",
        marginBottom:"8px"
    },
    amount: {
        float: "right",
        fontWeight: 700,
    },
    subAmount: {
        fontSize: "22px",
        float: "right",
        fontWeight: 700,
    },
    muted: {
        fontSize: "14px",
        marginTop: "4px",
        color: "#939393",
    },
    superScript: {
        position: "relative",
        fontSize: "10px",
        top: "-6px"
    },
    cancelBtn: {
        float: "right",
        padding: "0.8rem",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "700"
    },
    danger: {
        color: "#f00",
        fontSize: "14px",
        fontWeight: "700" 
    },
    success: {
        color: "#00ff61",
        fontSize: "14px",
        fontWeight: "700" 
    },
    primary: {
        color: "#1f64d5",
        fontSize: "14px",
        fontWeight: "700" 
    }
}));

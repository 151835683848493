import { axiosInstance } from "./client";

export const initializePayment = async (type , body) => {
    const redirect = type === "flutterwave" ? "link" : "authorization_url"
    await axiosInstance.post(`api/payments/${type}/initialize`, body)
      .then(({ data }) => {
        if(type !== "paypal"){
          return window.location.href = data.data[redirect];
        }

        window.location.href = data.links[1].href;
      })
      .catch(({ response }) => {
        console.log(response);
      })
  };
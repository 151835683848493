import { format } from "date-fns"


export const convertISOTime = (date) => {
    let d = new Date(0)
    return  d.setUTCSeconds(date)
}

export const getPreviousDays = (day) => {
    return format( new Date(new Date().setDate(new Date().getDate() - day)), 'yyyy-MM-dd');
    
  }
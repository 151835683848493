import { MenuItem, Select, TextField } from '@material-ui/core';
import { getLocations } from 'api/market';
import { useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { CartContext } from '../../context/cartContext';
import Button from '../Button/Button';
import CartItem from './CartItem';
import { useStyles } from './styles';
import * as Yup from "yup";
import PaymentModal from './PaymentModal';




export default function Checkout() {
    const classes = useStyles();
    const { items, total } = useContext(CartContext)
    const { isLoading, data:locations } = useQuery('getLocations', getLocations)
    const searchParams = new URLSearchParams(window.location.search);
    const paymentType =  searchParams.get("paymentType");
    const [open, setOpen] = useState(paymentType === "credit" ?? false)    

    const handleClose = () => {
        setOpen(false)
        searchParams.delete("paymentType")
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is Required"),
        first_name: Yup.string().required("First name is Required"),
        last_name: Yup.string().required("Last name is Required"),
        number: Yup.string().required("Phone Number is Required"),
        address: Yup.string().required("Address is Required"),
        location_id:  Yup.number().required("Location is required")

    });
   

    const handleSubmit = () => {
         setOpen(true)
    }


    const formik = useFormik({
        initialValues:{
            fee:0,
            location_id:"",
            email:"",
            first_name:"",
            last_name:"",
            number:"",
            address:"",
            type:"hardware"
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    })

    const handleChangeLocation = (e) => {
        formik.setFieldValue('location_id' , e.target.value.id)
        formik.setFieldValue('fee' , e.target.value.fee)
    }

    return (
        
        <div className={classes.container}>
            <h1>Checkout</h1>
            <div className={classes.mainSection}>
                <div className={classes.itemSection}>
                   <div>
                        <h2>Contact Information</h2>
                        <div className={classes.formSection}>
                            <div className={classes.textSection}>
                                <p>Phone Number</p>
                                <TextField fullWidth  variant="outlined" value={formik.values.number} name="number" onChange={formik.handleChange}/>
                                {formik.touched.number  && formik.errors.number ? (
                                        <span className={classes.content__form_error}>{formik.errors.number}</span>
                                    ) : null}
                            </div>
                            <div className={classes.textSection}>
                                <p>Email</p>
                                <TextField fullWidth placeholder={"email"} variant="outlined" value={formik.values.email} name="email" onChange={formik.handleChange} />
                                {formik.touched.email  && formik.errors.email ? (
                                        <span className={classes.content__form_error}>{formik.errors.email}</span>
                                    ) : null}
                            </div>
                        </div>
                   </div>
                   <div>
                        <h2>Shipping Information</h2>
                        <div className={classes.formSection}>
                            <div className={classes.textSection}>
                                <p>First Name</p>
                                <TextField  fullWidth  variant="outlined" value={formik.values.first_name} name="first_name" onChange={formik.handleChange}/>
                                {formik.touched.first_name  && formik.errors.first_name ? (
                                        <span className={classes.content__form_error}>{formik.errors.first_name}</span>
                                    ) : null}
                            </div>
                            <div className={classes.textSection}>
                                <p>Last Name</p>
                                <TextField fullWidth variant="outlined" value={formik.values.last_name} name="last_name" onChange={formik.handleChange} />
                                {formik.touched.last_name  && formik.errors.last_name ? (
                                        <span className={classes.content__form_error}>{formik.errors.last_name}</span>
                                    ) : null}
                            </div>
                        </div>

                        <div className={classes.formSection}>
                            <div className={classes.textSection}>
                                <p>Address</p>
                                <TextField  fullWidth  variant="outlined" value={formik.values.address} name="address" onChange={formik.handleChange}/>
                                {formik.touched.address  && formik.errors.address ? (
                                        <span className={classes.content__form_error}>{formik.errors.address}</span>
                                    ) : null}
                            </div>
                            <div className={classes.textSection}>                                          
                                <p>Shipping Location</p>
                                <Select
                                    className={classes.textfield}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    variant="outlined"
                                    size="small"
                                    name="location"
                                    fullWidth
                                    onChange={handleChangeLocation}
                                    value={formik.values.location}
                                >
                                    {
                                        !isLoading && (
                                            locations.map((item, index) => 
                                            <MenuItem  key={index} value={item}>
                                                {item.name}
                                            </MenuItem>)
                                        )
                                    }
                                    
                                </Select>  
                                {formik.touched.location_id  && formik.errors.location_id ? (
                                        <span className={classes.content__form_error}>{formik.errors.location_id}</span>
                                    ) : null} 

                            </div>
                        </div>
                   </div>

                </div>
                <div className={classes.summarySection}>
                    <h2>Product Summary</h2>
                    {
                        items.map((item, i) => 
                            <CartItem 
                                key={i}
                                slug={item.slug}
                                quantity={item.quantity} 
                                name={item.name} 
                                price={item.price} 
                                image={item.image}
                                summary={false} />)
                    }

                    <div className={classes.summaryItem}>
                        <span>Price</span>
                        <span>NGN{total}</span>
                    </div>
                    <div className={classes.summaryItem}>
                        <span>Total Shipping</span>
                        <span>NGN{formik.values.fee ?? 0}</span>
                    </div>
                    <div className={classes.summaryItem}>
                        <span>Discount</span>
                        <span>0%</span>
                    </div>
                    <div className={classes.summaryItem}>
                        <span>Total Price</span>
                        <span>NGN{total + formik.values.fee}</span>
                    </div>
                    <Button name={`Pay NGN${total + formik.values.fee}`} backgroundColor="#B00610" onClick={formik.handleSubmit}/>
                </div>
            </div>
            <PaymentModal open={open} handleClose={handleClose} formik={formik} />
        </div>
    )
}



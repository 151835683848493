import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './context/appContext';
import "./helpers/i18n";

ReactDOM.render(
  <React.StrictMode>
     {/* <ErrorBoundary
            FallbackComponent={Error}
            onError={(error, errorInfo) => {
                // log the error
            console.error(error);  
            console.error(errorInfo);
		
		// record the error in an APM tool...
            }}

            onReset={() => {
              // reloading the page to restore the initial state
              // of the current page
              window.location.reload();

              // other reset logic...
          }}
        > */}
       
          <React.Suspense fallback="Loading...">
            <AppProvider>
              <App />
            </AppProvider>
          </React.Suspense>
        {/* </ErrorBoundary> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Avatar, CircularProgress } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import React , { useEffect, useRef, useState, useContext }from 'react'
import Button from '../../components/Button/Button';
import { axiosInstance } from '../../helpers/client'
import { convertISOTime } from '../../helpers/date';
import { useStyles } from './styles';
import { AppContext } from '../../context/appContext';
import { useTranslation } from 'react-i18next';
import { FaWallet, FaCoins } from "react-icons/fa";
import SubscriptionCard from '../../components/SubscriptionCard/SubscriptionCard';
import SubscriptionDetails from '../../components/SubscriptionCard/SubscriptionDetails';
import SubscriptionTransaction from '../../components/SubscriptionCard/SubscriptionTransaction';
import TransactionCard from '../../components/TransactionCard/TransactionCard';
import Modal from "../../components/Modal/Modal";
import mtnLogo from '../../asset/img/mtn-logo.png';

export default function Subscription() {
    const [isPro, setIsPro] = useState(false);
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [openModalOne, setOpenModalOne] = useState(false);
    const [openModalTwo, setOpenModalTwo] = useState(false);
    const [openModalThree, setOpenModalThree] = useState(false);
    const [transactions, setTransactions] = useState([]);

    const [phoneNumber, setPhoneNumber] = useState('');

    const ref = useRef();
    const classes = useStyles();
    const { t } = useTranslation();

    const {setFeedback, loggedIn, user, setLogin} = useContext(AppContext);

    useEffect(() => {
        getSubscriptionPlans();
        getMyWalletBalance();
        getMySubscription();
        getWalletTrasactions();
    }, []);

    const handleModalOneOpen = () => {
        setOpenModalOne(true);
        setOpenModalTwo(false);
    }

    const handleModalOneClose = () => {
      setOpenModalOne(false);
    }

    const handleModalTwoOpen = () => {
        setOpenModalOne(false);
        setOpenModalTwo(true);
    }

    const handleModalTwoClose = () => {
      setOpenModalTwo(false);
    }

    const handleModalThreeOpen = () => {
        setOpenModalOne(false);
        setOpenModalTwo(false);
        setOpenModalThree(true);
    }

    const handleModalThreeClose = () => {
        setOpenModalThree(false);
    }

    const handleInputChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPhoneNumber(e.target.value);
        }
    }

    const getSubscriptionPlans = async () => {
        await axiosInstance.get("api/subscription-plans" ).then(function ({data}) {
            setPlans(data.data);
        }).catch(function ({response}) {
            setFeedback({message: "Error loading subscriptions plans"});
        });
    }

    const getMySubscription = async () => {
        await axiosInstance.get("api/subscriptions/active" ).then(function ({data}) {
            setSubscription(data.data);
        }).catch(function ({response}) {
            setFeedback({message: "Error loading active subscription details"});
        });
    }

    const getMyWalletBalance = async () => {
        await axiosInstance.get("api/wallet" ).then(function ({data}) {
            setWallet(data.data)
        }).catch(function ({response}) {
            console.log(response)
        });
    }

    const getWalletTrasactions = async () => {
        await axiosInstance.get('api/wallet/transactions').then(({data}) => {
            setTransactions(data.data)
        })
    }

    const subscribeWithMTN = async (plan) => {
        setLoading(true);
        await axiosInstance.post(`api/subscriptions/subscribe/mtn`, { 
            phone_number: user.phone,
            channel: 'mtn', 
            plan: plan.slug
        }).then(function ({data}) {
            setFeedback({message:data.message});
            logout();
        }).catch(({ response }) => {
            setFeedback({message: response.data.message, severity:"error"});
            if(response.data.status == "info"){
                setOpenModalThree(true);
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    const subscribeWithMtnPhoneNumber = async (plan) => {
        if(phoneNumber.length < 10 || phoneNumber === ''){
            setFeedback({message: "Invalid phone number, check the number and try again!"});
        }else{
            setLoading(true);
            await axiosInstance.post(`api/subscriptions/subscribe/mtn`, { 
                phone_number: phoneNumber,
                channel: 'mtn', 
                plan: plan.slug
            }).then(function ({data}) {
                setFeedback({message:data.message});
                logout();
            }).catch(({ response }) => {
                setFeedback({message: response.data.message, severity:"error"});
            }).finally(() => {
                setLoading(false)
            });
        }       
    }

    const subscribeWithWallet = async (plan) => {
        setLoading(true);
        await axiosInstance.post(`api/subscriptions/subscribe/wallet`, { 
            channel: 'wallet', 
            plan: plan.slug
        }).then(function ({data}) {
            setFeedback({message:data.message});
            logout();
        }).catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"});
        }).finally(() => {
            setLoading(false)
        });
    }

    const cancelSubscription = async () => {
        setLoading(true);
        await axiosInstance.post(`api/subscriptions/subscribe/cancel`).then(function ({data}) {
            setFeedback({message:data.message});
            logout();
        }).catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"});
        }).finally(() => {
            setLoading(false)
        });
    }

    const logout = async () => {
        await axiosInstance.post("api/logout").then(() => {
            // Set a timeout to call the function after 5000 milliseconds (5 seconds)
            const timeoutId = setTimeout(() => {
                setLogin(false);
                localStorage.clear();
                clearTimeout(timeoutId);
                window.location.href = "/home";
            }, 5000);
        });
    }

    const modalStyle = {
        width: '420px', // Set the desired width
        margin: 'auto', // Center the modal horizontally
        minHeight: '420px',
        padding: '20px',
        borderRadius: '8px',
    }

    const centerContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Optional: Set a height for full-height centering
        padding: '20px', // Optional: Add padding for better mobile appearance
        boxSizing: 'border-box',
    }

    const subscriptionData = {
        title: "Current Plan",
        detail: "Gamr Pro Plan",
        amount: "1.99",
        status: "active",
        endDate: "15th Feb, 2024",
        channel: "Card" 
    }

    const transacationData = {
        title: "Transaction", 
        detail: "All my subscription transaction data here...",
        data: [

        ]
    }

    return user.is_pro ? (
        <div className={classes.container}>
            <div>
                <br />
                <h3 className={classes.h3}>{t("Subscription")}</h3>
                {
                    subscription && (
                        <SubscriptionDetails {...subscription} className={classes.basic} handleCancel={cancelSubscription}  />
                    )
                }
                <br />
                {
                    transactions && subscription && ( 
                        <SubscriptionTransaction transactionsData={transactions} {...subscription}/>
                    )
                }  
            </div>   
        </div>
    ) : (
        <div className={classes.container}>
            <div style={centerContainerStyle}>
                <div>
                    <br />
                    <h3 className={classes.h3}>{t("Gamr Pro with Gamr")}</h3>
                    <p> {t(`Compete on a new level. Plans start at only $1.99/month. Cancel anytime`)} </p>
                    <br />
                    {plans.length > 0 ?
                        plans.map(({name, description, period, period_value, subscription_value, benefits}, index) =>  
                        <SubscriptionCard 
                            key={index}
                            name={name}
                            description={description}
                            periodValue={period_value}
                            subscriptionValue={subscription_value}
                            period={period}
                            benefits={benefits}
                        />)
                        : <p> {t("loading")}</p>
                    }
                    <br />
                    <Button className={classes.btnMTN} hasImage={true} imageUrl={mtnLogo}  backgroundColor="#FFCC08" name={t("Go Pro with ")} onClick={handleModalOneOpen} /> 
                    <br /> <br />
                    <Button className={classes.btnPrimary} name={t("Go Pro with Wallet Balance")} onClick={handleModalTwoOpen}/> 
                </div>   
            </div>

            {/* Subscribe using MTN */}
            <Modal size="sm"
                  open={openModalOne}
                  onClose={handleModalOneClose}
                >
                  <div ref={ref}>
                    <div style={modalStyle}>
                        <br />
                        <br />
                        <h2>{t("Subscribe using your MTN mobile phone number")}</h2>
                        <br />
                        <p className={classes.h5}>{t("Text GM to 5700 @ N1,000 for Monthly Subscription")}</p>
                        <br />
                        <p className={classes.h5}>{t("Text GW to 5700 @ N500 for Weekly Subscription")}</p>
                        <br />
                        <p className={classes.h5}>{t("Text GD to 5700 @ N100 for Daily Subscription")}</p>
                        <br />
                        <p className={classes.p}> 
                            <span className={classes.warning}>{t("Note")}: </span>
                            {t("The amount subscribed will be debited from your MTN Airtime")}
                        </p>
                        <br />
                        <Button 
                              className={classes.btnRed} backgroundColor="#B00610" name={t("Continue")}
                              onClick={() => subscribeWithMTN(plans[0])}/>
                    </div>
                  </div>
            </Modal>

            {/* Subscribe using Wallet Balance */}
            <Modal size="sm"
                  open={openModalTwo}
                  onClose={handleModalTwoClose}
                >
                  <div ref={ref}>
                    <div style={modalStyle}>
                        <h2>{t("Amount Payable")}</h2>
                        <br />
                        <p className={classes.p}>{t("This is the amount you are to pay for your Gamr pro subscription")}</p>
                        <br />
                        <div className={classes.subcriptionBox} >
                            <div className={classes.subscriptionAmount}>
                                ${plans.length > 0 && (plans[0].subscription_value)}
                            </div>

                            {wallet && (
                                <div className={classes.walletBalance}>
                                    <p className={classes.p}>
                                        <FaWallet className={classes.icon} /> Available USD: 
                                        <span className={classes.amount}> ${wallet.amount}</span>
                                    </p>
                                    <br />
                                    <p className={classes.muted}>
                                        <FaCoins className={classes.icon} /> Available Coin: 
                                        <span className={classes.amount}> {wallet.gc_amount}GC</span>
                                    </p>
                                </div>
                            )}
                        </div> 
                        <hr className={classes.divider} />
                        <p className={classes.p}> <span className={classes.warning}>{t("Note")}:</span> {t("Amount will be deducted from your Gamr wallet")}</p>
                        <br />

                        {plans.length > 0 && (<Button 
                            className={classes.btnRed} backgroundColor="#B00610" name={t("Go Pro")}
                              onClick={() => subscribeWithWallet(plans[0])}/>)}
                    </div>
                  </div>
            </Modal>

            {/* Subscribe using Form Input MTN Number  */}
            <Modal size="sm"
                  open={openModalThree}
                  onClose={handleModalThreeClose}
                >
                  <div ref={ref}>
                    <div style={modalStyle}>
                        <h2>{t("Subscribe using MTN mobile number")}</h2>
                        <br />
                        <p className={classes.p}>{t("Kindly, enter your MTN number to subscribe")}</p>
                        <br /><br />
                        <div className={classes.subcriptionBox} >
                            <div className={classes.container}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div>
                                        <TextField
                                          label="Phone Number"
                                          id="outlined-start-adornment"
                                          value={phoneNumber}
                                          sx={{ m: 1, width: '35ch' }}
                                          placeholder="080XXXXXXXX"
                                          InputLabelProps={{ style: { color: "white" } }}
                                          InputProps={{
                                            style: {color: "white"},
                                            startAdornment: <InputAdornment position="start"><span className={classes.inputAdorment}>+234</span></InputAdornment>,
                                          }}
                                          className={classes.inputText}
                                          onChange={handleInputChange}
                                        />
                                    </div>
                                </Box>
                            </div>
                        </div> 
                        <br /><br />
                        <p className={classes.p}> <span className={classes.warning}>{t("Note")}:</span> {t("You must have subscribe via SMS from your mobile device, Gamr will verify the MTN number and activate your subscription")}</p>
                        <br />

                        {plans.length > 0 && (<Button 
                            className={classes.btnRed} backgroundColor="#B00610" name={t("Continue to Gamr Pro")}
                              onClick={() => subscribeWithMtnPhoneNumber(plans[0])}/>)}
                    </div>
                  </div>
            </Modal>
        </div>
    )
}

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
},  
options:{
  display:"flex",
  justifyContent:"flex-end"
},
list: {
  width: "30vw",
  height:'100%',
  padding:"32px",
  backgroundColor:"#0B0D21",
  boxSizing:"border-box",
  color:"#FFF",
  '& h2':{
    fontSize:"32px"
  }
},
group:{
  marginBottom:"32px",
},
statusSection:{
  display:"flex",
  paddingBottom:"24px",
  justifyContent:"space-between"
},
status:{
  display:"flex",
  gap:"8px"
},
subtitle:{
  padding:"12px",
  backgroundColor: "#121B33",
  borderRadius:"8px"
},
customerSection:{
  display:"flex",
  gap:"8px"
},
itemsSection:{
  display:"flex",
  flexDirection:'column',
  padding: theme.spacing(1 , 0),
  gap:"24px"
},
detailSection:{
  display:"flex",
  justifyContent:"space-between"
},
img:{
  minWidth:"50px",
  height:"50px",  
  borderRadius: "50%",
  backgroundSize:"cover",
  backgroundPosition:"center"
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  }
},                                              
}));

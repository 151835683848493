import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { debounce, isEmpty } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';



const AddLeaderboard = ({  handleClose , edit , game , refetch}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);
    const [ search , setSearch ] = useState('');
    const [ searchTournament , setSearchTournamnt ] = useState('')
    const [ users , setUsers ] = useState([]);
    const [ userTournament , setUserTournament ] = useState([]);

    const createLeaderboardRecord =  (data) => {
        setLoading(true)

        let form = {};

        form.game = isEmpty(edit) ? data.tournament.games[0].slug : data.game
        form.type = data.type;
        form.tournament =  data.tournament.slug
        form.name =  isEmpty(edit) ? (data.type === "user" ?( search ?? data.name) : (users[0].slug ?? data.name)) : data.name
        form.points = data.points
        form.attendance_points = data.attendance_points

         axiosInstance.post("api/leaderboards" , form ).then(function ({ data }) {
            setFeedback({message:data.message , severity:"success"});
            refetch()
            handleClose()
        })
        .catch(({ response }) => {
            response.data.status === 400 
            ? setFeedback({message:response.data.errors[0] , severity:"error"})
            : setFeedback({message:response.data.error , severity:"error"})
        })
        .finally(() => {
            setLoading(false)
        });
    }

    const validationSchema = Yup.object().shape({
        points: Yup.number().required().positive().integer().label("Points"),
        attendance_points: Yup.number().required().positive().integer().label("Attendance Points"),
    });

    

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            game: edit.game ?? "",
            tournament: edit.tournament ?? {},
            points: edit.tournament_points ?? "",
            attendance_points: edit.tournament_attendance_points ?? "",
            type: edit.type ?? "user",
        },
        onSubmit: createLeaderboardRecord,
        validationSchema:validationSchema
    });

    
    const handleChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        formik.setFieldValue("name" , value);
    };

    const getParticipant = debounce( () => {
        axiosInstance.get(`api/players/search/${formik.values.type}s?q=${search}`).then(({data}) => {
            setUsers(data.data)
        })
    }, 1000)

    const getTournament = debounce(() => {
         axiosInstance.get(`api/tournaments/search?q=${searchTournament}`).then(({data}) => {
            setUserTournament(data.data)
        })
    }, 1000)
    

    useEffect(() => {
        if (search.length >= 3) getParticipant()
    } ,[search])

    useEffect(() => {
        if (searchTournament.length >= 3 ) getTournament()
    } ,[searchTournament])


    return (
        <>
            <h2>{isEmpty(edit) ? "Add Record" : "Update Record"}</h2>
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:"12px",

            }}>
                {
                    isEmpty(edit) &&
                    <Select
                        variant="outlined"
                        fullWidth
                        name='type'
                        value={formik.values.type}
                        onChange={formik.handleChange} 
                    >
                        <MenuItem  value="user">User</MenuItem> 
                        <MenuItem  value="team">Team</MenuItem> 
                    </Select>
                }
                <div >
                    {
                        isEmpty(edit) ?
                            <Autocomplete
                                id="combo-box-demo"
                                onInputChange={(e , v) => setSearch(v)}
                                options={users}
                                getOptionLabel={(option) => formik.values.type === "user" ? option.username : option.name}
                            
                                renderInput={(params) => <TextField {...params} onChange={handleChange} variant="outlined" fullWidth placeholder="Name" />}
                                />
                        :
                        <TextField value={edit.display}  variant="outlined" fullWidth disabled/>
                    }
                   
                    {formik.touched.name  && formik.errors.name ? (
                            <span className={classes.content__form_error}>{formik.errors.name}</span>
                        ) : null}
                </div>
                <div >
                    <TextField onChange={formik.handleChange} value={formik.values.points} placeholder="Point" variant="outlined" name="points" fullWidth type="number"/>
                    {formik.touched.points  && formik.errors.points ? (
                            <span className={classes.content__form_error}>{formik.errors.points}</span>
                        ) : null}
                </div>  
                <div >
                    <TextField onChange={formik.handleChange} value={formik.values.attendance_points} placeholder="Attendance Point" variant="outlined" name="attendance_points" fullWidth type="number"/>
                    {formik.touched.attendance_points  && formik.errors.attendance_points ? (
                            <span className={classes.content__form_error}>{formik.errors.attendance_points}</span>
                        ) : null}
                </div>  
                {
                    isEmpty(edit) && 
                    <>   
                     <Autocomplete
                        id="combo-box-demo"
                        onInputChange={(e , v) => setSearchTournamnt(v)}
                        onChange={(e , v) => formik.setFieldValue('tournament' , userTournament[0])}
                        options={userTournament}
                        getOptionLabel={(option) => option.name }
                        renderInput={(params) => <TextField {...params} variant="outlined" fullWidth placeholder="Tournament" />}
                        />
                    </>
                }

                <div style={{display:"flex" , justifyContent:"flex-end"}}>
                    <Button loading={loading} name={isEmpty(edit) ? "Add" : "Save"}  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                </div>
            </div>
        </> 
    )
}



export default AddLeaderboard
import React from 'react'
import { useStyles } from './styles';

const InfoCard = ({title , detail  }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.info}>
                <p className={classes.title}>{title}</p>
                <hr />
                <p className={classes.detail}>{detail}</p>
            </div>
        </div>
    )
}

export default InfoCard;
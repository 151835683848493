import React, { useContext, useState } from 'react';
import {  TextField } from '@material-ui/core';
import Button from "../../../components/Button/Button";
import { useStyles } from './styles';
import { axiosInstance } from '../../../helpers/client';
import { AppContext } from '../../../context/appContext';
import DateFnsUtils from '@date-io/date-fns';

import { useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from 'lodash';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ImageUploader from '../../../components/ImageUploader/ImageUploader';
import { format } from 'date-fns';
import { t } from 'i18next';



const CreateEvent = ({  handleClose , edit}) => {
    const classes = useStyles();
    const [loading , setLoading ] = useState(false)
    const {  setFeedback } = useContext(AppContext);

    const handleSubmit = () => {
        isEmpty(edit) ? createEvent() : updateEvent()
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        image_file: isEmpty(edit) ? Yup.string().required("Banner is Required") : Yup.string(),
        location:Yup.string().required("Must enter Location"),
        started_at:Yup.date().required().label("Start Date"),
        ended_at:Yup.date().min(Yup.ref('started_at') , 'End Date field must be later than Start Date').required().label('End Date')
    });

    const formik = useFormik({
        initialValues: {
            name: edit.name ?? "",
            image_file: edit.banner ?? "",
            bannerImage:"",
            description: edit.description ?? "",
            location:edit.location ?? "",
            lng:edit.lng ?? "",
            lat:edit.lat ?? "",
            started_at: edit.started_at ?? new Date(),
            ended_at: edit.ended_at ?? new Date(),
        },
        onSubmit: handleSubmit,
        validationSchema:validationSchema
    });

    const handleFile = (e) => {
   
        let reader = new FileReader();
        let baseURL = "";
    
        try {
            reader.readAsDataURL(e?.target?.files[0]);
        } catch (e) {
            formik.setFieldValue("image_file" , "")
            formik.setFieldValue("bannerImage" , "")
            return false;
        }
        reader.onload = () => {
            baseURL = reader.result;
            formik.setFieldValue("image_file" , baseURL)
            formik.setFieldValue("bannerImage" , e?.target.files[0])
        };
    }

    const handleChange = address => {
        formik.setFieldValue('location' , address)
    };
    
     
    const handleSelect = address => {
        formik.setFieldValue('location' , address)
        geocodeByAddress(address).then(results => getLatLng(results[0]))
          .then(({ lat , lng }) => {
              formik.setFieldValue('lng' , String(lng))
              formik.setFieldValue('lat' , String(lat))
            })
          .catch(error => console.error('Error', error));
      };

    


      const createEvent = async () => {
        setLoading(true)
        const eventForm = {...formik.values };

        const startDate = format(new Date(eventForm.started_at), "yyyy-MM-dd'T'hh:mm:ss");
        const endDate = format(new Date(eventForm.ended_at), "yyyy-MM-d'T'hh:mm:ss");

        const formData = {...formik.values , started_at:startDate , ended_at:endDate};

        await axiosInstance.post("api/events/store" , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
           setFeedback({message:response.data.message , severity:"error"})

            // response.data.status === 400 
            // ? setFeedback({message:response.data.errors[0] , severity:"error"})
            // : setFeedback({message:response.data.error , severity:"error"})

        })
        .finally(() => {
            setLoading(false)
            handleClose()
        })
    }


    const updateEvent = async () => {
        setLoading(true)
        const eventForm = {...formik.values };

        const startDate = format(new Date(eventForm.started_at), "yyyy-MM-dd'T'hh:mm:ss");
        const endDate = format(new Date(eventForm.ended_at), "yyyy-MM-d'T'hh:mm:ss");

        const formData = {...formik.values , started_at:startDate , ended_at:endDate};
 
        await axiosInstance.put(`api/events/update/${edit.slug}` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            handleClose()
        })
        .catch(({ response }) => {
            setFeedback({message:response.data.message , severity:"error"})
        })
        .finally(() => setLoading(false))
    }
    

    return (
            <>
                <h2>{isEmpty(edit) ? t("create_event"): t("update_event")}</h2>
                <div style={{
                    display:"flex",
                    flexDirection:"column",
                    gap:"12px",
                }}>
                            

                    <div className={classes.image_section}>
                        <ImageUploader handleFile={handleFile} file={ isEmpty(edit) ? formik.values?.bannerImage :  formik.values?.image_file} />
                        {formik.errors.image_file ? (
                            <span className={classes.content__form_error}>{formik.errors.image_file}</span>
                        ) : null}
                    </div>
                    <div>
                        <TextField onChange={formik.handleChange}  value={formik.values.name}  placeholder={t("name")} variant="outlined" name="name" fullWidth />
                        {formik.touched.name  &&  formik.errors.name ? (
                                <span className={classes.content__form_error}>{formik.errors.name}</span>
                            ) : null}
                    </div>
                    <div>
                        <PlacesAutocomplete
                            value={formik.values.location}
                            onChange={handleChange}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <TextField  {...getInputProps({placeholder: t("location"),className: 'location-search-input',})}  variant="outlined" name="address" fullWidth />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, i) => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : classes.suggestion_item;
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' ,color:"black"}
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' , color :"black" };
                                        return (
                                        <div
                                            key={i}
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
                        {formik.touched.location  &&  formik.errors.location ? (
                                    <span className={classes.content__form_error}>{formik.errors.location}</span>
                                ) : null}
                    </div>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className={classes.date}>
                            <DateTimePicker label={t("start_time")} inputVariant="outlined" value={formik.values.started_at} onChange={(date) => formik.setFieldValue('started_at' , date)} />
                            {formik.touched.started_at && formik.errors.started_at ? (
                                <span className={classes.content__form_error}>{formik.errors.started_at}</span>
                            ) : null}
                        </div>
                        <div className={classes.date} >
                            <DateTimePicker label={t("end_time")} inputVariant="outlined" value={formik.values.ended_at} onChange={(date) => formik.setFieldValue('ended_at' , date)} />
                            {formik.touched.ended_at &&  formik.errors.ended_at ? (
                                <span className={classes.content__form_error}>{formik.errors.ended_at}</span>
                            ) : null}
                        </div>
                    </MuiPickersUtilsProvider>


                    <TextField onChange={formik.handleChange}  value={formik.values.description}  placeholder={t("description")} variant="outlined" fullWidth name="description" minRows="3" multiline />
                    <div style={{display:"flex" , justifyContent:"flex-end"}}>
                        <Button loading={loading} name={isEmpty(edit) ? t("create_event") : t("update_event") }  backgroundColor="#B00610" className={classes.create_button} onClick={formik.handleSubmit}/>
                    </div>
                </div>
            </> 
                              
        )
    }



export default CreateEvent
import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

    container:{
        display:"flex",
        height:"50px",
        cursor:"pointer"
    },
    image:{
        height:"40px",
        width:"40px",
        borderRadius:"50%",
        backgroundSize:"cover",
        marginRight:"8px"
    },
    card_info:{
        display:"flex",
        flexDirection:"column",
        gap:theme.spacing(0.5)
    },
    card_info_name:{
        fontSize:"12px",
        width:"140px",
        textOverflow:"ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontWeight:700,
        fontFamily:"Quicksand"
    },
    card_info_date:{
        fontSize:"11px",
        fontWeight:500,
        fontFamily:"Quicksand"
    },
    price:{
        fontSize:"11px",
        fontWeight:500,
        fontFamily:"Quicksand",
        color:"#00B628"
    }
}));

import { MenuItem, Select } from '@material-ui/core'
import React from 'react'
import uk from '../../asset/img/uk.png'
import france from '../../asset/img/france.png'
import { useTranslation } from 'react-i18next';

export default function LanguageSwitch() {

  const { i18n } = useTranslation();

  return (
    <Select
        style={{ marginLeft:"8px"}}
        value={i18n.language}
        onChange={(e) => i18n.changeLanguage(e.target.value)}
    >
        <MenuItem value="en">
            <div style={{display:"flex" ,gap:"4px", alignItems:"center"}}>
            <img src={uk} width="24px" alt="uk"/>
            <span>en</span>
            </div>
        </MenuItem>
        <MenuItem value="fr">
            <div style={{display:"flex" ,gap:"4px", alignItems:"center"}}>
            <img src={france} width="24px" alt="france"/>
            <span>fr</span>
            </div>
        </MenuItem>
    </Select>
  )
}

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  
    content__form_error:{
      fontSize:"12px",
      color:"red"
    },
  
  create_section:{
    display:"flex",
    justifyContent:"space-between",
  },
  button_text:{
    padding:theme.spacing(1 , 2)
  },
 
}));

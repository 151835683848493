import { IconButton } from '@material-ui/core'
import React from 'react'
import { useContext } from 'react';
import { CartContext } from '../../context/cartContext';
import { IoCartOutline } from 'react-icons/io5';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';



export default function CartIcon() {
    const classes = useStyles();
    const history = useHistory();
    const { items } = useContext(CartContext)

    return (
        <IconButton color="inherit" onClick={() => history.push('/home/cart')}>
            <div style={{position:"relative" , display:"flex" , alignItems:"flex-end"}}>
                <IoCartOutline size={25}/>
                {
                    items.length > 0 && <span className={classes.notification_count}>{items.length}</span>
                }
            </div>
        </IconButton>
    )
}

import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
  container:{
    color:"#fff",
    padding: theme.spacing(2 , 0),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
}, 
prize_container:{
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(3),
    marginBottom:theme.spacing(2)
},
input_section:{
    width:"45%",
    "& p":{
        fontSize:"18px",
        marginBottom:theme.spacing(1)
    },
    [theme.breakpoints.down('sm')] :{
      width:"100%"
    }
},
textfield:{
    
},

content__form_error:{
    fontSize:"12px",
    color:"red",
},
  button_text:{
    padding:theme.spacing(1.5 , 2)
  },
  input:{
      display:"flex",
      flexDirection:"column",
      gap:theme.spacing(2),
  },
  input_container:{
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(2),
},
  content__form_error:{
    fontSize:"12px",
    color:"red",
  },
}));

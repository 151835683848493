import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container:{
        padding: theme.spacing(2),
        color:"#fff",
        "& h2":{
            fontSize:"30px",
            margin:theme.spacing(2, 0)
        }
    }, 
    input_section:{
        width:"200px",
        "& p":{
            fontSize:"18px",
            color:"white",
            marginBottom:theme.spacing(2)
        }
    },
    cardSection: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '16px',
        columnGap: '16px',
        marginTop: '10px'
    }
}));

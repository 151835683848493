import React from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { useStyles } from './styles';

const SubscriptionCard = ({name, periodValue, description, subscriptionValue, period, benefits = []}) => {
    console.log(benefits);
    const classes = useStyles();
    return (
        <div className={classes.containerBorderRed}>
            <div className={classes.info}>
                <div>
                  <p className={classes.title}>
                    {name} 
                    <span className={classes.amount}>
                      <span className={classes.superScript}>$</span> {subscriptionValue}
                    </span>
                  </p>
                  <p className={classes.detail}>{description}</p>
                </div>
                <hr className={classes.divider} />
                <br />
                {benefits.map((item, index) => (
                  <div className={classes.listItemWrapper} key={index}>
                    <p className={classes.listItem} key={index}> <FaRegCheckCircle className={classes.listIcon} /> {item.description} </p>
                  </div>
                ))}
                
            </div>
        </div>
    )
}

export default SubscriptionCard;
import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({

  container:{
    color:"#fff",
    padding:theme.spacing(4, 2),
    "& h2":{
        fontSize:"30px",
        margin:theme.spacing(2, 0)
    }
},  
options:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
},
date:{
  display:"flex",
  flexDirection:'column',
  gap:theme.spacing(0.5)
},
icons:{
   display:"flex",
   gap: theme.spacing(1)
},
select:{
  color:"#5A79A5",
  width:"200px",
  margin:theme.spacing(2, 0),
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5A79A5"
    },
    "& .MuiOutlinedInput-input" :{
      padding: "12px 16px"
  }
}, 
header:{
  padding:theme.spacing(2 , 3),
  display:"flex",
  backgroundSize:"cover",
  backgroundPosition:"center",
  flexDirection:"column",
  height:"25vh",
  justifyContent:"space-between",
},
header_info:{
  display:"flex",
  justifyContent:"space-between"
},
title:{
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  "& h2":{
      fontSize:"32px",
      color:"#fff",
      margin:0
  }
},
name_section:{
  display:"flex",
  flexDirection:"column",
  "& h2":{
      fontSize:"26px",
      margin:theme.spacing(1, 0),
      [theme.breakpoints.down("sm")]:{
          fontSize:"20px"
      }
  }
},
image_section:{
  display:"flex",
  alignItems:"center",
  gap:"8px"
},
content__form_error:{
  fontSize:"12px",
  color:"red",
},
button_section:{
  display:"flex",
  gap:"8px",
  [theme.breakpoints.down("sm")]:{
      display:"none"
  }
},                                             
}));

import React , {useEffect, useRef, useState, useContext}from 'react';
import {useStyles} from './styles';
import {Snackbar, TextField} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '../../components/Button/Button';
import {Link} from 'react-router-dom';
import {useHistory, useParams} from 'react-router';
import * as Yup from 'yup';
import {axiosInstance} from '../../helpers/client';
import PasswordField from '../../components/PasswordField/PasswordField';
import {useFormik, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../context/appContext';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import logo from '../../asset/img/logo.png';

const Password = () => {
    const classes = useStyles();
    const history =  useHistory()
    const {token} = useParams();
    const [feedback, setFeedback] = useState({
        message: "", severity: ""
    });
    const [loading, setLoading] = useState(false);
    const {loggedIn, user, setLogin} = useContext(AppContext);
    const { t } = useTranslation();

    const handleSubmit = async ({password}) => {
        setLoading(true);
        await axiosInstance.post('api/auth/update/password', {
            password: password,
        }).then(function ({data}) {
            setFeedback({message: data.message});
            logout();
        }).catch(function ({response}) {
            setFeedback({message: "Error updating password!"});
        }).finally(() => { 
            setLoading(false)
        });
    }

    const handleChange = async () => {
        
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setFeedback({});
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("No password provided.").min(8, "Password is too short - should be 8 chars minimum.").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
        password_confirmation: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match').label("Password Confirmation")
    });

    const formik = useFormik({
        initialValues:{
            email: "",
            password: "",
            password_confirmation:""
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    const logout = async () => {
        await axiosInstance.post("api/logout").then(() => {
            // Set a timeout to call the function after 5000 milliseconds (5 seconds)
            const timeoutId = setTimeout(() => {
                setLogin(false);
                localStorage.clear();
                clearTimeout(timeoutId);
                window.location.href = "/home";
            }, 3000);
        });
    }

    return (
        <div className={classes.container} >
            <div style={{display:'flex', justifyContent:"flex-end",paddingRight:"20px"}}>
                <LanguageSwitch />
            </div>
            <div style={{display:"flex" , justifyContent:"center"}}>

                <div className={classes.login_section}>
                    <br />
                    <img onClick={() => history.push('/home')} style={{marginBottom:"-8px", cursor:"pointer"}} src={logo} alt="logo" width="100px" />
                    <br />
                    <br />
                    <h3>Update account password</h3>
                    <p>To add extra security to your account we recommend updating your password</p>
                    <br />

                    <div>
                        <div className={classes.input}>
                            
                            <div className={classes.input_container}>
                                <PasswordField id="password" onChange={formik.handleChange("password")} name="Password" />
                                {formik.errors.password && (
                                    <span className={classes.content__form_error}>{formik.errors.password}</span>
                                )}
                            </div>

                            <div className={classes.input_container}>
                                <PasswordField id="confirm_password" onChange={formik.handleChange("password_confirmation")} confirm={true} name="Confirm Password" label="Password Confirmation" />
                                {formik.errors.password_confirmation && (
                                    <span className={classes.content__form_error}>{formik.errors.password_confirmation}</span>
                                )}
                            </div>

                            <div className={classes.input_container}>
                                <Button loading={loading} backgroundColor="#B00610"  name={t("Update Password")} classes={{text:classes.button_text}} onClick={formik.handleSubmit}/>    
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
            <Snackbar open={!!feedback.message} autoHideDuration={6000} onClose={handleClose}  key={"bottom" + "left"}  anchorOrigin={{ vertical:"bottom", horizontal:"left" }}>
                <MuiAlert  elevation={6} variant="filled" onClose={handleClose} severity={feedback.severity}>
                        {feedback.message}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default Password
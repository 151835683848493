import React, { useState, useEffect } from "react";
import Table from "../../../components/Table/Table";
import { useStyles } from "./styles";
import { get } from "lodash";
import { axiosInstance } from "../../../helpers/client";

import inflection from "inflection";
import { IconButton } from '@material-ui/core';
import {  MdDelete, MdEdit } from 'react-icons/md'
import { handlePage } from "../../../helpers/paginator";
import Button from "../../../components/Button/Button";
import { useToggle } from "hooks/useToggle";
import Modal from "../../../components/Modal/Modal";
import CreateAchievement from "./CreateAchievement";
import { AppContext } from "context/appContext";
import { useContext } from "react";

export default function ManageAchievement() {
  const classes = useStyles();
  const [edit, setEdit] = useState({});
  const [ collection , setCollection] = useState({
    data:[] , links:{}
})
  const [page, setPage] = useState(0);
  const [rows, setRowsPerPage] = useState(5);
  const [endpoint, setEndpoint] = useState("api/activity-challenges");
  const [ loading , setLoading ] = useState({table:true});
  const { toggle, handleToggle } = useToggle();
  const { setFeedback } = useContext(AppContext);



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const getAchievements = async () => {
    setLoading({...loading , table:true})
    await axiosInstance
      .get(endpoint )
      .then(function ({ data }) {
        setCollection(data);
      })
      .catch(({ response }) => console.log(response))
      .finally(() => setLoading({...loading , table:false}))
  };

  const handleDelete = async (id) => {
    if(!window.confirm("Are you sure you want to delete this activity?")) return
    await axiosInstance.delete(`api/activity-challenges/${id}`).then(({data}) => {
      setFeedback({message:data.message , severity:"success"})
      getAchievements();
    }).catch(({response}) =>   setFeedback({message:response.data.message , severity:"error"}))
  }

  const schema = [
    {
      label: "Name",
      func: ({ item }) => {
        return inflection.titleize(get(item, "activity"));
      },
    },
    {
      label: "Frequency",
      func: ({ item }) => get(item, "frequency"),
    },
    {
      label: "Number",
      func: ({ item }) => get(item, "number"),
    },
    {
      label: "Completion",
      func: ({ item }) => get(item, "completion"),
    },
    {
        label: "Reward",
        func: ({ item }) => get(item, "value")

      },

    {
      label: "Actions",
      func: ({ item }) =>  {
       
            return (
            <div style={{ display:"flex" , gap:"8px" }}>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdEdit size={25} onClick={() => {
                   setEdit(item)
                   handleToggle(true)
                }}  />
              </IconButton>
              <IconButton color="inherit" style={{padding:"0"}}>
                <MdDelete size={25} onClick={() => handleDelete(item.id)}  />
              </IconButton>
              
            </div>
          )
        }
    },
  ];


  useEffect(() => {
    getAchievements();
  }, [rows]);

  return (
    <div className={classes.container}>
      <h2>Manage Achievements</h2>
      <div className={classes.options}>
          <Button
            classes={{ text: classes.button_text }}
            backgroundColor="#B00610"
            name={`Create Achievement`}
            onClick={() => handleToggle()}
          />            
      </div>

      <Table   
        handlePrev={() => handlePage(setEndpoint ,collection , 'prev')} 
        handleNext={() => handlePage(setEndpoint ,collection , 'next')} 
        total={100} 
        schema={schema}  
        collection={collection.data} 
        links={collection.links} 
        paginate={false}
        loading={loading.table}
        shortLoading={true}
        page={page} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        rowsPerPage={rows}/>
         <Modal open={toggle} onClose={() => handleToggle(false)}>
          <CreateAchievement handleClose={() => handleToggle(false)} edit={edit} />
      </Modal>
    </div>
  );
}

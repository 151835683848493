import React from 'react'
import Modal from '../Modal/Modal'
import { AppContext } from 'context/appContext';
import { axiosInstance } from 'helpers/client';
import { useState } from 'react';
import { useContext } from 'react';
import { CartContext } from '../../context/cartContext';
import Button from '../Button/Button';
import { useStyles } from './styles';
import coin from 'asset/svg/coin-bundle.svg'
import { WalletContext } from 'context/walletCotext';
import Deposit from '../Payment/Deposit';
import { useHistory } from 'react-router-dom';
import { initializePayment } from 'helpers/transaction';
import { useEffect } from 'react';



export default function PaymentModal({handleClose , open , formik}) {
    const classes = useStyles();
    const history = useHistory();
    const { items, total, clearCart } = useContext(CartContext)
    const { wallet , refetch} = useContext(WalletContext);
    const { setFeedback ,user, settings} = useContext(AppContext)
    const [loading , setLoading] = useState(false);
    const [ openPayment,  setOpenPayment ] = useState(false)
    const searchParams = new URLSearchParams(window.location.search);
    const response = JSON.parse(searchParams.get("response"));
    const reference = searchParams.get("reference") ??  (searchParams.get("transaction_id") ?? response?.id);
    const paymentType =  searchParams.get("paymentType");
    const totalAmount =  searchParams.get("totalAmount") ?? (total + formik.values.fee);
    const usdRate = !!settings && settings.find((data) => data.name === "usd_rate").data
    const insufficient = wallet.amount < ((totalAmount ) / (usdRate ?? 1.00)).toFixed(2)


    const closePayment = () => {
        setOpenPayment(false)
    }
    
    const handlePayment = () => {

        const gatewayData = {
          amount:total + formik.values.fee,
          currency:"NGN",
          payment_options: "id",
          redirect_url: `${process.env.REACT_APP_FRONT_DOMAIN}home/${items[0].type === 'digital' ? 'cart' :"checkout"}?paymentType=purchase&totalAmount=${total + formik.values.fee}`,
          meta: {
            payment_type: "purchase",
          },
          customizations: {
            title: "Order Payment",
            description: `${user.name} is making a purchase of ${total + formik.values.fee}`,
          },
        } 
       
        initializePayment("flutterwave" , gatewayData)
      }

    //   http://localhost:5000/home/checkout?paymentType=credit&totalAmount=1000&status=successful&tx_ref=Gamr_16968449476523cc93176ab&transaction_id=4647247

      const handleOrder = () => {
        
        const formData = JSON.parse(localStorage.getItem('order'));
        formData.reference = reference;

        setLoading(true)

        axiosInstance.post(`api/orders/store` , formData ).then( ({ data }) =>  {
            setFeedback({message:data.message , severity:"success"})
            clearCart()
            refetch()
            handleClose()
            history.push("/home/market")
        })
        .catch(({ response }) => {
            if(response.status === 422) {
                const errors = response.data.errors;
                Object.keys(errors).map((error) => 
                    setTimeout(() =>  setFeedback({message: errors[error][0], severity:"error"}), 500))

            } else {

                setFeedback({message:response.data.message , severity:"error"})
            }
        })
        .finally(() =>{
            localStorage.removeItem('order')
            setLoading(false)
        })
      }


    const submit = (channel) => {
       
        localStorage.removeItem('order')
        const productsArr = [];

        items.map((item) => {
            let product = {};
            product.slug = item.slug;
            product.quantity = item.quantity;
           return productsArr.push(product)
        })

        const formData = {};
        formData.products = productsArr

        Object.keys(formik.values).map((key) => (
            formData[key] = formik.values[key]
        ))

        localStorage.setItem('order', JSON.stringify(formData))

        if(channel === "flutterwave"){
            setLoading(true)
            return handlePayment();
        }

        if (insufficient) {
            return setOpenPayment(true)
        }

        handleOrder()
    }


    useEffect(() => {
        if(paymentType === "purchase"){
            handleOrder()
        }
    },[])



  return (
    
    <Modal open={open} onClose={handleClose} >
        <div className={classes.paymentSection}>
            <h1>Make Payments</h1>
            <p>Your purchase will be delivered to you after completing your payment </p>

            <hr className={classes.line}/>
            {
                insufficient &&
                <p style={{
                    color:"red",
                    fontSize:"14px"
                }}>Insufficient funds</p>
            }
            <div className={classes.walletSection}>
                <div>
                    <h2>Gamr Wallet</h2>
                    <p>Your Wallet balance is ${wallet.amount}</p>
                </div>
                <img src={coin} alt="coin" />
            </div>
            <div className={classes.info}>
                <p>$1 -  NGN{usdRate}</p>
                <p>You Pay - NGN{totalAmount} / USD { ((totalAmount ) / (usdRate ?? 1.00)).toFixed(2)}</p>
            </div>
            <div className={classes.paymentButtonSection} >
                <Button 
                    disabled={loading} 
                    name={insufficient ? "Fund Wallet": `Pay NGN${total + formik.values.fee}`} 
                    loading={loading} 
                    backgroundColor="#B00610" 
                    onClick={() => submit('wallet')}/>
                <Button 
                    disabled={loading} 
                    name={ `Pay NGN${total + formik.values.fee} with flutterwave`} 
                    loading={loading} 
                    backgroundColor="#B00610" 
                    onClick={() => submit('flutterwave')}/>
            </div>
        </div>
        <Deposit open={openPayment} onClose={closePayment} />

    </Modal>
  )
}

import { useCallback, useContext } from "react"
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query"
import { getGames } from "../api/games"
import { AppContext } from "../context/appContext"


export const useGame = (type ,  operation , endpoint ) => {
    const { setFeedback } = useContext(AppContext)
    const queryClient = useQueryClient()

    const getQuery = useCallback(() => {
        switch(operation){
            case "getGames":
                return getGames();
            default:
                return  getGames();
        }
    } , [operation])
    
    const mutation = useMutation(getQuery ) 
    const { data , error , isLoading } = useQuery( `${operation}` , getQuery  ) 

    if(mutation.error){
        const { response } = error
        response?.data?.message !== undefined && setFeedback({message:response.data.message , severity:"error"}) 
        response?.data?.errors !== undefined 
        ? setFeedback({message:response.data.errors[0] , severity:"error"}) 
        : setFeedback({message:response.data.error , severity:"error"})
    }

    if(mutation.isSuccess){
        queryClient.invalidateQueries(operation)
    }

    return {
        data,
        isLoading,
        mutation
    }
}
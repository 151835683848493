import { makeStyles } from "@material-ui/core/styles";
;

export const useStyles = makeStyles((theme) => ({
    container: {
        flex: "1 1 auto",
        flexDirection: "column",
        width: "200px",
        flexGrow: 0,
        borderRadius: "14px",
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        display: "flex",
        color: "white",
    },
    stat:{
        fontSize: "24px",
        fontWeight: 900,
        margin: theme.spacing(1, 0),
    },
    info:{
        fontWeight: 300,
        fontSize: "12px",
    },
    title:{
        fontSize: "16px",
        fontWeight: 600
    },
    detailSection:{
        display:"flex",
        justifyContent:"space-between",
    },
    detail: {
        display:'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    }
}));

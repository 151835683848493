
export const createFormObject = (object) => {
    const data = new FormData();
    
    Object.keys(object).map((item) => data.append(item , object[item]));
    return data
}

export const handleCheck = (event, formik) => {
    formik.setFieldValue( [event.target.name] ,  event.target.checked)
}
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { axiosInstance } from '../../helpers/client';
import { useStyles } from './styles';
 
const MyCarousel = () => {
    const classes = useStyles();
    //get banners from settings app context
    const [banners , setBanners] = useState({});
    const [loading , setLoading] = useState({
        banners:true
    })

    const getBanners = async () => {
        setLoading({...loading , banners:true})
        await axiosInstance.get("api/configurations/banner").then(function ({ data }) {
            setBanners(data.data);
        })
        .finally(() => setLoading({...loading , banners:false}))
    };


    useEffect(() => {
        getBanners()
    }, [])
    return (
        <Carousel swipe>
            {
                loading.banners ? (
                    <div className={classes.img} style={{ backgroundSize:"contain", backgroundImage: `url("https://res.cloudinary.com/aladeen/image/upload/v1659620590/gamrbanner_sww0jg.jpg")`
                }} />
                ) :
                Object.keys(banners.data).map((item , i) => 
                <a key={i} style={{cursor:"pointer"}} href={banners.link[i]}>
                     <div className={classes.img} key={i} style={{backgroundImage: `url(${banners.data[item]})`}} /> 
                </a>
                )
            }
        </Carousel>
    )
}

export default MyCarousel;
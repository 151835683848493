import { makeStyles } from "@material-ui/core/styles";
;
const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
		container:{
				width:"16%",
				display:"flex",
				flexDirection:"column",
				justifyContent:"space-between",
				overflowY:"scroll",
				backgroundColor:"#0B0D21",
				boxSizing:"border-box",
				color:"#546884",
				fontFamily:"Quicksand",
				"&::-webkit-scrollbar": {
					display:"none",
				},
				[theme.breakpoints.down("sm")]:{
					display:"none"
				}
		},
		footerLinks:{
			display:"flex",
			padding:theme.spacing(1),
			gap:"4px",
			'& p':{
				'&:hover':{
					cursor:"pointer"
				}
			}
		},
		close:{
			height:0,
			visibility:"hidden"
		},
		open:{
			height:'fit-content',
			paddingLeft:"32px",
		},
		icon:{
				color:"#546884",
				fontSize: "22px",
				minWidth: '35px',
		},
		text:{
				fontSize:"14px",
				fontFamily:"Quicksand",
				fontWeight:700
		}, 
		listItem_active: {
			marginTop: "12px",
			background: "#12162B",
			color: "#FFF",
		},
			listItem_inactive: {
				marginTop: "12px",
				"&:hover": {
					background: "#12162B",
					color: "#FFF",
				},
			},
			root: {
				display: 'flex',
				backgroundColor:"#0B0D21",
				boxSizing:"border-box",
				color:"#546884",
				fontFamily:"Quicksand",
			},
			appBarShift: {
				marginLeft: drawerWidth,
				width: `calc(100% - ${drawerWidth}px)`,
				transition: theme.transitions.create(['width', 'margin'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
			},
			menuButton: {
				marginRight: 36,
			},
			hide: {
				display: 'none',
			},
			drawer: {
				width: drawerWidth,
				flexShrink: 0,
				whiteSpace: 'nowrap',
				backgroundColor:"#0B0D21",
				boxSizing:"border-box",
				color:"#546884",
				fontFamily:"Quicksand",
				[theme.breakpoints.down("sm")]:{
					display:"none"
				}
			},
			drawerOpen: {
				width: drawerWidth,
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
			},
			drawerClose: {
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				overflowX: 'hidden',
				width: theme.spacing(6) + 1,
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9) + 1,
				},
			},
			toolbar: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				padding: theme.spacing(0, 1),
				// necessary for content to be below app bar
				...theme.mixins.toolbar,
			},
			content: {
				flexGrow: 1,
				padding: theme.spacing(3),
			},
}));
